import { createContext } from "react";

import en from "./en";
import fr from "./fr";

export const TranslationsContext = createContext(null);
export const getStrings = (locale) => {
	if (locale.toLowerCase().includes("fr")) {
		return fr;
	}
	return en;
};
