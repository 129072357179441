import { Link } from "react-router-dom";
import Info from "./info";

const AboutEn = () => {
	return (
		<div className="flex flex-col">
			<p className="mb-4">
				GRANT SOLUTIONS SAS operates under a mandate from LEMONWAY SAS,
				a simplified joint stock company based at 8 rue du Sentier,
				75002 Paris, France. It is registered in the Paris Trade and
				Companies Register (no. 500 486 915) and was authorized as a
				payment institution by the Autorité de Contrôle Prudentiel et de
				Résolution (“ACPR”) on 24 December 2012, under license number
				16568, located at 4 place de Budapest CS 92459, 75436 Paris
			</p>
			<p className="mb-4">
				As an agent for Lemonway SAS, Grant Solutions SAS is listed with
				the ACPR as a payment service provider agent. GRANT is
				accordingly registered in REGAFI, the financial agents'
				directory that includes entities permitted to perform banking,
				electronic money, financial, or payment services in accordance
				under French and European monetary and financial laws. This
				directory is publicly available at{" "}
				<Link className="underline" to="https://www.regafi.fr">
					www.regafi.fr
				</Link>
				.
			</p>
			<hr />
			<Info
				items={{
					"Official company name": "Grant Solutions",
					"Legal Status": "SAS (Société par actions simplifiée)",
					Capital: "49 600 Euros",
					"Registration Date": "26/08/2020",
					"RCS registration, number": "888 360 757 Nanterre",
					"Registered Address":
						"8 Rue de l'Est 92100 Boulogne-Billancourt",
					"‎": "",
					VAT: "FR08888360757",
					Siret: "88836075700015",
					Siren: "888360757",
					DUNS: "274848163",
				}}
			/>
			<br />
			<p>
				Should you require further details or information, please email
				us at{" "}
				<Link className="underline" to="mailto:contact@joingrant.com">
					contact@joingrant.com
				</Link>
			</p>
		</div>
	);
};

export default AboutEn;
