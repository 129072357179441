import { Link } from "react-router-dom";
import config from "../config";

const Button = ({ type = "ios", to = "#" }) => {
	if (type === "ios") {
		return (
			<Link to={config.ios} className="store-button">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					stroke="#ffffff"
					className="icon"
				>
					<title>apple</title>
					<path d="M18.71,19.5C17.88,20.74 17,21.95 15.66,21.97C14.32,22 13.89,21.18 12.37,21.18C10.84,21.18 10.37,21.95 9.1,22C7.79,22.05 6.8,20.68 5.96,19.47C4.25,17 2.94,12.45 4.7,9.39C5.57,7.87 7.13,6.91 8.82,6.88C10.1,6.86 11.32,7.75 12.11,7.75C12.89,7.75 14.37,6.68 15.92,6.84C16.57,6.87 18.39,7.1 19.56,8.82C19.47,8.88 17.39,10.1 17.41,12.63C17.44,15.65 20.06,16.66 20.09,16.67C20.06,16.74 19.67,18.11 18.71,19.5M13,3.5C13.73,2.67 14.94,2.04 15.94,2C16.07,3.17 15.6,4.35 14.9,5.19C14.21,6.04 13.07,6.7 11.95,6.61C11.8,5.46 12.36,4.26 13,3.5Z" />
				</svg>
				<div className="text">App Store</div>
			</Link>
		);
	} else if (type === "android-beta") {
		return (
			<Link to={to} target="_blank" download className="store-button">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					x="0px"
					y="0px"
					width="24"
					height="24"
					viewBox="0 0 30 30"
					fill="#ffffff"
					className="icon"
				>
					<path
						d="M9.7,1.5C9.4,1.5,9.2,1.8,9.2,2c0,0.1,0,0.2,0.1,0.3l1.5,2.1C9.3,5.5,8.3,7.1,8.1,9h13.8
	c-0.3-1.9-1.3-3.5-2.7-4.6l1.5-2.1c0.2-0.2,0.1-0.5-0.1-0.7c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2l-1.5,2.2
	C17.4,3.3,16.2,3,15,3s-2.4,0.3-3.4,0.9l-1.5-2.2C10,1.6,9.8,1.5,9.7,1.5z M12,5c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S11.4,5,12,5
	z M18,5c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S17.4,5,18,5z M5,11c-0.6,0-1,0.4-1,1v8c0,0.6,0.4,1,1,1s1-0.4,1-1v-8
	C6,11.4,5.6,11,5,11z M8,11v10c0,1.1,0.9,2,2,2v3.5c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5V23h4v3.5c0,0.8,0.7,1.5,1.5,1.5
	s1.5-0.7,1.5-1.5V23c1.1,0,2-0.9,2-2V11H8z M25,11c-0.6,0-1,0.4-1,1v8c0,0.6,0.4,1,1,1s1-0.4,1-1v-8C26,11.4,25.6,11,25,11z"
					/>
				</svg>
				<div className="text">Get Android APK</div>
			</Link>
		);
	} else {
		return (
			<Link to={config.android} className="store-button">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					x="0px"
					y="0px"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="#ffffff"
					className="icon"
				>
					<path d="M 0.5 0 C 0.493 0.016 0.50920312 0.02615625 0.53320312 0.03515625 L 0.5 0 z M 5.4160156 2.328125 L 13.296875 10.535156 L 15.626953 8.109375 C 11.611953 5.838375 6.3020156 2.834125 5.4160156 2.328125 z M 3 2.625 L 3 21.332031 L 11.910156 11.978516 L 3 2.625 z M 17.423828 9.1269531 L 14.683594 11.978516 L 17.402344 14.810547 C 18.148344 14.388547 20.345875 13.182516 22.546875 11.978516 C 20.341875 10.755516 18.145828 9.5359531 17.423828 9.1269531 z M 13.296875 13.423828 L 5.4746094 21.566406 C 6.8216094 20.800406 11.797469 17.982172 15.605469 15.826172 L 13.296875 13.423828 z M 0.484375 23.972656 C 0.465375 23.979656 0.45303125 23.988 0.45703125 24 L 0.484375 23.972656 z"></path>
				</svg>
				<div className="text">Play Store</div>
			</Link>
		);
	}
};

export default Button;
