import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const withLayout = (Component, noPadding = false) => {
	return (props) => {
		return (
			<>
				<Navbar />
				<div
					className={` mx-auto ${
						noPadding ? "" : "container px-4 py-4"
					} mt-3`}
				>
					<Component {...props} />
				</div>
				<Footer />
			</>
		);
	};
};

export default withLayout;
