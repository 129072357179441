import { useContext } from "react";
import withLayout from "../withLayout";

import { TranslationsContext } from "../../services/intl";

import ContentEn from "./en";
import ContentFr from "./fr";

const Tutorial = () => {
	const Locale = useContext(TranslationsContext);

	const Content = Locale.getLocale() === "fr" ? ContentFr : ContentEn;

	return (
		<>
			<div className="flex flex-col mt-5">
				<Content />
			</div>
		</>
	);
};

export default withLayout(Tutorial, true);
