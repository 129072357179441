import { useContext } from "react";
import withLayout from "../withLayout";

import { isIOS, isAndroid } from "react-device-detect";
import { TranslationsContext } from "../../services/intl";
import config from "../../config";
import { Link } from "react-router-dom";

const DownloadApp = () => {
	const Locale = useContext(TranslationsContext);

	return (
		<>
			<div className="flex flex-col items-center mt-5">
				<img
					className="h-32 w-32 mb-4"
					src="assets/img/ic_launcher.png"
					alt="app icon"
				/>
				<h1 className="text-center mb-4">
					{Locale.get("download_grant")}
				</h1>
				<p className="text-center text-slate-400 mb-8">
					{Locale.get("get_latest")}
				</p>
				<div className="flex flex-col items-center">
					{isAndroid && (
						<Link
							to={config.android}
							className="w-full flex items-center justify-center"
						>
							<img
								onClick={() => {}}
								style={{
									width: "50%",
									height: "auto",
									marginTop: 10,
									marginBottom: 10,
								}}
								src={"/assets/icons/google-play-badge.png"}
								alt={"Google Play"}
							/>
						</Link>
					)}
					{isIOS && (
						<Link
							to={config.ios}
							className="w-full flex items-center justify-center"
						>
							<img
								onClick={() => {}}
								style={{
									width: "50%",
									height: "auto",
									marginTop: 10,
									marginBottom: 10,
								}}
								src={"/assets/icons/app-store-badge.png"}
								alt={"App Store"}
							/>
						</Link>
					)}
				</div>
			</div>
		</>
	);
};

export default withLayout(DownloadApp);
