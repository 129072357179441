const intl = {
	about: "A propos",
	how_works: "Comment ça marche",
	business: "Pour le business",
	grant_business: "Grant Team (Business)",
	support: "Assistance",
	privacy: "Politique de confidentialité",
	terms: "Termes et conditions",
	title1: () => (
		<>
			Collecte des <span className="text-primary">Pourboires</span>,{" "}
			<span className="text-base">avec un QR-code</span>
		</>
	),
	title2: () => (
		<>
			Dis <span className="text-primary">Merci</span>,{" "}
			<span className="text-base">avec un QR-code</span>
		</>
	),
	content: () => (
		<>
			<p className="text-slate-300">Rejoins la famille Grant en 2'</p>
			<p className="text-slate-300">
				Crée ton QR-code & commence à collecter des pourboires
				instantanément gràce à Grant, la 1<sup>ère</sup> solution de
				pourboire 100% digitale.
			</p>
		</>
	),
	request_delete: "Demander la suppression de mon compte",
	request_delete_text:
		"Vous pouvez demander que nous supprimions les informations personnelles que nous collectons ou conservons à votre sujet. Pour faire une demande de suppression, veuillez nous contacter en utilisant notre bouton d'assistance et demander la suppression des informations personnelles.",
	request_delete_privacy:
		"Pour protéger votre vie privée, nous prendrons des mesures pour vérifier votre identité avant de répondre à votre demande.",
	download_grant: "Télécharger Grant",
	get_latest:
		"Obtenez la dernière version de Grant et commencez à recevoir des pourboires",

	more_about_grant: "EN SAVOIR PLUS SUR LA GRANT",
	legals: "Légal",
	all_rights_reserved: "Tous droits réservés.",
	not_available: "Pas encore disponible",
};

export default intl;
