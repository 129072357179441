import { useContext } from "react";
import withLayout from "../withLayout";

import { TranslationsContext } from "../../services/intl";
import StoreButton from "../../components/StoreButton";

const Home = () => {
	const Locale = useContext(TranslationsContext);
	return (
		<>
			<h1>{Locale.get("title1")()}</h1>
			<h1 className="mt-2">{Locale.get("title2")()}</h1>
			<br />
			<div>{Locale.get("content")()}</div>
			<div className="flex flex-row justify-center mt-5">
				<StoreButton type="android" />
				<StoreButton type="ios" />
			</div>
			<div className="flex flex-row justify-center mt-5">
				<img src="assets/img/showcase.png" alt="Grant app" />
			</div>
		</>
	);
};

export default withLayout(Home);
