import { useContext } from "react";
import withLayout from "../withLayout";

import { TranslationsContext } from "../../services/intl";

import StoreButton from "../../components/StoreButton";

const AndroidPrerelease = () => {
	const Locale = useContext(TranslationsContext);

	return (
		<>
			<div className="flex flex-col items-center mt-5">
				<img
					className="h-32 w-32 mb-4"
					src="assets/img/ic_launcher.png"
					alt="app icon"
				/>
				<h1 className="text-center mb-4">
					Join Beta program
					<br />
					for Grant in Android
				</h1>
				<p className="text-center text-slate-400 mb-12">
					Help us test beta version of Grant and check out the latest
					features. Get the latest apk from the button below.
				</p>
				<StoreButton
					to={`${window.location.origin}/assets/apks/grant-1-0-3.apk`}
					type="android-beta"
				/>
				<div className="text-center text-sm mt-2 text-slate-400">
					Grant v1.0.3
				</div>
			</div>
			<hr className="h-px my-12 bg-stone-600 border-0 w-full" />
			<div className="flex flex-1 flex-col items-start">
				<h1>Getting Started</h1>
				<p>
					Thank you for your interest in testing the beta version of
					Grant. Follow the steps below to get started
				</p>
				<h4 className="mt-8 mb-2">Step 1: Download APK</h4>
				<ul className="ml-4 text-slate-300">
					<li>
						•&nbsp;&nbsp;&nbsp;&nbsp;Click "Get Android APK" button
						above
					</li>
					<li>
						•&nbsp;&nbsp;&nbsp;&nbsp;If prompted, choose where you
						want to save the APK
					</li>
				</ul>
				<h4 className="mt-8 mb-2">Step 2: Download APK</h4>
				<ul className="ml-4 text-slate-300">
					<li>
						•&nbsp;&nbsp;&nbsp;&nbsp;Once the download is complete,
						locate the APK file in your device's Downloads folder or
						notification panel.
					</li>
					<li>
						•&nbsp;&nbsp;&nbsp;&nbsp;Tap on the APK file to start
						the installation process.
					</li>
					<li>
						•&nbsp;&nbsp;&nbsp;&nbsp;If prompted, enable
						installation from unknown sources in your device's
						settings.
					</li>
					<li>
						•&nbsp;&nbsp;&nbsp;&nbsp;Follow the on-screen
						instructions to complete the installation.
					</li>
				</ul>
				<h4 className="mt-8 mb-2">Step 3: Provide Feedback</h4>
				<ul className="ml-4 text-slate-300">
					<li>
						•&nbsp;&nbsp;&nbsp;&nbsp;After installing the beta
						version of Grant, explore its features and
						functionalities.
					</li>
					<li>
						•&nbsp;&nbsp;&nbsp;&nbsp;Test different aspects of the
						app and take note of any bugs, glitches, or
						improvements.
					</li>
					<li>
						•&nbsp;&nbsp;&nbsp;&nbsp;Share your feedback with us
						through our Contact, including details of any issues
						encountered and suggestions for enhancements.
					</li>
				</ul>

				<p className="mt-8">
					If you encounter any difficulties during the installation
					process or have any questions, feel free to reach out to us.
				</p>
				<p className="mt-3">Happy testing!</p>
			</div>
			<hr className="h-px my-12 bg-stone-600 border-0 w-full" />
			<div className="flex flex-1 flex-col items-start">
				<h2 className="mb-8">Changelog</h2>
				<h4 className="mb-3">v 1.0.1</h4>
				<ul className="ml-4 text-slate-300">
					<li>
						•&nbsp;&nbsp;&nbsp;&nbsp;Fixed public profile not
						creation (Black screen after onboarding)
					</li>
				</ul>
				<h4 className="mb-3 mt-3">v 1.0.2</h4>
				<ul className="ml-4 text-slate-300">
					<li>
						•&nbsp;&nbsp;&nbsp;&nbsp;Fixed onboarding stuck problem
						if no profile image was added
					</li>
					<li>
						•&nbsp;&nbsp;&nbsp;&nbsp;Fixed UI Problems that Keyboard
						was sending offscreen
					</li>
				</ul>
				<h4 className="mb-3 mt-3">v 1.0.3</h4>
				<ul className="ml-4 text-slate-300">
					<li>•&nbsp;&nbsp;&nbsp;&nbsp;Fixed login crash problems</li>
					<li>•&nbsp;&nbsp;&nbsp;&nbsp;Fixed minor UI problems</li>
				</ul>
			</div>
		</>
	);
};

export default withLayout(AndroidPrerelease);
