const Content = () => {
	return (
		<div class="c29 doc-content">
			<div>
				<p class="c23 c40">
					<span class="c21"></span>
				</p>
			</div>
			<h1 class="c22 c42" id="h.gjdgxs">
				<span class="c20">
					General Terms and Conditions of Use of the Applications
				</span>
			</h1>
			<p class="c3">
				<span class="c26 c35">26 February 2023</span>
			</p>
			<p class="c3">
				<span>Click here to view the </span>
				<span class="c11 c24">
					<a
						class="c17"
						href="https://www.google.com/url?q=https://joingrant.com/conditions-site/&amp;sa=D&amp;source=editors&amp;ust=1678550564563376&amp;usg=AOvVaw1gA4AYZHJ25xRnEYh7ZOb6"
					>
						Terms of Use of the site (TUSC)
					</a>
				</span>
			</p>
			<p class="c3">
				<span>
					GRANT SOLUTIONS (&quot;GRANT&quot;) is a simplified joint
					stock company with a capital of &euro;49,600, registered in
					the Nanterre Trade and Companies Register under no. 888 360
					757, whose registered office is located at 8 rue de
					l&#39;Est, 92100 Boulogne-Billancourt, France (EU VAT no.{" "}
				</span>
				<span class="c26">FR08888360757</span>
				<span>) and which publishes the </span>
				<span class="c27">
					<a
						class="c17"
						href="https://www.google.com/url?q=http://www.joingrant.com&amp;sa=D&amp;source=editors&amp;ust=1678550564563978&amp;usg=AOvVaw208C3HpGFtvaSCaLwesRcn"
					>
						www.joingrant.com
					</a>
				</span>
				<span>&nbsp;website (hereinafter the &quot;</span>
				<span class="c5">Site</span>
				<span>
					&quot;) and the &quot;grant&quot; and &quot;grant T&quot;
					mobile phone applications (hereinafter the &quot;
				</span>
				<span class="c5">Application(s)</span>
				<span>
					&quot;). If you have any questions, you can contact us via{" "}
				</span>
				<span class="c27">
					<a class="c17" href="mailto:contact@joingrant.com">
						contact@joingrant.com
					</a>
				</span>
				<span class="c2">
					&nbsp;or by telephone on +33 (0)9 77 19 59 12 (price of a
					call according to your telephone operator&#39;s rates).
				</span>
			</p>
			<p class="c3 c31">
				<span class="c2">
					Director of publication: Kersat, President of GRANT.
				</span>
			</p>
			<p class="c3 c25">
				<span class="c2">
					Host: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Google
					Cloud France, 8 rue de Londres, 75009 Paris, tel. 0805 540
					801
				</span>
			</p>
			<p class="c3 c44">
				<span class="c2">
					Hosting in the &quot;europe-west3&quot; data centre in
					Frankfurt, Germany
				</span>
			</p>
			<p class="c3">
				<span>
					The Applications can be used by any natural person who has
					registered as a &quot;
				</span>
				<span class="c5">User</span>
				<span>&quot; and opened a &quot;</span>
				<span class="c5">User Account</span>
				<span>
					&quot;; they provide access to the functionalities of a
					program owned by GRANT and provided in software-as-a-service
					(SaaS) mode via the Internet (the &quot;
				</span>
				<span class="c5">Solution</span>
				<span>
					&quot;). The Users and GRANT are hereinafter collectively
					referred to as the &quot;
				</span>
				<span class="c5">Parties</span>
				<span>&quot; and individually each as a &quot;</span>
				<span class="c5">Party</span>
				<span class="c2">&quot;.</span>
			</p>
			<p class="c3">
				<span>
					Users use the Solution to (i) transmit and receive &quot;
				</span>
				<span class="c5">Grants&quot; </span>
				<span>
					of less than fifty euros (&euro;50) between Users and (ii)
					give a rating to the actions and services of other Users to
					whom they have transmitted one or more Grants (hereinafter
					the &quot;
				</span>
				<span class="c5">Services&quot;</span>
				<span class="c2">).</span>
			</p>
			<p class="c3">
				<span class="c5">
					In order to provide these Services on the Solution, GRANT is
					mandated by the following payment service provider:
				</span>
			</p>
			<p class="c3">
				<span>PSP: </span>
				<span class="c5">Lemonway</span>
				<span>
					, a simplified joint stock company whose registered office
					is located at 8 rue du Sentier, 75002 Paris, France,
					registered in the Paris Trade and Companies Register under
					number 500 486 915 (
				</span>
				<span class="c5">&quot;Lemonway&quot;</span>
				<span>
					), approved on 24 December 2012 by the Autorit&eacute; de
					Contr&ocirc;le Prudentiel et de R&eacute;solution
					(&quot;ACPR&quot;, France, website acpr.banque-france.fr/),
					4 place de Budapest CS 92459, 75436 Paris, as a payment
					institution, under number 16568 and whose terms and
					conditions of use of services are available{" "}
				</span>
				<span class="c11">
					<a
						class="c17"
						href="https://www.google.com/url?q=https://www.lemonway.com/conditions-generales-dutilisation/&amp;sa=D&amp;source=editors&amp;ust=1678550564566206&amp;usg=AOvVaw1VAtKXL74R9B1pS_zPC_w4"
					>
						here
					</a>
				</span>
				<span class="c2">.</span>
			</p>
			<p class="c3">
				<span>
					To act as LEMONWAY&#39;s agent, GRANT is registered as an
					agent of payment service providers (&quot;
				</span>
				<span class="c5">APSP</span>
				<span>
					&quot;) under the number [94455], on [20/09/2021], with the
					Autorit&eacute; de Contr&ocirc;le Prudentiel et de
					R&eacute;solution (&quot;
				</span>
				<span class="c5">ACPR&quot;</span>
				<span>
					), established at 4 Place de Budapest - 75436 Paris Cedex
					09. GRANT is registered as such in the register of financial
					agents available at{" "}
				</span>
				<span class="c27">
					<a
						class="c17"
						href="https://www.google.com/url?q=https://www.regafi.fr/spip.php?page%3Dresults%26type%3Dsimple%26id_secteur%3D1%26lang%3Dfr%26denomination%3DLemonway&amp;sa=D&amp;source=editors&amp;ust=1678550564566931&amp;usg=AOvVaw07u3EDe1_9LGj__fY8Eau1"
					>
						https://www.regafi.fr/spip.php?page=results&amp;type=simple&amp;id_secteur=1&amp;lang=fr&amp;denomination=Lemonway
					</a>
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					If GRANT no longer meets the conditions for registration
					with the ACPR, Users will be informed within fifteen (15)
					days of receipt by GRANT of any notification from the ACPR
					or LEMONWAY.
				</span>
			</p>
			<p class="c3">
				<span>
					These general terms and conditions of use of the
					Applications (&quot;
				</span>
				<span class="c5">GTCUA&quot;</span>
				<span class="c2">
					) determine the conditions of registration and use of the
					Solution. Your use of the Solution is subject to your
					acceptance of these GCUA. You may keep a copy of the GCUA on
					your computer or on any other medium, in particular by
					printing it out to keep a hard copy.
				</span>
			</p>
			<h2 class="c6" id="h.30j0zll">
				<span class="c10">Article 1. Definitions</span>
			</h2>
			<p class="c3 c22">
				<span class="c2">
					Terms beginning with a capital letter, used in the singular
					or plural, have the meaning given below.
				</span>
			</p>
			<ol class="c12 lst-kix_list_6-0 start" start="1">
				<li class="c19 c22 li-bullet-0">
					<span class="c5">Application</span>
					<span class="c2">
						: The &quot;Grant&quot; and/or &quot;Grant T&quot;
						applications that can be downloaded from the App Store
						and Google Store and allow any User to access their User
						Account and use the Solution&#39;s Services. Each
						Application can only be used by one type of User:
						&quot;Grant&quot; by Issuers, &quot;Grant T&quot; by
						Recipients.
					</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">ACPR</span>
					<span class="c2">
						: L&#39;Autorit&eacute; de Contr&ocirc;le Prudentiel et
						de R&eacute;solution, established at 4 Place de Budapest
						- 75436 Paris Cedex 09.
					</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">Anomalies </span>
					<span class="c2">
						: Reproducible malfunction, slowdown or unavailability
						of the Solution, not attributable to a Maintenance
						Exclusion Event.
					</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">APSP</span>
					<span class="c2">
						: An agent of payment service providers, e.g. GRANT, as
						defined in Articles L. 523-1 et seq. of the Monetary and
						Financial Code.
					</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">CGUA</span>
					<span class="c2">
						: The present general conditions of use and their
						annexes.
					</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">CGUS</span>
					<span class="c2">
						: The general conditions of use of the Site.
					</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">QR Code</span>
					<span class="c2">
						: A QR Code is a two-dimensional barcode, consisting of
						dark and light coloured square modules. A QR Code can be
						(i) a QR Code permanently associated with a Recipient
						(&quot;QRL&quot;) as long as the Recipient satisfies the
						KYC Procedures or (ii) a QR Code associated with each
						Grant issued by an Issuer on the Solution
						(&quot;QRG&quot;). A QR Code must be scanned by a User
						via his mobile phone in order to perform an action
						and/or a Service on the Solution.
					</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">User Account</span>
					<span class="c2">
						: Account allowing access via Identifiers to the
						Solution made available to the User and enabling him (i)
						to use the Solution and (ii) to order and/or benefit
						from the Services. The User Account is strictly personal
						and exclusive to the User, non-transferable and
						non-transferable. It is connected by the User to his
						bank account and contains a history of Services and
						Ratings.
					</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">Content</span>
					<span class="c2">
						: The Site and each of its component parts (such as all
						texts, decision-making processes, software, animations,
						photographs, illustrations, images, diagrams,
						soundtracks, texts, logos, trademarks, designs and
						models), including the software elements necessary for
						the operation of the Site, trademarks registered and/or
						owned by GRANT, databases, e-mails and/or the newsletter
						sent automatically to Users.
					</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">Documentation</span>
					<span class="c2">
						: The standard documentation of the Solution made
						available on the Applications by GRANT, in French and
						presenting the various Services. The Documentation is
						attached as Annex 1 to the GCUA and forms part of the
						contractual documents.
					</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">Data</span>
					<span class="c2">
						: Data, files, information created, collected, acquired,
						aggregated and/or hosted by or on behalf of the User as
						part of his activity or given to GRANT as part of the
						provision of the Services, excluding GRANT data. The
						Data uploaded by the User is strictly linked to his
						activity on the Solution and must respect the terms of
						the GCUA: the User guarantees that no sensitive data, in
						particular health data, is part of the Data. The Data
						includes Personal Data and payment data within the
						meaning of the applicable law.
					</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">Personal Data</span>
					<span class="c2">
						: Any data that directly or indirectly identifies a
						natural person as defined by Article 4 of the GDPR.
					</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">Issuer</span>
					<span class="c2">
						: User creating and awarding a Grant to a Recipient via
						the Application or a landing page to the Solution. After
						the creation of the Grant on his Application, the Issuer
						must show the CQRG displayed on his mobile phone to the
						Recipient so that the latter can scan it via his mobile
						phone and receive the amount of the said Grant.
					</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">Fees or Transaction Fees</span>
					<span class="c2">
						: The fees in euros (including VAT) paid by the User and
						added to the amount of the Grant at the time it is
						withdrawn. They represent the sum of commissions and
						costs charged by LEMONWAY for the transfer of the Grant
						and the operating costs of GRANT (management of Grants,
						monitoring of transactions, interface between banks and
						Users, etc.). Additional charges may be applied by third
						parties, not included in the Fees, in particular roaming
						charges levied separately by the Users&#39; telephone
						operators according to the mobile subscriptions of the
						Users of the Applications or exchange charges applied by
						the Users&#39; banking institutions when they do not use
						euro accounts.
					</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">Grant </span>
					<span class="c2">
						: A tip paid between Users on the Solution, the amount
						of which is chosen from a predefined list of &euro;1 to
						&euro;50 maximum (&euro;1, &euro;2, &euro;5, &euro;10,
						&euro;15, &euro;20, &euro;30, &euro;40, &euro;50). This
						Grant must be associated with a Rating.
					</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">Identifiers</span>
					<span class="c2">
						: The User&#39;s login and password, which gives him/her
						access to his/her User Account on the Solution where
						he/she can connect his/her bank account and use any
						Service.
					</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">Infrastructure</span>
					<span class="c2">
						: Hardware and software infrastructure used by GRANT via
						a subcontracted hosting provider to provide the Site,
						the Solution and the Services. The hosting provider is a
						third party who is solely responsible for the quality of
						the Infrastructure.
					</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">Maintenance</span>
					<span class="c2">
						: Service of restoration or correction of possible
						Anomalies of the Solution.
					</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">Update </span>
					<span class="c2">
						: Any evolution, update, upgrade or new functional or
						technical version of the Solution implemented by GRANT,
						and automatically deployed on the Applications for all
						GRANT Users.
					</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">Rating</span>
					<span class="c2">
						: The service whereby the Issuer gives the Recipient a
						rating in the form of a positive (thumbs up) or negative
						(thumbs down) opinion. It is not possible to rate
						Recipients without having sent them Grants (although the
						Grant amount may be set to 0&euro;). Each Recipient has
						access, from their User Account, to (i) a history of the
						Ratings awarded for each Grant and (ii) a total average
						Rating (as a percentage of positive Ratings out of the
						total).
					</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">Services</span>
					<span class="c2">
						: The services provided by GRANT to the User pursuant to
						the GTUA, including (i) transmitting and receiving
						Grants of less than fifty euros (&euro;50) between Users
						and (ii) awarding a Rating.
					</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">KYC procedures</span>
					<span class="c2">
						: The &quot;Know Your Customer&quot; procedures for
						verifying the identity and transactions of Users as
						imposed by the law applicable to payment providers
						(including GRANT as APSP and LEMONWAY as PSP) in order
						to combat corruption, money laundering and terrorist
						financing.
					</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">Recipient</span>
					<span class="c2">
						: User who can credit Grants via the &quot;Grant T&quot;
						Application and who must (i) scan the CQRG generated and
						issued by the Issuer&#39;s Application with his mobile
						phone and (ii) connect a bank account to the Application
						in compliance with the verification and anti-money
						laundering procedures.
					</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">GDPR</span>
					<span class="c2">
						: General Data Protection Regulation No. 2016/679 of 27
						April 2016
					</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">Site</span>
					<span>: The GRANT website </span>
					<span class="c11">
						<a
							class="c17"
							href="https://www.google.com/url?q=https://joingrant.com&amp;sa=D&amp;source=editors&amp;ust=1678550564573685&amp;usg=AOvVaw3jWKLWqhQwtx7BbwJ87Cgn"
						>
							www.joingrant.com
						</a>
					</span>
					<span class="c2">&nbsp;which Visitors can access.</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">Solution</span>
					<span class="c2">
						: The GRANT software as a service available on the
						Applications, as well as its maintenance and support.
						The User accesses the Solution via APIs, software
						libraries or synchronisation plugins.
					</span>
				</li>
				<li class="c18 li-bullet-0">
					<span class="c5">Users</span>
					<span class="c2">
						: Any natural person who uses the Services offered by
						the Solution in application of the GCUA, either from an
						Application and their User Account, or from a landing
						page.
					</span>
				</li>
				<li class="c4 c22 li-bullet-0">
					<span class="c5">Visitors</span>
					<span class="c2">
						: Any natural or legal person accessing the Site.
					</span>
				</li>
			</ol>
			<h2 class="c6" id="h.1fob9te">
				<span class="c10">Article 2. Acceptance</span>
			</h2>
			<p class="c3">
				<span class="c2">
					The User declares that he/she has taken note of and
					acknowledges :
				</span>
			</p>
			<ul class="c12 lst-kix_list_7-0 start">
				<li class="c1 li-bullet-0">
					<span class="c2">
						that they have received from GRANT all the information
						and recommendations necessary to assess whether the
						Solution and the Services meet their needs;
					</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">
						that he/she has read and accepted the terms and
						conditions of the GCUA;
					</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">
						that the Services are provided remotely with no
						guarantee or obligation of availability;
					</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">
						that it has ensured compliance with the prerequisites
						necessary for the use of the Solution, in particular
						those listed in the &quot;Prerequisites&quot; section
						below; and
					</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">
						that he/she has all the necessary skills to access and
						use the Solution and the Services in optimal conditions.
					</span>
				</li>
			</ul>
			<p class="c3">
				<span class="c2">
					The use of the Applications (and therefore of the Solution
					and the Services) is conditional upon downloading the
					Applications and creating a User Account. When creating a
					User Account, you will be expressly asked to formally accept
					these GCUA via a superimposed page containing the entire
					GCUA, by clicking on the &quot;Accept&quot; button. If you
					access the Solution via a landing page, you must formally
					accept these GCUA by ticking the box to this effect on the
					form.
				</span>
			</p>
			<p class="c3">
				<span class="c5">
					It is expressly stated that the acceptance of the GTUA
					implies the acceptance of the terms of use of Lemonway which
					ensures the proper functioning of the payment system offered
					by the Solution and available at{" "}
				</span>
				<span class="c11 c5">
					<a
						class="c17"
						href="https://www.google.com/url?q=https://www.lemonway.com/conditions-generales-dutilisation/&amp;sa=D&amp;source=editors&amp;ust=1678550564575912&amp;usg=AOvVaw0Kzt3c1eM6Foz1vkQ07SqL"
					>
						this address
					</a>
				</span>
				<span class="c5 c26 c34">.</span>
			</p>
			<p class="c3">
				<span class="c2">
					The acceptance of the GCUA is complete and forms an
					indivisible whole. The Parties may not derogate from them or
					express reservations and may not decide on a partial
					application. The concluded GTUA remain available at any time
					on the User&#39;s Account.
				</span>
			</p>
			<h2 class="c6" id="h.3znysh7">
				<span class="c10">Article 3. Contractual documents</span>
			</h2>
			<p class="c3">
				<span class="c2">
					GRANT may modify the GCUA at any time, including its
					appendices, in order to update them and in particular to
					take into account any legal, jurisprudential, editorial or
					technical evolution. The previous GTUA will then be
					automatically terminated and replaced by the new version of
					the GTUA, mentioning the date of the last update at the top
					of the GTUA.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The version of the GTUA applicable to Users is the latest
					version formally accepted by the User when accessing the
					Solution. The User may at any time refuse the new version of
					the GCUA by cancelling his User Account and uninstalling the
					Application or by leaving the landing page.
				</span>
			</p>
			<p class="c3">
				<span>
					The GTUA are composed, indivisibly and in decreasing order
					of contractual priority, of (i) the present GTUA and (ii)
					its appendices, in particular the documentation of the
					Solution, the privacy policy as well as the cookies policy
					of GRANT and (iii) the terms of use of LEMONWAY available at{" "}
				</span>
				<span class="c38">
					<a
						class="c17"
						href="https://www.google.com/url?q=https://www.lemonway.com/conditions-generales-dutilisation/&amp;sa=D&amp;source=editors&amp;ust=1678550564576909&amp;usg=AOvVaw1hYDF7Pry5xVKblTF6Ji3S"
					>
						this address
					</a>
				</span>
				<span class="c2">
					. In case of contradiction, ambiguity or difference of
					interpretation between two contractual documents, the
					higher-ranking document prevails over the lower-ranking
					document. If it occurs between two contractual documents of
					the same rank, the latest one prevails over the least recent
					one.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					These contractual documents form a whole and constitute the
					entire relationship between Users and GRANT, to the
					exclusion of any other document; they cancel and replace all
					previous documents relating to the same subject.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					Rejection of the new GTUA is equivalent to termination of
					the GTUA, which will remain in force in their last accepted
					version for the duration of the closure of the User Account.
				</span>
			</p>
			<h2 class="c6" id="h.2et92p0">
				<span class="c10">Article 4. Duration</span>
			</h2>
			<p class="c3 c22">
				<span class="c2">
					The GCUA is entered into between GRANT and the User from the
					time of registration for the duration of the use of the User
					Account, which shall end in the event of (i) termination of
					the GCUA by GRANT for fault of the User, (ii)
					de-registration of the User at any time or (iii) by default,
					at the end of a period of three years from the last active
					contact between the User and GRANT.
				</span>
			</p>
			<p class="c3">
				<span class="c43">
					The User may terminate the GTUA at any time from his User
					Account.
				</span>
				<span class="c2">
					&nbsp;In the event of termination for any reason whatsoever,
					the Parties shall apply the article &quot;Termination&quot;
					below.
				</span>
			</p>
			<h2 class="c6" id="h.tyjcwt">
				<span class="c10">Article 5. Requirements for use</span>
			</h2>
			<p class="c3">
				<span class="c2">
					Users may access and use the Solution provided that they (i)
					have the legal capacity to enter into this GCUA and to use
					the Services and (ii) have the appropriate computer
					equipment to download and use the Application, in accordance
					with the technical and network connection requirements
					listed by GRANT in the Solution Documentation attached as
					Appendix 3 to this GCUA The Parties are solely responsible
					for the hardware, software and Internet connection required
					to access and use the Applications and the Solution. Each
					party shall bear the related costs, including any
					communication costs, which it shall bear as a result.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					Any natural person may register and become a User via the
					User Account opening procedure, provided that they enter a
					valid e-mail address, (iii) providing a bank account number
					(RIB) and/or bank card details (CB) for a bank account (the
					User may only provide a bank card in his or her own name)
					and (iv) not using the Solution to issue or receive Grants
					from funds whose source does not comply with applicable law
					(in particular with regard to criminal law and money
					laundering or concealment).
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The creation of a User Account is free of charge, subject to
					the formal acceptance of the GTUA when installing the
					Application or via a checkbox on the landing pages.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					All Users must respect these prerequisites under their sole
					responsibility in order to access and use the Solution. In
					particular, your access to the Internet, the compatibility
					and proper functioning of your information system (hardware,
					software, firewall, technical protection and network
					connections) with the Solution remain at all times under
					your sole responsibility. GRANT accepts no responsibility or
					guarantee in this respect, particularly in the event of
					incompatibility or malfunction of your information system
					with the Solution.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					If the User&#39;s information system is incompatible, the
					Solution cannot be provided and GRANT shall have no
					obligation whatsoever. &nbsp;Similarly, if the User uploads
					the RIB or the credit card linked to his bank account in a
					format not recognised by the Solution, GRANT shall not be
					able to provide the Services in relation to the Grants and
					shall not be held liable for any obligation in this respect.
					If the User modifies any part of his initial installation,
					user configuration and/or information system during the term
					of the GCUA, GRANT shall not be liable or responsible for
					any problems of access and use of the Solution resulting
					from this modification.
				</span>
			</p>
			<h2 class="c6" id="h.3dy6vkm">
				<span class="c10">Article 6. User account</span>
			</h2>
			<h3 class="c9" id="h.1t3h5sf">
				<span class="c0">6.1 Creating a User Account</span>
			</h3>
			<p class="c3 c22">
				<span class="c2">
					To access and use the Solution, you must first register and
					create a User Account with GRANT. As an APSP, GRANT is
					subject to due diligence obligations before creating a User
					Account, particularly for Recipients: therefore, the
					creation of the User Account is subject to several
					conditions, including the provision of three pieces of
					evidence:
				</span>
			</p>
			<ul class="c12 lst-kix_list_3-0 start">
				<li class="c19 li-bullet-0">
					<span class="c2">
						Valid identity document (CNI, passport, residence
						permit) ;
					</span>
				</li>
				<li class="c30 li-bullet-0">
					<span class="c2">
						For recipients only, a second proof of identity (second
						identity card, tax notice, driving licence, family
						record book, birth certificate or marriage certificate);
						and
					</span>
				</li>
				<li class="c4 li-bullet-0">
					<span class="c2">
						A mobile phone number and an email address so that we
						can send you the confirmation codes needed to ensure the
						security of the Applications.
					</span>
				</li>
			</ul>
			<p class="c3">
				<span class="c2">
					In order to use the Services of the Solution, the User must
					also provide the bank account details (RIB) or the bank card
					details (CB) of the bank account associated with his User
					Account. Only one bank account may be associated with a User
					Account, whatever it may be. If the associated bank account
					is in currencies other than the euro, the User&#39;s credit
					institution may charge a foreign exchange fee when the User
					issues or receives Grants on his User Account. The validity
					of this bank account will be checked by LEMONWAY before
					generating a LQRL; the LQRL will be renewed each time the
					bank account is changed.
				</span>
			</p>
			<p class="c3">
				<span>
					Once the registration form has been completed and validated,
					you will receive an SMS code to check the validity of the
					telephone number you have entered. By copying this sms code,
					you will be able to finalise the creation of your User
					Account and choose your Identifiers.
				</span>
			</p>
			<p class="c3">
				<span>
					The provision of this information may give rise to a KYC
					Procedure. On the other hand, the optional Data (username,
					profile picture, etc.) of the User Account are not subject
					to KYC procedures.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					Upon completion of the Recipient registration process,
					Recipients will have access to their QRLs, which they can
					show to Issuers from any terminal, but which they can also
					print on any tangible medium (business card, sticker,
					T-shirt, menu, etc.).
				</span>
			</p>
			<h3 class="c9" id="h.4d34og8">
				<span class="c0">6.2 KYC procedures</span>
			</h3>
			<p class="c3">
				<span class="c2">
					In the context of the fight against terrorism, corruption
					and money laundering, GRANT must verify the identity of
					Users and the nature of transactions carried out via User
					Accounts. Consequently, the KYC Procedure carried out at the
					time of opening the User Account in order to verify the
					sincerity of the supporting documents and the Data entered
					by the User on his User Account must be periodically
					renewed. The User thus undertakes to provide promptly the
					updated documents requested by GRANT. If necessary, GRANT
					may suspend the performance of the Services as a result of
					these KYC Procedures, which the User acknowledges and
					accepts.
				</span>
			</p>
			<p class="c3">
				<span class="c34 c5 c26">
					In particular, the identity of the User on the IBAN, the RIB
					or the credit card details must be the same as that entered
					when the User Account was created.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					In particular, GRANT may trigger a KYC Procedure when any
					User Account is created, when a Grant is first issued by an
					Issuer and/or when a Recipient first transfers the amount of
					Grants credited to their User Account. Any change in your
					User Account Data, including any change in the bank account
					associated with the User Account, may trigger the KYC
					Procedures.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					GRANT may ask you to update the supporting documents
					provided at any time and restrict or suspend access to and
					use of your User Account pending this. In this respect,
					GRANT informs you that the production of false documents or
					proofs by the User when creating the User Account
					constitutes documentary fraud which may result in a
					declaration of suspicion being sent to the competent
					administrative authorities.
				</span>
			</p>
			<h3 class="c9" id="h.2s8eyo1">
				<span class="c0">6.3 User Account credentials</span>
			</h3>
			<p class="c3">
				<span class="c2">
					Identifiers are strictly personal and confidential to each
					User; they may not be shared between Users and/or passed on
					to a third party. Any loss or theft of Identifiers must be
					communicated to GRANT as soon as possible and the User must
					immediately change the Identifiers concerned.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The User understands and acknowledges that the integrity,
					availability and confidentiality of the Data uploaded to the
					Solution depend on the confidentiality of his/her
					Credentials. If a third party accesses your User Account
					and/or its settings, that person may do anything that you
					may do, including transmitting any Grant, making any
					representation or giving any warranty, and such actions
					shall be deemed to have been taken in your name and on your
					behalf.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					Therefore, we strongly recommend that you keep your User
					Account Credentials confidential and do not allow others to
					access them, as you will be fully responsible for all
					actions taken through your User Account, whether or not you
					have expressly authorized it, and for any damages, costs or
					losses that may result from such actions. Any access, action
					and/or use of the Solution by a User through a User Account
					is made under the sole responsibility of the User, including
					in case of accidental disclosure of the Identifiers and/or
					any subsequent use of these Identifiers by an unauthorized
					third party.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					GRANT excludes any liability or guarantee in the event of
					the User failing to comply with the conditions relating to
					his Identifiers and the User shall in particular be liable
					for any loss or deterioration of Data, any infringement of
					intellectual property rights or know-how and in general for
					any damage suffered by the Parties. If necessary, GRANT may
					invoice the User for any repairs or checks carried out in
					the event of fraudulent introduction or use of the Solution
					by an unauthorised third party.
				</span>
			</p>
			<h3 class="c9" id="h.17dp8vu">
				<span class="c0">6.4 Restrictions on User Accounts</span>
			</h3>
			<p class="c3">
				<span class="c2">
					A User may only have one User Account, which is strictly
					personal, individual, non-assignable and non-transferable.
					The User accesses the Solution via the Internet connection
					of the User&#39;s terminals and no Data or functionality of
					the Solution will be hosted or accessible directly on the
					User&#39;s terminals.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					You agree (i) not to have another User Account and not to
					create another User Account in the future, (ii) to check the
					automatically filled-in User Account fields and to complete
					the other fields in good faith, providing accurate
					information and filling in all fields marked as mandatory,
					(iii) not to provide a false identity or the identity of a
					third party or to impersonate a third party and (iv) not to
					mislead GRANT or any third party about your identity. GRANT
					shall not be held responsible for out-of-date, misleading,
					invalid or erroneous information, including in the event of
					a change in the User&#39;s circumstances which the User does
					not reflect in their Customer Account. Any change of
					situation may give rise to a KYC procedure.
				</span>
			</p>
			<p class="c3">
				<span>
					GRANT may refuse the registration of Users located outside
					the European Union, in particular with regard to the
					European Commission&#39;s blacklist, the list of
					non-cooperative countries in tax matters and the lists of
					countries under international sanctions (restrictive
					measures, economic sanctions, embargoes, etc.) (the &quot;
				</span>
				<span class="c5">Excluded Territories&quot;)</span>
				<span class="c2">
					, and/or refuse to allow the User to attach to his/her User
					Account a bank account located in these Excluded Territories
					or opened with financial institutions belonging in whole or
					in part to citizens or entities of these Excluded
					Territories. The restrictions relating to the Excluded
					Territories are necessary to satisfy a legal obligation by
					exception to Article L. 121-23 of the Consumer Code.
				</span>
			</p>
			<h3 class="c9" id="h.3rdcrjn">
				<span class="c0">6.5 Suspension of the User Account</span>
			</h3>
			<p class="c3">
				<span class="c2">
					The User Account is an automated data processing system. Any
					fraudulent access to the latter is prohibited and punishable
					by law. GRANT and/or LEMONWAY may take any emergency measure
					necessary for the security of a User Account and/or the
					Solution. GRANT and/or LEMONWAY may suspend, freeze or close
					access to all or part of your User Account (and therefore to
					all or part of the Solution) at any time, in particular in
					the event of suspected fraud, identity theft or security
					breach.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The User Account is deemed to be created by the User through
					any person with apparent authority to bind and represent the
					User. You may notify GRANT and/or LEMONWAY at any time if
					you believe that your User Account may be subject to
					fraudulent use by a third party by contacting GRANT at
					dpo@joingrant.com.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					In the event of a dispute over the ownership of a User
					Account, we reserve the right to determine the ownership of
					a User Account in our discretion and to suspend a User
					Account until the parties to the dispute have reached a
					resolution of the relevant dispute, without GRANT and/or
					LEMONWAY incurring any liability to you or any other party
					in doing so. We may request any documents from you that may
					enable us to determine the ownership of the User Account;
					failing which, GRANT and/or LEMONWAY shall be entitled to
					suspend all or part of the disputed User Account.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					You agree not to collect and/or use login data and/or
					passwords of third parties on the Applications, the Solution
					as well as for other websites, software or services.
				</span>
			</p>
			<p class="c3">
				<span>
					The User is informed that their User Account may be
					suspended in the event that LEMONWAY blocks or freezes the
					bank account used for payments, in accordance with its terms
					and conditions available{" "}
				</span>
				<span class="c11">
					<a
						class="c17"
						href="https://www.google.com/url?q=https://www.lemonway.com/conditions-generales-dutilisation/&amp;sa=D&amp;source=editors&amp;ust=1678550564583192&amp;usg=AOvVaw2RdErPDLYmoGZ42pCZcTnN"
					>
						here
					</a>
				</span>
				<span class="c2">
					. GRANT will inform the User if this is the case.
				</span>
			</p>
			<h2 class="c6" id="h.26in1rg">
				<span class="c10">
					Article 7. Conditions for the provision of the Services
				</span>
			</h2>
			<p class="c3">
				<span class="c2">
					GRANT never collects money directly in the name and on
					behalf of the Users, but is mandated as an APSP by LEMONWAY,
					which carries out these transactions as a PSP. GRANT credits
					the transactions carried out by LEMONWAY directly to the
					User Accounts of the Applications.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					In the event that LEMONWAY makes major changes that affect
					or significantly modify the operation of its payment service
					and consequently the Services, GRANT will inform the User
					within a reasonable period of time following the
					notification made by LEMONWAY. If the User does not accept
					these changes, he/she may exercise the right to terminate
					the contract.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					Each User must use the Solution in a responsible manner and
					respect the rights of GRANT and third parties. By accessing
					the Solution via his User Account, the User undertakes and
					guarantees in particular (i) to respect the stipulations of
					the GCUA and the applicable law, (ii) to communicate
					accurate, exhaustive and up-to-date Data, (iii) to respect
					the rights of GRANT and third parties and (iv) to use the
					Solution solely for the purposes set out in the
					Documentation, on a user configuration that meets
					GRANT&#39;s recommendations, and that accepts the cookies
					used by the Solution.
				</span>
			</p>
			<h3 class="c9" id="h.lnxbz9">
				<span class="c0">7.1 Access and use of the User Account</span>
			</h3>
			<p class="c3 c22">
				<span class="c2">
					The use of the Solution and the Services is conditional on
					the prior creation of a User Account, free of charge, in
					application of the &quot;User Account&quot; article of the
					GTUA above.
				</span>
			</p>
			<p class="c3 c22">
				<span class="c2">
					Once the User Account has been created, the User may pay or
					receive Grants and rate the actions, products and services
					of other Users with whom he has interacted in accordance
					with these GCUA. In order to ensure the security of online
					payments, the User must be permanently logged into their
					User Account via their Login and Password (except in the
					case of one-off use of the Solution by an Issuer via a
					landing page) and generate and/or scan a QR Code in order to
					send and/or receive a Grant. GRANT may ask you to re-enter
					your Identifiers when validating the transmission of a
					Grant.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					Depending on whether the User is an Issuer or a Recipient,
					he/she accesses his/her User Account via one of the
					Applications with features and interfaces specific to
					his/her use of the Solution and the Services as an Issuer or
					a Recipient. His User Account and KYC Procedures may also
					vary according to this capacity. It is not possible for a
					User to be an Issuer and a Recipient.
				</span>
			</p>
			<p class="c3 c22">
				<span>
					GRANT provides each User with access to a history of Grants
					issued or received in their User Account, including details
					of the relevant transactions and payments,{" "}
				</span>
				<span class="c26">
					without any right of modification, as well as any associated
					Ratings and acknowledgements. Users may download these
					histories and reports and store them on a separate durable
					medium. Ratings are listed in the history by date of award.
					If a User has first passed through a landing page without
					creating a User Account, Grants created or credited before
					the creation of the User Account via these landing pages are
					not included in the history of the User Account.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The Recipients&#39; User Account includes a balance equal to
					the total amount of Grants credited to the User Account, as
					well as a button to trigger the transfer of these amounts to
					the Recipient&#39;s bank account, under the conditions
					specified in the article &quot;Cashing out Grants&quot;
					below.
				</span>
			</p>
			<h3 class="c9" id="h.35nkun2">
				<span class="c0">7.2 Conditions for Grants</span>
			</h3>
			<p class="c3">
				<span class="c2">
					While the User may update his or her User Account at any
					time, a User&#39;s first Grant must be issued from or cashed
					into a bank account opened in the User&#39;s name within a
					Member State of the European Union.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					Users may not pay Grants for any fraudulent or illegal
					activity, nor for the following activities:
				</span>
			</p>
			<h4 class="c15" id="h.1ksv4uv">
				<span class="c32">
					Participatory financing (donation, loan, investment)
				</span>
			</h4>
			<ul class="c12 lst-kix_list_4-0 start">
				<li class="c22 c28 li-bullet-0">
					<span class="c2">
						Extremist / ultra-radical / dissident / propaganda
						movements ;
					</span>
				</li>
				<li class="c8 li-bullet-0">
					<span class="c2">Sects ;</span>
				</li>
				<li class="c8 li-bullet-0">
					<span class="c2">
						Promotion of causes close to prohibited activities (e.g.
						legalization of drugs);
					</span>
				</li>
				<li class="c22 c36 li-bullet-0">
					<span class="c2">Financing of criminal fines ;</span>
				</li>
				<li class="c13 li-bullet-0">
					<span>Investments in diamonds or rare earths.</span>
				</li>
			</ul>
			<h4 class="c15" id="h.44sinio">
				<span class="c32">Sale of products</span>
			</h4>
			<ul class="c12 lst-kix_list_4-0">
				<li class="c13 li-bullet-0">
					<span>
						Sale of narcotics and cannabis products (e.g.
						cannabidiol);
					</span>
				</li>
				<li class="c13 li-bullet-0">
					<span>
						Sale of hi-tech and/or high-end products at knock-down
						prices (e.g. iPhone for 1 euro);
					</span>
				</li>
				<li class="c13 li-bullet-0">
					<span>Sale of alcohol without a licence ;</span>
				</li>
				<li class="c13 li-bullet-0">
					<span>Sale of sex toys ;</span>
				</li>
				<li class="c13 li-bullet-0">
					<span>Tobacco sales ;</span>
				</li>
				<li class="c13 li-bullet-0">
					<span>Sale of e-cigarettes ;</span>
				</li>
				<li class="c13 li-bullet-0">
					<span>Sale of hacking devices / Spyware ;</span>
				</li>
				<li class="c13 li-bullet-0">
					<span>Sale of counterfeit goods ;</span>
				</li>
				<li class="c13 li-bullet-0">
					<span>Sale of weapons, airsoft or dummy weapons.</span>
				</li>
			</ul>
			<h4 class="c15" id="h.2jxsxqh">
				<span class="c32">Sale of services</span>
			</h4>
			<ul class="c12 lst-kix_list_8-0 start">
				<li class="c1 li-bullet-0">
					<span class="c2">Accessibility diagnostics</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">Voyance ;</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">Astrology ;</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">Dating sites ;</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">Unauthorised streaming sites;</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">
						Pornographic sites (services, videos) ;
					</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">Charming sites ;</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">Escort services ;</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">Issuance of prepaid cards ;</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">File sharing sites ;</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">Trading site or FOREX ;</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">
						Cryptocurrency/crypto-asset activity;
					</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">Bonding by card ;</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">Virtual private network (VPN) ;</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">
						Debt collection on behalf of third parties ;
					</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">Sites promoting terrorism ;</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">Proselytizing sites ;</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">Bailiffs&#39; offices ;</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">Casinos ;</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">
						Online gambling (sports betting, poker) ;
					</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">Selling followers ;</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">Lotteries.</span>
				</li>
			</ul>
			<p class="c3">
				<span class="c2">
					GRANT reserves the right (i) to transmit, at its discretion,
					any information likely to be illegal or fraudulent to the
					competent authorities, without notifying the User, in
					particular the amount and number of Grants collected and
					paid in the event of a suspicious transaction report or at
					the request of the tax authorities, the administrative
					authorities and/or the competent courts (ii) in the same
					situations, to take any measure, including the sequestration
					of funds that GRANT legitimately suspects are linked to a
					potentially illicit or fraudulent activity, the triggering
					of a KYC Procedure or the compulsory provision of supporting
					documents or additional information, the suspension of the
					execution of Grants (issue or collection) or the
					transmission of funds to the competent authority. If GRANT
					identifies that Grants (iii) are transmitted to or from
					Excluded Territories, (iv) characterise, by their repetition
					or amount, a repatriation of funds and/or (v) meet one of
					the criteria set out in Articles L. 561-15 and D 561-32-1 of
					the French Monetary and Financial Code, GRANT and its
					payment service provider LEMONWAY are obliged to file a
					suspicious transaction report with the relevant
					administrative authorities.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The User waives any right or remedy of any kind that may be
					granted or available to him/her, in any place whatsoever, as
					a result of GRANT&#39;s communication of this information to
					the competent authorities, within the limits of applicable
					law.
				</span>
			</p>
			<h3 class="c9" id="h.z337ya">
				<span class="c0">7.3 Benefits</span>
			</h3>
			<p class="c3">
				<span class="c2">
					Some Services may require an initialization and
					parameterization phase before they can produce results. The
					acceptance of the Services is tacitly accepted upon receipt
					of the results of the Services by the User.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The User undertakes to cooperate with GRANT to enable it to
					provide the Services and the Solution in accordance with the
					terms of the GCUA. The User acknowledges that the Services
					and the Solution can only be correctly provided by GRANT
					with the active cooperation of the User, who must provide
					the requested information and actions in good time, in
					particular by entering the confirmation sms codes and
					complying with the KYC Procedures.
				</span>
			</p>
			<p class="c3 c22">
				<span class="c2">
					GRANT is not a party and shall not intervene in any way in
					the relations between Users following or in connection with
					the Services. It is the sole responsibility of the User to
					organise the management of his Grants.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					For the sole purpose of enabling the proper functioning of
					the Solution and the provision of the Services, the User
					grants GRANT a personal, non-exclusive, non-assignable and
					non-transferable licence to use the Data, for the duration
					of the GCUA and throughout the world. The User expressly
					authorises GRANT to use its payment data for the purpose of
					executing the Grants and to provide Services to Users on the
					basis of these Grants.
				</span>
			</p>
			<h4 class="c15" id="h.3j2qqm3">
				<span class="c32">7.3.1 Payment of Grants</span>
			</h4>
			<p class="c3 c22">
				<span class="c2">
					Any Issuer may freely generate and transmit Grants provided
					that it indicates the bank account from which the Grants and
					Transaction Fees will be deducted.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					Any natural person may scan a Recipient&#39;s QRL at any
					time using the camera on their mobile phone or any suitable
					terminal:
				</span>
			</p>
			<ul class="c12 lst-kix_list_13-0 start">
				<li class="c1 li-bullet-0">
					<span class="c2">
						If the Issuer Application is installed on the terminal
						used, the scan triggers the opening of the Application
						where the Issuer can fill in a Rating and issue the
						Grant; or
					</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">
						If the Application is not installed, the scan triggers
						the opening of a landing page on the terminal&#39;s
						Internet browser, connected to the Solution, which
						proposes to fill in a Rating and issue a Grant either by
						installing the Application and creating a User Account,
						or directly from the landing page.
					</span>
				</li>
			</ul>
			<p class="c3">
				<span>
					The first step is to establish a Rating to be associated
					with the Recipient. This Rating is assigned by the Issuer
					without any obligation to do so and without the need for the
					Issuer to identify the actions, products or services of the
					Recipient that led it to transmit the Grant and/or to assign
					this Rating. The Issuers do not receive any consideration in
					exchange for the Rating. This Rating is not subject to a
					priori or a posteriori control, but the Recipients may
					report a doubt on their authenticity. It is not possible to
					assign a Rating after a Grant has been issued or to modify
					it.
				</span>
			</p>
			<p class="c3">
				<span>
					The second step is to set the amount of the Grant to be sent
					to the Recipient, it being understood that it is possible to
					set this amount to 0 &euro; (no Transaction Fee will be
					charged in this case).
				</span>
			</p>
			<p class="c3">
				<span>
					Upon validating the second step, the Issuer is presented
					with a Grant summary page, specifying (i) the Rating
					assigned to the Recipient, (ii) the amount of the Grant and
					the Transaction Fee, and (iii) a warning regarding any
					roaming charges that his mobile operator may levy. If the
					Issuer is not logged into his User Account on the relevant
					Application, he must accept the GSTC via a checkbox on the
					landing page. In any case, he must also agree to waive his
					right of withdrawal so that GRANT can issue the Grant
					immediately.
				</span>
			</p>
			<p class="c3">
				<span class="c34 c5 c26">
					The Grant is a digital content independent of any material
					support. The User must agree to waive his right of
					withdrawal if he wants to be able to issue the Grant
					immediately upon validation of his transaction order.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					By validating this summary, the Issuer may be asked to enter
					a confirmation sms code or to perform a strong
					authentication (3D Secure or any other technical standard in
					force). With or without this step, the Issuer authorises the
					final validation of the Grant and the generation of an
					associated CQRG. The Issuer can then present this CQRG to
					the Recipient from his mobile phone or any other terminal
					used. As soon as the CQRG is generated, the amount of the
					Grant is blocked on the Issuer&#39;s bank account until it
					is scanned by the Recipient or until its validity period
					expires.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The Issuer may no longer transmit new Grants to a Recipient
					after (i) a maximum amount of fifty euros (&euro;50) over a
					twenty-four hour (24 hour) period and (ii) a maximum amount
					of five hundred euros (&euro;500) over a one month period.
					These ceilings are cumulative and are reset, respectively,
					at the end of a new twenty-four hour (24h) period and a new
					one month period.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					If the Grant is not (i) scanned within fifteen minutes by
					the Recipient and/or (ii) credited to the Recipient&#39;s
					User Account within four hours (4h) of its issuance, it is
					cancelled and can no longer be used by a Recipient. Once the
					QR Code is scanned by the Recipient, the Issuer&#39;s bank
					account is debited with the amount of the Grant. The Grant
					is immediately debited and cannot be subject to any deferred
					debit, cancellation or modification after it has been
					credited.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The Issuer is also charged a Transaction Fee when sending
					the Grant. This Transaction Fee is clearly indicated
					separately from the Grant before its validation. The Issuer
					is informed and accepts without reservation that under no
					circumstances may these Transaction Costs be reimbursed.
				</span>
			</p>
			<p class="c3">
				<span class="c5">
					Any User may complain to GRANT about one or more Grants, in
					particular because of the misleading or abusive nature or
					behaviour of a Recipient or if all or part of the Grants
					fall within the prohibited activities set out in the article
					&quot;Conditions for issuing Grants&quot;. GRANT reserves
					the right to suspend the payment of any disputed Grant.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The Issuer may cancel the Grant at any time during the
					procedure by leaving the Application or the landing page to
					the Solution.
				</span>
			</p>
			<h4 class="c15" id="h.1y810tw">
				<span class="c32">7.3.2 Receipt of Grants</span>
			</h4>
			<p class="c3">
				<span class="c2">
					Any Recipient may freely receive any Grant provided they
					install the &quot;Grant T&quot; Application. Any natural
					person may scan the CQRG shown to them by the Issuer within
					fifteen minutes of its issue using the camera of their
					mobile phone or any suitable terminal:
				</span>
			</p>
			<p class="c3 c23">
				<span class="c2"></span>
			</p>
			<ul class="c12 lst-kix_list_11-0 start">
				<li class="c19 li-bullet-0">
					<span class="c2">
						If the Recipient Application is installed on the
						terminal used, the scan triggers the opening of the
						Application where the Recipient can send a &quot;Thank
						you!&quot; to the Issuer and credit his/her User Account
						with the amount of the Grant;
					</span>
				</li>
				<li class="c4 li-bullet-0">
					<span class="c2">
						If the Application is not installed, the scan triggers
						the opening of a landing page on the terminal&#39;s
						Internet browser, connected to the Solution, which
						proposes to credit the amount of the Grant by installing
						the Application and creating a User Account, within a
						maximum of four hours (4h) from the arrival on the
						landing page.
					</span>
				</li>
			</ul>
			<p class="c3">
				<span>
					Once the Recipient has installed and opened the Application,
					the Recipient can credit the amount of the Grant to his/her
					User Account and view the Rating awarded by the Issuer.{" "}
				</span>
				<span class="c26">
					The ratings awarded are kept for a period of{" "}
				</span>
				<span>5 years </span>
				<span class="c26">
					after they are issued. GRANT provides a free feature that
					allows rated Recipients who are rated by an Issuer to report
					a doubt about the authenticity of the rating, provided that
					the report is substantiated. It should be noted that this
					Rating has no impact on the{" "}
				</span>
				<span>Recipients </span>
				<span class="c2">
					and a bad Rating can in no way lead to the suspension of the
					associated User Account.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The crediting of a Grant on the Recipient&#39;s User Account
					definitively consumes the Grant which can no longer be
					cancelled, refunded or allocated to another User. The sums
					credited to the Recipient&#39;s User Account are placed in
					escrow on a LEMONWAY suspense account pending their
					collection by the Recipient.
				</span>
			</p>
			<h4 class="c15" id="h.4i7ojhp">
				<span class="c32">7.3.3 Collection of Grants</span>
			</h4>
			<p class="c3 c22">
				<span class="c2">
					The Recipient may redeem the Grants credited to his/her User
					Account at any time provided that he/she indicates on
					his/her User Account the bank account where the Grants will
					be paid.
				</span>
			</p>
			<p class="c3 c22">
				<span class="c2">
					At the time of the first encashment, the Recipient will be
					required to satisfy a KYC Procedure. If the Recipient
					refuses to follow or fails to satisfy the KYC Procedure, the
					Recipient may still proceed with the first encashment of the
					Grant(s) but (i) the transfer will take place after a
					fifteen (15) day waiting period and (ii) its User Account
					will then be temporarily suspended until the Recipient has
					satisfied a KYC Procedure.
				</span>
			</p>
			<p class="c3 c22">
				<span class="c2">
					If the Recipient has already completed a KYC Procedure, the
					Recipient may also proceed with the transfer without having
					to repeat one, at GRANT&#39;s discretion. GRANT may impose a
					new KYC Procedure on the Recipient at any time, and in
					particular whenever the Recipient&#39;s situation,
					supporting documents or User Account Data changes.
				</span>
			</p>
			<p class="c3 c22">
				<span class="c2">
					The amount of the Grants is collected within a maximum of
					fifteen (15) days from the completion of the collection
					procedure on the Application, with or without a KYC
					Procedure.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The Recipient may not receive any further Grants from any
					User after a maximum amount of five thousand euros
					(&euro;5,000) per month (or its equivalent in local currency
					at the conversion rate applicable on the date of issue of
					each Grant).
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					If the Grant credited to the Recipient&#39;s User Account is
					not transferred to its bank accounts within a maximum of
					five (5) years from the date of its credit, the funds will
					be automatically transferred to the Caisse des
					D&eacute;p&ocirc;ts et Consignation in accordance with the
					law applicable to unused accounts and funds.
				</span>
			</p>
			<h3 class="c9" id="h.2xcytpi">
				<span class="c0">7.4 Referral of Recipients</span>
			</h3>
			<p class="c3">
				<span class="c2">
					GRANT can be described as an online platform operator by
					offering on the Solution to put several Users in contact
					with each other with a view to exchanging Grants. In this
					context, GRANT indicates to you:
				</span>
			</p>
			<p class="c3 c23">
				<span class="c2"></span>
			</p>
			<ul class="c12 lst-kix_list_9-0 start">
				<li class="c19 li-bullet-0">
					<span class="c2">
						GRANT does not have a capital link with the Users of the
						Solution or derive remuneration for its benefit from the
						latter, who do not influence the operation of the
						Solution; and
					</span>
				</li>
				<li class="c4 li-bullet-0">
					<span class="c2">
						GRANT informs you of the rights and obligations of the
						Issuer in civil and tax matters when the Issuer
						interacts with Recipients within the Solution in the
						article &quot;Special conditions applicable to
						consumers&quot; of these GCUA.
					</span>
				</li>
			</ul>
			<h2 class="c6" id="h.1ci93xb">
				<span class="c10">
					Article 8. Special conditions applicable to consumers
				</span>
			</h2>
			<p class="c3 c22">
				<span class="c2">
					The User may download or save a copy of these GCUA on a
					durable medium at any time. These GCUA are accessible from
					the User Account of each User and from the landing pages to
					the Solution.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The User accepts that his Data, in particular his banking
					and/or payment data, may be collected as part of the
					provision of Services by GRANT and are transmitted to
					GRANT&#39;s operational service providers, in particular to
					LEMONWAY, for the sole purpose of executing the orders and
					payment operations requested by the User when he issues or
					receives a Grant. These operational service providers are
					subject to regulations guaranteeing a sufficient level of
					protection as defined in article 561-7, II, 2&deg; of the
					Monetary and Financial Code.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The GCUA constitute a contract relating to Services, part of
					which are financial services; the latter are excluded from
					the scope of application of Articles L. 221-1 et seq.
				</span>
			</p>
			<p class="c3">
				<span class="c5">
					Furthermore, GRANT informs the Users that the right of
					withdrawal cannot apply to Services, which are (i) fully
					executed before the end of the withdrawal period, (ii)
					provided online without material support and whose execution
					is based (iii) on a specific and prior agreement of the User
					for each Grant and (iv) on the express renunciation of his
					right of withdrawal.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The Recipient is responsible for declaring and paying any
					associated taxes on the amounts received in its bank account
					from the Grants paid by the Issuers. In this respect, GRANT
					informs the Recipient that the amounts of Grants received
					may be considered as salaries and wages subject to income
					tax, in accordance with Article L. 3244-1 of the French
					Labour Code, according to which Grants are comparable to
					tips given voluntarily by the Issuers, in addition to their
					salary.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The User is informed that GRANT is bound by obligations in
					terms of the fight against terrorism, corruption and money
					laundering, which are set out in particular in the article
					&quot;Conditions for paying Grants&quot;. The User may not
					use the Solution to make donations; in this respect, GRANT
					informs the User that any donation must be declared to the
					tax authorities.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					GRANT provides deaf, hard of hearing, deaf-blind and aphasic
					people with a simultaneous written and visual translation
					service at no extra cost to the User, accessible at the same
					contact details during the same opening hours as GRANT
					customer service.
				</span>
			</p>
			<h2 class="c6" id="h.3whwml4">
				<span class="c10">Article 9. Licence to use the Solution</span>
			</h2>
			<p class="c3">
				<span class="c2">
					The User acknowledges that GRANT is the full owner of the
					Solution and the sole owner of the intellectual property
					rights to the Solution. The User therefore acknowledges and
					accepts that the licence to use the Solution, which is
					granted in the following section of the article, is not an
					exclusive licence and cannot prevent GRANT from granting
					other licences to use the Solution to other Users.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The rights of use granted to the User under these GCUA may
					not have the effect of transferring any property and/or
					intellectual property rights whatsoever over all or part of
					the Solution, other than those strictly necessary for the
					use of the Solution by the User for the duration, for the
					purposes and under the conditions indicated in the GCUA.
				</span>
			</p>
			<h3 class="c9" id="h.2bn6wsx">
				<span class="c0">9.1 Licence to use</span>
			</h3>
			<p class="c3">
				<span class="c2">
					The creation of the User Account by the User implies the
					granting to the User of a personal, non-exclusive,
					non-assignable and non-transferable right to use the
					Solution and the results of the Services for the duration of
					the validity of these GCUA, throughout the world. This right
					of use extends to updates of the Solution, including any
					correction or bypassing of an anomaly.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					This licence excludes any sub-licences to third parties,
					affiliates or agents of Users.
				</span>
			</p>
			<h2 class="c6" id="h.qsh70q">
				<span class="c10">Article 10. Restrictions</span>
			</h2>
			<h3 class="c9" id="h.3as4poj">
				<span class="c0">10.1 Restrictions on use</span>
			</h3>
			<p class="c3">
				<span class="c2">
					At all times, you undertake and agree not to :
				</span>
			</p>
			<ol class="c12 lst-kix_list_2-0 start" start="1">
				<li class="c3 c14 li-bullet-0">
					<span class="c2">
						copy, modify, reuse, create derivative works, download,
						adapt, reverse engineer, emulate, migrate to another
						service, translate, compile, decompile or disassemble
						the Solution (or any part thereof);
					</span>
				</li>
				<li class="c3 c14 li-bullet-0">
					<span class="c2">
						publicly display, perform, transmit or distribute the
						Solution without the specific prior written permission
						of GRANT and/or without being expressly permitted under
						these TOU;
					</span>
				</li>
				<li class="c3 c14 li-bullet-0">
					<span class="c2">
						purchasing keywords from a search engine or
						pay-per-click service (such as Google Ads) or domain
						names that use the same or similar trademarks, variants
						or spelling as the Solution name;
					</span>
				</li>
				<li class="c3 c14 li-bullet-0">
					<span class="c2">
						upload to the Solution or take any action that would
						otherwise result in the transmission of software viruses
						or other computer code, files or programs designed to
						interrupt, destroy or limit the functionality of any
						computer software or hardware or telecommunications
						equipment;
					</span>
				</li>
				<li class="c3 c14 li-bullet-0">
					<span class="c2">
						disrupt or interrupt the operation of the Solution, the
						servers or networks connected to it or violate any
						conditions, procedures, rules or regulations relating to
						the Solution;
					</span>
				</li>
				<li class="c3 c14 li-bullet-0">
					<span class="c2">
						attempt to interfere or interfere with the Solution,
						including exposing them to a virus, overloading the
						bandwidth or Infrastructure (whether unintentionally or
						through a distributed denial of service), sending spam
						or overloading the services provided on the Solution;
					</span>
				</li>
				<li class="c3 c14 li-bullet-0">
					<span class="c2">
						view information that is not intended for you or access
						a server or account that you are not authorized to
						access;
					</span>
				</li>
				<li class="c3 c14 li-bullet-0">
					<span class="c2">
						evaluate, record or test the vulnerability of the
						Solution, or breach their security or authentication
						measures without the prior written consent of GRANT, or
						attempt to do so;
					</span>
				</li>
				<li class="c3 c14 li-bullet-0">
					<span class="c2">
						engage in any illegal activity or any other activity
						that may prejudice the rights of GRANT, its suppliers,
						service providers, partners, Users or any other persons,
						and to encourage others to do so;
					</span>
				</li>
				<li class="c3 c14 li-bullet-0">
					<span class="c2">
						post on the Solution or make available by any means
						unsolicited or unauthorized advertising or promotional
						materials, &quot;spam&quot;, &quot;chain letters&quot;
						or any other form of solicitation;
					</span>
				</li>
				<li class="c3 c14 li-bullet-0">
					<span class="c2">
						usurp the Identifiers of a third party (other User) to
						access their Data, the Solution or the Services;
					</span>
				</li>
				<li class="c3 c14 li-bullet-0">
					<span class="c2">
						post on the Solution or make available by any means any
						content that is unlawful, harmful, defamatory, libelous,
						offensive, racist, vulgar, obscene, threatening,
						immoral, invasive of another&#39;s privacy, racially or
						ethnically objectionable or otherwise objectionable;
					</span>
				</li>
				<li class="c3 c14 li-bullet-0">
					<span class="c2">
						use the Solution for political or polemical purposes,
						contrary to public order or morality or likely to
						infringe rights recognised by law and, in general ;
					</span>
				</li>
				<li class="c3 c14 li-bullet-0">
					<span class="c2">
						associating the Solution with actions or activities
						likely to harm or be harmful to GRANT, in particular any
						behaviour that may be associated directly or indirectly
						with acts of counterfeiting or unfair competition, or
						with any social, accounting or fiscal procedure that is
						prohibited or punishable under the law applicable to
						GRANT and to the User;
					</span>
				</li>
				<li class="c3 c14 li-bullet-0">
					<span class="c2">
						use a &quot;robot&quot;, &quot;spider&quot; or any other
						identical or similar device, program, script, algorithm,
						automatic methodology or other manual process to access,
						acquire, copy or monitor any part of the Solution or the
						Data;
					</span>
				</li>
				<li class="c3 c14 li-bullet-0">
					<span class="c2">
						reproduce or misappropriate the navigational structure
						or presentation of the Solution in order to obtain or
						attempt to obtain information, documents, services or
						materials in a manner not available through the
						Solution.
					</span>
				</li>
			</ol>
			<p class="c3">
				<span class="c2">
					You agree to comply with all applicable laws, rules and
					procedures regarding online user behaviour and the
					transmission of technical data. &nbsp;GRANT may terminate
					your right to access the Solution at any time if you fail to
					comply with your obligations under these TOU, without
					prejudice to GRANT&#39;s right to claim damages.
				</span>
			</p>
			<h3 class="c9" id="h.1pxezwc">
				<span class="c0">10.2 Licence restrictions</span>
			</h3>
			<p class="c3">
				<span class="c2">
					Any other use of the Solution and/or the results of the
					Services than those expressly authorised by GRANT above is
					prohibited. The licence excludes any right of sub-licence,
					reproduction, representation or modification not expressly
					authorised and which are prohibited in application of
					Article L. 122-4 of the Intellectual Property Code.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The User shall refrain from (i) any temporary or permanent
					reproduction of all or part of the Solution, by any means
					whatsoever, (ii) any access or attempt to access GRANT&#39;s
					applications and automated data processing systems, in
					particular those enabling the Services to be carried out,
					(iii) any decompilation or reverse engineering of the
					Solution, in particular with a view to creating a similar
					service, (iv) any interfacing or integration of the Solution
					with other services or software without the prior written
					authorisation of GRANT, (v) any dissemination, distribution,
					free or paid provision of the Solution for the benefit of
					another company, the public or third parties (except in the
					case of simple mention of the existence of the Solution with
					a hypertext link redirecting to the latter, which
					unambiguously states that GRANT is the publisher), (vi) any
					adaptation or modification of the Solution whatsoever, or
					(vii) any fraudulent or unauthorised introduction or
					attempted introduction of the Solution. The prohibitions in
					this paragraph apply to any Update integrated into the
					Solution within the framework of the GCUA.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The User undertakes not to register, in any capacity
					whatsoever (trademark, design, etc.) or to develop, use or
					exploit, in any capacity whatsoever and in any territory
					whatsoever, software, products, services, signs or logos
					that are identical or similar or that are likely to infringe
					GRANT&#39;s intellectual property rights on the Solution.
				</span>
			</p>
			<h3 class="c9" id="h.49x2ik5">
				<span class="c0">10.3 Violation of restrictions</span>
			</h3>
			<p class="c3">
				<span class="c2">
					GRANT shall not be liable for any claim, action or demand
					brought by a third party due to the words, actions or
					omissions of a User in violation of these restrictions. The
					User agrees to indemnify and hold GRANT harmless from any
					damages suffered as a result of any violation of these
					restrictions by the User or through the User&#39;s Account.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The User is solely responsible for the Data uploaded, as
					well as the processing, instructions and procedures that he
					activates on the Solution. He undertakes not to use the
					Solution and the Services in a manner contrary to the law
					applicable to his activities or to electronic commerce, nor
					to assist in such use.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					In the event of loading Data onto the Solution that is
					contrary to these GCUA or in the event of using the Solution
					for purposes other than those authorised by GRANT, GRANT
					reserves the right (i) to delete the disputed Data in the
					event of an emergency or threat to the Infrastructure, (ii)
					to interrupt access to the Solution without delay or prior
					notice and (iii) to suspend or terminate the User Account of
					the offending User. This termination shall not give rise to
					any compensation whatsoever, without prejudice to the
					damages that GRANT may claim from the User for its actions.
				</span>
			</p>
			<h2 class="c6" id="h.2p2csry">
				<span class="c10">Article 11. Maintenance</span>
			</h2>
			<h3 class="c9" id="h.147n2zr">
				<span class="c0">11.1 Safety</span>
			</h3>
			<p class="c3">
				<span class="c2">
					The Solution and its User Accounts are an automated data
					processing system. Any fraudulent access to the latter is
					prohibited and punishable by law. Within the framework of an
					obligation of means, GRANT implements state-of-the-art
					security measures on the Solution to prevent any
					unauthorised access to the Solution or any other security
					breach. Similarly, the User shall use its best efforts to
					ensure the security of its Data and Internet access,
					including, but not limited to, installing firewalls and
					antivirus software to protect its information system and
					Data.
				</span>
			</p>
			<h3 class="c9" id="h.3o7alnk">
				<span class="c0">11.2 Availability</span>
			</h3>
			<p class="c3">
				<span class="c2">
					The Parties acknowledge and accept that, given the current
					state of the art, GRANT cannot guarantee continuous
					availability of the Solution or the Services. GRANT
					undertakes to make commercially reasonable efforts to
					provide the User with continuous access to the Solution 24
					hours a day, 7 days a week (including Sundays and public
					holidays), with the exception of periods of planned
					maintenance, unavailability for security reasons, events
					beyond its control which may create interruptions or
					disruptions of services in whole or in part, or suspension
					of access to the Solution for default by the User, under the
					conditions set out in these GCUA. These cases of suspension
					of access to the Solution shall not be taken into account
					when assessing availability.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					As a result, GRANT cannot guarantee the permanent and
					optimal availability of the Solution, and shall not be held
					responsible when these difficulties in accessing or
					interrupting the Solution result from disruptions to the
					User&#39;s internet connection or any problem linked to the
					User&#39;s internet service providers. GRANT accepts no
					responsibility for the unavailability of the Solution and
					provides the Solution on an &quot;as is&quot; basis, without
					warranty of any kind, including any express or implied
					warranties or obligations of quality, fitness for purpose or
					any other warranty - to the extent permitted by applicable
					law and the warranties set out in these TOU.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					GRANT shall take commercially reasonable steps available to
					it to limit or resolve service interruptions or disruptions
					to the Solution.
				</span>
			</p>
			<h3 class="c9" id="h.23ckvvd">
				<span class="c0">11.3 Hosting</span>
			</h3>
			<p class="c3 c23">
				<span class="c2"></span>
			</p>
			<p class="c3 c22">
				<span class="c2">
					The Solution and the Data are hosted on the Infrastructure
					of GRANT&#39;s hosts, where they are regularly backed up.
					The User expressly grants GRANT and GRANT&#39;s
					subcontracted hosts a personal, non-assignable and
					non-transferable right to reproduce the Data on the Solution
					as well as for the provision of the Services, to the
					exclusion of any other use or transfer, for the duration of
					the GCUA and throughout the world. This licence includes the
					right to grant the same rights to GRANT&#39;s host. The
					Solution and the Services do not include any Data monitoring
					or cleaning services, the integrity, legality and use of
					which remain the sole responsibility of the User. GRANT does
					not use or reproduce the Data in any way that is not
					strictly necessary for the provision of the Solution and/or
					the provision of the Services.
				</span>
			</p>
			<h3 class="c9" id="h.ihv636">
				<span class="c0">11.4 Corrective maintenance</span>
			</h3>
			<p class="c3">
				<span class="c2">
					In accordance with Article L. 122-6-1, 2&deg; of the
					Intellectual Property Code, GRANT reserves the right to
					correct Anomalies and to publish Updates. Consequently, the
					User agrees not to carry out any corrective and/or
					evolutionary maintenance of the Solution. Where applicable,
					the price of Maintenance is included in the Transaction Fee.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The User acknowledges that software is never free of
					defects. Subject to the other provisions of these GCUA,
					GRANT does not guarantee that the Services or the Solution
					will be free of Defects. In the event of a fault in the
					Solution, the User may notify GRANT from the application
					detailing the circumstances of the fault. GRANT will make an
					initial assessment of the reported incident to determine
					whether it is a fault attributable to the Solution or an
					incident caused by external factors such as the Data, the
					information system, the connection or the User&#39;s
					network. If the cause is not attributable to the Solution,
					the incident is excluded from Maintenance.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					GRANT provides Corrective Maintenance on a best effort
					basis. The User acknowledges that the time required to
					correct Defects may vary depending on the specific
					circumstances of each Defect, including, without limitation,
					the nature of the Defect, the extent and accuracy of
					information available about the Defect, and the level of
					co-operation and responsiveness of the User in providing the
					documentation, information, access and support reasonably
					required by the Provider to achieve resolution of the
					Defect.
				</span>
			</p>
			<h3 class="c9" id="h.32hioqz">
				<span class="c0">11.5 Upgraded maintenance</span>
			</h3>
			<p class="c3">
				<span class="c2">
					GRANT shall provide Upgrade Maintenance on a best effort
					basis. Updates are published in accordance with GRANT&#39;s
					product roadmap and do not include specific requests from
					the User. They are automatically deployed on the Solution
					without any functional regression for the Users.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					In accordance with Articles L. 217-21 et seq. of the
					Consumer Code:
				</span>
			</p>
			<p class="c3 c23">
				<span class="c2"></span>
			</p>
			<ul class="c12 lst-kix_list_14-0 start">
				<li class="c19 li-bullet-0">
					<span class="c2">
						Upgraded Maintenance is provided during the term of the
						GCUA and until its termination, regardless of the reason
						and regardless of the Party that terminated it;
					</span>
				</li>
				<li class="c4 li-bullet-0">
					<span class="c2">
						The User is informed of the Updates, including security
						Updates, which are necessary to maintain the compliance
						of the Solution, when he/she logs into his/her User
						Account;
					</span>
				</li>
			</ul>
			<p class="c3">
				<span class="c2">
					Users may not object to or prevent an Update to the
					Solution, the timeliness and implementation of which is the
					sole responsibility of GRANT: if they refuse the Update,
					this leads to the termination of the GCUA by the User.
					Updates do not include updates to third party software
					interconnected with the Solution or backwards compatibility
					of the Solution due to an update to such software.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The User acknowledges and agrees that Upgrade Maintenance
					shall take place for at least two (2) hours per week. In the
					event of a major Update (new version of the Solution), GRANT
					shall give one working day&#39;s notice to Users. Any
					implementation of an Update on the Solution may result in a
					temporary and planned unavailability of the Solution.
					Updates do not constitute a failure by GRANT to make the
					Solution available and under no circumstances shall the User
					be entitled to a refund due to unavailability caused by
					Upgrade Maintenance.
				</span>
			</p>
			<h3 class="c9" id="h.1hmsyys">
				<span class="c0">11.6 Maintenance exclusions</span>
			</h3>
			<p class="c3">
				<span class="c2">
					In any event, GRANT cannot be held responsible and shall not
					be liable for the Maintenance of any Anomaly resulting from
					(i) use of the Solution by the User not in accordance with
					its intended purpose, its Documentation or GRANT&#39;s
					recommendations, (ii) a hardware or software failure of one
					or more elements of the User&#39;s system or network, (iii)
					the failure, (iii) the failure, slowdown or congestion of
					the User&#39;s electronic communications network or any
					other event of force majeure affecting GRANT or its
					subcontractors, (iv) the configuration of the User&#39;s
					information system, including that of its terminals,
					infrastructures or workstations, or the format or content of
					the Data put online by the User in the Solution, (v) the
					refusal of the User to cooperate fully with GRANT in the
					resolution of the Anomalies, (vi) incompatibility between
					the Solution and new hardware or third party software
					implemented by the User, (vii) contamination by a computer
					virus of the User&#39;s information system or of the Data
					uploaded by the User to the Solution (viii) an act of piracy
					or fraudulent intrusion into the User&#39;s information
					system, (ix) intervention by a third party on the Solution
					without the authorisation of GRANT, (x) failure by the User
					to comply with the restrictions set out in the
					&quot;Restrictions&quot; article, and (xi) more generally,
					any deliberate act of degradation, malice, sabotage by the
					User or a third party, or deterioration due to any force
					majeure event.
				</span>
			</p>
			<h2 class="c22 c39" id="h.41mghml">
				<span class="c10">Article 12. Financial conditions</span>
			</h2>
			<h3 class="c9" id="h.2grqrue">
				<span class="c0">12.1 Transaction Fees</span>
			</h3>
			<p class="c3">
				<span class="c2">
					The creation of the User Account, the use of the Solution
					and the Services are provided in return for the charging of
					Transaction Fees when the Grants are paid between Users.
					Where applicable, the Issuer undertakes to pay the price of
					the Transaction Fees provided, which is deemed to include
					the price of the Services provided as well as the fee for
					use of the Solution.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The Transaction Fees are calculated from a fixed amount per
					Grant, to which are added (i) the fees proportional to the
					Grant requested by LEMONWAY and (ii) the operating costs of
					GRANT. Depending on the territories from which the Services
					are performed (place of issuance of the Grant, place of
					collection of the Grant) and where the bank account that the
					User has attached to his User Account is domiciled, these
					Transaction Fees may vary in a non-discriminatory way for
					all Users. In any case, the Fees may be revised at any time,
					as from the notification to the User when he/she logs into
					his/her User Account.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					Furthermore, the User is expressly informed that additional
					charges may apply in the following cases:
				</span>
			</p>
			<ul class="c12 lst-kix_list_10-0 start">
				<li class="c1 li-bullet-0">
					<span class="c2">
						Depending on the User&#39;s mobile operator, the mobile
						operator may levy a route charge on the User, which
						GRANT cannot predict or control; and
					</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">
						If the bank account used by the User on the Solution
						does not have the euro as its currency, exchange charges
						may be applied by the User&#39;s bank, which GRANT or
						LEMONWAY cannot predict or control.
					</span>
				</li>
			</ul>
			<h3 class="c9" id="h.vx1227">
				<span class="c0">12.2 Terms of payment</span>
			</h3>
			<p class="c3">
				<span class="c2">
					Grants and Fees are expressed in Euros, net and without
					discount, and include applicable value added tax. In the
					event of termination of the GCUA, GRANT shall not refund any
					Grants already issued and shall not pay out any Grants not
					yet cashed in by the User, which the User acknowledges.
				</span>
			</p>
			<p class="c3">
				<span>
					Grants and Fees are payable immediately, by secure payment
					via our service provider LEMONWAY
				</span>
				<span class="c41">.</span>
			</p>
			<h3 class="c9" id="h.3fwokq0">
				<span class="c0">12.3 Late payment</span>
			</h3>
			<p class="c3">
				<span class="c2">
					In any event, any delay or failure to pay will result in the
					application to the User of (i) the legal fixed indemnity
					(40&euro;) for collection costs, in accordance with Article
					L.441-10 of the Commercial Code, (ii) any additional costs
					that may be necessary to recover the debt and (iii)
					non-dischargeable late payment interest at a rate equal to
					three (3) times the legal interest rate in force, calculated
					in days from the first day of delay on the due date of the
					Grant and/or the Costs and until the day of actual payment.
					GRANT shall collect the collection costs from consumer Users
					under the conditions set out in Article L. 111-8 of the Code
					of Civil Enforcement Procedures.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					In the event of persistent delay of more than one (1) month,
					GRANT may suspend the Services and/or the right to use the
					Solution without compensation or notice, and/or terminate
					the GCUA to the exclusive detriment of the User in
					application of article 1217 of the Civil Code and the
					article &quot;Termination&quot; of the GCUA. During the
					suspension, the User shall remain liable for all sums
					normally due under the GTUA.
				</span>
			</p>
			<h2 class="c6" id="h.1v1yuxt">
				<span class="c10">Article 13. Guarantee</span>
			</h2>
			<p class="c3">
				<span class="c2">
					GRANT guarantees the substantial conformity of the Solution
					with its Documentation for a period of twenty-four (24)
					months from the creation of the User Account. To the extent
					permitted by applicable law, GRANT does not guarantee the
					accuracy or truthfulness of the result of the Services,
					which depend entirely on the Data provided by the User, and
					disclaims any liability or warranty in this respect. In the
					event of a failure to comply with this requirement, the
					defective component of the Solution shall be repaired or
					replaced at no cost to the User under the same conditions as
					the Maintenance.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					GRANT guarantees that it has the authorisations and
					intellectual property rights to the Solution. Consequently,
					GRANT agrees to defend and indemnify the User for damages
					related to claims, lawsuits or convictions brought by a
					third party alleging that the Solution infringes an
					intellectual property right, provided that the User
					immediately notifies GRANT in writing of the existence of
					the lawsuit, makes a request for its defence, cooperates
					fully with GRANT in the said defence and does not settle
					without the prior written consent of GRANT. This warranty
					does not apply to uses of the results of the Services or to
					components licensed under open source.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					Insofar as GRANT recognises that the disputed component of
					the Solution is infringing, it may, at its choice and at its
					expense: (i) modify it so that it is no longer infringing,
					(ii) replace it with a non-infringing component with
					equivalent overall functionality or (iii) obtain the rights
					of use so that the User may continue to use the Solution in
					accordance with the terms of the GCUA. If the User fails to
					do so, GRANT may terminate the GTUA and shall refund any
					amount paid in advance by the User for the remaining term of
					the GTUA from the effective date of termination as exclusive
					compensation.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					For his part, the User shall provide GRANT with the same
					guarantee on his use of the Solution and the results of the
					Services. The User shall indemnify GRANT against any claim,
					prosecution or conviction resulting from the allegations of
					a third party based on illegal, misleading or more generally
					prejudicial use of the results of the Services or on
					infringement of intellectual property rights, on facts or
					allegations of money laundering, corruption, financing of
					terrorism, unfair competition, infringement of privacy, data
					protection, business secrecy and any contractual, legal or
					regulatory obligation of confidentiality due to the
					User&#39;s use of the Solution and/or the results of the
					Services.
				</span>
			</p>
			<p class="c3 c22">
				<span class="c2">
					To the extent permitted by applicable law, GRANT excludes
					any warranty other than those specified above. In
					particular, GRANT does not guarantee (i) that the Solution
					or the Site are compatible with the User&#39;s information
					system and/or the formatting of the User&#39;s Data on a
					permanent basis, as GRANT has no control over these, which
					the User acknowledges, and (ii) that the Solution will be
					free of errors, which leads GRANT to offer Maintenance, the
					price of which is included in the Transaction Fee. GRANT
					excludes any warranty for open source components integrated
					or used in conjunction with the Solution, which, if
					applicable, are bound by their respective open source
					licenses.
				</span>
			</p>
			<h2 class="c6" id="h.4f1mdlm">
				<span class="c10">Article 14. Liability</span>
			</h2>
			<p class="c3 c22">
				<span class="c2">
					The Solution is provided under a general obligation of
					means. GRANT shall put an end to any unavailability of the
					Solution within a commercially reasonable timeframe and
					shall not be held liable in the event of failure of the
					electronic communications networks, slowdown or congestion
					of the Internet or the User&#39;s network, contamination by
					a computer virus, an act of piracy, fraudulent intrusion or
					unauthorised access or intervention by a third party, and
					more generally any deliberate act of degradation, malice,
					sabotage or deterioration or any other case of force majeure
					affecting GRANT or its subcontractors.
				</span>
			</p>
			<p class="c3 c22">
				<span class="c2">
					Each Party assumes its responsibility according to the
					common law. As an APSP, GRANT can provide the Services by
					relying on the exchange of Grants on the services of
					LEMONWAY, under the responsibility of LEMONWAY.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					By express agreement between the Parties and within the
					limits of applicable law, the liability incurred by GRANT in
					the event of a proven breach of its obligations by the User,
					all damages included, shall be limited to an amount equal to
					the Transaction Fees actually collected during the six (6)
					months preceding the date of occurrence of the damage or, if
					the User has been registered for less than six (6) months,
					to the amount of the Transaction Fees actually collected by
					GRANT for the contractual period elapsed on the date of
					occurrence of the damage. This limitation is stipulated with
					regard to the prices and fees agreed upon and is part of the
					economic balance of the GCUA.
				</span>
			</p>
			<p class="c3 c22">
				<span>
					In any event, the User is solely responsible for the
					accuracy, sincerity and relevance of the Data transmitted to
					GRANT as well as for his use of the Services and the
					Solution and undertakes not to make use of the Services
					and/or the Solution in a manner contrary to the applicable
					law, in particular the law relating to unfair competition,
					business secrecy and the rules specific to his sector of
					activity, nor to assist in such use.{" "}
				</span>
				<span class="c26">
					The User acknowledges and accepts that he/she makes his/her
					own backups of the Data he/she uploads to his/her User
					Account and GRANT declines all responsibility in the event
					of loss of the Data duplicated on it.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					Likewise, GRANT may not be held liable under any
					circumstances for indirect damage suffered by the User or
					third parties (including in particular any loss of business,
					customers, prospects, commercial or financial loss, loss of
					image, loss of profit, etc.) resulting from the provision of
					the Services and/or the use of the Solution and the results
					of the Services, even if GRANT was warned or could
					reasonably foresee such damage.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					It is also the responsibility of each of the Parties to take
					out the necessary insurance policies for its activity and
					its products. It is recalled that GRANT cannot be held
					contractually liable if damage occurs in the event of a
					breach of the &quot;Restrictions&quot; and &quot;Maintenance
					Exclusions&quot; articles of the GCUA. Similarly, GRANT
					shall not be held liable in the event of a request for
					temporary or permanent interruption of the Solution, the
					Site or the Services from an administrative or judicial
					authority, and more generally in the event of a breach,
					negligence or intentional fault on the part of the User or
					in the event of the intervention of a third party not under
					the control of GRANT.
				</span>
			</p>
			<h2 class="c6" id="h.2u6wntf">
				<span class="c10">Article 15. Termination</span>
			</h2>
			<p class="c3 c22">
				<span class="c2">
					The User may terminate the GTUA at any time.
				</span>
			</p>
			<h3 class="c9" id="h.19c6y18">
				<span class="c0">15.1 Termination for default</span>
			</h3>
			<p class="c3">
				<span class="c2">
					If the User fails to comply with the articles
					&quot;Conditions of supply of the Services&quot;,
					&quot;Licence to use the Solution&quot;,
					&quot;Restrictions&quot;, &quot;Maintenance&quot; and
					&quot;Financial conditions&quot; of the GCUA and if the User
					infringes GRANT&#39;s intellectual property rights, GRANT
					reserves the right to suspend the Services and/or the
					licence to use the Solution and to terminate the GCUA
					without notice or compensation, solely on the basis of the
					non-performance, which is deemed to be sufficiently serious
					by the Parties, and the User acknowledges this.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					For any other breach of the GCUA, the non-offending Party
					shall send a formal notice by registered letter with
					acknowledgement of receipt to the offending Party mentioning
					this resolutory clause, the breach(s) invoked as well as the
					indication of a period of thirty (30) days from the receipt
					of the formal notice for the offending Party to comply,
					failing which the termination shall be acquired. In the
					absence of compliance at the end of the period, the GCUA
					shall be deemed to be terminated automatically and without
					compensation, upon simple notification by the non-defaulting
					Party to the defaulting Party by any means.
				</span>
			</p>
			<h3 class="c9" id="h.3tbugp1">
				<span class="c0">15.2. Consequences of termination</span>
			</h3>
			<p class="c3">
				<span class="c2">
					In the event of termination of the GCUA for breach by the
					User, all sums paid shall be retained by GRANT and/or the
					Recipients. The termination shall take effect after the
					balance of the User Account to which the User may have been
					credited has been paid to the User&#39;s bank account, if
					necessary after the completion of KYC procedures.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The User shall cease to use the Solution in relation to its
					Data and, in particular, cease to use the results of the
					Services on any communication or promotional medium or
					accounting document. The User shall no longer be able to
					issue Grants, scan Grants or collect their amounts. On
					request, GRANT shall send a copy of all the Data in standard
					format in its latest state at the time of the request. GRANT
					undertakes not to keep any copies of the Data after the end
					of the UGC, apart from its legal obligations.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The Parties expressly acknowledge that the conditions of the
					GCUA which, by their nature, continue to apply after its
					expiry or termination, will remain in force and in
					particular the stipulations of the articles
					&quot;Definitions&quot;, &quot;Financial Conditions&quot;,
					&quot;Guarantee&quot;, &quot;Liability&quot;,
					&quot;Confidentiality&quot; and &quot;Miscellaneous&quot;.
				</span>
			</p>
			<h2 class="c6" id="h.28h4qwu">
				<span class="c10">Article 16. Confidentiality</span>
			</h2>
			<p class="c3 c22">
				<span class="c2">
					As an APSP, GRANT is considered as an employee of its agent
					LEMONWAY (the payment service provider) and must therefore
					respect the same obligation of professional secrecy on the
					transactions and data relating to the transactions carried
					out by the issuance and collection of Grants. The Solution,
					the Services, the Data (including payment data) and the
					Grants of each User constitute confidential information for
					the Parties.
				</span>
			</p>
			<p class="c3 c22">
				<span class="c26">
					GRANT may disclose confidential information to its
					subcontractors in accordance with applicable law and this
					confidentiality obligation. GRANT shall enter into any
					appropriate confidentiality agreements with such contractors
					prior to any such disclosure.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					Each Party undertakes (i) to keep confidential all
					information it receives from the other Party, (ii) not to
					disclose the other Party&#39;s confidential information to
					any third party and (iii) to use the other Party&#39;s
					confidential information only for the purpose of performing
					its obligations under the GTUA. A Party&#39;s
					confidentiality undertaking shall continue for as long as
					the other Party intends to keep its information
					confidential, except in the event of a request for
					transmission from a legal or judicial authority.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					Notwithstanding the foregoing, neither Party shall have any
					obligation with respect to information that (i) has entered
					the public domain, or (ii) was independently developed by
					the receiving Party, or (iii) was known to the Party without
					an obligation of confidentiality prior to its disclosure, or
					(iv) was legitimately received from a third party without
					breach of confidentiality, or (v) would have to be disclosed
					by law or court order (in which case it shall be disclosed
					only to the extent required and after written notice to the
					supplying Party).
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The confidentiality obligations of the Parties shall remain
					in force for the duration of the TOU and for a period of
					five (5) years after the termination of the TOU. Upon
					termination of the GTUA, each Party shall return all copies
					of documents and media containing confidential information
					of the other Party, unless the Party to whom they belong is
					duly notified of the legal obligation to retain them.
				</span>
			</p>
			<h2 class="c6" id="h.nmf14n">
				<span class="c10">Article 17. Protection of Personal Data</span>
			</h2>
			<p class="c3 c22">
				<span class="c2">
					Personal Data is collected by GRANT and is processed for the
					purpose of enabling you to access and use the Solution,
					either directly when the data subject uses the Solution from
					the Applications or the Solution landing pages, or
					indirectly when a User submits Personal Data on the Solution
					landing pages or on the Solution.
				</span>
			</p>
			<ul class="c12 lst-kix_list_5-0 start">
				<li class="c1 li-bullet-0">
					<span>
						GRANT is a company that respects the privacy of its
						Users. As such, it respects its obligations under the
						protection of Personal Data and has established a
						dedicated{" "}
					</span>
					<span class="c11 c24">
						<a
							class="c17"
							href="https://www.google.com/url?q=https://joingrant.com/donnees-personnelles/&amp;sa=D&amp;source=editors&amp;ust=1678550564611986&amp;usg=AOvVaw1UOeF62KOQIyPP8qSaVWT1"
						>
							privacy policy
						</a>
					</span>
					<span class="c2">
						&nbsp;for the purpose of informing the persons
						concerned. This privacy policy is attached as Annex 1 to
						the GTUA and forms part of the GTUA.
					</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c2">
						In accordance with article L. 561-12 of the French
						Monetary and Financial Code, GRANT and its principal
						LEMONWAY may be obliged to keep documents relating to
						Grants exchanged by Users for a period of five years
						from the termination or expiry of the GCUA, whatever the
						reason.
					</span>
				</li>
			</ul>
			<p class="c3">
				<span class="c2">
					GRANT keeps track of Users&#39; connections to the
					Applications. &nbsp;In addition, GRANT may track Users&#39;
					browsing within each Application and the landing pages to
					the Solution through the use of cookies. When you access and
					use the Solution via these, cookies are set on the device
					you are using to navigate.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					A cookie is a text file that is downloaded to your device as
					you browse. Its purpose is to collect information about your
					use of the Solution and to provide you with the
					functionality of the Solution and/or a better user
					experience of the Solution by exchanging information between
					GRANT and your terminal equipment. Some cookies are
					temporary in nature and limited to a browser session; they
					are all deleted when you close the browser window. Some
					cookies remain on your device for a period of time after you
					close your browser session, during which time they will be
					activated each time you use the Solution.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					Some cookies are necessary for the provision of the
					Solution; others are optional.
				</span>
			</p>
			<ul class="c12 lst-kix_list_1-0 start">
				<li class="c1 li-bullet-0">
					<span class="c2">
						GRANT informs you and gives you the opportunity to
						accept or decline optional cookies in an information
						banner on your first visit to the Solution landing pages
						or Applications.
					</span>
				</li>
				<li class="c1 li-bullet-0">
					<span>You can also consult its dedicated </span>
					<span class="c11 c24">
						<a
							class="c17"
							href="https://www.google.com/url?q=https://joingrant.com/cookies/&amp;sa=D&amp;source=editors&amp;ust=1678550564613318&amp;usg=AOvVaw1qS31g0GQlkSUm9yfVK6tj"
						>
							cookie policy
						</a>
					</span>
					<span class="c2">
						&nbsp;for more information on the cookies used. This
						cookie policy is attached as Annex 2 to these TOU and
						forms part of the TOU.
					</span>
				</li>
			</ul>
			<h2 class="c6" id="h.37m2jsg">
				<span class="c10">Article 18. Miscellaneous</span>
			</h2>
			<p class="c3">
				<span class="c2">
					The GCUA constitute the entire agreement between the Parties
					relating to the provision of the Services and the use of the
					Solution. As such, the GCUA cancel and replace all previous
					documents concluded between them in this respect.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The Parties are deemed to be independent contractors and
					neither Party shall be deemed to be the employee or agent of
					the other. Nothing in the TOS shall be deemed or construed
					to establish a partnership or joint venture between the
					Parties or to establish one Party as the representative or
					agent of the other Party.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					GRANT may subcontract all or part of its Services (in
					particular to its hosting provider) but remains responsible
					for all services subcontracted by it. The User may not
					assign or transfer his User Account to a third party in any
					way without the express agreement of GRANT.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					In the event that one or more of the provisions hereof are
					held to be invalid, unenforceable or unenforceable by any
					competent court, the remaining provisions hereof shall
					remain valid unless otherwise provided by such court. The
					Parties agree that in such a case, they will negotiate in
					good faith replacement provisions that will be consistent
					with the original intention of the Parties.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					GRANT shall not be held liable for a cause which originates
					from a case of force majeure, including if such a case of
					force majeure affects one of its subcontractors, as well as
					for any event outside its exclusive control. In the event of
					force majeure, the obligations of the Parties shall be
					suspended for the duration of this cause. Expressly, the
					cases of force majeure are those usually retained by the
					jurisprudence of the French courts and tribunals. Initially,
					cases of force majeure will suspend the execution of the
					GCUA. If the case of force majeure continues for more than
					three (3) months, the present GCUA shall be terminated upon
					notification by registered letter with acknowledgement of
					receipt sent by one of the Parties, unless otherwise agreed
					between the Parties.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The GRANT employees assigned to the execution of the GCUA
					shall remain under the full and exclusive responsibility of
					GRANT, which alone is authorised to issue them with
					instructions. GRANT undertakes to provide the User, at the
					latter&#39;s request, with the information and certificates
					required by law in social and fiscal matters.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					The fact that one of the Parties does not avail itself of a
					commitment by the other Party to any of the obligations
					referred to herein shall not be interpreted for the future
					as a waiver of the obligation in question.
				</span>
			</p>
			<h2 class="c6" id="h.1mrcu09">
				<span class="c10">Article 19. Complaints - Mediation</span>
			</h2>
			<p class="c3">
				<span class="c2">
					In the event of a dispute, the User undertakes to contact
					GRANT as a matter of priority in order to attempt to resolve
					amicably any dispute which may arise concerning the
					execution of the GCUA and the provision of the Services.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					In the absence of an amicable agreement or in the absence of
					a response from GRANT within a reasonable period of one (1)
					month, the consumer User within the meaning of the
					introductory article of the Consumer Code has the
					possibility of referring, free of charge, if a disagreement
					remains, to a mediator registered on the list of mediators
					established by the Commission d&#39;&eacute;valuation et de
					contr&ocirc;le de la m&eacute;diation de la consommation
					(Commission for the evaluation and control of consumer
					mediation) in application of Article L. 615-1 of the
					Consumer Code, namely :
				</span>
			</p>
			<ul class="c12 lst-kix_list_12-0 start">
				<li class="c37 li-bullet-0">
					<span class="c2">
						The AFEPAME Mediator, AFEPAME association, 36 rue
						Taitbout 75009 Paris.
					</span>
				</li>
			</ul>
			<p class="c3">
				<span class="c2">
					The mediator is responsible for recommending solutions to
					the dispute and shall give a ruling within two months from
					the date of referral. The mediation procedure is free of
					charge for the User, who nevertheless retains responsibility
					for his own costs, in particular his travel expenses or
					expenses related to the remuneration of the counsel he
					chooses to retain.
				</span>
			</p>
			<p class="c3">
				<span class="c2">
					Furthermore, the User who is not satisfied with the
					mediator&#39;s decision may refer the matter to the
					competent court at the end of the mediation procedure under
					the conditions set out in the article &quot;Applicable law
					and jurisdiction&quot;. The findings and statements made by
					the mediator may not be produced or invoked in the
					subsequent proceedings without the agreement of the Parties.
				</span>
			</p>
			<p class="c7">
				<span>
					For any complaint concerning the proper functioning of the
					payment system implemented by LEMONWAY and used by the
					Solution, the User may contact LEMONWAY directly to make a
					complaint in accordance with its general conditions
					available{" "}
				</span>
				<span class="c5 c11">
					<a
						class="c17"
						href="https://www.google.com/url?q=https://www.lemonway.com/conditions-generales-dutilisation/&amp;sa=D&amp;source=editors&amp;ust=1678550564615694&amp;usg=AOvVaw2w4OKLHXkh-QTUMp5odC50"
					>
						here
					</a>
				</span>
				<span>
					, particularly with regard to the conditions of time limits{" "}
				</span>
				<span class="c11 c5">
					<a
						class="c17"
						href="https://www.google.com/url?q=https://www.lemonway.com/conditions-generales-dutilisation/&amp;sa=D&amp;source=editors&amp;ust=1678550564616121&amp;usg=AOvVaw1RPHTqF8UqFM3ozfPnJXq9"
					>
						here
					</a>
				</span>
				<span class="c2">.</span>
			</p>
			<h2 class="c6" id="h.46r0co2">
				<span class="c10">
					Article 20. Applicable law and jurisdiction
				</span>
			</h2>
			<p class="c3">
				<span class="c2">These GTUA are governed by French law. </span>
			</p>
			<p class="c3">
				<span class="c2">
					The Parties shall make their best efforts to reach an
					amicable resolution of any dispute arising from or in
					connection with the GTUA. If the Parties fail to negotiate
					an amicable agreement to resolve the dispute in question
					under the mediation conditions provided for in the
					&quot;Mediation&quot; article of these GCUA, each Party
					shall resume its freedom of action.
				</span>
			</p>
			<p class="c3">
				<span class="c5">
					In the event that mediation fails and except in the event of
					the application of a rule of public order, any dispute
					relating to the GTUA and the use of the Solution is subject
					to the exclusive jurisdiction of the courts within the
					jurisdiction of the Court of Appeal of Paris, even in the
					event of multiple defendants, guarantee appeals, protective
					proceedings, summary proceedings or applications.
				</span>
			</p>
			<p class="c3">
				<span class="c2">The GCUA contains three Annexes:</span>
			</p>
			<p class="c3">
				<span class="c24">Appendix 1. </span>
				<span class="c11 c24">
					<a
						class="c17"
						href="https://www.google.com/url?q=https://joingrant.com/donnees-personnelles/&amp;sa=D&amp;source=editors&amp;ust=1678550564617230&amp;usg=AOvVaw2LGOFXMuyPEJhWzAcrRXKj"
					>
						Privacy Policy
					</a>
				</span>
			</p>
			<p class="c3">
				<span class="c24">Appendix 2. </span>
				<span class="c11 c24">
					<a
						class="c17"
						href="https://www.google.com/url?q=https://joingrant.com/cookies/&amp;sa=D&amp;source=editors&amp;ust=1678550564617616&amp;usg=AOvVaw0dMvrpmXip1XizloyJoHwh"
					>
						Cookie policy
					</a>
				</span>
			</p>
			<p class="c3">
				<span class="c24">Annex 3. </span>
				<span class="c24 c41">Documentation</span>
			</p>
			<p class="c3 c23">
				<span class="c2"></span>
			</p>
			<p class="c3 c23">
				<span class="c2"></span>
			</p>
			<p class="c3 c23">
				<span class="c2"></span>
			</p>
			<p class="c3 c23">
				<span class="c2"></span>
			</p>
			<p class="c3 c23">
				<span class="c2"></span>
			</p>
			<p class="c3 c23">
				<span class="c2"></span>
			</p>
			<p class="c3 c23">
				<span class="c2"></span>
			</p>
			<div>
				<p class="c23 c33">
					<span class="c2"></span>
				</p>
				<p class="c16">
					<span class="c21"></span>
				</p>
			</div>
		</div>
	);
};

export default Content;
