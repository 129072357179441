const intl = {
	about: "About",
	how_works: "How it works",
	business: "For businesses",
	grant_business: "Grant Team (Business)",
	support: "Support",
	privacy: "Privacy Policy",
	terms: "Terms & Conditions",
	title1: () => (
		<>
			Collect <span className="text-primary">Tips</span>,{" "}
			<span className="text-base">with a QR-code</span>
		</>
	),
	title2: () => (
		<>
			Say <span className="text-primary">Thanks</span>,{" "}
			<span className="text-base">with a QR-code</span>
		</>
	),
	content: () => (
		<>
			<p className="text-slate-300">Join the Grant family in 2'</p>
			<p className="text-slate-300">
				Create your QR-code & start collecting tips instantly with
				Grant, the 1<sup>st</sup> fully digital tipping solution.
			</p>
		</>
	),
	request_delete: "Request Delete my Account",
	request_delete_text:
		"You can request that we delete personal information that we collect or maintain about you. To make a deletion request, please contact us by using our support button and request personal information removal.",
	request_delete_privacy:
		"To protect your privacy, we will take steps to verify your identity before fulfilling your request.",
	download_grant: "Download Grant",
	get_latest: "Get the latest version of Grant and start receiving tips",

	more_about_grant: "MORE ABOUT GRANT",
	legals: "LEGALS",
	all_rights_reserved: "All Rights Reserved.",
	not_available: "Not Available Yet",
};

export default intl;
