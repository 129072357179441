const Content = () => {
	return (
		<div class="c13 doc-content">
			<div>
				<p class="c9 c25">
					<span class="c19 c16"></span>
				</p>
			</div>
			<h1 class="c5 c23" id="h.gjdgxs">
				<span class="c16 c22">
					Politique de protection des donn&eacute;es personnelles
				</span>
			</h1>
			<p class="c1">
				<span class="c4 c19">
					Mise &agrave; jour le 26 f&eacute;vrier 2023
				</span>
			</p>
			<p class="c6">
				<span class="c11">
					1. Qui est responsable du traitement de mes donn&eacute;es
					personnelles ?
				</span>
			</p>
			<p class="c1">
				<span class="c4">
					Le responsable de traitement est la soci&eacute;t&eacute;
					GRANT Solutions SAS (ci-apr&egrave;s d&eacute;nomm&eacute;e
					&ldquo;
				</span>
				<span class="c7">GRANT</span>
				<span class="c0">
					&rdquo;), dont le si&egrave;ge social est situ&eacute; au 8
					Rue de l&rsquo;Est 92100 Boulogne-Billancourt.
				</span>
			</p>
			<p class="c1">
				<span class="c0">
					Elle d&eacute;termine pour quel usage et comment les
					donn&eacute;es &agrave; caract&egrave;re personnel
					(ci-apr&egrave;s les &ldquo;donn&eacute;es
					personnelles&rdquo;) des utilisateurs de l&rsquo;application
					Grant (ci-apr&egrave;s d&eacute;sign&eacute;s par
					&ldquo;vous&rdquo; / &ldquo;vos&rdquo;) sont
					trait&eacute;es.
				</span>
			</p>
			<p class="c1">
				<span class="c0">
					Les donn&eacute;es personnelles d&eacute;signent toutes les
					informations qui se rapportent &agrave; une personne
					physique identifi&eacute;e ou identifiable, tel que par
					exemple votre adresse email, vos nom et pr&eacute;nom ou
					encore votre num&eacute;ro de t&eacute;l&eacute;phone.
				</span>
			</p>
			<p class="c6">
				<span class="c11">
					2. Pourquoi GRANT collecte mes donn&eacute;es
					personnelles&nbsp;?
				</span>
			</p>
			<p class="c6">
				<span class="c10">
					2.1 Finalit&eacute;s g&eacute;n&eacute;rales du
					traitement&nbsp;
				</span>
			</p>
			<p class="c1">
				<span class="c0">
					Nous traitons vos donn&eacute;es personnelles dans le cadre
					des services propos&eacute;s sur notre application et pour
					en assurer la gestion.
				</span>
			</p>
			<p class="c1">
				<span class="c4">
					Les finalit&eacute;s des informations collect&eacute;es via
					nos cookies et traceurs sont d&eacute;taill&eacute;es au
					sein de notre&nbsp;
				</span>
				<span class="c4 c8 c17">
					<a
						class="c5"
						href="https://www.google.com/url?q=https://joingrant.com/cookies/&amp;sa=D&amp;source=editors&amp;ust=1679077988457625&amp;usg=AOvVaw1Q-0XzZsfKNG_XnlvzjVc2"
					>
						Politique de gestion des cookies
					</a>
				</span>
				<span class="c4 c17">.</span>
			</p>
			<p class="c6">
				<span class="c10">
					2.2 D&eacute;tail des finalit&eacute;s et bases juridiques
					applicables aux traitements
				</span>
			</p>
			<p class="c1">
				<span class="c0">
					Le d&eacute;tail des finalit&eacute;s du traitement de vos
					donn&eacute;es personnelles et les fondements juridiques sur
					lesquels repose la poursuite de chacune de ces
					finalit&eacute;s est le suivant : &nbsp;
				</span>
			</p>
			<p class="c1">
				<span class="c0">
					Les traitements sont n&eacute;cessaires &agrave;
					l&rsquo;ex&eacute;cution du contrat qui nous lie&nbsp;:
				</span>
			</p>
			<ul class="c3 lst-kix_list_1-0 start">
				<li class="c9 li-bullet-0">
					<span class="c0">
						Gestion de l&rsquo;ex&eacute;cution des services
						propos&eacute;s sur l&rsquo;application,
					</span>
				</li>
				<li class="c9 li-bullet-0">
					<span class="c0">Gestion du support,</span>
				</li>
				<li class="c9 li-bullet-0">
					<span class="c0">Gestion du compte utilisateur,</span>
				</li>
				<li class="c9 li-bullet-0">
					<span class="c0">
						Gestion des dons et du transfert de fonds.
					</span>
				</li>
			</ul>
			<p class="c1">
				<span class="c0">
					Les traitements sont n&eacute;cessaires pour respecter une
					obligation l&eacute;gale&nbsp;:
				</span>
			</p>
			<ul class="c3 lst-kix_list_2-0 start">
				<li class="c9 li-bullet-0">
					<span class="c0">
						Lutter contre la fraude, le blanchiment et le
						financement du terrorisme,
					</span>
				</li>
				<li class="c9 li-bullet-0">
					<span class="c0">
						G&eacute;rer les r&eacute;clamations,
						pr&eacute;contentieux et contentieux,
					</span>
				</li>
				<li class="c9 li-bullet-0">
					<span class="c0">
						Conservation des donn&eacute;es li&eacute;es aux
						cr&eacute;ations des comptes utilisateur, aux connexions
						&agrave; l&rsquo;application, aux paiements
						effectu&eacute;s, en vue d&rsquo;&eacute;ventuelles
						r&eacute;quisitions judiciaires,
					</span>
				</li>
				<li class="c9 li-bullet-0">
					<span class="c0">
						G&eacute;rer les droits des utilisateurs.
					</span>
				</li>
			</ul>
			<p class="c1">
				<span class="c0">
					Les traitements sont r&eacute;alis&eacute;s si nous
					disposons d&rsquo;un int&eacute;r&ecirc;t l&eacute;gitime
					&agrave; le faire :
				</span>
			</p>
			<ul class="c3 lst-kix_list_3-0 start">
				<li class="c9 li-bullet-0">
					<span class="c0">
						organiser des op&eacute;rations marketing (statistiques
						d&rsquo;audience, mesures de performance, prospection
						commerciale, formations, etc.
					</span>
				</li>
			</ul>
			<p class="c6">
				<span class="c11">
					3. Traitements r&eacute;alis&eacute;s par LEMONWAY
				</span>
			</p>
			<p class="c1">
				<span class="c4">
					L&rsquo;encaissement des paiements et le versement des fonds
					sont g&eacute;r&eacute;s par notre prestataire de paiement
					LEMONWAY. Il effectue des traitements de donn&eacute;es
					personnelles dont il est responsable, comme d&eacute;crit
					dans sa politique de confidentialit&eacute; accessible sur
					le lien suivant&nbsp;:&nbsp;
				</span>
				<span class="c4 c8">
					<a
						class="c5"
						href="https://www.google.com/url?q=https://www.lemonway.com/conditions-generales-dutilisation/&amp;sa=D&amp;source=editors&amp;ust=1679077988459975&amp;usg=AOvVaw0_HGFu1eBekknpTBNrm9gu"
					>
						https://www.lemonway.com/conditions-generales-dutilisation/
					</a>
				</span>
			</p>
			<p class="c6">
				<span class="c11">
					4. Quelles sont les donn&eacute;es personnelles qui sont
					collect&eacute;es me concernant ?
				</span>
			</p>
			<p class="c6">
				<span class="c10">
					4.1 Cat&eacute;gories de donn&eacute;es trait&eacute;es
				</span>
			</p>
			<p class="c1">
				<span class="c0">
					Nous sommes amen&eacute;s &agrave; collecter et &agrave;
					traiter les donn&eacute;es personnelles suivantes :
				</span>
			</p>
			<ul class="c3 lst-kix_list_4-0 start">
				<li class="c9 li-bullet-0">
					<span class="c0">
						Donn&eacute;es d&rsquo;identification, nom,
						pr&eacute;nom, adresse postale, pays de r&eacute;sidence
						et num&eacute;ro de t&eacute;l&eacute;phone ;
					</span>
				</li>
				<li class="c9 li-bullet-0">
					<span class="c0">
						Donn&eacute;es n&eacute;cessaires au paiement des
						dons&nbsp;: coordonn&eacute;es bancaires (code de carte
						bancaire, cryptogramme visuel &ndash; uniquement afin
						d&rsquo;assurer la transaction &ndash;, nom et
						pr&eacute;nom du titulaire de la carte
					</span>
				</li>
				<li class="c9 li-bullet-0">
					<span class="c0">
						Donn&eacute;es n&eacute;cessaires &agrave; la
						r&eacute;ception des dons : IBAN, document justifiant
						votre identit&eacute; (Carte d&rsquo;identit&eacute;
						et/ou Passeport et/ou permis de conduire et/ou
						Justificatif de travail. Sans que cette liste soit
						exhaustive.)
					</span>
				</li>
				<li class="c9 li-bullet-0">
					<span class="c0">
						Donn&eacute;es relatives &agrave; la navigation sur le
						site web (horodatage, adresse IP des usagers,
						donn&eacute;es techniques relatives &agrave;
						l&rsquo;&eacute;quipement et au navigateur
						utilis&eacute;s par les usagers, cookies).
					</span>
				</li>
			</ul>
			<p class="c6">
				<span class="c10">
					4.2 Caract&egrave;re obligatoire du recueil des
					donn&eacute;es personnelles
				</span>
			</p>
			<p class="c1">
				<span class="c0">
					Dans tous les cas, le caract&egrave;re obligatoire des
					informations est indiqu&eacute; par un ast&eacute;risque (*)
					&agrave; c&ocirc;t&eacute; des champs concern&eacute;s lors
					de leur collecte. Dans cette hypoth&egrave;se, seules les
					donn&eacute;es personnelles strictement n&eacute;cessaires
					au regard des finalit&eacute;s d&eacute;crites ci-dessus
					sont collect&eacute;es. En cas d&rsquo;absence
					d&rsquo;ast&eacute;risque lors de leur collecte, les
					informations demand&eacute;es sont facultatives.
				</span>
			</p>
			<p class="c6">
				<span class="c10">
					4.3 R&egrave;gles d&rsquo;utilisation des champs libres
				</span>
			</p>
			<p class="c1">
				<span class="c0">
					Lorsqu&rsquo;un formulaire (avis, contacts) propose un champ
					libre, nous vous demandons de n&rsquo;y indiquer aucune
					information personnelle et de vous limiter aux informations
					strictement n&eacute;cessaires au traitement de votre
					demande.
				</span>
			</p>
			<p class="c1">
				<span class="c7">
					Nous nous r&eacute;servons le droit de supprimer toute
					information superflue &agrave; votre demande initiale.
				</span>
			</p>
			<p class="c1">
				<span class="c0">
					Nous ne collectons pas de donn&eacute;es sensibles vous
					concernant.
				</span>
			</p>
			<p class="c1">
				<span class="c0">
					Nous vous rappelons que sont des donn&eacute;es sensibles
					les donn&eacute;es qui r&eacute;v&egrave;lent
					l&rsquo;origine raciale ou ethnique, les opinions
					politiques, les convictions religieuses ou philosophiques ou
					l&rsquo;appartenance syndicale, ainsi que le traitement des
					donn&eacute;es g&eacute;n&eacute;tiques, des donn&eacute;es
					biom&eacute;triques aux fins d&rsquo;identifier une personne
					physique de mani&egrave;re unique, des donn&eacute;es
					concernant la sant&eacute; ou des donn&eacute;es concernant
					la vie sexuelle ou l&rsquo;orientation sexuelle d&rsquo;une
					personne physique.
				</span>
			</p>
			<p class="c1">
				<span class="c7">
					Si vous nous communiquez volontairement des donn&eacute;es
					sensibles, notamment dans le cadre de vos demandes
					sp&eacute;ciales, nous consid&eacute;rerons que vous jugez
					utile cette transmission d&rsquo;informations
					compl&eacute;mentaires. Par cette transmission
					d&rsquo;information, vous consentez d&egrave;s lors
					explicitement &agrave; ce que nous traitions ces
					cat&eacute;gories particuli&egrave;res de donn&eacute;es
					personnelles. N&eacute;anmoins, si nous jugeons que ces
					informations ne sont pas pertinentes au regard de votre
					demande, nous nous r&eacute;servons le droit de les
					supprimer imm&eacute;diatement.
				</span>
			</p>
			<p class="c6">
				<span class="c10">
					4.4 Exactitude des donn&eacute;es communiqu&eacute;es
				</span>
			</p>
			<p class="c1">
				<span class="c0">
					Vous vous d&eacute;clarez, en tant qu&rsquo;utilisateur de
					l&rsquo;application, inform&eacute;(e) de l&rsquo;importance
					de l&rsquo;exactitude des donn&eacute;es personnelles vous
					concernant et que vous renseignez au travers de
					l&rsquo;application et/ou dans le cadre de vos
					&eacute;changes avec nous.
				</span>
			</p>
			<p class="c1">
				<span class="c0">
					Aussi vous engagez vous &agrave; ne renseigner que des
					donn&eacute;es personnelles exactes lors de vos
					&eacute;changes avec nous, de vos demandes et inscription,
					et pendant toute la dur&eacute;e de l&rsquo;utilisation de
					l&rsquo;application et &agrave; les mettre &agrave; jour
					imm&eacute;diatement si une ou plusieurs de ces
					donn&eacute;es personnelles venaient &agrave; changer au
					cours de ladite dur&eacute;e d&rsquo;utilisation de
					l&rsquo;application.
				</span>
			</p>
			<p class="c1">
				<span class="c4">
					&Agrave; ce titre, vous pouvez notamment faire une demande
					d&rsquo;acc&egrave;s et de rectification, ainsi que
					d&rsquo;opposition ou d&rsquo;effacement telle que
					pr&eacute;vue (entre autres) &agrave; l&rsquo;article 8
					&ldquo;
				</span>
				<span class="c4 c8">
					<a
						class="c5"
						href="https://www.google.com/url?q=https://joingrant.com/donnees-personnelles/%23droits&amp;sa=D&amp;source=editors&amp;ust=1679077988463095&amp;usg=AOvVaw2WknrY6H0OlwN7EfK51Yuh"
					>
						Quels sont mes droits au regard de l&rsquo;utilisation
						des donn&eacute;es personnelles&nbsp;?
					</a>
				</span>
				<span class="c0">
					&rdquo; de la pr&eacute;sente Politique de protection des
					donn&eacute;es personnelles.
				</span>
			</p>
			<p class="c6">
				<span class="c11">
					5. Qui sont les destinataires de mes donn&eacute;es ?
				</span>
			</p>
			<p class="c6">
				<span class="c10">5.1 Cat&eacute;gories de destinataires</span>
			</p>
			<p class="c1">
				<span class="c0">
					En fonction de leurs besoins respectifs, sont destinataires
					de tout ou partie des donn&eacute;es&nbsp;:
				</span>
			</p>
			<ul class="c3 lst-kix_list_5-0 start">
				<li class="c9 li-bullet-0">
					<span class="c0">
						le personnel habilit&eacute; de GRANT en charge de la
						publication des contenus, de l&rsquo;administration
						technique de l&rsquo;application ou du traitement des
						demandes re&ccedil;ues via les formulaires de
						contact&nbsp;;
					</span>
				</li>
				<li class="c9 li-bullet-0">
					<span class="c0">
						le personnel de GRANT responsable de la
						s&eacute;curit&eacute; des syst&egrave;mes
						d&rsquo;information&nbsp;;
					</span>
				</li>
				<li class="c9 li-bullet-0">
					<span class="c0">
						lesdites donn&eacute;es pourront &ecirc;tre
						transmises&nbsp;&agrave; des prestataires techniques
						participant &agrave; la r&eacute;alisation de ces
						finalit&eacute;s, &agrave; titre d&rsquo;information,
						les donn&eacute;es concernant nos applications sont
						h&eacute;berg&eacute;es par les serveurs de Google
						&agrave; Francfort, Allemagne (zone europe-west3).
					</span>
				</li>
				<li class="c9 li-bullet-0">
					<span class="c0">
						&agrave; notre prestataire de services de paiement en
						ligne (LEMONWAY) afin d&rsquo;assurer la
						r&eacute;alisation effective des transferts de fonds et
						leur permettre de remplir leurs propres diligences et
						obligations en mati&egrave;re de lutte contre la fraude,
						le blanchiment et le financement du terrorisme.
					</span>
				</li>
				<li class="c9 li-bullet-0">
					<span class="c0">
						aux organismes et autorit&eacute;s publics, auxiliaires
						de justice, personnes charg&eacute;es du contr&ocirc;le
						financier (commissaires aux comptes,
						experts-comptables), entit&eacute;s charg&eacute;es de
						la lutte contre la fraude, le blanchiment de capitaux et
						le financement du terrorisme, et ce exclusivement afin
						de r&eacute;pondre &agrave; nos obligations
						l&eacute;gales.
					</span>
				</li>
			</ul>
			<p class="c6">
				<span class="c10">
					5.2 Transfert des donn&eacute;es personnelles en dehors de
					l&rsquo;Union Europ&eacute;enne
				</span>
			</p>
			<p class="c1">
				<span class="c0">
					Dans le cadre de l&rsquo;utilisation de l&rsquo;application,
					il n&rsquo;est pas pr&eacute;vu de transfert de
					donn&eacute;es &agrave; caract&egrave;re personnel &agrave;
					destination de pays ne disposant pas d&rsquo;un niveau de
					protection ad&eacute;quat au sens de la
					r&eacute;glementation applicable.
				</span>
			</p>
			<p class="c6">
				<span class="c11">
					6. Quelle est la dur&eacute;e de conservation de mes
					donn&eacute;es&nbsp;?
				</span>
			</p>
			<p class="c1">
				<span class="c0">
					Les donn&eacute;es personnelles collect&eacute;es ne sont
					conserv&eacute;es que pour la dur&eacute;e strictement
					n&eacute;cessaire &agrave; l&rsquo;accomplissement des
					finalit&eacute;s pour lesquelles elles sont
					collect&eacute;es et trait&eacute;es&nbsp;:
				</span>
			</p>
			<ul class="c3 lst-kix_list_6-0 start">
				<li class="c9 li-bullet-0">
					<span class="c7">Compte utilisateur&nbsp;</span>
					<span class="c0">
						: les donn&eacute;es relatives au compte utilisateur
						seront conserv&eacute;es pendant la dur&eacute;e
						d&rsquo;activit&eacute; du compte de l&rsquo;utilisateur
						et jusqu&rsquo;&agrave; la fermeture de celui-ci. En cas
						d&rsquo;inactivit&eacute; du compte pendant une
						p&eacute;riode ininterrompue de trois (3) ans, une
						notification sera adress&eacute;e &agrave;
						l&rsquo;utilisateur et, en absence de nouvelle connexion
						dans le mois suivant la notification, le compte sera
						ferm&eacute; et les donn&eacute;es personnelles qui y
						sont attach&eacute;es supprim&eacute;es.
					</span>
				</li>
				<li class="c9 li-bullet-0">
					<span class="c7">
						Donn&eacute;e relatives aux comptes utilisateurs et
						&eacute;mission de dons ou r&eacute;ception de dons
					</span>
					<span class="c0">
						&nbsp;: pendant la dur&eacute;e d&rsquo;activit&eacute;
						du compte de l&rsquo;utilisateur, puis 5 ans &agrave;
						compter de la demande de cl&ocirc;ture du compte (pour
						respecter la prescription l&eacute;gale). En cas
						d&rsquo;inactivit&eacute; du compte pendant 5 ans sans
						connexion, nous vous demanderons, si vous souhaitez
						conserver votre compte. Si vous souhaitez le
						cl&ocirc;turer, vos donn&eacute;es seront
						supprim&eacute;es dans les 3 mois &agrave; compter de
						votre demande
					</span>
				</li>
				<li class="c9 li-bullet-0">
					<span class="c7">Cookies</span>
					<span class="c4">
						&nbsp;: les donn&eacute;es n&eacute;cessaires &agrave;
						la production de statistiques d&rsquo;audience et
						d&rsquo;utilisation des services en ligne sont
						conserv&eacute;es dans un format ne permettant pas
						l&rsquo;identification des personnes par leur adresse
						IP, et comportent un identifiant (relatif au cookie)
						conserv&eacute; pour une dur&eacute;e maximale de treize
						mois sauf retrait du consentement de la personne
						concern&eacute;e.&nbsp;
					</span>
					<span class="c4 c8">
						<a
							class="c5"
							href="https://www.google.com/url?q=https://joingrant.com/cookies/&amp;sa=D&amp;source=editors&amp;ust=1679077988466258&amp;usg=AOvVaw3b-M_ZnGtAU1zs1xFLGOrp"
						>
							Pour en savoir plus sur les cookies
						</a>
					</span>
				</li>
				<li class="c9 li-bullet-0">
					<span class="c7">
						R&eacute;clamation et demande d&rsquo;exercice de droits
					</span>
					<span class="c0">
						&nbsp;: vos donn&eacute;es sont archiv&eacute;es selon
						les d&eacute;lais de prescriptions l&eacute;gales de
						mani&egrave;re &agrave; nous permettre de constater et
						exercer nos droits en justice.
					</span>
				</li>
				<li class="c9 li-bullet-0">
					<span class="c7">
						Donn&eacute;es relatives &agrave; nos obligations de
						lutte contre la fraude
					</span>
					<span class="c0">
						, le blanchiment de capitaux et le financement du
						terrorisme&nbsp;: 5 ans &agrave; compter de la cessation
						de la relation contractuelle ou de la cl&ocirc;ture de
						votre compte s&rsquo;agissant des donn&eacute;es et
						documents d&rsquo;identit&eacute;. 5 ans &agrave;
						compter de l&rsquo;ex&eacute;cution de
						l&rsquo;op&eacute;ration financi&egrave;re en question
						s&rsquo;agissant des documents et informations relatifs
						aux op&eacute;rations effectu&eacute;es
					</span>
				</li>
			</ul>
			<p class="c6">
				<span class="c11">
					7. Comment GRANT prot&egrave;ge-t-elle mes
					donn&eacute;es&nbsp;?
				</span>
			</p>
			<p class="c1">
				<span class="c0">
					Toutes les mesures utiles sont prises pour pr&eacute;server
					la s&eacute;curit&eacute; et la confidentialit&eacute; des
					donn&eacute;es et notamment, emp&ecirc;cher qu&rsquo;elles
					soient endommag&eacute;es, ou que des tiers non
					autoris&eacute;s y aient acc&egrave;s. &Agrave; cette fin,
					nous mettons en &oelig;uvre des mesures techniques et
					organisationnelles pour garantir un niveau de
					s&eacute;curit&eacute; ad&eacute;quat et adapt&eacute; aux
					risques.
				</span>
			</p>
			<p class="c6">
				<span class="c11">
					8. Quels sont mes droits au regard de l&rsquo;utilisation
					des donn&eacute;es personnelles ?
				</span>
			</p>
			<p class="c1">
				<span class="c0">
					S&rsquo;agissant de vos donn&eacute;es personnelles, vous
					disposez des droits suivants, dans les limites des
					dispositions l&eacute;gales&nbsp;:
				</span>
			</p>
			<ul class="c3 lst-kix_list_7-0 start">
				<li class="c9 li-bullet-0">
					<span class="c0">droit d&rsquo;acc&egrave;s ;</span>
				</li>
				<li class="c9 li-bullet-0">
					<span class="c0">droit de rectification ;</span>
				</li>
				<li class="c9 li-bullet-0">
					<span class="c0">droit &agrave; l&rsquo;effacement ;</span>
				</li>
				<li class="c9 li-bullet-0">
					<span class="c0">
						droit &agrave; la limitation d&rsquo;un ou plusieurs
						traitements des donn&eacute;es personnelles ;
					</span>
				</li>
				<li class="c9 li-bullet-0">
					<span class="c0">
						droits de modification et/ou de retrait, &agrave; tout
						moment, des consentements concernant le traitement de
						ses donn&eacute;es personnelles reposant sur son
						consentement ;
					</span>
				</li>
				<li class="c9 li-bullet-0">
					<span class="c0">
						droit d&rsquo;opposition &agrave; un traitement de ses
						donn&eacute;es personnelles ;
					</span>
				</li>
				<li class="c9 li-bullet-0">
					<span class="c0">
						droit &agrave; la portabilit&eacute; de vos
						donn&eacute;es personnelles.
					</span>
				</li>
				<li class="c9 li-bullet-0">
					<span class="c0">
						droit de d&eacute;finir des directives
						g&eacute;n&eacute;rales et/ou particuli&egrave;res
						relatives au sort de ses donn&eacute;es personnelles et
						&agrave; la mani&egrave;re dont il souhaite que ses
						droits soient exerc&eacute;s apr&egrave;s son
						d&eacute;c&egrave;s.
					</span>
				</li>
			</ul>
			<p class="c1">
				<span class="c0">
					Selon le type de droit que vous exercez et selon les
					informations que vous demandez/souhaitez supprimer, il est
					possible que GRANT vous demande de d&eacute;montrer votre
					identit&eacute;.
				</span>
			</p>
			<p class="c1">
				<span class="c0">
					Il est possible de formuler toute demande d&rsquo;exercice
					de vos droits relatifs &agrave; vos donn&eacute;es
					personnelles en nous contactant :
				</span>
			</p>
			<ul class="c3 lst-kix_list_8-0 start">
				<li class="c9 li-bullet-0">
					<span class="c0">
						par email&nbsp;: privacy@joingrant.com
					</span>
				</li>
				<li class="c9 li-bullet-0">
					<span class="c0">
						par courrier &agrave; l&rsquo;adresse suivante&nbsp;:
						Grant Solutions, 8 rue de l&rsquo;Est 92100
						Boulogne-Billancourt, France
					</span>
				</li>
			</ul>
			<p class="c1">
				<span class="c0">
					En cas d&rsquo;absence de r&eacute;ponse ou d&rsquo;absence
					de r&eacute;ponse satisfaisante dans les deux mois de votre
					demande, aupr&egrave;s de la Commission Nationale de
					l&rsquo;Informatique et des Libert&eacute;s (CNIL)&nbsp;au 3
					Place de Fontenoy, TSA 80715, 75334 Paris Cedex 07 France
					&ndash; T&eacute;l : 01 53 73 22 22
				</span>
			</p>
			<p class="c1">
				<span class="c4">Ou via ce formulaire&nbsp;:&nbsp;</span>
				<span class="c4 c8">
					<a
						class="c5"
						href="https://www.google.com/url?q=https://www.cnil.fr/fr/webform/adresser-une-plainte&amp;sa=D&amp;source=editors&amp;ust=1679077988469542&amp;usg=AOvVaw3FDraer8ta4F0pGy7Mmd5d"
					>
						https://www.cnil.fr/fr/webform/adresser-une-plainte
					</a>
				</span>
				<span class="c0">&nbsp;</span>
			</p>
			<p class="c1">
				<span class="c4">
					&Agrave; toutes fins utiles, vous trouverez ci-apr&egrave;s
					des informations compl&eacute;mentaires quant &agrave; vos
					droits&nbsp;:&nbsp;
				</span>
				<span class="c4 c8">
					<a
						class="c5"
						href="https://www.google.com/url?q=https://www.cnil.fr/fr/les-droits-pour-maitriser-vos-donnees-personnelles&amp;sa=D&amp;source=editors&amp;ust=1679077988470251&amp;usg=AOvVaw13_x5PNogSlOLKsiC8DE3U"
					>
						https://www.cnil.fr/fr/les-droits-pour-maitriser-vos-donnees-personnelles
					</a>
				</span>
			</p>
			<p class="c6">
				<span class="c11">
					9. Les modifications de la pr&eacute;sente politique
				</span>
			</p>
			<p class="c1">
				<span class="c0">
					La pr&eacute;sente politique a vocation &agrave;
					&eacute;voluer et est donc susceptible d&rsquo;&ecirc;tre
					modifi&eacute;e. En cas de modifications mineures, la
					nouvelle politique sera mise en ligne sur
					l&rsquo;application &agrave; la rubrique
					d&eacute;di&eacute;e. En cas de modifications substantielles
					portant par exemple sur les finalit&eacute;s ou les
					destinataires, vous pourriez en &ecirc;tre tenu directement
					inform&eacute;(e).
				</span>
			</p>
			<p class="c16">
				<span class="c2">
					&copy; copyright 2021 grant solutions SAS
				</span>
			</p>
			<p class="c16">
				<span class="c12 c8">
					<a
						class="c5"
						href="https://www.google.com/url?q=https://joingrant.com/donnees-personnelles/&amp;sa=D&amp;source=editors&amp;ust=1679077988471282&amp;usg=AOvVaw2Ww0rvHIunYr1lQWfQxjw2"
					>
						Confidentialit&eacute;
					</a>
				</span>
				<span class="c12">&nbsp;|&nbsp;</span>
				<span class="c12 c8">
					<a
						class="c5"
						href="https://www.google.com/url?q=https://joingrant.com/conditions-site/&amp;sa=D&amp;source=editors&amp;ust=1679077988471784&amp;usg=AOvVaw356CO-Fw7GxDHt5oaRSLPs"
					>
						Conditions
					</a>
				</span>
				<span class="c12">&nbsp;|&nbsp;</span>
				<span class="c12 c8">
					<a
						class="c5"
						href="https://www.google.com/url?q=https://joingrant.com/cookies/&amp;sa=D&amp;source=editors&amp;ust=1679077988472156&amp;usg=AOvVaw1Nhn6v_X5z1U-jdtz0VX1C"
					>
						Cookies
					</a>
				</span>
				<span class="c12">&nbsp;|&nbsp;</span>
				<span class="c12 c8">
					<a
						class="c5"
						href="https://www.google.com/url?q=https://joingrant.com/mentions/&amp;sa=D&amp;source=editors&amp;ust=1679077988472584&amp;usg=AOvVaw23_9KP2SQ_i1iv3YCz2CgC"
					>
						Mentions l&eacute;gales
					</a>
				</span>
			</p>
			<p class="c18">
				<span class="c14"></span>
			</p>
		</div>
	);
};

export default Content;
