import { useState } from "react";
import { RouterProvider } from "react-router-dom";

// import "@picocss/pico/scss/pico";
import "./styles/index.scss";

import Router from "./router";
import { getStrings, TranslationsContext } from "./services/intl";

const deviceLanguage = navigator.language || navigator.userLanguage || "fr";

const localSettings = {
	locale: deviceLanguage,
	strings: getStrings(deviceLanguage),
};

function App() {
	const [settings, setSettings] = useState(localSettings);

	const Localization = {
		changeLocale: (locale) => {
			setSettings((s) =>
				Object.assign({}, s, {
					locale: locale,
					strings: getStrings(locale),
				})
			);
		},
		toggleLanguage: () => {
			const newLang = settings.locale.toLowerCase().includes("fr")
				? "en"
				: "fr";
			setSettings((s) =>
				Object.assign({}, s, {
					locale: newLang,
					strings: getStrings(newLang),
				})
			);
		},
		getFlag: () => {
			return `assets/icons/${
				settings.locale.toLowerCase().includes("fr")
					? "en.png"
					: "fr.png"
			}`;
		},
		getLocale: () => {
			return settings.locale.toLowerCase().includes("fr") ? "fr" : "en";
		},
		get: (label) => {
			return (
				settings.strings[label] || `<<< MISSING STRING FOR ${label} >>>`
			);
		},
	};
	return (
		<TranslationsContext.Provider value={Localization}>
			<main data-theme="dark">
				<RouterProvider router={Router} />
			</main>
		</TranslationsContext.Provider>
	);
}

export default App;
