import { useContext } from "react";
import { Link } from "react-router-dom";
import { TranslationsContext } from "../services/intl";

const links = [
	{
		label: "about",
		to: "/about",
	},
	{
		label: "how_works",
		to: "/how-it-works",
	},
	{
		label: "grant_business",
		to: "/",
		disabled: true,
	},
	{
		label: "support",
		to: "https://wa.me/41787400957?text=",
	},
];

const legals = [
	{
		label: "privacy",
		to: "/privacy",
	},
	{
		label: "terms",
		to: "/terms",
	},
];

const FooterItem = ({ label, to, disabled = false }) => {
	const Locale = useContext(TranslationsContext);

	return (
		<li className="py-2 mr-4 md:mr-6">
			{disabled ? (
				<>
					<span className="mr-2 cursor-not-allowed">
						{Locale.get(label)}
					</span>
					<span className="text-xs font-bold px-2 py-1 bg-stone-800 rounded">
						{Locale.get("not_available")}
					</span>
				</>
			) : (
				<Link className="mr-2" to={to}>
					{Locale.get(label)}
				</Link>
			)}
		</li>
	);
};

const Footer = () => {
	const Locale = useContext(TranslationsContext);

	return (
		<footer className="px-4 py-10 mt-3 bg-neutral-900">
			<div className="container mx-auto">
				<h6>{Locale.get("more_about_grant")}</h6>
				<ul className="flex flex-wrap flex-col mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
					{links.map((l, index) => (
						<FooterItem key={`about-footer-${index}`} {...l} />
					))}
				</ul>
				<h6 className="mt-10">{Locale.get("legals")}</h6>
				<ul className="flex flex-wrap flex-col mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
					{legals.map((l, index) => (
						<FooterItem key={`legals-footer-${index}`} {...l} />
					))}
				</ul>
				<br />
				<span className="text-sm mt-10 sm:text-center text-gray-400">
					© 2024 <Link to="/">Grant Solutions</Link>.{" "}
					{Locale.get("all_rights_reserved")}
				</span>
			</div>
		</footer>
	);
};

export default Footer;
