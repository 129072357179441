import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TranslationsContext } from "../services/intl";

const Navbar = () => {
	const Locale = useContext(TranslationsContext);
	const navigate = useNavigate();
	return (
		<nav>
			<ul>
				<li></li>
			</ul>
			<ul>
				<li
					className="nav-clickable"
					data-replace={Locale.get("privacy")}
					onClick={() => navigate("/privacy")}
				>
					<span>{Locale.get("privacy")}</span>
				</li>
				<li
					className="nav-clickable"
					data-replace={Locale.get("terms")}
					onClick={() => navigate("/terms")}
				>
					<span>{Locale.get("terms")}</span>
				</li>
				<li
					className="nav-clickable"
					data-replace={Locale.get("business")}
				>
					<span>{Locale.get("business")}</span>
				</li>
				<li
					className="nav-clickable"
					data-replace={Locale.get("contact")}
					onClick={() =>
						window.open("https://wa.me/41787400957?text=")
					}
				>
					<span>{Locale.get("contact")}</span>
				</li>
				<li
					className="nav-clickable"
					onClick={() => Locale.toggleLanguage()}
				>
					<img
						style={{ height: 18, width: "auto" }}
						src={Locale.getFlag()}
						alt="language change"
					/>
				</li>
			</ul>
		</nav>
	);
};

export default Navbar;
