const Content = () => {
	return (
		<div class="c24 doc-content">
			<div>
				<p class="c9 c25">
					<span class="c19 c16"></span>
				</p>
			</div>
			<h1 class="c5 c23" id="h.gjdgxs">
				<span class="c16 c22">
					Terms and Conditions of Use of the Site
				</span>
			</h1>
			<p class="c0">
				<span class="c1">26 February 2023</span>
			</p>
			<p class="c0">
				<span>
					GRANT SOLUTIONS (&quot;GRANT&quot;) is a simplified joint
					stock company with a capital of 49,600 Euros, registered in
					the Nanterre Trade and Companies Register under no. 888 360
					757, whose registered office is located at 8 rue de
					l&#39;Est, 92100 Boulogne-Billancourt, France (EU VAT no.{" "}
				</span>
				<span class="c16">FR08888360757</span>
				<span>) and which publishes the website </span>
				<span class="c15">
					<a
						class="c7"
						href="https://www.google.com/url?q=http://www.joingrant.com&amp;sa=D&amp;source=editors&amp;ust=1679077747469713&amp;usg=AOvVaw2THlYoIrRavOkR55-3HiJI"
					>
						www.joingrant.com
					</a>
				</span>
				<span>&nbsp;(hereinafter referred to as the &quot;</span>
				<span class="c8">Site</span>
				<span>
					&quot;) and the mobile phone applications &quot;Grant&quot;
					and &quot;Grant T&quot; (hereinafter referred to as &quot;
				</span>
				<span class="c8">Application(s)</span>
				<span>
					&quot;). If you have any questions, you can contact us via{" "}
				</span>
				<span class="c15">
					<a class="c7" href="mailto:contact@joingrant.com">
						contact@joingrant.com
					</a>
				</span>
				<span class="c1">
					&nbsp;or by telephone on +33 (0)9 77 19 59 12 (price of a
					call according to your telephone operator&#39;s rates).
				</span>
			</p>
			<p class="c0">
				<span>
					The Site is a showcase site accessible to any person with
					Internet access (hereinafter the &quot;
				</span>
				<span class="c8">Visitors</span>
				<span>
					&quot;) and intended to present the Applications and the
					Services that can be provided from these Applications. The
					Visitors and GRANT are hereinafter collectively referred to
					as the &quot;
				</span>
				<span class="c8">Parties</span>
				<span>&quot; and individually each as a &quot;</span>
				<span class="c8">Party</span>
				<span class="c1">&quot;.</span>
			</p>
			<p class="c0">
				<span>
					These general terms and conditions of use of the Site
					(&quot;
				</span>
				<span class="c8">GTCU&quot;</span>
				<span class="c1">
					) determine the conditions of use of the Site by Visitors.
					Your use of the Site is subject to your acceptance of these
					TOS. You may keep a copy of the GTUA on your computer or on
					any other medium, in particular by printing it out to keep a
					hard copy.
				</span>
			</p>
			<p class="c0">
				<span>
					Separately from this Site, GRANT provides Applications where
					it acts as an agent of LEMONWAY, which is a payment service
					provider. PSP:{" "}
				</span>
				<span class="c8">Lemonway</span>
				<span>
					, a simplified joint stock company whose registered office
					is located at 8 rue du Sentier, 75002 Paris, France,
					registered in the Paris Trade and Companies Register under
					the number 500 486 915 (
				</span>
				<span class="c8">&quot;Lemonway&quot;</span>
				<span>
					), approved on 24 December 2012 by the Autorit&eacute; de
					Contr&ocirc;le Prudentiel et de R&eacute;solution
					(&quot;ACPR&quot;, France, website acpr.banque-france.fr/),
					4 place de Budapest CS 92459, 75436 Paris, as a payment
					institution, under number 16568 and whose terms and
					conditions of use of services are available{" "}
				</span>
				<span class="c14">
					<a
						class="c7"
						href="https://www.google.com/url?q=https://www.lemonway.com/conditions-generales-dutilisation/&amp;sa=D&amp;source=editors&amp;ust=1679077747471367&amp;usg=AOvVaw1oNWuVb7j21z_UjKXYzozI"
					>
						here
					</a>
				</span>
				<span class="c1">.</span>
			</p>
			<p class="c0">
				<span>
					To act as LEMONWAY&#39;s agent, GRANT is registered as an
					agent of payment service providers (&quot;
				</span>
				<span class="c8">APSP</span>
				<span>
					&quot;) under the number [94455], on [20/09/2021], with the
					Autorit&eacute; de Contr&ocirc;le Prudentiel et de
					R&eacute;solution (&quot;
				</span>
				<span class="c8">ACPR&quot;</span>
				<span>
					), established at 4 Place de Budapest - 75436 Paris Cedex
					09. GRANT is registered as such in the register of financial
					agents available at{" "}
				</span>
				<span class="c15">
					<a
						class="c7"
						href="https://www.google.com/url?q=https://www.regafi.fr/spip.php?page%3Dresults%26type%3Dsimple%26id_secteur%3D1%26lang%3Dfr%26denomination%3DLemonway&amp;sa=D&amp;source=editors&amp;ust=1679077747471982&amp;usg=AOvVaw2f7FpNxZRGupz91gXKQZa3"
					>
						https://www.regafi.fr/spip.php?page=results&amp;type=simple&amp;id_secteur=1&amp;lang=fr&amp;denomination=Lemonway
					</a>
				</span>
			</p>
			<h2 class="c2" id="h.30j0zll">
				<span class="c4">Article 1. Definitions</span>
			</h2>
			<p class="c0 c5">
				<span class="c1">
					Words beginning with a capital letter, used in the singular
					or plural, have the meaning given below:
				</span>
			</p>
			<ol class="c11 lst-kix_list_1-0 start" start="1">
				<li class="c3 li-bullet-0">
					<span class="c8">Application</span>
					<span class="c1">
						: The &quot;Grant&quot; and/or &quot;Grant T&quot;
						applications can be downloaded from the App Store and
						Google Store.
					</span>
				</li>
				<li class="c10 c5 li-bullet-0">
					<span class="c8">CGUA</span>
					<span class="c1">
						: The general conditions of use of the Applications.
					</span>
				</li>
				<li class="c10 c5 li-bullet-0">
					<span class="c8">CGUS</span>
					<span class="c1">
						: The present general conditions of use and their
						annexes.
					</span>
				</li>
				<li class="c10 c5 li-bullet-0">
					<span class="c8">Content</span>
					<span class="c1">
						: The Site and each of its component parts (such as all
						texts, decision-making processes, software, animations,
						photographs, illustrations, images, diagrams,
						soundtracks, texts, logos, trademarks, designs and
						models), including the software elements necessary for
						the operation of the Site, trademarks registered and/or
						owned by GRANT, databases, e-mails and/or the newsletter
						sent automatically to Visitors.
					</span>
				</li>
				<li class="c10 c5 li-bullet-0">
					<span class="c8">Personal Data</span>
					<span class="c1">
						: Any data that directly or indirectly identifies a
						natural person as defined by Article 4 of the GDPR.
					</span>
				</li>
				<li class="c10 c5 li-bullet-0">
					<span class="c8">Infrastructure</span>
					<span class="c1">
						: Hardware and software infrastructure used by GRANT via
						a subcontracted hosting provider to provide the Site.
						The hosting provider is a third party who is solely
						responsible for the quality of the Infrastructure.
					</span>
				</li>
				<li class="c10 c5 li-bullet-0">
					<span class="c8">GDPR</span>
					<span class="c1">
						: General Data Protection Regulation No. 2016/679 of 27
						April 2016
					</span>
				</li>
				<li class="c10 c5 li-bullet-0">
					<span class="c8">Site</span>
					<span>: The GRANT website </span>
					<span class="c14">
						<a
							class="c7"
							href="https://www.google.com/url?q=https://joingrant.com&amp;sa=D&amp;source=editors&amp;ust=1679077747474010&amp;usg=AOvVaw0dF59rH7gYwzCf8XkWS_nF"
						>
							www.joingrant.com
						</a>
					</span>
					<span class="c1">&nbsp;which Visitors can access.</span>
				</li>
				<li class="c5 c10 li-bullet-0">
					<span class="c8">Solution</span>
					<span class="c1">
						: GRANT&#39;s software as a service is available from
						the Applications, as is its maintenance and support.
					</span>
				</li>
				<li class="c20 c5 li-bullet-0">
					<span class="c8">Visitors</span>
					<span class="c1">
						: Any natural or legal person accessing the Site.
					</span>
				</li>
			</ol>
			<h2 class="c2" id="h.1fob9te">
				<span class="c4">Article 2. Acceptance</span>
			</h2>
			<p class="c0">
				<span class="c1">
					All Visitors must read and accept these GCUS before being
					able to use the Site. By accessing and using the Site, you
					are deemed to have accepted without reservation the
					application of these GCUS, as well as any document to which
					the GCUS refers, by the mere fact of accessing and/or using
					the Site.
				</span>
			</p>
			<p class="c0 c9">
				<span class="c1"></span>
			</p>
			<p class="c0">
				<span class="c1">
					The acceptance of the GTCS is complete and forms an
					indivisible whole. The parties may not deviate from them or
					make reservations and may not decide to apply them in part.
					The agreed GTCU shall remain available on the Website at all
					times.
				</span>
			</p>
			<p class="c0">
				<span class="c1">
					If you do not read, understand and/or agree to these TOS,
					you should leave the Site immediately.
				</span>
			</p>
			<h2 class="c2" id="h.3znysh7">
				<span class="c4">Article 3. Contractual documents</span>
			</h2>
			<p class="c0">
				<span class="c16">
					The version of the GCUS applicable to Visitors is the one in
					force on the day the Visitor uses the Site and any update is
					immediately enforceable against the Visitor.{" "}
				</span>
				<span class="c1">
					GRANT may modify the GCUS at any time, including its
					appendices, in order to update them and in particular to
					take into account any legal, jurisprudential, editorial or
					technical developments. The previous GCUS shall then be
					terminated by operation of law and replaced by the new
					version of the GCUS, mentioning the date of the last update
					at the top of the GCUS.
				</span>
			</p>
			<p class="c0">
				<span class="c1">
					In order to be informed of these possible modifications and
					updates, the Visitor is recommended to consult the GCUS
					regularly.
				</span>
			</p>
			<p class="c0">
				<span class="c1">
					The GTUA consists, indivisibly and in decreasing order of
					contractual priority, of (i) the present GTUA and (ii) its
					annexes, in particular the GRANT Privacy Policy and the
					GRANT Cookies Policy. In case of contradiction, ambiguity or
					difference of interpretation between two contractual
					documents, the higher ranking document shall prevail over
					the lower ranking document. If there is a discrepancy
					between two contractual documents of the same rank, the most
					recent one shall prevail over the least recent one.
				</span>
			</p>
			<p class="c0">
				<span class="c1">
					These contractual documents form a whole and constitute the
					entire relationship between Visitors and GRANT, to the
					exclusion of any other document; they cancel and replace all
					previous documents relating to the same subject.
				</span>
			</p>
			<h2 class="c2" id="h.2et92p0">
				<span class="c4">Article 4. Duration</span>
			</h2>
			<p class="c0">
				<span class="c1">
					The GCUS are concluded between GRANT and any Visitor for the
					duration of his/her visit to the Site.
				</span>
			</p>
			<h2 class="c2" id="h.tyjcwt">
				<span class="c4">Article 5. Requirements for use</span>
			</h2>
			<h3 class="c2" id="h.3dy6vkm">
				<span class="c12">5.1. Prerequisites</span>
			</h3>
			<p class="c0">
				<span class="c1">
					Visitors may access and use the Site provided that they (i)
					have the legal capacity to enter into commitments under
					these GCUS and (ii) have the appropriate computer equipment
					to access the Site.
				</span>
			</p>
			<p class="c0">
				<span class="c1">
					Browsing the Site is free of charge, subject to formal
					acceptance of the GCUS.
				</span>
			</p>
			<p class="c0">
				<span class="c1">
					Your access to the Internet, the compatibility and proper
					functioning of your information system (hardware, software,
					firewall, technical protection and network connections) with
					the Site remain at all times your sole responsibility. GRANT
					accepts no responsibility or guarantee in this respect,
					particularly in the event of incompatibility or malfunction
					of your browser with the Site.
				</span>
			</p>
			<h3 class="c2" id="h.1t3h5sf">
				<span class="c12">5.2. Access, use and availability</span>
			</h3>
			<p class="c0">
				<span class="c1">
					The Parties are solely responsible for the hardware,
					software and Internet connection necessary to access the
					Site. Each party is responsible for its own costs, including
					any communication costs, which it incurs as a result. GRANT
					provides deaf, hard of hearing, deaf-blind and aphasic
					persons with a simultaneous written and visual translation
					service at no extra cost to the Visitor, accessible at the
					same contact details during the same opening hours as the
					GRANT customer service.
				</span>
			</p>
			<p class="c0">
				<span class="c1">
					Similarly, you must use the Site for lawful purposes and,
					where applicable, provide accurate, truthful, up-to-date and
					bug-free data.
				</span>
			</p>
			<p class="c0">
				<span class="c1">
					GRANT reserves the right, in its sole discretion, to suspend
					or terminate access to all or any part of the Site, the
					Content, products or services offered on the Site, without
					notice and without compensation. Modifying the software
					components of the Site to gain unauthorized access to the
					Site is strictly prohibited.
				</span>
			</p>
			<p class="c0">
				<span class="c1">
					The Parties acknowledge and accept that, given the current
					state of the art, GRANT cannot guarantee continuous
					availability of the Site, which is provided on an &quot;as
					is&quot; basis.
				</span>
			</p>
			<h3 class="c2" id="h.4d34og8">
				<span class="c12">5.3 Hypertext links</span>
			</h3>
			<p class="c0">
				<span class="c1">
					The Site may contain links to other sites or sources on the
					Internet. Insofar as GRANT cannot control these external
					sites and sources, GRANT cannot be held responsible for the
					content, advertising, products, services or any other
					element available on these external sites or sources. In
					addition, GRANT cannot be held responsible for any damage,
					proven or alleged, direct or indirect, in connection with
					the use of the content, goods or services available on these
					external sites or sources.
				</span>
			</p>
			<h2 class="c2" id="h.2s8eyo1">
				<span class="c4">
					Article 6. Intellectual property of the Content
				</span>
			</h2>
			<p class="c0">
				<span class="c1">
					The Content may contain confidential information and data
					protected by intellectual property or other applicable law.
					Thus, unless otherwise stated on the Site, the intellectual
					property rights in the Content are the exclusive property of
					GRANT and GRANT does not grant you any license or right
					other than to view the Site.
				</span>
			</p>
			<p class="c0">
				<span class="c1">
					Temporary reproduction of all or part of the Content is
					authorised for the sole purpose of using the Site. Any
					reproduction and any use of copies of the Content made for
					other purposes, in any manner and in any form whatsoever,
					are expressly prohibited.
				</span>
			</p>
			<h2 class="c2" id="h.17dp8vu">
				<span class="c4">Article 7. Restrictions on use</span>
			</h2>
			<p class="c0">
				<span class="c1">
					You must not at any time (i) post on the Site or take any
					action that results in the transmission in any manner of
					software viruses or other computer code, files or programs
					designed to interrupt, destroy or limit the functionality of
					any computer software or hardware or telecommunications
					equipment; (ii) disrupt or interfere with the operation of
					the Site, servers or networks connected to the Site or
					violate any terms, conditions, procedures, rules or
					regulations relating to the Site (iii) attempting to
					interfere with the Site, including, without limitation,
					exposing the Site to a virus, causing a traffic jam or
					infrastructure overload (whether intentional or by means of
					a distributed denial of service), sending &quot;spam&quot;
					or otherwise overloading the services provided on the Site;
					(iv) accessing information not intended for you or accessing
					a server or account which you are not authorized to access
					(v) attempting to evaluate, rate or test the vulnerability
					of the Site, or to breach the security or authentication
					measures of the Site without the prior written consent of
					GRANT; (vi) engaging in any illegal activity or any other
					activity which may prejudice the rights of GRANT, its
					suppliers, service providers, partners, Users or any other
					persons, and inciting others to do so; (vii) post on the
					Site or make available by any means unsolicited or
					unauthorised advertising or promotional materials,
					&quot;junk mail&quot;, &quot;spam&quot;, &quot;chain
					letters&quot; or any other form of solicitation (viii)
					impersonate any other person to gain access to the Site; and
					(ix) post on the Site or make available by any means any
					unlawful, harmful, libelous, defamatory, offensive, racist,
					vulgar, obscene, threatening, immoral, invasive of
					another&#39;s privacy, racially or ethnically objectionable
					or otherwise objectionable material.
				</span>
			</p>
			<p class="c0">
				<span class="c1">
					You also agree not to copy, modify, reuse, create derivative
					works from, download, adapt, reverse engineer, emulate,
					migrate to another service, translate, compile, decompile or
					disassemble the Site or any Content and/or any portion
					thereof, or publicly display, perform, transmit or
					distribute any of the foregoing without GRANT&#39;s specific
					prior written permission and/or without being expressly
					permitted to do so under this TOS. Similarly, you agree not
					to purchase any keywords from a search engine or
					pay-per-click service (such as Google Ads) or domain names
					that use the same or similar marks, variations or spelling
					as the name of the Site and any of the Content.
				</span>
			</p>
			<p class="c0">
				<span class="c1">
					You agree not to use any &quot;robot&quot;,
					&quot;spider&quot; or other similar or identical device,
					program, script, algorithm, automatic methodology or other
					manual process to access, acquire, copy or monitor any
					portion of the Site (or its data and/or Content as defined
					above), or to reproduce or misappropriate the navigational
					structure or presentation of the Site in order to obtain or
					attempt to obtain information, documents, services or
					materials in a manner not available through the Site.
				</span>
			</p>
			<p class="c0">
				<span class="c1">
					You agree to comply with all applicable laws, rules and
					procedures regarding online user behaviour and the
					transmission of technical data. &nbsp;GRANT may terminate
					your right to access the Site at any time if you fail to
					comply with your obligations under this TOS, without
					prejudice to GRANT&#39;s right to claim damages.
				</span>
			</p>
			<p class="c0">
				<span class="c1">
					You undertake not to register, under any title whatsoever
					(trademark, design, etc.) nor to develop, use or exploit,
					under any title whatsoever and in any territory whatsoever,
					software, products, services, signs or logos that are
					identical or similar or that are likely to infringe the
					intellectual property rights of GRANT on the Site.
				</span>
			</p>
			<h2 class="c2" id="h.3rdcrjn">
				<span class="c4">Article 8. Financial conditions</span>
			</h2>
			<p class="c0">
				<span>
					The Site can be consulted free of charge by any Visitor.
				</span>
			</p>
			<h2 class="c2" id="h.26in1rg">
				<span>Article 9. Guarantee</span>
			</h2>
			<p class="c0 c5">
				<span class="c1">
					Within the limits of applicable law, GRANT excludes any
					guarantee on the Site. In particular, GRANT does not
					guarantee that the Site is permanently compatible with the
					Visitor&#39;s information system (in particular the browser
					and/or terminal), as GRANT has no control over these, which
					the Visitor acknowledges.
				</span>
			</p>
			<h2 class="c2" id="h.lnxbz9">
				<span class="c4">Article 10. Liability</span>
			</h2>
			<p class="c0">
				<span class="c1">
					GRANT makes every effort to ensure that the information on
					the Site is accurate and up to date. However, GRANT cannot
					guarantee the accuracy, precision or completeness of the
					information made available on the Site.
				</span>
			</p>
			<p class="c0">
				<span class="c1">
					GRANT provides the Site on an &quot;as is&quot; basis
					without warranty of any kind, including any express or
					implied warranties or obligations of quality, fitness for
					purpose, non-infringement, or any other warranty - to the
					fullest extent permitted by applicable law. Specifically, we
					do not represent or warrant that the Site (or any portion,
					feature or Content contained therein) is (or will be)
					complete and accurate, of a certain quality, reliable and
					secure, suitable or compatible with your business,
					terminals, operating systems, browsers, software or tools,
					or compliant with any law applicable to you (including in
					any jurisdiction in which you operate), or that its
					operation will be free of viruses, bugs or other harmful
					components.
				</span>
			</p>
			<p class="c0 c9">
				<span class="c1"></span>
			</p>
			<p class="c0">
				<span class="c1">
					GRANT allows Visitors to access the Site free of charge and
					declines all responsibility (i) for any interruption or
					unavailability of the Site, (ii) in the event of the
					occurrence of bugs, display or download errors, (iii) for
					any inaccuracy or omission in the Content available on the
					Site, (iv) for any damage resulting from the fraudulent
					intrusion of a third party, and (v) more generally, for any
					damage, direct or indirect, whatever the causes or
					consequences, which may arise from access to the Site and
					resulting from information originating directly or
					indirectly from the Site, within the limits authorised by
					applicable law.
				</span>
			</p>
			<p class="c0 c5">
				<span class="c1">
					The Site is provided under a general obligation of means.
					GRANT shall put an end to any unavailability of either
					within a commercially reasonable period of time and shall
					not be held liable in the event of failure of the electronic
					communications networks, slowdown or congestion of the
					Internet or the Visitor&#39;s network, contamination by a
					computer virus, an act of piracy, fraudulent intrusion or
					unauthorised access or intervention by a third party, and
					more generally any deliberate act of degradation, malice,
					sabotage or deterioration or any other case of force majeure
					affecting GRANT or its subcontractors.
				</span>
			</p>
			<p class="c0 c5">
				<span class="c1">
					Each of the Parties shall be liable under common law. GRANT
					may not under any circumstances be held liable for indirect
					damage suffered by the Visitor or third parties (including
					in particular any loss of business, customers, prospects,
					commercial or financial loss, loss of image, loss of profit,
					etc.) resulting from the use of the Site, even if GRANT has
					been warned or could reasonably foresee such damage.
				</span>
			</p>
			<p class="c0">
				<span class="c1">
					GRANT shall not be liable for any cause arising out of force
					majeure, including any such force majeure affecting any of
					its subcontractors, nor for any event beyond its exclusive
					control. You acknowledge and agree that these limitations of
					liability represent an agreement as to the risks inherent in
					the use of the Site provided to you. In the same way, GRANT
					cannot be held liable in the event of a request for
					temporary or permanent interruption of the Site from an
					administrative or judicial authority, and more generally in
					the event of failure, negligence or intentional fault on the
					part of the Visitor or in the event of the intervention of a
					third party not under the control of GRANT.
				</span>
			</p>
			<h2 class="c2" id="h.35nkun2">
				<span class="c4">Article 11. Protection of Personal Data</span>
			</h2>
			<p class="c0 c5">
				<span class="c1">
					Personal Data is collected by GRANT and is processed for the
					purpose of enabling you to access and use the Site, either
					directly when the Visitor consults the Site or indirectly
					when the Visitor transmits Personal Data to the Site.
				</span>
			</p>
			<p class="c0">
				<span>
					GRANT is a company that respects the privacy of its
					Visitors. As such, it respects its obligations under the
					protection of Personal Data and has established a dedicated{" "}
				</span>
				<span class="c14 c6">
					<a
						class="c7"
						href="https://www.google.com/url?q=https://joingrant.com/donnees-personnelles/&amp;sa=D&amp;source=editors&amp;ust=1679077747481405&amp;usg=AOvVaw0vt6HZyyRUhiPjfaZ_3ZzI"
					>
						privacy policy
					</a>
				</span>
				<span class="c1">
					&nbsp;for the purpose of informing data subjects. This
					privacy policy is attached as Annex 1 to the GTUA and forms
					part of the GTUA.
				</span>
			</p>
			<p class="c0">
				<span class="c1">
					GRANT keeps track of Visitors&#39; connections to the Site.
					&nbsp;In addition, GRANT may track Visitors&#39; navigation
					within the Site through the use of cookies. When you access
					and use the Site, cookies are placed on the device you are
					using to browse the Site.
				</span>
			</p>
			<p class="c0">
				<span class="c1">
					A cookie is a text file that is downloaded to your device
					when you browse the Site. Its purpose is to collect
					information about your browsing on the Site and to provide
					you with the features of the Site and/or a better user
					experience of the Site, by exchanging information between
					GRANT and your terminal equipment. Some cookies are
					temporary in nature and limited to one browsing session;
					they are all deleted when you close the browser window. Some
					cookies remain on your device for a period of time after you
					close your browser session, during which time they will be
					activated each time you resume browsing the Site.
				</span>
			</p>
			<p class="c0">
				<span class="c1">
					Some cookies are necessary for the provision of the Site;
					others are optional.
				</span>
			</p>
			<ul class="c11 lst-kix_list_2-0 start">
				<li class="c21 li-bullet-0">
					<span class="c1">
						GRANT informs you and gives you the opportunity to
						accept or decline optional cookies in an information box
						when you first visit the Site.
					</span>
				</li>
				<li class="c20 li-bullet-0">
					<span>You can also consult its dedicated </span>
					<span class="c14 c6">
						<a
							class="c7"
							href="https://www.google.com/url?q=https://joingrant.com/cookies/&amp;sa=D&amp;source=editors&amp;ust=1679077747482232&amp;usg=AOvVaw3oL4P-8-y8yniGy6gdXVOp"
						>
							cookie policy
						</a>
					</span>
					<span class="c1">
						&nbsp;for more information on the cookies used. This
						cookie policy is attached as Annex 2 to these TOS and
						forms part of the TOS.
					</span>
				</li>
			</ul>
			<p class="c9 c18">
				<span class="c1"></span>
			</p>
			<h2 class="c2" id="h.1ksv4uv">
				<span class="c4">Article 12. Miscellaneous</span>
			</h2>
			<p class="c0">
				<span class="c1">
					The GCUS constitute the entire agreement between the Parties
					relating to the use of the Site. As such, the GTUA
					supersedes all previous documents concluded between them in
					this respect.
				</span>
			</p>
			<p class="c0">
				<span class="c1">
					In the event that one or more of the provisions hereof are
					held to be invalid, unenforceable or unenforceable by any
					competent court, the remaining provisions hereof shall
					remain valid unless otherwise provided by such court. The
					Parties agree that in such a case, they shall negotiate in
					good faith replacement provisions that are consistent with
					the original intention of the Parties.
				</span>
			</p>
			<p class="c0">
				<span class="c1">
					The fact that one of the Parties does not avail itself of a
					commitment by the other Party to any of the obligations
					referred to herein shall not be interpreted for the future
					as a waiver of the obligation in question.
				</span>
			</p>
			<h2 class="c5 c17" id="h.44sinio">
				<span class="c4">
					Article 13. Applicable law and jurisdiction
				</span>
			</h2>
			<p class="c0">
				<span class="c1">These GCUS are governed by French law.</span>
			</p>
			<p class="c0">
				<span class="c8">
					Except in the case of application of a rule of public order,
					any dispute relating to the GCUS and the use of the Site is
					subject to the exclusive jurisdiction of the courts of the
					jurisdiction of the Court of Appeal of Paris, even in the
					case of multiple defendants, appeal for guarantee,
					protective proceedings, summary proceedings or petition.
				</span>
			</p>
			<p class="c0">
				<span class="c1">The GCUS contains two Annexes:</span>
			</p>
			<p class="c0">
				<span class="c6">Appendix 1. </span>
				<span class="c14 c6">
					<a
						class="c7"
						href="https://www.google.com/url?q=https://joingrant.com/donnees-personnelles/&amp;sa=D&amp;source=editors&amp;ust=1679077747483584&amp;usg=AOvVaw2XOiUT2OQEnFV77LGbeIMo"
					>
						Privacy Policy
					</a>
				</span>
			</p>
			<p class="c0">
				<span class="c6">Appendix 2. </span>
				<span class="c14 c6">
					<a
						class="c7"
						href="https://www.google.com/url?q=https://joingrant.com/cookies/&amp;sa=D&amp;source=editors&amp;ust=1679077747483905&amp;usg=AOvVaw2IA5B24Id6ec6Yn2itEPb2"
					>
						Cookie Management Policy
					</a>
				</span>
			</p>
			<div>
				<p class="c9 c13">
					<span class="c16 c19"></span>
				</p>
			</div>
		</div>
	);
};

export default Content;
