const ListInfo = ({ items }) => {
	return (
		<ul className="mt-4 list-disc">
			{Object.entries(items).map(([key, value]) => {
				return (
					<li className="mb-4" key={"key"}>
						<span className="font-bold">{key}:</span>
						<span>{value()}</span>
					</li>
				);
			})}
		</ul>
	);
};

export default ListInfo;
