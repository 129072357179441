const Content = () => {
	return (
		<div class="c24 doc-content">
			<div>
				<p class="c19 c21">
					<span class="c13"></span>
				</p>
			</div>
			<h1 class="c3" id="h.gjdgxs">
				<span class="c20 c25">
					Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation du
					Site
				</span>
			</h1>
			<p class="c5">
				<span class="c0">26 f&eacute;vrier 2023</span>
			</p>
			<p class="c5">
				<span>
					GRANT SOLUTIONS (&ldquo;GRANT&rdquo;) est une
					soci&eacute;t&eacute; par actions simplifi&eacute;e au
					capital de 49 600 Euros
				</span>
				<span class="c16">&nbsp;</span>
				<span>
					immatricul&eacute;e au registre du commerce et des
					soci&eacute;t&eacute;s de Nanterre sous le n&deg;
					888&nbsp;360 757, dont le si&egrave;ge social est
					situ&eacute; 8 rue de l&rsquo;Est, 92100
					Boulogne-Billancourt (TVA communautaire n&deg; TVA{" "}
				</span>
				<span class="c20">FR08888360757</span>
				<span>) et qui &eacute;dite le site Internet </span>
				<span class="c18">
					<a
						class="c12"
						href="https://www.google.com/url?q=http://www.joingrant.com&amp;sa=D&amp;source=editors&amp;ust=1679077729843014&amp;usg=AOvVaw3fvCBudKReBGtyp5RCr2-5"
					>
						www.joingrant.com
					</a>
				</span>
				<span>&nbsp;(ci-apr&egrave;s le &ldquo;</span>
				<span class="c4">Site</span>
				<span>
					&rdquo;) et les applications pour t&eacute;l&eacute;phone
					mobile &quot;Grant&quot; et &quot;Grant T&quot;
					(ci-apr&egrave;s la ou les &ldquo;
				</span>
				<span class="c4">Application(s)</span>
				<span>
					&rdquo;). Pour toute question, vous pouvez nous contacter
					via{" "}
				</span>
				<span class="c18">
					<a class="c12" href="mailto:contact@joingrant.com">
						contact@joingrant.com
					</a>
				</span>
				<span class="c0">
					&nbsp;ou par t&eacute;l&eacute;phone au +33 (0)9 77 19 59 12
					(prix d&rsquo;un appel selon les tarifs de votre
					op&eacute;rateur de t&eacute;l&eacute;phonie).
				</span>
			</p>
			<p class="c5">
				<span>
					Le Site est un site vitrine accessible &agrave; toute
					personne disposant d&rsquo;un acc&egrave;s &agrave; Internet
					(ci-apr&egrave;s les &ldquo;
				</span>
				<span class="c4">Visiteurs</span>
				<span>
					&rdquo;) et destin&eacute; &agrave; pr&eacute;senter les
					Applications ainsi que les Prestations qui peuvent
					&ecirc;tre fournies &agrave; partir de ces Applications. Les
					Visiteurs et GRANT sont ci-apr&egrave;s
					d&eacute;sign&eacute;es collectivement comme les &ldquo;
				</span>
				<span class="c4">Parties</span>
				<span>
					&rdquo; et individuellement chacun comme une &ldquo;
				</span>
				<span class="c4">Partie</span>
				<span class="c0">&rdquo;.</span>
			</p>
			<p class="c5">
				<span>
					Les pr&eacute;sentes conditions g&eacute;n&eacute;rales
					d&rsquo;utilisation du Site (&ldquo;
				</span>
				<span class="c4">CGUS</span>
				<span class="c0">
					&rdquo;) d&eacute;terminent les conditions
					d&rsquo;utilisation du Site par les Visiteurs. Votre
					utilisation du Site est soumise &agrave; l&rsquo;acceptation
					des pr&eacute;sentes CGUS. Vous pouvez conserver une copie
					des CGUS sur votre ordinateur ou sur tout autre support,
					notamment en l&rsquo;imprimant afin d&rsquo;en conserver une
					copie papier.
				</span>
			</p>
			<p class="c5">
				<span>
					S&eacute;par&eacute;ment de ce Site, GRANT fournit des
					Applications o&ugrave; elle agit en tant que mandataire de
					LEMONWAY, qui est prestataire de services de paiement.
					PSP&nbsp;:{" "}
				</span>
				<span class="c4">Lemonway</span>
				<span>
					, soci&eacute;t&eacute; par actions simplifi&eacute;e dont
					le si&egrave;ge social est situ&eacute; au 8 rue du Sentier,
					75002 Paris, France, immatricul&eacute;e au registre du
					commerce et des soci&eacute;t&eacute;s de Paris sous le
					num&eacute;ro 500 486 915 (
				</span>
				<span class="c4">&laquo; Lemonway &raquo;</span>
				<span>
					), agr&eacute;&eacute;e le 24 d&eacute;cembre 2012 par
					l&rsquo;Autorit&eacute; de Contr&ocirc;le Prudentiel et de
					R&eacute;solution (&laquo;&nbsp;ACPR&nbsp;&raquo;, France,
					site internet acpr.banque-france.fr/), 4&nbsp;place de
					Budapest CS 92459, 75436 Paris, en qualit&eacute;
					d&rsquo;&eacute;tablissement de paiement, sous le
					num&eacute;ro 16568 et dont les conditions
					d&rsquo;utilisation des services sont disponibles{" "}
				</span>
				<span class="c14">
					<a
						class="c12"
						href="https://www.google.com/url?q=https://www.lemonway.com/conditions-generales-dutilisation/&amp;sa=D&amp;source=editors&amp;ust=1679077729844170&amp;usg=AOvVaw36hd2csQ2L9YHG7UC07ND2"
					>
						ici
					</a>
				</span>
				<span class="c0">.</span>
			</p>
			<p class="c5">
				<span>
					Pour agir en tant que mandataire de LEMONWAY, GRANT est
					enregistr&eacute;e en tant qu&rsquo;agent de prestataires de
					services de paiement (&quot;
				</span>
				<span class="c4">APSP</span>
				<span>
					&quot;) sous le num&eacute;ro [94455], le [20/09/2021],
					aupr&egrave;s de l&rsquo;Autorit&eacute; de Contr&ocirc;le
					Prudentiel et de R&eacute;solution (&quot;
				</span>
				<span class="c4">ACPR</span>
				<span>
					&quot;), &eacute;tablie au 4 Place de Budapest - 75436 Paris
					Cedex 09. GRANT est inscrite &agrave; ce titre sur le
					registre des agents financiers consultable{" "}
				</span>
				<span class="c18">
					<a
						class="c12"
						href="https://www.google.com/url?q=https://www.regafi.fr/spip.php?page%3Dresults%26type%3Dsimple%26id_secteur%3D1%26lang%3Dfr%26denomination%3DLemonway&amp;sa=D&amp;source=editors&amp;ust=1679077729844556&amp;usg=AOvVaw2ky2mk3E7VhmZ88F1iV0QH"
					>
						https://www.regafi.fr/spip.php?page=results&amp;type=simple&amp;id_secteur=1&amp;lang=fr&amp;denomination=Lemonway
					</a>
				</span>
			</p>
			<h2 class="c7" id="h.30j0zll">
				<span class="c8">Article 1. D&eacute;finitions</span>
			</h2>
			<p class="c5 c11">
				<span class="c0">
					Les termes d&eacute;butant par une majuscule,
					utilis&eacute;s au singulier ou au pluriel, ont la
					signification qui leur est donn&eacute;e ci-apr&egrave;s :
				</span>
			</p>
			<ol class="c2 lst-kix_list_1-0 start" start="1">
				<li class="c10 c11 li-bullet-0">
					<span class="c4">Application</span>
					<span class="c0">
						&nbsp;: Les applications &ldquo;Grant&rdquo; et/ou
						&ldquo;Grant T&rdquo; t&eacute;l&eacute;chargeables sur
						App Store et Google Store.
					</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c4">CGUA</span>
					<span class="c0">
						&nbsp;: Les conditions g&eacute;n&eacute;rales
						d&rsquo;utilisation des Applications.
					</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c4">CGUS</span>
					<span class="c0">
						&nbsp;: Les pr&eacute;sentes conditions
						g&eacute;n&eacute;rales d&rsquo;utilisation et leurs
						annexes.
					</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c4">Contenu</span>
					<span class="c0">
						&nbsp;: Le Site et chacun des &eacute;l&eacute;ments qui
						les composent (tels que tous textes, processus de
						d&eacute;cision, logiciels, animations, photographies,
						illustrations, images, sch&eacute;mas, bandes sonores,
						textes, logos, marques, dessins et mod&egrave;les), y
						compris les &eacute;l&eacute;ments logiciels
						n&eacute;cessaires au fonctionnement du Site, les
						marques d&eacute;pos&eacute;es et/ou d&eacute;tenues par
						GRANT, les bases de donn&eacute;es, les courriels et/ou
						la newsletter transmis automatiquement aux Visiteurs.
					</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c4">Donn&eacute;es Personnelles</span>
					<span class="c0">
						&nbsp;: Toute donn&eacute;e permettant
						d&rsquo;identifier directement ou indirectement une
						personne physique telle que d&eacute;finie par
						l&rsquo;article 4 du RGPD.
					</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c4">Infrastructure</span>
					<span class="c0">
						&nbsp;: Infrastructure mat&eacute;rielle et logicielle
						utilis&eacute;e par GRANT via un prestataire
						sous-traitant h&eacute;bergeur afin de fournir le Site.
						Le prestataire d&rsquo;h&eacute;bergement est un tiers
						qui est seul responsable de la qualit&eacute; de
						l&rsquo;Infrastructure.
					</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c4">RGPD</span>
					<span class="c0">
						&nbsp;: Le r&egrave;glement g&eacute;n&eacute;ral de
						protection des donn&eacute;es n&deg;2016/679 du 27 avril
						2016
					</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c4">Site</span>
					<span>&nbsp;: Le site Internet </span>
					<span class="c14">
						<a
							class="c12"
							href="https://www.google.com/url?q=https://joingrant.com&amp;sa=D&amp;source=editors&amp;ust=1679077729846682&amp;usg=AOvVaw34pHK4aHATCUPT_7Wi4xRw"
						>
							www.joingrant.com
						</a>
					</span>
					<span class="c0">
						&nbsp;de GRANT que les Visiteurs peuvent consulter.
					</span>
				</li>
				<li class="c1 li-bullet-0">
					<span class="c4">Solution</span>
					<span class="c0">
						&nbsp;: Le logiciel-service de GRANT est disponible
						&agrave; partir des Applications, ainsi que sa
						maintenance et son support.
					</span>
				</li>
				<li class="c6 c11 li-bullet-0">
					<span class="c4">Visiteurs</span>
					<span class="c0">
						&nbsp;: Toute personne physique ou morale
						acc&eacute;dant au Site.
					</span>
				</li>
			</ol>
			<h2 class="c7" id="h.1fob9te">
				<span class="c8">Article 2. Acceptation</span>
			</h2>
			<p class="c5">
				<span class="c0">
					Tout Visiteur doit prendre connaissance et accepter les
					pr&eacute;sentes CGUS avant de pouvoir utiliser le Site. En
					acc&eacute;dant &agrave; et en utilisant le Site, vous
					&ecirc;tes r&eacute;put&eacute; avoir accept&eacute; sans
					r&eacute;serve l&rsquo;application des pr&eacute;sentes
					CGUS, ainsi que de tout document auquel les CGUS font
					r&eacute;f&eacute;rence, du seul fait de
					l&rsquo;acc&egrave;s &agrave; et/ou de l&rsquo;utilisation
					du Site.
				</span>
			</p>
			<p class="c5 c19">
				<span class="c0"></span>
			</p>
			<p class="c5">
				<span class="c0">
					L&rsquo;acceptation des CGUS est enti&egrave;re et forme un
					tout indivisible. Les Parties ne peuvent y d&eacute;roger ou
					formuler des r&eacute;serves et ne peuvent pas
					d&eacute;cider d&rsquo;une application partielle. Les CGUS
					conclues restent disponibles &agrave; tout moment sur le
					Site.
				</span>
			</p>
			<p class="c5">
				<span class="c0">
					Si vous ne lisez pas, ne comprenez pas et/ou n&#39;acceptez
					pas ces CGUS, vous devez imm&eacute;diatement quitter le
					Site.
				</span>
			</p>
			<h2 class="c7" id="h.3znysh7">
				<span class="c8">Article 3. Documents contractuels</span>
			</h2>
			<p class="c5">
				<span class="c20">
					La version des CGUS applicable aux Visiteurs est celle en
					vigueur le jour de l&rsquo;utilisation du Site par le
					Visiteur et toute mise &agrave; jour est
					imm&eacute;diatement opposable au Visiteur.{" "}
				</span>
				<span class="c0">
					GRANT peut modifier les CGUS &agrave; tout moment, y compris
					ses annexes, afin de les mettre &agrave; jour et notamment
					de prendre en compte toute &eacute;volution l&eacute;gale,
					jurisprudentielle, &eacute;ditoriale ou technique. Les CGUS
					pr&eacute;c&eacute;dentes seront alors
					r&eacute;sili&eacute;es de plein droit et remplac&eacute;es
					par la nouvelle version des CGUS, mentionnant la date de la
					derni&egrave;re mise &agrave; jour en t&ecirc;te des CGUS.
				</span>
			</p>
			<p class="c5">
				<span class="c0">
					Pour &ecirc;tre inform&eacute; de ces &eacute;ventuelles
					modifications et mises &agrave; jour, il est
					recommand&eacute; au Visiteur de consulter les CGUS
					r&eacute;guli&egrave;rement.
				</span>
			</p>
			<p class="c5">
				<span class="c0">
					Les CGUS se composent, de mani&egrave;re indivisible et par
					ordre d&eacute;croissant de priorit&eacute; contractuelle,
					(i) des pr&eacute;sentes CGUS et (ii) de ses annexes,
					notamment de la politique de confidentialit&eacute; ainsi
					que de la politique cookies de GRANT. En cas de
					contradiction, d&rsquo;ambigu&iuml;t&eacute; ou de
					divergence d&rsquo;interpr&eacute;tation entre deux
					documents contractuels, le document de rang sup&eacute;rieur
					pr&eacute;vaut sur le document de rang inf&eacute;rieur. Si
					elle a lieu entre deux documents contractuels de m&ecirc;me
					rang, le dernier en date pr&eacute;vaut sur le moins
					r&eacute;cent.
				</span>
			</p>
			<p class="c5">
				<span class="c0">
					Ces documents contractuels forment un ensemble et
					constituent l&rsquo;int&eacute;gralit&eacute; de la relation
					entre les Visiteurs et GRANT, &agrave; l&rsquo;exclusion de
					tout autre document ; ils annulent et remplacent tous
					documents ant&eacute;rieurs relatifs au m&ecirc;me objet.
				</span>
			</p>
			<h2 class="c7" id="h.2et92p0">
				<span class="c8">Article 4. Dur&eacute;e</span>
			</h2>
			<p class="c5">
				<span class="c0">
					Les CGUS sont conclues entre GRANT et tout Visiteur pour la
					dur&eacute;e de sa visite sur le Site.
				</span>
			</p>
			<h2 class="c7" id="h.tyjcwt">
				<span class="c8">
					Article 5. Pr&eacute;requis d&rsquo;utilisation
				</span>
			</h2>
			<h3 class="c7" id="h.3dy6vkm">
				<span class="c15">5.1. Pr&eacute;requis</span>
			</h3>
			<p class="c5">
				<span class="c0">
					Les Visiteurs peuvent acc&eacute;der &agrave; et utiliser le
					Site sous r&eacute;serve de (i) disposer de la
					capacit&eacute; juridique leur permettant de s&rsquo;engager
					au titre des pr&eacute;sentes CGUS et de (ii) disposer
					d&rsquo;un &eacute;quipement informatique adapt&eacute; pour
					acc&eacute;der au Site.
				</span>
			</p>
			<p class="c5">
				<span class="c0">
					La navigation sur le Site est libre et gratuite, sous
					r&eacute;serve de l&rsquo;acceptation formelle des CGUS.
				</span>
			</p>
			<p class="c5">
				<span class="c0">
					Votre acc&egrave;s &agrave; Internet, la
					compatibilit&eacute; et le bon fonctionnement de votre
					syst&egrave;me d&rsquo;information (mat&eacute;riel,
					logiciels, pare-feu, protections techniques et connexions
					r&eacute;seau) avec le Site restent &agrave; tout moment
					sous votre seule responsabilit&eacute;. GRANT d&eacute;cline
					toute responsabilit&eacute; ou garantie &agrave; cet
					&eacute;gard, notamment en cas d&#39;incompatibilit&eacute;
					ou de dysfonctionnement de votre navigateur avec le Site.
				</span>
			</p>
			<h3 class="c7" id="h.1t3h5sf">
				<span class="c15">
					5.2. Acc&egrave;s, utilisation et disponibilit&eacute;
				</span>
			</h3>
			<p class="c5">
				<span class="c0">
					Les Parties sont seules responsables du mat&eacute;riel, des
					logiciels et de la connexion Internet n&eacute;cessaires
					pour acc&eacute;der au Site. Chacun assume les frais
					aff&eacute;rents, y compris les frais &eacute;ventuels de
					communication, qu&rsquo;il supporte de ce fait. GRANT met
					&agrave; disposition des personnes sourdes, malentendantes,
					sourd-aveugles et aphasiques un service de traduction
					simultan&eacute;e &eacute;crite et visuelle sans
					surco&ucirc;t pour le Visiteur, accessible aux m&ecirc;mes
					coordonn&eacute;es de contact durant les m&ecirc;mes
					horaires d&rsquo;ouverture que le service client de GRANT.
				</span>
			</p>
			<p class="c5">
				<span class="c0">
					De m&ecirc;me, vous devez utiliser le Site &agrave; des fins
					licites et, le cas &eacute;ch&eacute;ant, fournir des
					donn&eacute;es exactes, av&eacute;r&eacute;es, mises
					&agrave; jour et exemptes de bogues.
				</span>
			</p>
			<p class="c5">
				<span class="c0">
					GRANT se r&eacute;serve le droit, &agrave; sa seule
					discr&eacute;tion, de suspendre ou de mettre fin &agrave;
					l&#39;acc&egrave;s &agrave; tout ou partie du Site, du
					Contenu, des produits ou des services offerts sur le Site,
					sans pr&eacute;avis et sans aucune compensation. Il est
					strictement interdit de modifier les &eacute;l&eacute;ments
					logiciels du Site afin d&#39;obtenir un acc&egrave;s non
					autoris&eacute; au Site.
				</span>
			</p>
			<p class="c5">
				<span class="c0">
					Les Parties reconnaissent et acceptent qu&rsquo;en
					l&rsquo;&eacute;tat actuel de la technique, GRANT ne peut
					pas garantir une disponibilit&eacute; continue du Site, qui
					est fourni en l&rsquo;&eacute;tat.
				</span>
			</p>
			<h3 class="c7" id="h.4d34og8">
				<span class="c15">5.3 Liens hypertexte</span>
			</h3>
			<p class="c5">
				<span class="c0">
					Le Site peut contenir des liens vers d&#39;autres sites ou
					d&#39;autres sources sur Internet. Dans la mesure o&ugrave;
					GRANT ne peut contr&ocirc;ler ces sites et ces sources
					externes, GRANT ne peut &ecirc;tre tenue pour responsable du
					contenu, des publicit&eacute;s, des produits, des services
					ou de tout autre &eacute;l&eacute;ment disponible sur ces
					sites ou sources externes. De plus, GRANT ne pourra
					&ecirc;tre tenu responsable de tous dommages
					av&eacute;r&eacute;s ou all&eacute;gu&eacute;s, directs ou
					indirects, en relation avec l&#39;utilisation du contenu,
					des biens ou des services disponibles sur ces sites ou
					sources externes.
				</span>
			</p>
			<h2 class="c7" id="h.2s8eyo1">
				<span class="c8">
					Article 6. Propri&eacute;t&eacute; intellectuelle du Contenu
				</span>
			</h2>
			<p class="c5">
				<span class="c0">
					Le Contenu peut contenir des informations et des
					donn&eacute;es confidentielles prot&eacute;g&eacute;es par
					le droit de la propri&eacute;t&eacute; intellectuelle ou
					toute autre loi applicable. Ainsi, sauf mention contraire
					sur le Site, les droits de propri&eacute;t&eacute;
					intellectuelle sur le Contenu sont la
					propri&eacute;t&eacute; exclusive de GRANT, qui ne vous
					conc&egrave;de aucune licence, ni aucun autre droit que
					celui de consulter le Site.
				</span>
			</p>
			<p class="c5">
				<span class="c0">
					La reproduction temporaire de tout ou partie du Contenu est
					autoris&eacute;e aux seules fins d&rsquo;utilisation du
					Site. Toute reproduction et toute utilisation de copies du
					Contenu r&eacute;alis&eacute;es &agrave; d&#39;autres fins,
					de quelque mani&egrave;re et sous quelque forme que ce soit,
					sont express&eacute;ment interdites.
				</span>
			</p>
			<h2 class="c7" id="h.17dp8vu">
				<span class="c8">
					Article 7. Restrictions d&rsquo;utilisation
				</span>
			</h2>
			<p class="c5">
				<span class="c0">
					&Agrave; tout moment, vous devez vous abstenir (i) de mettre
					en ligne sur le Site ou de prendre toute action qui aurait
					pour effet la transmission de toute autre mani&egrave;re de
					virus logiciels ou autres codes informatiques, fichiers ou
					programmes con&ccedil;us pour interrompre, d&eacute;truire
					ou limiter la fonctionnalit&eacute; de tout logiciel ou
					mat&eacute;riel informatique ou &eacute;quipement de
					t&eacute;l&eacute;communication ; (ii) de perturber ou
					d&#39;interrompre le fonctionnement du Site, des serveurs ou
					des r&eacute;seaux connect&eacute;s au Site ou de violer
					toutes conditions, proc&eacute;dures, r&egrave;gles ou
					r&eacute;glementations relatives au Site ; (iii) de toute
					tentative d&#39;interf&eacute;rer avec le Site, notamment en
					exposant le Site &agrave; un virus, en provoquant une
					surcharge de la bande passante ou de l&rsquo;Infrastructure
					(que ce soit de mani&egrave;re involontaire ou par le biais
					d&#39;un d&eacute;ni de service distribu&eacute;), en
					envoyant des &quot;spams&quot; ou en surchargeant les
					services fournis sur le Site ; (iv) de la consultation
					d&#39;informations qui ne vous sont pas destin&eacute;es ou
					d&rsquo;acc&eacute;der &agrave; un serveur ou &agrave; un
					compte auquel vous n&#39;&ecirc;tes pas autoris&eacute;
					&agrave; acc&eacute;der ; (v) de toute tentative
					d&#39;&eacute;valuer, de noter ou de tester la
					vuln&eacute;rabilit&eacute; du Site, ou de violer les
					mesures de s&eacute;curit&eacute; ou d&#39;authentification
					du Site sans le consentement &eacute;crit pr&eacute;alable
					de GRANT ; (vi) de vous &nbsp;livrer &agrave; toute
					activit&eacute; ill&eacute;gale ou toute autre
					activit&eacute; pouvant porter pr&eacute;judice aux droits
					de GRANT, de ses fournisseurs, prestataires de services,
					partenaires, Utilisateurs ou de toutes autres personnes, et
					d&#39;inciter des tiers &agrave; le faire ; (vii) de mettre
					en ligne sur le Site ou rendre disponible par tout moyen des
					publicit&eacute;s ou des mat&eacute;riels promotionnels non
					sollicit&eacute;s ou non autoris&eacute;s, des
					&quot;pourriels&quot;, des &quot;spams&quot;, des
					&quot;cha&icirc;nes de lettres&quot; ou toute autre forme de
					sollicitation ; (viii) d&rsquo;usurper
					l&rsquo;identit&eacute; d&rsquo;un tiers pour acc&eacute;der
					au Site et (ix) de mettre en ligne sur le Site ou rendre
					disponible par tout moyen tout contenu ill&eacute;gal,
					nuisible, diffamatoire, calomnieux, offensant, raciste,
					vulgaire, obsc&egrave;ne, mena&ccedil;ant, immoral, portant
					atteinte &agrave; la vie priv&eacute;e d&#39;autrui,
					r&eacute;pr&eacute;hensible sur le plan racial ou ethnique
					ou autrement r&eacute;pr&eacute;hensible.
				</span>
			</p>
			<p class="c5">
				<span class="c0">
					Vous vous engagez &eacute;galement et vous acceptez de ne
					pas copier, modifier, r&eacute;utiliser, cr&eacute;er des
					&oelig;uvres d&eacute;riv&eacute;es,
					t&eacute;l&eacute;charger, adapter, faire
					l&#39;ing&eacute;nierie inverse, &eacute;muler, migrer vers
					un autre service, traduire, compiler, d&eacute;compiler ou
					d&eacute;sassembler le Site ou tout Contenu et/ou toute
					partie de ceux-ci, ou de ne pas afficher publiquement,
					ex&eacute;cuter, transmettre ou distribuer tout ce qui
					pr&eacute;c&egrave;de sans l&#39;autorisation &eacute;crite
					pr&eacute;alable sp&eacute;cifique de GRANT et/ou sans
					&ecirc;tre express&eacute;ment autoris&eacute; en vertu des
					pr&eacute;sentes CGUS. De m&ecirc;me, vous acceptez de ne
					pas acheter de mots-cl&eacute;s d&#39;un moteur de recherche
					ou d&#39;un service de paiement au clic (tel que Google Ads)
					ou de noms de domaine qui utilisent des marques, des
					variantes ou une orthographe identique(s) ou similaire(s) au
					nom du Site et &agrave; tout ou partie du Contenu.
				</span>
			</p>
			<p class="c5">
				<span class="c0">
					Vous vous engagez &agrave; vous abstenir d&#39;utiliser un
					&quot;robot&quot;, un &quot;spider&quot; ou tout autre
					dispositif, programme, script, algorithme,
					m&eacute;thodologie automatique ou tout autre
					proc&eacute;d&eacute; manuel identique ou similaire, aux
					fins d&rsquo;acc&eacute;der &agrave;,
					d&rsquo;acqu&eacute;rir, de copier ou de surveiller toute
					partie du Site (ou ses donn&eacute;es et/ou de son Contenu
					tels que d&eacute;finis ci-dessus), ou de reproduire ou de
					d&eacute;tourner la structure de navigation ou la
					pr&eacute;sentation du Site afin d&#39;obtenir ou de tenter
					d&#39;obtenir des informations, documents, services ou
					mat&eacute;riels d&#39;une mani&egrave;re non disponible par
					le biais du Site.
				</span>
			</p>
			<p class="c5">
				<span class="c0">
					Vous acceptez de vous conformer &agrave; toutes les lois,
					r&egrave;gles et proc&eacute;dures applicables concernant le
					comportement des utilisateurs en ligne et la transmission de
					donn&eacute;es techniques. &nbsp;GRANT peut mettre fin
					&agrave; votre droit d&#39;acc&egrave;s au Site &agrave;
					tout moment si vous ne respectez pas vos obligations en
					vertu de ces CGUS, sans pr&eacute;judice du droit de GRANT
					de r&eacute;clamer des dommages et int&eacute;r&ecirc;ts.
				</span>
			</p>
			<p class="c5">
				<span class="c0">
					Vous vous engagez &agrave; ne pas d&eacute;poser, &agrave;
					quelque titre que ce soit (marque, dessin et mod&egrave;le,
					etc.) ni d&eacute;velopper, utiliser ou exploiter, &agrave;
					quelque titre que ce soit et dans quelque territoire que ce
					soit, des logiciels, produits, services,
					signal&eacute;tiques ou logos identiques ou similaires ou
					susceptibles de porter atteinte aux droits de
					propri&eacute;t&eacute; intellectuelle de GRANT sur le Site.
				</span>
			</p>
			<h2 class="c7" id="h.3rdcrjn">
				<span class="c8">Article 8. Conditions financi&egrave;res</span>
			</h2>
			<p class="c5">
				<span>
					Le Site peut &ecirc;tre consult&eacute; gratuitement par
					tout Visiteur.
				</span>
			</p>
			<h2 class="c7" id="h.26in1rg">
				<span>Article 9. Garantie</span>
			</h2>
			<p class="c5 c11">
				<span class="c0">
					Dans la limite du droit applicable, GRANT exclut toute
					garantie sur le Site. En particulier, GRANT ne garantit pas
					que le Site soit compatible avec le syst&egrave;me
					d&rsquo;information (notamment le navigateur et/ou le
					terminal) du Visiteur de mani&egrave;re permanente, GRANT
					n&#39;ayant pas contr&ocirc;le sur ceux-ci, ce que le
					Visiteur reconna&icirc;t.
				</span>
			</p>
			<h2 class="c7" id="h.lnxbz9">
				<span class="c8">Article 10. Responsabilit&eacute;</span>
			</h2>
			<p class="c5">
				<span class="c0">
					GRANT s&#39;efforce d&#39;assurer du mieux qu&rsquo;elle
					peut l&#39;exactitude et la mise &agrave; jour des
					informations figurant sur le Site. Toutefois, GRANT ne peut
					garantir l&#39;exactitude, la pr&eacute;cision ou
					l&#39;exhaustivit&eacute; des informations mises &agrave;
					votre disposition sur le Site.
				</span>
			</p>
			<p class="c5">
				<span class="c0">
					GRANT fournit le Site en l&rsquo;&eacute;tat, sans garantie
					d&#39;aucune sorte, en ce compris les garanties ou
					obligations expresses ou implicites de qualit&eacute;,
					d&#39;ad&eacute;quation aux besoins,
					d&rsquo;&eacute;viction, ou toute autre garantie &ndash;
					dans la limite de ce qui est autoris&eacute; par le droit
					applicable. Plus pr&eacute;cis&eacute;ment, nous ne
					d&eacute;clarons ni ne garantissons que le Site (ou toute
					partie, caract&eacute;ristique ou Contenu qu&#39;il
					contient) est complet et pr&eacute;cis, d&#39;une certaine
					qualit&eacute;, fiable et s&ucirc;r, adapt&eacute; ou
					compatible avec votre entreprise, vos terminaux, vos
					syst&egrave;mes d&#39;exploitation, vos navigateurs, vos
					logiciels ou vos outils (ou qu&#39;il le restera), ou
					conforme &agrave; toute loi qui s&#39;applique &agrave; vous
					(y compris dans toute juridiction dans laquelle vous
					op&eacute;rez), ou que son fonctionnement sera exempt de
					tout virus, bogue ou autre composant pr&eacute;judiciable.
				</span>
			</p>
			<p class="c5 c19">
				<span class="c0"></span>
			</p>
			<p class="c5">
				<span class="c0">
					GRANT permet aux Visiteurs d&#39;acc&eacute;der au Site
					gratuitement et d&eacute;cline toute responsabilit&eacute;
					(i) pour toute interruption ou indisponibilit&eacute; du
					Site, (ii) en cas de survenance de bogues, d&#39;erreurs
					d&#39;affichage ou de t&eacute;l&eacute;chargement, (iii)
					pour toute inexactitude ou omission du Contenu disponible
					sur le Site, (iv) pour tout dommage r&eacute;sultant de
					l&rsquo;intrusion frauduleuse d&#39;un tiers, et (v) plus
					g&eacute;n&eacute;ralement, pour tous dommages, directs ou
					indirects, quelles qu&#39;en soient les causes ou
					cons&eacute;quences, pouvant survenir &agrave; raison de
					l&#39;acc&egrave;s au Site et r&eacute;sultant d&#39;une
					information provenant directement ou indirectement du Site,
					dans les limites autoris&eacute;es par le droit applicable.
				</span>
			</p>
			<p class="c5 c11">
				<span class="c0">
					Le Site est fourni dans le cadre d&rsquo;une obligation
					g&eacute;n&eacute;rale de moyens. GRANT met fin &agrave;
					toute indisponibilit&eacute; de l&rsquo;un ou de
					l&rsquo;autre dans des d&eacute;lais commercialement
					raisonnables et ne peut &ecirc;tre tenue responsable en cas
					de d&eacute;faillance des r&eacute;seaux de communications
					&eacute;lectroniques, de ralentissement ou
					d&rsquo;engorgement du r&eacute;seau internet ou du
					r&eacute;seau du Visiteur, de contamination par un virus
					informatique, d&rsquo;acte de piratage, d&rsquo;intrusion
					frauduleuse ou d&rsquo;acc&egrave;s ou d&rsquo;intervention
					non autoris&eacute; d&rsquo;un tiers, et plus
					g&eacute;n&eacute;ralement de tout acte volontaire de
					d&eacute;gradation, malveillance, sabotage ou
					d&eacute;t&eacute;rioration ou devant tout autre cas de
					force majeure impactant GRANT ou ses sous-traitants.
				</span>
			</p>
			<p class="c5 c11">
				<span class="c0">
					Chacune des Parties assure sa responsabilit&eacute; selon le
					droit commun. GRANT ne peut &ecirc;tre tenue en aucun cas
					responsable des dommages indirects qui seraient subis par le
					Visiteur ou les tiers (incluant notamment toute perte
					d&rsquo;exploitation, de client&egrave;le, de prospect,
					perte commerciale ou financi&egrave;re, pr&eacute;judice
					d&rsquo;image, manque &agrave; gagner, etc.)
					cons&eacute;cutifs &agrave; l&rsquo;utilisation du Site,
					m&ecirc;me si GRANT a &eacute;t&eacute; pr&eacute;venue ou
					pouvait raisonnablement pr&eacute;voir lesdits dommages.
				</span>
			</p>
			<p class="c5">
				<span class="c0">
					La responsabilit&eacute; de GRANT ne pourra &ecirc;tre
					engag&eacute;e pour une cause dont le fait
					g&eacute;n&eacute;rateur a pour origine un cas de force
					majeure, y compris si un tel cas de force majeure touche un
					de ses sous-traitants, ainsi que pour tout
					&eacute;v&egrave;nement en dehors de son contr&ocirc;le
					exclusif. Vous reconnaissez et acceptez que ces limitations
					de responsabilit&eacute; repr&eacute;sentent un accord sur
					les risques inh&eacute;rents &agrave; l&#39;utilisation du
					Site qui vous est fourni. De la m&ecirc;me mani&egrave;re,
					la responsabilit&eacute; de GRANT ne peut &ecirc;tre
					engag&eacute;e en cas de demande d&rsquo;interruption
					temporaire ou d&eacute;finitive du Site &eacute;manant
					d&rsquo;une autorit&eacute; administrative ou judiciaire, et
					plus g&eacute;n&eacute;ralement en cas de manquement, de
					n&eacute;gligence ou de faute intentionnelle du Visiteur ou
					en cas d&rsquo;intervention d&rsquo;un tiers non
					plac&eacute; sous le contr&ocirc;le de GRANT.
				</span>
			</p>
			<h2 class="c7" id="h.35nkun2">
				<span class="c8">
					Article 11. Protection des Donn&eacute;es Personnelles
				</span>
			</h2>
			<p class="c5 c11">
				<span class="c0">
					Des Donn&eacute;es Personnelles sont collect&eacute;es par
					GRANT et sont trait&eacute;es dans le but de vous permettre
					d&rsquo;acc&eacute;der &agrave; et d&rsquo;utiliser le Site,
					soit directement lorsque le Visiteur consulte le Site, soit
					indirectement lorsque le Visiteur transmet des
					Donn&eacute;es Personnelles au Site.
				</span>
			</p>
			<p class="c5">
				<span>
					GRANT est une soci&eacute;t&eacute; respectueuse de la vie
					priv&eacute;e de ses Visiteurs. &Agrave; ce titre, elle
					respecte ses obligations au titre de la protection des
					Donn&eacute;es Personnelles et a &eacute;tabli une{" "}
				</span>
				<span class="c9">
					<a
						class="c12"
						href="https://www.google.com/url?q=https://joingrant.com/donnees-personnelles/&amp;sa=D&amp;source=editors&amp;ust=1679077729856749&amp;usg=AOvVaw3zq1NRLPhxkhH6kjxoYDHa"
					>
						politique de confidentialit&eacute;
					</a>
				</span>
				<span class="c0">
					&nbsp;d&eacute;di&eacute;e aux fins d&rsquo;informer les
					personnes concern&eacute;es. Cette politique de
					confidentialit&eacute; est jointe en Annexe 1 des CGUS et
					fait partie des CGUS.
				</span>
			</p>
			<p class="c5">
				<span class="c0">
					GRANT conserve l&rsquo;historique des connexions des
					Visiteurs au Site. &nbsp;En outre, GRANT pourra suivre la
					navigation des Visiteurs au sein du Site gr&acirc;ce
					&agrave; des cookies. Lorsque vous acc&eacute;dez et
					utilisez le Site, des cookies sont install&eacute;s sur
					l&#39;appareil que vous utilisez pour naviguer.
				</span>
			</p>
			<p class="c5">
				<span class="c0">
					Un cookie est un fichier texte
					t&eacute;l&eacute;charg&eacute; sur votre appareil lors de
					la navigation sur le Site. Son but est de collecter des
					informations relatives &agrave; votre navigation sur le Site
					et de vous fournir les fonctionnalit&eacute;s du Site et/ou
					une meilleure exp&eacute;rience utilisateur du Site, en
					&eacute;changeant des informations entre GRANT et votre
					&eacute;quipement terminal. Certains cookies sont de nature
					temporaire et limit&eacute;s &agrave; une session de
					navigation ; ils sont tous supprim&eacute;s lorsque vous
					fermez la fen&ecirc;tre du navigateur. Certains cookies
					restent sur votre appareil pendant un certain temps
					apr&egrave;s la fermeture de la session de votre navigateur,
					durant lequel ils seront activ&eacute;s chaque fois que vous
					recommencerez &agrave; naviguer sur le Site.
				</span>
			</p>
			<p class="c5">
				<span class="c0">
					Certains cookies sont n&eacute;cessaires &agrave; la
					fourniture du Site&nbsp;; d&rsquo;autres sont optionnels.
				</span>
			</p>
			<ul class="c2 lst-kix_list_2-0 start">
				<li class="c10 li-bullet-0">
					<span class="c0">
						GRANT vous informe et vous donne l&rsquo;occasion
						d&rsquo;accepter ou de refuser les cookies optionnels
						dans un bandeau d&rsquo;information lors de votre
						premi&egrave;re visite sur le Site.
					</span>
				</li>
				<li class="c6 li-bullet-0">
					<span>Vous pouvez &eacute;galement consulter sa </span>
					<span class="c9">
						<a
							class="c12"
							href="https://www.google.com/url?q=https://joingrant.com/cookies/&amp;sa=D&amp;source=editors&amp;ust=1679077729857779&amp;usg=AOvVaw3EbkUzFsDesOe8lpBnJQvu"
						>
							politique de gestion des cookies
						</a>
					</span>
					<span class="c0">
						&nbsp;d&eacute;di&eacute;e pour plus d&rsquo;information
						sur les cookies utilis&eacute;s. Cette politique cookies
						constitue l&rsquo;Annexe 2 des pr&eacute;sentes CGUS et
						fait partie des CGUS.
					</span>
				</li>
			</ul>
			<p class="c21 c19 c23">
				<span class="c0"></span>
			</p>
			<h2 class="c7" id="h.1ksv4uv">
				<span class="c8">Article 12. Divers</span>
			</h2>
			<p class="c5">
				<span class="c0">
					Les CGUS constituent l&rsquo;int&eacute;gralit&eacute; de
					l&rsquo;accord entre les Parties relatif &agrave;
					l&rsquo;utilisation du Site. &Agrave; ce titre, les CGUS
					annulent et remplacent tous documents ant&eacute;rieurs
					conclus entre elles &agrave; cet &eacute;gard.
				</span>
			</p>
			<p class="c5">
				<span class="c0">
					Dans l&#39;hypoth&egrave;se o&ugrave; une ou plusieurs
					stipulations des pr&eacute;sentes seraient
					consid&eacute;r&eacute;es nulles, inapplicables ou
					inopposables par toute juridiction comp&eacute;tente, les
					autres stipulations des pr&eacute;sentes resteront valables
					sauf disposition contraire de ladite juridiction. Les
					Parties conviennent qu&rsquo;en telle hypoth&egrave;se,
					elles n&eacute;gocieront de bonne foi des stipulations de
					remplacement qui seront conformes &agrave; l&#39;intention
					initiale des Parties.
				</span>
			</p>
			<p class="c5">
				<span class="c0">
					Le fait pour l&#39;une des Parties de ne pas se
					pr&eacute;valoir d&#39;un engagement par l&#39;autre Partie
					&agrave; l&#39;une quelconque des obligations vis&eacute;es
					par les pr&eacute;sentes, ne saurait &ecirc;tre
					interpr&eacute;t&eacute; pour l&#39;avenir comme une
					renonciation &agrave; l&#39;obligation en cause.
				</span>
			</p>
			<h2 class="c11 c22" id="h.44sinio">
				<span class="c8">
					Article 13. Droit et juridiction applicables
				</span>
			</h2>
			<p class="c5">
				<span class="c0">
					Les pr&eacute;sentes CGUS sont r&eacute;gies par le droit
					fran&ccedil;ais.
				</span>
			</p>
			<p class="c5">
				<span class="c4">
					Sauf en cas d&rsquo;application d&rsquo;une r&egrave;gle
					d&rsquo;ordre public, tout litige relatif au CGUS et
					&agrave; l&rsquo;utilisation du Site est soumis &agrave; la
					comp&eacute;tence exclusive des tribunaux du ressort de la
					cour d&rsquo;appel de Paris, m&ecirc;me en cas de
					pluralit&eacute; de d&eacute;fendeurs, d&rsquo;appel en
					garantie, de proc&eacute;dures conservatoires, de
					r&eacute;f&eacute;r&eacute; ou de requ&ecirc;te.
				</span>
			</p>
			<p class="c5">
				<span class="c0">Les CGUS contiennent deux Annexes&nbsp;:</span>
			</p>
			<p class="c5">
				<span class="c17">Annexe 1. </span>
				<span class="c9">
					<a
						class="c12"
						href="https://www.google.com/url?q=https://joingrant.com/donnees-personnelles/&amp;sa=D&amp;source=editors&amp;ust=1679077729859659&amp;usg=AOvVaw1sT0vbVCGLBIVeXFO69mvR"
					>
						Politique de confidentialit&eacute;
					</a>
				</span>
			</p>
			<p class="c5">
				<span class="c17">Annexe 2. </span>
				<span class="c9">
					<a
						class="c12"
						href="https://www.google.com/url?q=https://joingrant.com/cookies/&amp;sa=D&amp;source=editors&amp;ust=1679077729860032&amp;usg=AOvVaw2QgJc8bT5xTy8RjDFZRB2D"
					>
						Politique de gestion des cookies
					</a>
				</span>
			</p>
			<div>
				<p class="c19 c26">
					<span class="c13"></span>
				</p>
			</div>
		</div>
	);
};

export default Content;
