import { createBrowserRouter } from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import Business from "./pages/Business";
import TermsMobile from "./pages/Terms/TermsMobile";
import Terms from "./pages/Terms/Terms";
import Privacy from "./pages/Terms/Privacy";
import AndroidPrerelease from "./pages/AndroidPrerelease";
import RequestDelete from "./pages/RequestDelete";
import DownloadApp from "./pages/DownloadApp";
import Tutorial from "./pages/Tutorial";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Home />,
	},
	{
		path: "/about",
		element: <About />,
	},
	{
		path: "/business",
		element: <Business />,
	},
	{
		path: "/terms-mobile",
		element: <TermsMobile />,
	},
	{
		path: "/terms",
		element: <Terms />,
	},
	{
		path: "/privacy",
		element: <Privacy />,
	},
	{
		path: "/android",
		element: <AndroidPrerelease />,
	},
	{
		path: "/delete-my-account",
		element: <RequestDelete />,
	},
	{
		path: "/download-app",
		element: <DownloadApp />,
	},
	{
		path: "/how-it-works",
		element: <Tutorial />,
	},
]);

export default router;
