import { Link } from "react-router-dom";
import Info from "./info";

const AboutFr = () => {
	return (
		<div className="flex flex-col">
			<p className="mb-4">
				GRANT SOLUTIONS SAS opère sous mandat de LEMONWAY SAS, société
				par actions simplifiée basée au 8 rue du Sentier, 75002 Paris,
				France. Elle est immatriculée au Registre du Commerce et des
				Sociétés de Paris (n° 500 486 915) et a été agréée en qualité
				d'établissement de paiement par l'Autorité de Contrôle
				Prudentiel et de Résolution (« ACPR ») le 24 décembre 2012, sous
				le numéro d'immatriculation 16568, située à 4 place de Budapest
				CS 92459, 75436 Paris.
			</p>
			<p className="mb-4">
				En tant qu'agent de Lemonway SAS, Grant Solutions SAS est
				répertoriée auprès de l'ACPR en tant qu'agent prestataire de
				services de paiement. GRANT est ainsi inscrit au REGAFI,
				l'annuaire des agents financiers qui regroupe les entités
				habilitées à fournir des services bancaires, de monnaie
				électronique, financiers ou de paiement conformément à la
				législation monétaire et financière française et européene. Ce
				répertoire est accessible au public sur{" "}
				<Link className="underline" to="https://www.regafi.fr">
					www.regafi.fr
				</Link>
				.
			</p>
			<hr />
			<Info
				items={{
					"Raison sociale": "Grant Solutions",
					"Forme juridique": "SAS (Société par actions simplifiée)",
					"Capital social": "49 600 Euros",
					"Date d'immatriculation": "26/08/2020",
					"Immatriculation au RCS": "888 360 757 Nanterre",
					"Adresse du siège":
						"8 Rue de l'Est 92100 Boulogne-Billancourt",
					"‎": "",
					TVA: "FR08888360757",
					Siret: "88836075700015",
					Siren: "888360757",
					DUNS: "274848163",
				}}
			/>
			<br />
			<p>
				Si vous avez besoin de plus de détails ou d'informations,
				veuillez nous envoyer un e-mail à{" "}
				<Link className="underline" to="mailto:contact@joingrant.com">
					contact@joingrant.com
				</Link>
			</p>
		</div>
	);
};

export default AboutFr;
