const Content = () => {
	return (
		<div class="c18 doc-content">
			<div>
				<p class="c9 c25">
					<span class="c19 c16"></span>
				</p>
			</div>
			<h1 class="c5 c23" id="h.gjdgxs">
				<span class="c16 c22">Personal data protection policy</span>
			</h1>
			<p class="c4">
				<span class="c11 c13">Updated on 26 February 2023</span>
			</p>
			<p class="c3">
				<span class="c5">
					1. Who is responsible for processing my personal data?
				</span>
			</p>
			<p class="c4">
				<span class="c11">
					The data controller is GRANT Solutions SAS (hereinafter
					referred to as &quot;
				</span>
				<span class="c12">GRANT&quot;</span>
				<span class="c1">
					), whose registered office is located at 8 Rue de l&#39;Est
					92100 Boulogne-Billancourt.
				</span>
			</p>
			<p class="c4">
				<span class="c1">
					It determines for what purpose and how the personal data
					(hereinafter &quot;personal data&quot;) of the users of the
					Grant application (hereinafter referred to as
					&quot;you&quot; / &quot;your&quot;) are processed.
				</span>
			</p>
			<p class="c4">
				<span class="c1">
					Personal data is any information that relates to an
					identified or identifiable natural person, such as your
					email address, first and last name or telephone number.
				</span>
			</p>
			<p class="c3">
				<span class="c5">
					2. Why does GRANT collect my personal data?
				</span>
			</p>
			<p class="c3">
				<span class="c2">2.1 General purposes of the processing </span>
			</p>
			<p class="c4">
				<span class="c1">
					We process your personal data for the purpose of providing
					and managing the services offered on our application.
				</span>
			</p>
			<p class="c4">
				<span class="c11">
					The purposes of the information collected via our cookies
					and trackers are detailed in our{" "}
				</span>
				<span class="c15 c11 c16">
					<a
						class="c7"
						href="https://www.google.com/url?q=https://joingrant.com/cookies/&amp;sa=D&amp;source=editors&amp;ust=1679077967118861&amp;usg=AOvVaw1iPHO3gQSSgCX4f78QYndr"
					>
						Cookie Management Policy
					</a>
				</span>
				<span class="c11 c16">.</span>
			</p>
			<p class="c3">
				<span class="c2">
					2.2 Details of the purposes and legal bases for the
					processing operations
				</span>
			</p>
			<p class="c4">
				<span class="c1">
					The details of the purposes for which your personal data is
					processed and the legal basis for the pursuit of each of
					these purposes are as follows: &nbsp;
				</span>
			</p>
			<p class="c4">
				<span class="c1">
					The processing is necessary for the performance of the
					contract between us:
				</span>
			</p>
			<ul class="c8 lst-kix_list_1-0 start">
				<li class="c0 li-bullet-0">
					<span class="c1">
						Management of the execution of the services offered on
						the application,
					</span>
				</li>
				<li class="c0 li-bullet-0">
					<span class="c1">Support management,</span>
				</li>
				<li class="c0 li-bullet-0">
					<span class="c1">User account management,</span>
				</li>
				<li class="c0 li-bullet-0">
					<span class="c1">
						Management of donations and transfer of funds.
					</span>
				</li>
			</ul>
			<p class="c4">
				<span class="c1">
					Processing is necessary to comply with a legal obligation:
				</span>
			</p>
			<ul class="c8 lst-kix_list_2-0 start">
				<li class="c0 li-bullet-0">
					<span class="c1">
						Combating fraud, money laundering and terrorist
						financing,
					</span>
				</li>
				<li class="c0 li-bullet-0">
					<span class="c1">
						Managing complaints, pre-litigation and litigation,
					</span>
				</li>
				<li class="c0 li-bullet-0">
					<span class="c1">
						Retention of data related to the creation of user
						accounts, connections to the application, payments made,
						with a view to possible judicial requisitions,
					</span>
				</li>
				<li class="c0 li-bullet-0">
					<span class="c1">Manage user rights.</span>
				</li>
			</ul>
			<p class="c4">
				<span class="c1">
					Processing is carried out if we have a legitimate interest
					in doing so:
				</span>
			</p>
			<ul class="c8 lst-kix_list_3-0 start">
				<li class="c0 li-bullet-0">
					<span class="c1">
						organise marketing operations (audience statistics,
						performance measurements, commercial prospecting,
						training, etc.)
					</span>
				</li>
			</ul>
			<p class="c3">
				<span class="c5">3. Treatments carried out by LEMONWAY</span>
			</p>
			<p class="c4">
				<span class="c11">
					The collection of payments and the disbursement of funds are
					managed by our payment provider LEMONWAY. It carries out
					personal data processing for which it is responsible, as
					described in its privacy policy accessible on the following
					link: https:
				</span>
				<span class="c11 c15">
					<a
						class="c7"
						href="https://www.google.com/url?q=https://www.lemonway.com/conditions-generales-dutilisation/&amp;sa=D&amp;source=editors&amp;ust=1679077967120691&amp;usg=AOvVaw2pAFymyaDPKbMZpvENgpTV"
					>
						//www.lemonway.com/conditions-generales-dutilisation/
					</a>
				</span>
			</p>
			<p class="c3">
				<span class="c5">
					4. What personal data is collected about me?
				</span>
			</p>
			<p class="c3">
				<span class="c2">4.1 Categories of data processed</span>
			</p>
			<p class="c4">
				<span class="c1">
					We collect and process the following personal data:
				</span>
			</p>
			<ul class="c8 lst-kix_list_4-0 start">
				<li class="c0 li-bullet-0">
					<span class="c1">
						Identification data, surname, first name, postal
						address, country of residence and telephone number;
					</span>
				</li>
				<li class="c0 li-bullet-0">
					<span class="c1">
						Data necessary for the payment of donations: bank
						details (credit card code, visual cryptogram - only to
						ensure the transaction -, name and surname of the
						cardholder
					</span>
				</li>
				<li class="c0 li-bullet-0">
					<span class="c1">
						Data required to receive donations: IBAN, document
						proving your identity (ID card and/or passport and/or
						driving licence and/or proof of employment. This list is
						not exhaustive).
					</span>
				</li>
				<li class="c0 li-bullet-0">
					<span class="c1">
						Data relating to navigation on the website (time stamps,
						IP address of users, technical data relating to the
						equipment and browser used by users, cookies).
					</span>
				</li>
			</ul>
			<p class="c3">
				<span class="c2">
					4.2 Compulsory nature of the collection of personal data
				</span>
			</p>
			<p class="c4">
				<span class="c1">
					In all cases, the compulsory nature of the information is
					indicated by an asterisk (*) next to the fields concerned
					when they are collected. In this case, only the personal
					data strictly necessary for the purposes described above are
					collected. If there is no asterisk at the time of
					collection, the information requested is optional.
				</span>
			</p>
			<p class="c3">
				<span class="c2">4.3 Rules for the use of free fields</span>
			</p>
			<p class="c4">
				<span class="c1">
					When a form (notices, contacts) has a free field, we ask you
					not to enter any personal information and to limit yourself
					to the information strictly necessary to process your
					request.
				</span>
			</p>
			<p class="c4">
				<span class="c12">
					We reserve the right to remove any information that is not
					required for your initial request.
				</span>
			</p>
			<p class="c4">
				<span class="c1">
					We do not collect sensitive data about you.
				</span>
			</p>
			<p class="c4">
				<span class="c1">
					We remind you that sensitive data includes data revealing
					racial or ethnic origin, political opinions, religious or
					philosophical beliefs or trade union membership, as well as
					the processing of genetic data, biometric data for the
					purpose of uniquely identifying a natural person, data
					concerning health or data concerning the sex life or sexual
					orientation of a natural person.
				</span>
			</p>
			<p class="c4">
				<span class="c12">
					If you voluntarily provide us with sensitive data, in
					particular in the context of your special requests, we will
					consider that you consider this provision of additional
					information useful. By providing this information, you
					explicitly consent to our processing of these special
					categories of personal data. However, if we consider that
					this information is not relevant to your request, we reserve
					the right to delete it immediately.
				</span>
			</p>
			<p class="c3">
				<span class="c2">4.4 Accuracy of reported data</span>
			</p>
			<p class="c4">
				<span class="c1">
					As a user of the application, you declare that you are aware
					of the importance of the accuracy of the personal data that
					you provide through the application and/or in your dealings
					with us.
				</span>
			</p>
			<p class="c4">
				<span class="c1">
					You therefore undertake to provide only accurate personal
					data in your dealings with us, in your applications and
					registrations, and throughout the period of use of the
					application and to update them immediately if one or more of
					these personal data should change during the said period of
					use of the application.
				</span>
			</p>
			<p class="c4">
				<span class="c11">
					In this respect, you may in particular make a request for
					access and rectification, as well as opposition or deletion
					as provided for (among others) in Article 8 &quot;
				</span>
				<span class="c15 c11">
					<a
						class="c7"
						href="https://www.google.com/url?q=https://joingrant.com/donnees-personnelles/%23droits&amp;sa=D&amp;source=editors&amp;ust=1679077967122511&amp;usg=AOvVaw0XGgR83KtC1ih9AcbYjGbd"
					>
						What are my rights with regard to the use of personal
						data?
					</a>
				</span>
				<span class="c1">
					&quot; of this Personal Data Protection Policy.
				</span>
			</p>
			<p class="c3">
				<span class="c5">5. Who are the recipients of my data?</span>
			</p>
			<p class="c3">
				<span class="c2">5.1 Categories of recipients</span>
			</p>
			<p class="c4">
				<span class="c1">
					Depending on their respective needs, the following are
					recipients of all or part of the data
				</span>
			</p>
			<ul class="c8 lst-kix_list_5-0 start">
				<li class="c0 li-bullet-0">
					<span class="c1">
						authorised GRANT staff in charge of publishing content,
						technical administration of the application or
						processing requests received via the contact forms;
					</span>
				</li>
				<li class="c0 li-bullet-0">
					<span class="c1">
						GRANT staff responsible for the security of information
						systems;
					</span>
				</li>
				<li class="c0 li-bullet-0">
					<span class="c1">
						This data may be transmitted to technical service
						providers involved in the realisation of these purposes.
						For your information, the data concerning our
						applications are hosted by Google servers in Frankfurt,
						Germany (europe-west3 zone).
					</span>
				</li>
				<li class="c0 li-bullet-0">
					<span class="c1">
						to our online payment service provider (LEMONWAY) in
						order to ensure the effective completion of the fund
						transfers and to enable them to fulfil their own due
						diligence and obligations in relation to the fight
						against fraud, money laundering and terrorist financing.
					</span>
				</li>
				<li class="c0 li-bullet-0">
					<span class="c1">
						to public bodies and authorities, judicial officers,
						persons in charge of financial control (auditors,
						chartered accountants), entities in charge of the fight
						against fraud, money laundering and the financing of
						terrorism, exclusively in order to meet our legal
						obligations.
					</span>
				</li>
			</ul>
			<p class="c3">
				<span class="c2">
					5.2 Transfer of personal data outside the European Union
				</span>
			</p>
			<p class="c4">
				<span class="c1">
					Within the framework of the use of the application, no
					transfer of personal data to countries that do not have an
					adequate level of protection within the meaning of the
					applicable regulations is foreseen.
				</span>
			</p>
			<p class="c3">
				<span class="c5">6. How long will my data be kept?</span>
			</p>
			<p class="c4">
				<span class="c1">
					The personal data collected are kept only for the time
					strictly necessary to achieve the purposes for which they
					are collected and processed:
				</span>
			</p>
			<ul class="c8 lst-kix_list_6-0 start">
				<li class="c0 li-bullet-0">
					<span class="c12">User account</span>
					<span class="c1">
						: User account data will be retained for the duration of
						the user&#39;s account activity and until the account is
						closed. If the account is inactive for an uninterrupted
						period of three (3) years, the user will be notified
						and, in the absence of a new connection within one (1)
						month of the notification, the account will be closed
						and the personal data attached to it deleted.
					</span>
				</li>
				<li class="c0 li-bullet-0">
					<span class="c12">
						Data relating to user accounts and the issuing of
						donations or receipt of donations
					</span>
					<span class="c1">
						: for the duration of the user&#39;s account activity,
						then 5 years from the date of the account closure
						request (to comply with the legal prescription). In case
						of inactivity of the account for 5 years without
						connection, we will ask you if you wish to keep your
						account. If you wish to close it, your data will be
						deleted within 3 months of your request.
					</span>
				</li>
				<li class="c0 li-bullet-0">
					<span class="c12">Cookies</span>
					<span class="c11">
						: the data required to produce statistics on the
						audience and use of online services are kept in a format
						that does not allow people to be identified by their IP
						address, and include an identifier (relating to the
						cookie) kept for a maximum of thirteen months unless the
						person concerned withdraws their consent.{" "}
					</span>
					<span class="c15 c11">
						<a
							class="c7"
							href="https://www.google.com/url?q=https://joingrant.com/cookies/&amp;sa=D&amp;source=editors&amp;ust=1679077967124153&amp;usg=AOvVaw0CiCuupm61ZtC8SLZIMw9p"
						>
							For more information on cookies
						</a>
					</span>
				</li>
				<li class="c0 li-bullet-0">
					<span class="c12">
						Complaints and requests for the exercise of rights
					</span>
					<span class="c1">
						: your data is archived in accordance with the legal
						requirements so that we can establish and exercise our
						legal rights.
					</span>
				</li>
				<li class="c0 li-bullet-0">
					<span class="c12">
						Data relating to our obligations to combat fraud
					</span>
					<span class="c1">
						, money laundering and the financing of terrorism: 5
						years from the termination of the contractual
						relationship or the closure of your account as regards
						identity data and documents. 5 years from the execution
						of the financial transaction in question as regards
						documents and information relating to the transactions
						carried out
					</span>
				</li>
			</ul>
			<p class="c3">
				<span class="c5">7. How does GRANT protect my data?</span>
			</p>
			<p class="c4">
				<span class="c1">
					All necessary measures are taken to preserve the security
					and confidentiality of the data and in particular to prevent
					them from being damaged or accessed by unauthorised third
					parties. To this end, we implement technical and
					organisational measures to guarantee an adequate level of
					security adapted to the risks.
				</span>
			</p>
			<p class="c3">
				<span class="c5">
					8. What are my rights regarding the use of personal data?
				</span>
			</p>
			<p class="c4">
				<span class="c1">
					With regard to your personal data, you have the following
					rights, within the limits of the legal provisions:
				</span>
			</p>
			<ul class="c8 lst-kix_list_7-0 start">
				<li class="c0 li-bullet-0">
					<span class="c1">right of access ;</span>
				</li>
				<li class="c0 li-bullet-0">
					<span class="c1">right of rectification ;</span>
				</li>
				<li class="c0 li-bullet-0">
					<span class="c1">right to erasure ;</span>
				</li>
				<li class="c0 li-bullet-0">
					<span class="c1">
						right to restrict one or more processing operations of
						personal data ;
					</span>
				</li>
				<li class="c0 li-bullet-0">
					<span class="c1">
						rights to modify and/or withdraw, at any time, consents
						regarding the processing of their personal data based on
						their consent;
					</span>
				</li>
				<li class="c0 li-bullet-0">
					<span class="c1">
						right to object to the processing of personal data ;
					</span>
				</li>
				<li class="c0 li-bullet-0">
					<span class="c1">
						the right to portability of your personal data.
					</span>
				</li>
				<li class="c0 li-bullet-0">
					<span class="c1">
						right to define general and/or specific directives
						concerning the fate of his/her personal data and the way
						in which he/she wishes his/her rights to be exercised
						after his/her death.
					</span>
				</li>
			</ul>
			<p class="c4">
				<span class="c1">
					Depending on the type of right you are exercising and the
					information you are requesting/wanting to be removed, GRANT
					may ask you to prove your identity.
				</span>
			</p>
			<p class="c4">
				<span class="c1">
					You can make any request to exercise your rights in relation
					to your personal data by contacting us:
				</span>
			</p>
			<ul class="c8 lst-kix_list_8-0 start">
				<li class="c0 li-bullet-0">
					<span class="c1">by email : privacy@joingrant.com</span>
				</li>
				<li class="c0 li-bullet-0">
					<span class="c1">
						by post to the following address Grant Solutions, 8 rue
						de l&#39;Est 92100 Boulogne-Billancourt, France
					</span>
				</li>
			</ul>
			<p class="c4">
				<span class="c1">
					If there is no reply or no satisfactory reply within two
					months of your request, to the Commission Nationale de
					l&#39;Informatique et des Libert&eacute;s (CNIL) at 3 Place
					de Fontenoy, TSA 80715, 75334 Paris Cedex 07 France - Tel:
					01 53 73 22 22
				</span>
			</p>
			<p class="c4">
				<span class="c1">
					Or via this form:
					https://www.cnil.fr/fr/webform/adresser-une-plainte
				</span>
			</p>
			<p class="c4">
				<span class="c11">
					For your convenience, further information on your rights can
					be found below: https:
				</span>
				<span class="c15 c11">
					<a
						class="c7"
						href="https://www.google.com/url?q=https://www.cnil.fr/fr/les-droits-pour-maitriser-vos-donnees-personnelles&amp;sa=D&amp;source=editors&amp;ust=1679077967125982&amp;usg=AOvVaw16izHcMDTUkCMlwgsXkpSi"
					>
						//www.cnil.fr/fr/les-droits-pour-maitriser-vos-donnees-personnelles
					</a>
				</span>
			</p>
			<p class="c3">
				<span class="c5">9. Changes to this policy</span>
			</p>
			<p class="c4">
				<span class="c1">
					This policy is intended to evolve and is therefore subject
					to change. In case of minor modifications, the new policy
					will be posted on the application in the dedicated section.
					In the event of substantial changes, for example concerning
					the purposes or recipients, you may be informed directly.
				</span>
			</p>
			<p class="c10">
				<span class="c14">copyright 2021 grant solutions SAS</span>
			</p>
			<p class="c10">
				<span class="c6">
					<a
						class="c7"
						href="https://www.google.com/url?q=https://joingrant.com/donnees-personnelles/&amp;sa=D&amp;source=editors&amp;ust=1679077967126570&amp;usg=AOvVaw2674OgKmhkjfs-cHKCDFqZ"
					>
						Privacy
					</a>
				</span>
				<span class="c9">&nbsp;| </span>
				<span class="c6">
					<a
						class="c7"
						href="https://www.google.com/url?q=https://joingrant.com/conditions-site/&amp;sa=D&amp;source=editors&amp;ust=1679077967126806&amp;usg=AOvVaw3L5EOi11_PtEzfspFkeyww"
					>
						Terms and Conditions
					</a>
				</span>
				<span class="c9">&nbsp;| </span>
				<span class="c6">
					<a
						class="c7"
						href="https://www.google.com/url?q=https://joingrant.com/cookies/&amp;sa=D&amp;source=editors&amp;ust=1679077967126995&amp;usg=AOvVaw3sxvtyeNTu8XB5IJpm-Mgb"
					>
						Cookies
					</a>
				</span>
				<span class="c9">&nbsp;| </span>
				<span class="c6">
					<a
						class="c7"
						href="https://www.google.com/url?q=https://joingrant.com/mentions/&amp;sa=D&amp;source=editors&amp;ust=1679077967127215&amp;usg=AOvVaw3oaBpEqtsuPPvYgL4Aar_9"
					>
						Legal Notice
					</a>
				</span>
			</p>
			<p class="c20">
				<span class="c17"></span>
			</p>
		</div>
	);
};

export default Content;
