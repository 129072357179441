import Section from "../../components/Section";
import Button from "../../components/Button";
import Highlight from "../../components/Highlight";
import ListInfo from "./ListInfo";

const Tutorial = () => {
	return (
		<>
			<Section>
				<h1>How does it work?</h1>
				<br />
				<br />
				<h6 className="mb-4">Who is Grant Solutions?</h6>
				<p className="mb-4">
					Grant Solutions is a French company that develops{" "}
					<Highlight>grant</Highlight>, which is a mobile rating and
					compensation service application, whose mission is to
					facilitate the transmission of tips and grants between
					donors (grantors) and service providers (grantees). It is a
					practical and advantageous alternative to traditional means
					of payment. It is GLOBAL and there are no currency barriers.
				</p>
				<p className="mb-4">
					<Highlight>Grant</Highlight> empowers professionals to be
					better rewarded for their services. In a cashless world,{" "}
					<Highlight>grant</Highlight> is an intuitive app enabling
					digital gratuity collection, providing you with a seamless
					path to higher earnings.
				</p>
			</Section>
			<Section alt>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-12">
					<div className="">
						<h6 className="mb-4">
							Why <Highlight>grant</Highlight>?
						</h6>
						<ListInfo
							items={{
								"Instant Gratification": () => (
									<p>
										Create your unique QR code and let
										customers tip you within seconds.
									</p>
								),
								"Simplicity and Efficiency": () => (
									<p>
										<Highlight>grant</Highlight> requires no
										registration or app downloads from
										givers. A single QR scan enables an
										effortless tipping experience.
									</p>
								),
								"Quick Cash-Outs": () => (
									<p>
										Monitor your earnings in real-time and
										access them through immediate
										withdrawals. Our direct bank transfers
										ensure your tips are always readily
										available.
									</p>
								),
								"Global Capability": () => (
									<p>
										Earn tips anywhere in the world,
										regardless of your customers' home
										countries.
									</p>
								),
								"Secure and Private Transactions": () => (
									<p>
										Your full name, phone number, or other
										personal data are not shared with
										customers, and we do not request such
										information from them.
									</p>
								),
								"Professional Growt": () => (
									<p>
										<Highlight>grant</Highlight> is more
										than a financial app; it's a source of
										motivation for achieving service
										excellence, offering rewards for your
										dedication and hard work.
									</p>
								),
							}}
						/>
					</div>
					<div className="md:mt-0 mt-4">
						<h6 className="mb-4">
							<Highlight>Grant's</Highlight> Unique Features
						</h6>
						<ListInfo
							items={{
								"Quick Setup": () => (
									<p>
										Start in less than a minute by providing
										your name and phone number to generate
										your unique QR code for immediate
										tipping.
									</p>
								),
								"Real-Time Alerts": () => (
									<p>
										Receive notifications instantly for each
										transaction, keeping you informed about
										your earnings on the go.
									</p>
								),
								"User-Friendly": () => (
									<p>
										<Highlight>grant</Highlight> offers a
										simple and easy-to-use interface with a
										quick-start tutorial, ensuring that
										everyone can use it effortlessly.
									</p>
								),
								"Customizable Interface": () => (
									<p>
										Tailor <Highlight>grant</Highlight> to
										fit your customers' preferences. Choose
										your language and app color and
										customize your text for tip requests.
									</p>
								),
								"Cash-Outs with ID Verification": () => (
									<p>
										Access your earnings swiftly after
										providing proof of identity.
									</p>
								),
								"Transparent Fees": () => (
									<p>
										<Highlight>grant</Highlight> is free to
										use, and only a small transaction fee is
										applied at cash-out, ensuring most of
										your tips remain yours.
									</p>
								),
								"Easy Sharing": () => (
									<p>
										Invite colleagues and other service
										professionals to use{" "}
										<Highlight>grant</Highlight> through a
										link or an onboarding QR code.
									</p>
								),
								"Secure Transactions": () => (
									<p>
										We prioritize your security.{" "}
										<Highlight>Grant</Highlight> is GDPR
										compliant, and all transactions are
										regulated by authorized financial
										institutions and payment service
										providers headquartered in France.
									</p>
								),
							}}
						/>
					</div>
				</div>
			</Section>
			<Section>
				<h6 className="mb-4">
					What is <Highlight>grant</Highlight> for?
				</h6>
				<p className="mb-4">
					<Highlight>grant</Highlight> is a mobile application used by
					all kind of Service Providers (SP) and taskers around the
					world, allowing him/her, to " request" a note on the quality
					of the service provided and potentially a tip, from a
					potential unknown or known donor/giver/grantor, by simply
					having its QR payment code flashed/scanned, without having
					to exchange phone numbers. This is an exceptionally secure
					and confidential way.
				</p>
				<p className="mb-4">
					From <Highlight>grant</Highlight>, the SP can create his own
					QR Code, in 1 minute, with lifetime validity and
					exclusivity, show it to anyone to whom he has rendered a
					service, and after being flashed/scanned, receive a thumbs
					up on the quality of the service and maybe also a tip which
					will be stored in his "jar" (a virtual wallet which is
					stored and secured at a global Tier 1 bank).
				</p>
			</Section>
			<Section alt>
				<h6 className="mb-4">Terminology</h6>
				<p className="mb-4">
					The{" "}
					<strong className="underline">
						Service Provider or Recipient or Grantee or Tasker
					</strong>
					: any person, individual and independant, who provides a
					service of any kind to another person and anywhere in the
					world: table service, delivery, porter, room service, valet
					parking, plumber, electrician, farmer, car washer,
					babysitter, etc ...
				</p>
				<p className="mb-4">
					The{" "}
					<strong className="underline">
						Donor or Grantor or Giver
					</strong>
					: any person, individual, who has been asked to assign a
					dematerialized note and tip/grant to reward the quality of a
					service, provided by a Service Provider and has been asked
					to flash a QR Code without having to ask for the SP's phone
					number or bank account or IBAN or address etc..total
					confidentiality.
				</p>
				<p className="mb-4">
					<strong className="underline">Jar</strong>: Service
					Provider's wallet in its cybersecurity space and in which
					all its tips and notes are added and stored and can only be
					viewed by the grantee.
				</p>
			</Section>
			<Section>
				<h1 className="mb-8">
					<Highlight>If you are a</Highlight> Service Provider or
					Recipient or Grantee or Tasker{" "}
					<Highlight>START HERE</Highlight>
				</h1>
				<p className="mb-4">
					Download for free the <Highlight>grant</Highlight>{" "}
					application from App Store or Google Play Store or receive
					an invitation by someone who already is using it.
				</p>
				<p className="mb-4">
					Create a <Highlight>grant</Highlight> user account. It
					should take you 1 minute to accomplish the first onboarding.
					There are 2 onboardings. Number 1 allows you instantly to
					have your own QR code created and have it flashed on the go.
					Then you have 5 days, once you get the first tips, to
					accomplish the second onboarding in order to cash out what
					you have in your jar.
				</p>
				<div className="mb-4 mt-12 text-lg">
					<strong>Step 1:</strong> install the{" "}
					<Highlight>grant</Highlight> app
				</div>
				<div className="flex flex-row items-center pl-8">
					<p className="mr-2">
						Get <Highlight>grant</Highlight> from:{" "}
					</p>
					<Button to="/download-app">Donwload Grant</Button>
				</div>
				<div className="mb-4 mt-12 text-lg">
					<strong>Step 2:</strong> Create a{" "}
					<Highlight>grant</Highlight> user account
				</div>
				<div className="pl-8 mb-4">
					<p className="">
						To create an account, you must launch the grant
						application on your phone. The grant home screen
						appears. It is then necessary to:
					</p>
					<ol className="list-decimal mt-4 pl-8">
						<li className="mb-2">
							Click on <strong>"I sign and I accept"</strong>{" "}
							after having read our T & Cs: happy reading.
						</li>
						<li className="mb-2">
							In the 1st screen, enter your phone number , press
							Continue, receive an SMS with a 6-digit code ...
							then? not received? check your number and ask for
							the SMS again or maybe you have received it on your
							SMS app and it doesn't show on the{" "}
							<Highlight>grant</Highlight> app. Go and get it…copy
							it and type it into <Highlight>grant</Highlight>{" "}
						</li>
						<li className="mb-2">
							In the 2nd screen, enter your first and last name
						</li>
						<li className="mb-2">
							Confirm the creation of the account by clicking on
							"Next".
						</li>
						<li className="mb-2">
							Your QR Code is now yours and you can start having
							it flashed
						</li>
					</ol>
					<p className="mb-4">
						Later on (MAX. 5 DAYS) or immediately if you have
						sometime available, do your second and final onboarding:
						Add your IDs + Iban / RIB in{" "}
						<Highlight>grant</Highlight>
					</p>
					<p className="mb-4">
						With <Highlight>grant</Highlight> , the user must add
						the details of his bank account from the home page of
						the application to: receive his tips in his jar,
						Transfer his money from his jar to his bank account,
						with a swipe of finger ; See his tip balance in the
						application, See all his payments in a single history;
						Have relevant alerts to quickly have an overview of the
						status of your <Highlight>grant</Highlight> account, at
						any time (notifications on your balance, or for each
						transaction carried out)
					</p>
				</div>
				<div className="mb-4 mt-12 text-lg">
					<strong>Step 3:</strong> Personalize your{" "}
					<Highlight>grant</Highlight> environment
				</div>
				<div className="pl-8 mb-4">
					These display options are available during registration or
					each time you want to present your QR Code. Slide your
					finger on the QR code to change the color, click on the
					photo to change it, click on the sentence to choose the one
					that matches the mood of the moment or the language.
				</div>
			</Section>
		</>
	);
};

export default Tutorial;
