const Content = () => {
	return (
		<div class="doc-content">
			<div>
				<p class="c11 c31">
					<span class="c21"></span>
				</p>
			</div>
			<h1 class="c15 c27" id="h.gjdgxs">
				<span class="c22 c39">
					Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation des
					Applications
				</span>
			</h1>
			<p class="c2">
				<span class="c22 c36">26 f&eacute;vrier 2023</span>
			</p>
			<p class="c2">
				<span>Cliquez ici pour consulter les </span>
				<span class="c14 c18">
					<a
						class="c12"
						href="https://www.google.com/url?q=https://joingrant.com/conditions-site/&amp;sa=D&amp;source=editors&amp;ust=1679074681163270&amp;usg=AOvVaw1itDM79iz57y4z_hs1QdHm"
					>
						Conditions d&rsquo;utilisation du site (CGUS)
					</a>
				</span>
			</p>
			<p class="c2">
				<span>
					GRANT SOLUTIONS (&quot;GRANT&quot;) est une
					soci&eacute;t&eacute; par actions simplifi&eacute;e au
					capital de 49 600 &euro; immatricul&eacute;e au registre du
					commerce et des soci&eacute;t&eacute;s de Nanterre sous le
					n&deg; 888&nbsp;360 757, dont le si&egrave;ge social est
					situ&eacute; 8 rue de l&rsquo;Est, 92100
					Boulogne-Billancourt (TVA communautaire n&deg; TVA{" "}
				</span>
				<span class="c22">FR08888360757</span>
				<span>) et qui &eacute;dite le site Internet </span>
				<span class="c17">
					<a
						class="c12"
						href="https://www.google.com/url?q=http://www.joingrant.com&amp;sa=D&amp;source=editors&amp;ust=1679074681164322&amp;usg=AOvVaw06FOHsd11JTVotjtz2a714"
					>
						www.joingrant.com
					</a>
				</span>
				<span>&nbsp;(ci-apr&egrave;s le &quot;</span>
				<span class="c3">Site</span>
				<span>
					&quot;) et les applications pour t&eacute;l&eacute;phone
					mobile &quot;grant&quot; et &quot;grant T&quot;
					(ci-apr&egrave;s la ou les &quot;
				</span>
				<span class="c3">Application(s)</span>
				<span>
					&quot;). Pour toute question, vous pouvez nous contacter via{" "}
				</span>
				<span class="c17">
					<a class="c12" href="mailto:contact@joingrant.com">
						contact@joingrant.com
					</a>
				</span>
				<span class="c0">
					&nbsp;ou par t&eacute;l&eacute;phone au +33 (0)9 77 19 59 12
					(prix d&rsquo;un appel selon les tarifs de votre
					op&eacute;rateur de t&eacute;l&eacute;phonie).
				</span>
			</p>
			<p class="c2 c35">
				<span class="c0">
					Directeur de publication&nbsp;: Kersat, pr&eacute;sident de
					GRANT.
				</span>
			</p>
			<p class="c2 c19 c40">
				<span class="c0">
					H&eacute;bergeur&nbsp;:
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Google Cloud
					France, 8 rue de Londres, 75009 Paris, t&eacute;l. 0805 540
					801
				</span>
			</p>
			<p class="c2 c19">
				<span class="c0">
					H&eacute;bergement dans le datacenter
					&quot;europe-west3&quot; de Francfort en Allemagne
				</span>
			</p>
			<p class="c2">
				<span>
					Les Applications peuvent &ecirc;tre utilis&eacute;es par
					toute personne physique s&rsquo;&eacute;tant inscrit en tant
					&quot;qu&rsquo;
				</span>
				<span class="c3">Utilisateur</span>
				<span>&quot; et ayant ouvert un &quot;</span>
				<span class="c3">Compte Utilisateur</span>
				<span>
					&quot;&nbsp;; elles donnent acc&egrave;s aux
					fonctionnalit&eacute;s d&rsquo;un programme appartenant
					&agrave; GRANT et fourni en mode logiciel-service (SaaS) via
					Internet (la &quot;
				</span>
				<span class="c3">Solution</span>
				<span>
					&quot;). Les Utilisateurs et GRANT sont ci-apr&egrave;s
					d&eacute;sign&eacute;es collectivement comme les &quot;
				</span>
				<span class="c3">Parties</span>
				<span>&quot; et individuellement chacun comme une &quot;</span>
				<span class="c3">Partie</span>
				<span class="c0">&quot;.</span>
			</p>
			<p class="c2">
				<span>
					Les Utilisateurs ont recours &agrave; la Solution pour (i)
					transmettre et recevoir des &quot;
				</span>
				<span class="c3">Grant</span>
				<span>
					&quot; d&rsquo;un montant inf&eacute;rieur &agrave;
					cinquante euros (50 &euro;) entre Utilisateurs et (ii)
					attribuer une notation aux actions et services des autres
					Utilisateurs auxquels ils ont transmis un ou plusieurs
					Grants (ci-apr&egrave;s les &quot;
				</span>
				<span class="c3">Prestations</span>
				<span class="c0">&quot;).</span>
			</p>
			<p class="c2">
				<span class="c3">
					Afin de fournir ces Prestations sur la Solution, GRANT est
					mandat&eacute;e par le prestataire de services de paiement
					suivant&nbsp;:
				</span>
			</p>
			<p class="c2">
				<span>PSP&nbsp;: </span>
				<span class="c3">Lemonway</span>
				<span>
					, soci&eacute;t&eacute; par actions simplifi&eacute;e dont
					le si&egrave;ge social est situ&eacute; au 8 rue du Sentier,
					75002 Paris, France, immatricul&eacute;e au registre du
					commerce et des soci&eacute;t&eacute;s de Paris sous le
					num&eacute;ro 500 486 915 (
				</span>
				<span class="c3">&laquo; Lemonway &raquo;</span>
				<span>
					), agr&eacute;&eacute;e le 24 d&eacute;cembre 2012 par
					l&rsquo;Autorit&eacute; de Contr&ocirc;le Prudentiel et de
					R&eacute;solution (&laquo;&nbsp;ACPR&nbsp;&raquo;, France,
					site internet acpr.banque-france.fr/), 4&nbsp;place de
					Budapest CS 92459, 75436 Paris, en qualit&eacute;
					d&rsquo;&eacute;tablissement de paiement, sous le
					num&eacute;ro 16568 et dont les conditions
					d&rsquo;utilisation des services sont disponibles{" "}
				</span>
				<span class="c14">
					<a
						class="c12"
						href="https://www.google.com/url?q=https://www.lemonway.com/conditions-generales-dutilisation/&amp;sa=D&amp;source=editors&amp;ust=1679074681169621&amp;usg=AOvVaw2fXGJWEAL56mUkuY7DYZVS"
					>
						ici
					</a>
				</span>
				<span class="c0">.</span>
			</p>
			<p class="c2">
				<span>
					Pour agir en tant que mandataire de LEMONWAY, GRANT est
					enregistr&eacute;e en tant qu&rsquo;agent de prestataires de
					services de paiement (&quot;
				</span>
				<span class="c3">APSP</span>
				<span>
					&quot;) sous le num&eacute;ro [94455], le [20/09/2021],
					aupr&egrave;s de l&rsquo;Autorit&eacute; de Contr&ocirc;le
					Prudentiel et de R&eacute;solution (&quot;
				</span>
				<span class="c3">ACPR</span>
				<span>
					&quot;), &eacute;tablie au 4 Place de Budapest - 75436 Paris
					Cedex 09. GRANT est inscrite &agrave; ce titre sur le
					registre des agents financiers consultable{" "}
				</span>
				<span class="c17">
					<a
						class="c12"
						href="https://www.google.com/url?q=https://www.regafi.fr/spip.php?page%3Dresults%26type%3Dsimple%26id_secteur%3D1%26lang%3Dfr%26denomination%3DLemonway&amp;sa=D&amp;source=editors&amp;ust=1679074681170756&amp;usg=AOvVaw1B34i0Q3rxHNKJ9TKnUFXd"
					>
						https://www.regafi.fr/spip.php?page=results&amp;type=simple&amp;id_secteur=1&amp;lang=fr&amp;denomination=Lemonway
					</a>
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Si GRANT ne remplit plus les conditions
					d&rsquo;enregistrement aupr&egrave;s de l&rsquo;ACPR, les
					Utilisateurs en seront inform&eacute;s dans un d&eacute;lai
					de quinze (15) jours &agrave; compter de la r&eacute;ception
					par GRANT de toute notification de l&rsquo;ACPR ou de
					LEMONWAY.
				</span>
			</p>
			<p class="c2">
				<span>
					Les pr&eacute;sentes conditions g&eacute;n&eacute;rales
					d&rsquo;utilisation des Applications (&quot;
				</span>
				<span class="c3">CGUA</span>
				<span class="c0">
					&quot;) d&eacute;terminent les conditions
					d&rsquo;inscription et d&rsquo;utilisation de la Solution.
					Votre utilisation de la Solution est soumise &agrave;
					l&rsquo;acceptation des pr&eacute;sentes CGUA. Vous pouvez
					conserver une copie des CGUA sur votre ordinateur ou sur
					tout autre support, notamment en l&rsquo;imprimant afin
					d&rsquo;en conserver une copie papier.
				</span>
			</p>
			<h2 class="c8" id="h.30j0zll">
				<span class="c20">Article 1. D&eacute;finitions</span>
			</h2>
			<p class="c2 c15">
				<span class="c0">
					Les termes d&eacute;butant par une majuscule,
					utilis&eacute;s au singulier ou au pluriel, ont la
					signification qui leur est donn&eacute;e ci-apr&egrave;s.
				</span>
			</p>
			<ol class="c1 lst-kix_list_6-0 start" start="1">
				<li class="c24 c7 c15 li-bullet-0">
					<span class="c3">Application</span>
					<span class="c0">
						&nbsp;: Les applications &quot;Grant&quot; et/ou
						&quot;Grant T&quot; t&eacute;l&eacute;chargeables sur
						l&rsquo;App Store et Google Store et permettant &agrave;
						tout Utilisateur d&rsquo;acc&eacute;der &agrave; son
						Compte Utilisateur et d&rsquo;utiliser les Prestations
						de la Solution. Chaque Application est uniquement
						utilisable par un type d&rsquo;Utilisateur :
						&quot;Grant&quot; par les Emetteurs, &quot;Grant T&quot;
						par les R&eacute;cipiendaires.
					</span>
				</li>
				<li class="c26 c7 c15 li-bullet-0">
					<span class="c3">ACPR</span>
					<span class="c0">
						&nbsp;: L&rsquo;Autorit&eacute; de Contr&ocirc;le
						Prudentiel et de R&eacute;solution, &eacute;tablie au 4
						Place de Budapest - 75436 Paris Cedex 09.
					</span>
				</li>
				<li class="c26 c7 c15 li-bullet-0">
					<span class="c3">Anomalies</span>
					<span class="c0">
						&nbsp;: Dysfonctionnement reproductible, ralentissement
						ou indisponibilit&eacute; de la Solution, non imputable
						&agrave; un cas d&rsquo;exclusion de Maintenance.
					</span>
				</li>
				<li class="c26 c7 c15 li-bullet-0">
					<span class="c3">APSP</span>
					<span class="c0">
						&nbsp;: Un agent de prestataires de services de
						paiement, par exemple GRANT, au sens des articles L.
						523-1 et suivants du Code mon&eacute;taire et financier.
					</span>
				</li>
				<li class="c26 c7 c15 li-bullet-0">
					<span class="c3">CGUA</span>
					<span class="c0">
						&nbsp;: Les pr&eacute;sentes conditions
						g&eacute;n&eacute;rales d&rsquo;utilisation et leurs
						annexes.
					</span>
				</li>
				<li class="c26 c7 c15 li-bullet-0">
					<span class="c3">CGUS</span>
					<span class="c0">
						&nbsp;: Les conditions g&eacute;n&eacute;rales
						d&rsquo;utilisation du Site.
					</span>
				</li>
				<li class="c26 c7 c15 li-bullet-0">
					<span class="c3">Code QR</span>
					<span class="c0">
						&nbsp;: Un Code QR est un code-barre en deux dimensions,
						constitu&eacute; de modules carr&eacute;s de couleurs
						fonc&eacute;es et de couleurs claires. Un Code QR peut
						&ecirc;tre (i) un Code QR associ&eacute; en permanence
						&agrave; un R&eacute;cipiendaire (&quot;CQRL&quot;) tant
						que celui-ci satisfait les Proc&eacute;dures KYC ou (ii)
						un Code QR associ&eacute; &agrave; chaque Grant
						&eacute;mis par un Emetteur sur la Solution
						(&quot;CQRG&quot;). Un Code QR doit &ecirc;tre
						scann&eacute; par un Utilisateur via son
						t&eacute;l&eacute;phone portable afin de r&eacute;aliser
						une action et/ou une Prestation sur la Solution.
					</span>
				</li>
				<li class="c26 c7 c15 li-bullet-0">
					<span class="c3">Compte Utilisateur</span>
					<span class="c0">
						&nbsp;: Compte permettant l&rsquo;acc&egrave;s par des
						Identifiants &agrave; la Solution mise &agrave;
						disposition de l&rsquo;Utilisateur et lui permettant (i)
						d&rsquo;utiliser la Solution et (ii) de commander et/ou
						de b&eacute;n&eacute;ficier des Prestations. Le Compte
						Utilisateur est strictement personnel et exclusif
						&agrave; l&rsquo;Utilisateur, non cessible et non
						transmissible. Il est connect&eacute; par
						l&rsquo;Utilisateur &agrave; son compte bancaire et
						contient un historique des Prestations et des Notations.
					</span>
				</li>
				<li class="c7 c15 c26 li-bullet-0">
					<span class="c3">Contenu</span>
					<span class="c0">
						&nbsp;: Le Site et chacun des &eacute;l&eacute;ments qui
						les composent (tels que tous textes, processus de
						d&eacute;cision, logiciels, animations, photographies,
						illustrations, images, sch&eacute;mas, bandes sonores,
						textes, logos, marques, dessins et mod&egrave;les), y
						compris les &eacute;l&eacute;ments logiciels
						n&eacute;cessaires au fonctionnement du Site, les
						marques d&eacute;pos&eacute;es et/ou d&eacute;tenues par
						GRANT, les bases de donn&eacute;es, les courriels et/ou
						la newsletter transmis automatiquement aux Utilisateurs.
					</span>
				</li>
				<li class="c26 c7 c15 li-bullet-0">
					<span class="c3">Documentation</span>
					<span class="c0">
						&nbsp;: La documentation standard de la Solution mise
						&agrave; disposition sur les Applications par GRANT, en
						langue fran&ccedil;aise et pr&eacute;sentant les
						diff&eacute;rentes Prestations. La Documentation est
						jointe en Annexe 1 des CGUA et fait partie des documents
						contractuels.
					</span>
				</li>
				<li class="c26 c7 c15 li-bullet-0">
					<span class="c3">Donn&eacute;es</span>
					<span class="c0">
						&nbsp;: Donn&eacute;es, fichiers, informations
						cr&eacute;&eacute;es, collect&eacute;es, acquises,
						agr&eacute;g&eacute;es et/ou h&eacute;berg&eacute;es par
						ou pour le compte de l&rsquo;Utilisateur dans le cadre
						de son activit&eacute; ou remis &agrave; GRANT dans le
						cadre de la fourniture des Prestations, &agrave;
						l&rsquo;exclusion des donn&eacute;es de GRANT. Les
						Donn&eacute;es charg&eacute;es par l&rsquo;Utilisateur
						sont strictement li&eacute;es &agrave; son
						activit&eacute; sur la Solution et doivent respecter les
						termes des CGUA : l&rsquo;Utilisateur garantit
						qu&rsquo;aucune donn&eacute;e sensible, en particulier
						de sant&eacute;, ne fait partie des Donn&eacute;es. Les
						Donn&eacute;es incluent des Donn&eacute;es Personnelles
						et des donn&eacute;es de paiement au sens du droit
						applicable.
					</span>
				</li>
				<li class="c26 c7 c15 li-bullet-0">
					<span class="c3">Donn&eacute;es Personnelles</span>
					<span class="c0">
						&nbsp;: Toute donn&eacute;e permettant
						d&rsquo;identifier directement ou indirectement une
						personne physique telle que d&eacute;finie par
						l&rsquo;article 4 du RGPD.
					</span>
				</li>
				<li class="c26 c7 c15 li-bullet-0">
					<span class="c3">Emetteur</span>
					<span class="c0">
						&nbsp;: Utilisateur cr&eacute;ant et attribuant un Grant
						&agrave; un R&eacute;cipiendaire via l&rsquo;Application
						ou une page de renvoi vers la Solution. Apr&egrave;s la
						cr&eacute;ation du Grant sur son Application,
						l&rsquo;Emetteur doit montrer le CQRG affich&eacute; sur
						son t&eacute;l&eacute;phone portable au
						R&eacute;cipiendaire pour que ce dernier puisse le
						scanner via son t&eacute;l&eacute;phone portable et
						recevoir le montant dudit Grant.
					</span>
				</li>
				<li class="c26 c7 c15 li-bullet-0">
					<span class="c3">Frais ou Frais de Transaction</span>
					<span class="c0">
						&nbsp;: Les frais en euros TTC acquitt&eacute;s par
						l&rsquo;Utilisateur et s&#39;ajoutant au montant de son
						Grant au moment o&ugrave; celui-ci est
						pr&eacute;lev&eacute;. Ils repr&eacute;sentent la somme
						des commissions et co&ucirc;ts pr&eacute;lev&eacute;s
						par LEMONWAY &agrave; l&rsquo;occasion du transfert du
						Grant et des frais de fonctionnement de GRANT (gestion
						des Grants, suivi des transactions, interface entre les
						banques et les Utilisateurs, etc&hellip;). Des frais
						suppl&eacute;mentaires peuvent &ecirc;tre
						appliqu&eacute;es par des tiers, non compris dans les
						Frais, notamment des frais d&rsquo;itin&eacute;rance
						pr&eacute;lev&eacute;s s&eacute;par&eacute;ment par les
						op&eacute;rateurs de t&eacute;l&eacute;phonie des
						Utilisateurs selon les abonnements mobiles des
						Utilisateurs des Applications ou des frais de change
						appliqu&eacute;s par les &eacute;tablissements bancaires
						des Utilisateurs lorsque ceux-ci n&rsquo;utilisent pas
						de comptes en euros.
					</span>
				</li>
				<li class="c26 c7 c15 li-bullet-0">
					<span class="c3">Grant</span>
					<span class="c0">
						&nbsp;: Un pourboire vers&eacute; entre Utilisateurs sur
						la Solution et dont le montant est choisi dans une liste
						pr&eacute;d&eacute;finie de 1 &euro; &agrave; 50 &euro;
						maximum (1&euro;, 2&euro;, 5&euro;, 10&euro;, 15&euro;,
						20&euro;, 30&euro;, 40&euro;, 50&euro;). Ce Grant est
						obligatoirement associ&eacute; &agrave; une Notation.
					</span>
				</li>
				<li class="c26 c7 c15 li-bullet-0">
					<span class="c3">Identifiants</span>
					<span class="c0">
						&nbsp;: Le login et le mot de passe de
						l&rsquo;Utilisateur, qui lui donne acc&egrave;s &agrave;
						son Compte Utilisateur sur la Solution o&ugrave; il peut
						connecter son compte bancaire ainsi qu&rsquo;utiliser
						toute Prestation.
					</span>
				</li>
				<li class="c26 c7 c15 li-bullet-0">
					<span class="c3">Infrastructure</span>
					<span class="c0">
						&nbsp;: Infrastructure mat&eacute;rielle et logicielle
						utilis&eacute;e par GRANT via un prestataire
						sous-traitant h&eacute;bergeur afin de fournir le Site,
						la Solution et les Prestations. Le prestataire
						d&rsquo;h&eacute;bergement est un tiers qui est seul
						responsable de la qualit&eacute; de
						l&rsquo;Infrastructure.
					</span>
				</li>
				<li class="c26 c7 c15 li-bullet-0">
					<span class="c3">Maintenance</span>
					<span class="c0">
						&nbsp;: Prestation de r&eacute;tablissement ou de
						correction des &eacute;ventuelles Anomalies de la
						Solution.
					</span>
				</li>
				<li class="c26 c7 c15 li-bullet-0">
					<span class="c3">Mise &agrave; jour</span>
					<span class="c0">
						&nbsp;: Toute &eacute;volution, mise &agrave; jour, mise
						&agrave; niveau ou nouvelle version fonctionnelle ou
						technique de la Solution mise en place par GRANT, et
						automatiquement d&eacute;ploy&eacute;e sur les
						Applications pour l&#39;ensemble des Utilisateurs de
						GRANT.
					</span>
				</li>
				<li class="c26 c7 c15 li-bullet-0">
					<span class="c3">Notation</span>
					<span class="c0">
						&nbsp;: La Prestation par laquelle l&rsquo;Emetteur
						attribue au R&eacute;cipiendaire une &eacute;valuation
						sous la forme d&rsquo;un avis positif (pouce vers le
						haut) ou n&eacute;gatif (pouce vers le bas). Il
						n&rsquo;est pas possible de noter les
						R&eacute;cipiendaires sans leur avoir transmis des
						Grants (m&ecirc;me si le montant du Grant peut
						&ecirc;tre fix&eacute; &agrave; 0&euro;). Chaque
						R&eacute;cipiendaire a acc&egrave;s, depuis son Compte
						Utilisateur, (i) &agrave; un historique des Notations
						attribu&eacute;es pour chaque Grant et (ii) &agrave; une
						Notation totale moyenne (en pourcentage de Notations
						positives par rapport au total).
					</span>
				</li>
				<li class="c26 c7 c15 li-bullet-0">
					<span class="c3">Prestations</span>
					<span class="c0">
						&nbsp;: Les prestations fournies par GRANT &agrave;
						l&rsquo;Utilisateur en application des CGUA, notamment
						(i) transmettre et recevoir des Grants d&rsquo;un
						montant inf&eacute;rieur &agrave; cinquante euros (50
						&euro;) entre Utilisateurs et (ii) attribuer une
						Notation.
					</span>
				</li>
				<li class="c26 c7 c15 li-bullet-0">
					<span class="c3">Proc&eacute;dures KYC</span>
					<span class="c0">
						&nbsp;: Les proc&eacute;dures &quot;Know Your
						Customer&quot; de v&eacute;rification de
						l&rsquo;identit&eacute; et des transactions des
						Utilisateurs telles qu&rsquo;impos&eacute;es par le
						droit applicable aux prestataires de paiement (dont
						GRANT en tant qu&rsquo;APSP et LEMONWAY en tant que PSP)
						afin de lutter contre la corruption, le blanchiment
						d&rsquo;argent et le financement du terrorisme.
					</span>
				</li>
				<li class="c26 c7 c15 li-bullet-0">
					<span class="c3">R&eacute;cipiendaire</span>
					<span class="c0">
						&nbsp;: Utilisateur pouvant cr&eacute;diter des Grants
						via l&rsquo;Application &quot;Grant T&quot; et qui doit
						pour cela (i) scanner le CQRG
						g&eacute;n&eacute;r&eacute; et &eacute;mis par
						l&rsquo;Application de l&rsquo;Emetteur avec son
						t&eacute;l&eacute;phone portable et (ii) connecter un
						compte bancaire &agrave; l&rsquo;Application en
						conformit&eacute; avec les proc&eacute;dures de
						v&eacute;rification et de lutte contre le blanchiment.
					</span>
				</li>
				<li class="c26 c7 c15 li-bullet-0">
					<span class="c3">RGPD</span>
					<span class="c0">
						&nbsp;: Le r&egrave;glement g&eacute;n&eacute;ral de
						protection des donn&eacute;es n&deg;2016/679 du 27 avril
						2016
					</span>
				</li>
				<li class="c26 c7 c15 li-bullet-0">
					<span class="c3">Site</span>
					<span>&nbsp;: Le site Internet </span>
					<span class="c14">
						<a
							class="c12"
							href="https://www.google.com/url?q=https://joingrant.com&amp;sa=D&amp;source=editors&amp;ust=1679074681180470&amp;usg=AOvVaw1bMc4r6ZNHC7VzfnmUlIrY"
						>
							www.joingrant.com
						</a>
					</span>
					<span class="c0">
						&nbsp;de GRANT que les Visiteurs peuvent consulter.
					</span>
				</li>
				<li class="c26 c7 c15 li-bullet-0">
					<span class="c3">Solution</span>
					<span class="c0">
						&nbsp;: Le logiciel-service de GRANT disponible sur les
						Applications, ainsi que sa maintenance et son support.
						L&rsquo;Utilisateur acc&egrave;de &agrave; la Solution
						via des API, librairies logicielles ou plugins de
						synchronisation.
					</span>
				</li>
				<li class="c26 c7 c15 li-bullet-0">
					<span class="c3">Utilisateurs</span>
					<span class="c0">
						&nbsp;: Toute personne physique qui utilise les
						Prestations propos&eacute;es par la Solution en
						application des CGUA, soit &agrave; partir d&rsquo;une
						Application et de son Compte Utilisateur, soit &agrave;
						partir d&rsquo;une page de renvoi.
					</span>
				</li>
				<li class="c10 c7 c15 li-bullet-0">
					<span class="c3">Visiteurs</span>
					<span class="c0">
						&nbsp;: Toute personne physique ou morale
						acc&eacute;dant au Site.
					</span>
				</li>
			</ol>
			<h2 class="c8" id="h.1fob9te">
				<span class="c20">Article 2. Acceptation</span>
			</h2>
			<p class="c2">
				<span class="c0">
					L&rsquo;Utilisateur d&eacute;clare avoir pris connaissance
					et reconna&icirc;t&nbsp;:
				</span>
			</p>
			<ul class="c1 lst-kix_list_7-0 start">
				<li class="c2 c7 li-bullet-0">
					<span class="c0">
						qu&rsquo;il a re&ccedil;u de GRANT toutes les
						informations et recommandations n&eacute;cessaires pour
						&eacute;valuer si la Solution et les Prestations
						r&eacute;pondent &agrave; ses besoins&nbsp;;
					</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">
						qu&rsquo;il a pris connaissance et accept&eacute; les
						termes et conditions des CGUA&nbsp;;
					</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">
						que les Prestations sont fournies &agrave; distance sans
						garantie ni obligation de disponibilit&eacute; ;
					</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">
						qu&rsquo;il s&rsquo;est assur&eacute; du respect des
						pr&eacute;requis n&eacute;cessaires pour
						l&rsquo;utilisation de la Solution, notamment ceux
						recens&eacute;s dans la section
						&laquo;&nbsp;Pr&eacute;requis&nbsp;&raquo;
						ci-apr&egrave;s&nbsp;; et
					</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">
						qu&rsquo;il dispose de toutes les comp&eacute;tences
						n&eacute;cessaires pour acc&eacute;der et utiliser la
						Solution et les Prestations dans des conditions
						optimales.
					</span>
				</li>
			</ul>
			<p class="c2">
				<span class="c0">
					L&rsquo;utilisation des Applications (et, partant, de la
					Solution et des Prestations) est conditionn&eacute;e au
					t&eacute;l&eacute;chargement des Applications et &agrave; la
					cr&eacute;ation d&rsquo;un Compte Utilisateur. Lors de la
					cr&eacute;ation du Compte Utilisateur, il vous sera
					express&eacute;ment demand&eacute; d&rsquo;accepter
					formellement les pr&eacute;sentes CGUA via une page
					surimpos&eacute;e contenant
					l&rsquo;int&eacute;gralit&eacute; des CGUA, en cliquant sur
					le bouton &laquo;&nbsp;Accepter&nbsp;&raquo;. Si vous
					acc&eacute;dez &agrave; la Solution via une page de renvoi,
					vous devez accepter formellement les pr&eacute;sentes CGUA
					en cochant la case &agrave; cet effet sur le formulaire.
				</span>
			</p>
			<p class="c2">
				<span class="c3">
					Il est express&eacute;ment pr&eacute;cis&eacute; que
					l&rsquo;acceptation des CGUA emporte celle des conditions
					d&rsquo;utilisation de LEMONWAY qui assure le bon
					fonctionnement du syst&egrave;me de paiement propos&eacute;
					par la Solution et disponible &agrave;{" "}
				</span>
				<span class="c3 c14">
					<a
						class="c12"
						href="https://www.google.com/url?q=https://www.lemonway.com/conditions-generales-dutilisation/&amp;sa=D&amp;source=editors&amp;ust=1679074681184105&amp;usg=AOvVaw2clXuX_e2JqViAboZVMygj"
					>
						cette adresse
					</a>
				</span>
				<span class="c3 c22 c25">.</span>
			</p>
			<p class="c2">
				<span class="c0">
					L&rsquo;acceptation des CGUA est enti&egrave;re et forme un
					tout indivisible. Les Parties ne peuvent y d&eacute;roger ou
					formuler des r&eacute;serves et ne peuvent pas
					d&eacute;cider d&rsquo;une application partielle. Les CGUA
					conclues restent disponibles &agrave; tout moment sur le
					Compte Utilisateur de l&rsquo;Utilisateur.
				</span>
			</p>
			<h2 class="c8" id="h.3znysh7">
				<span class="c20">Article 3. Documents contractuels</span>
			</h2>
			<p class="c2">
				<span class="c0">
					GRANT peut modifier les CGUA &agrave; tout moment, y compris
					ses annexes, afin de les mettre &agrave; jour et notamment
					de prendre en compte toute &eacute;volution l&eacute;gale,
					jurisprudentielle, &eacute;ditoriale ou technique. Les CGUA
					pr&eacute;c&eacute;dentes seront alors
					r&eacute;sili&eacute;es de plein droit et remplac&eacute;es
					par la nouvelle version des CGUA, mentionnant la date de la
					derni&egrave;re mise &agrave; jour en t&ecirc;te des CGUA.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					La version des CGUA applicable aux Utilisateurs est la
					derni&egrave;re version formellement accept&eacute;e par
					l&rsquo;Utilisateur lors de son acc&egrave;s &agrave; la
					Solution. L&rsquo;Utilisateur peut &agrave; tout moment
					refuser la nouvelle version des CGUA en r&eacute;siliant son
					Compte Utilisateur et en d&eacute;sinstallant
					l&rsquo;Application ou en quittant la page de renvoi.
				</span>
			</p>
			<p class="c2">
				<span>
					Les CGUA se composent, de mani&egrave;re indivisible et par
					ordre d&eacute;croissant de priorit&eacute; contractuelle,
					(i) des pr&eacute;sentes CGUA et (ii) de ses annexes,
					notamment de la documentation de la Solution, de la
					politique de confidentialit&eacute; ainsi que de la
					politique cookies de GRANT et (iii) des conditions
					d&rsquo;utilisation de LEMONWAY disponibles
				</span>
				<span>&nbsp;</span>
				<span>&agrave; </span>
				<span class="c30">
					<a
						class="c12"
						href="https://www.google.com/url?q=https://www.lemonway.com/conditions-generales-dutilisation/&amp;sa=D&amp;source=editors&amp;ust=1679074681186209&amp;usg=AOvVaw2D7NfHD-oVlO6IJw466UfR"
					>
						cette adresse
					</a>
				</span>
				<span class="c0">
					. En cas de contradiction, d&rsquo;ambigu&iuml;t&eacute; ou
					de divergence d&rsquo;interpr&eacute;tation entre deux
					documents contractuels, le document de rang sup&eacute;rieur
					pr&eacute;vaut sur le document de rang inf&eacute;rieur. Si
					elle a lieu entre deux documents contractuels de m&ecirc;me
					rang, le dernier en date pr&eacute;vaut sur le moins
					r&eacute;cent.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Ces documents contractuels forment un ensemble et
					constituent l&rsquo;int&eacute;gralit&eacute; de la relation
					entre les Utilisateurs et GRANT, &agrave; l&rsquo;exclusion
					de tout autre document ; ils annulent et remplacent tous
					documents ant&eacute;rieurs relatifs au m&ecirc;me objet.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Le refus des nouvelles CGUA vaut r&eacute;siliation des
					CGUA, qui resteront en vigueur dans leur derni&egrave;re
					version accept&eacute;e pendant la dur&eacute;e des
					op&eacute;rations de fermeture du Compte Utilisateur.
				</span>
			</p>
			<h2 class="c8" id="h.2et92p0">
				<span class="c20">Article 4. Dur&eacute;e</span>
			</h2>
			<p class="c2 c15">
				<span class="c0">
					Les CGUA sont conclues entre GRANT et l&rsquo;Utilisateur
					&agrave; compter de son inscription pour la dur&eacute;e
					d&rsquo;utilisation du Compte Utilisateur, qui prend fin en
					cas (i) de r&eacute;siliation du CGUA par GRANT pour faute
					de l&rsquo;Utilisateur, (ii) de d&eacute;sinscription de
					l&rsquo;Utilisateur &agrave; tout moment ou (iii) par
					d&eacute;faut, &agrave; l&rsquo;issue d&rsquo;une
					p&eacute;riode de trois ans &agrave; compter du dernier
					contact actif entre l&rsquo;Utilisateur et GRANT.
				</span>
			</p>
			<p class="c2">
				<span class="c41">
					L&rsquo;Utilisateur peut r&eacute;silier les CGUA &agrave;
					tout moment &agrave; partir de son Compte Utilisateur.
				</span>
				<span class="c0">
					&nbsp;En cas de r&eacute;siliation pour quelque raison que
					ce soit, les Parties font application de l&rsquo;article
					&quot;R&eacute;siliation&quot; ci-dessous.
				</span>
			</p>
			<h2 class="c8" id="h.tyjcwt">
				<span class="c20">
					Article 5. Pr&eacute;requis d&rsquo;utilisation
				</span>
			</h2>
			<p class="c2">
				<span class="c0">
					Les Utilisateurs peuvent acc&eacute;der &agrave; et utiliser
					la Solution sous r&eacute;serve de (i) disposer de la
					capacit&eacute; juridique leur permettant de s&rsquo;engager
					au titre des pr&eacute;sentes CGUA et d&rsquo;utiliser les
					Prestations et de (ii) disposer d&rsquo;un &eacute;quipement
					informatique adapt&eacute; pour t&eacute;l&eacute;charger et
					utiliser l&rsquo;Application, en conformit&eacute; avec les
					pr&eacute;requis techniques et de connexion r&eacute;seau
					&eacute;num&eacute;r&eacute;s par GRANT dans la
					Documentation de la Solution jointe en Annexe 3 des
					pr&eacute;sentes CGUA. Les Parties sont seules responsables
					du mat&eacute;riel, des logiciels et de la connexion
					Internet n&eacute;cessaires pour acc&eacute;der aux
					Applications et &agrave; la Solution et les utiliser. Chacun
					assume les frais aff&eacute;rents, y compris les frais
					&eacute;ventuels de communication, qu&rsquo;il supporte de
					ce fait.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Toute personne physique peut s&rsquo;inscrire et devenir
					Utilisateur via la proc&eacute;dure d&rsquo;ouverture du
					Compte Utilisateur, sous r&eacute;serve de renseigner une
					adresse de courrier &eacute;lectronique valide, (iii) de
					renseigner le relev&eacute; d&rsquo;identit&eacute; bancaire
					(RIB) et/ou les donn&eacute;es de la carte bancaire (CB)
					d&rsquo;un compte bancaire (l&rsquo;Utilisateur peut
					uniquement renseigner une carte bancaire &eacute;tablie en
					son nom propre) et (iv) de ne pas utiliser la Solution pour
					&eacute;mettre ou recevoir des Grants &agrave; partir de
					fonds dont la source n&rsquo;est pas conforme au droit
					applicable (notamment au regard du droit p&eacute;nal et en
					mati&egrave;re de blanchiment ou de recel).
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					La cr&eacute;ation d&rsquo;un Compte Utilisateur est libre
					et gratuite, sous r&eacute;serve de l&rsquo;acceptation
					formelle des CGUA lors de l&rsquo;installation de
					l&rsquo;Application ou via une case &agrave; cocher sur les
					pages de renvoi.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Tout Utilisateur doit respecter ces pr&eacute;requis sous sa
					seule responsabilit&eacute; afin d&#39;acc&eacute;der et
					d&#39;utiliser la Solution. En particulier, votre
					acc&egrave;s &agrave; Internet, la compatibilit&eacute; et
					le bon fonctionnement de votre syst&egrave;me
					d&rsquo;information (mat&eacute;riel, logiciels, pare-feu,
					protections techniques et connexions r&eacute;seau) avec la
					Solution restent &agrave; tout moment sous votre seule
					responsabilit&eacute;. GRANT d&eacute;cline toute
					responsabilit&eacute; ou garantie &agrave; cet &eacute;gard,
					notamment en cas d&#39;incompatibilit&eacute; ou de
					dysfonctionnement de votre syst&egrave;me
					d&rsquo;information avec la Solution.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					En cas d&#39;incompatibilit&eacute; du syst&egrave;me
					d&rsquo;information de l&rsquo;Utilisateur, la Solution ne
					pourra pas &ecirc;tre fournie et GRANT n&#39;aura aucune
					obligation de quelque nature que ce soit. &nbsp;De
					m&ecirc;me, si l&rsquo;Utilisateur met en ligne le RIB ou la
					CB reli&eacute;e &agrave; son compte bancaire sous un format
					non reconnu par la Solution, GRANT ne pourra pas fournir les
					Prestations en relation avec les Grant et ne saurait
					&ecirc;tre tenu responsable d&rsquo;une obligation &agrave;
					ce titre. Si l&rsquo;Utilisateur modifie une partie de son
					installation initiale, de sa configuration utilisateur et/ou
					de son syst&egrave;me d&rsquo;information pendant la
					dur&eacute;e des CGUA, GRANT d&eacute;cline toute
					responsabilit&eacute; ou garantie concernant tout
					probl&egrave;me d&#39;acc&egrave;s et d&#39;utilisation de
					la Solution d&eacute;coulant de cette modification.
				</span>
			</p>
			<h2 class="c8" id="h.3dy6vkm">
				<span class="c20">Article 6. Compte Utilisateur</span>
			</h2>
			<h3 class="c5" id="h.1t3h5sf">
				<span class="c9">
					6.1 Cr&eacute;ation du Compte Utilisateur
				</span>
			</h3>
			<p class="c2 c15">
				<span class="c0">
					Pour acc&eacute;der &agrave; et utiliser la Solution, vous
					devez d&#39;abord vous inscrire et cr&eacute;er un Compte
					Utilisateur aupr&egrave;s de GRANT. En tant qu&rsquo;APSP,
					GRANT est soumis &agrave; des obligations de vigilance avant
					de pouvoir cr&eacute;er un Compte Utilisateur, notamment
					pour les R&eacute;cipiendaires&nbsp;: d&egrave;s lors, la
					cr&eacute;ation du Compte Utilisateur est soumise &agrave;
					plusieurs conditions, et notamment la fourniture de trois
					justificatifs&nbsp;:
				</span>
			</p>
			<ul class="c1 lst-kix_list_3-0 start">
				<li class="c24 c7 li-bullet-0">
					<span class="c0">
						Titre d&rsquo;identit&eacute; en cours de
						validit&eacute; (CNI, passeport, titre de
						s&eacute;jour)&nbsp;;
					</span>
				</li>
				<li class="c26 c7 li-bullet-0">
					<span class="c0">
						Pour les R&eacute;cipiendaires uniquement, un second
						justificatif d&rsquo;identit&eacute; (second titre
						d&rsquo;identit&eacute;, avis d&rsquo;imposition, permis
						de conduire, livret de famille, acte de naissance ou
						acte de mariage)&nbsp;; et
					</span>
				</li>
				<li class="c10 c7 li-bullet-0">
					<span class="c0">
						Un num&eacute;ro de t&eacute;l&eacute;phone portable et
						une adresse email afin de pouvoir vous transmettre les
						codes de confirmation n&eacute;cessaires pour garantir
						la s&eacute;curit&eacute; des Applications.
					</span>
				</li>
			</ul>
			<p class="c2">
				<span class="c0">
					Pour utiliser les Prestations de la Solution,
					l&rsquo;Utilisateur devra &eacute;galement renseigner le
					relev&eacute; d&rsquo;identit&eacute; bancaire (RIB) ou les
					coordonn&eacute;es de carte bancaire (CB) du compte bancaire
					associ&eacute; &agrave; son Compte Utilisateur. Un seul
					compte bancaire peut &ecirc;tre associ&eacute; &agrave; un
					Compte Utilisateur, quel qu&rsquo;il soit. Si le compte
					bancaire associ&eacute; est en d&rsquo;autres devises que
					l&rsquo;euro, l&rsquo;&eacute;tablissement de cr&eacute;dit
					de l&rsquo;Utilisateur peut pr&eacute;lever des frais de
					change lorsque l&rsquo;Utilisateur &eacute;mettra ou recevra
					des Grants sur son Compte Utilisateur. La validit&eacute; de
					ce compte bancaire sera v&eacute;rifi&eacute;e par
					LEMONWAY&nbsp;avant de g&eacute;n&eacute;rer un CQRL ; le
					CQRL sera renouvel&eacute; &agrave; chaque changement de
					compte bancaire.
				</span>
			</p>
			<p class="c2">
				<span>
					Une fois le formulaire d&rsquo;inscription
					compl&eacute;t&eacute; et valid&eacute;, vous recevrez un
					code sms afin de v&eacute;rifier la validit&eacute; du
					num&eacute;ro de t&eacute;l&eacute;phone renseign&eacute;.
					En recopiant ce code sms, vous pourrez finaliser la
					cr&eacute;ation de votre Compte Utilisateur et choisir ses
					Identifiants.
				</span>
			</p>
			<p class="c2">
				<span>
					La fourniture de ces informations peut donner lieu &agrave;
					une Proc&eacute;dure KYC. En revanche, les Donn&eacute;es
					optionnelles (pseudo, photo de profil, etc.) du Compte
					Utilisateur ne sont pas soumises &agrave; des
					proc&eacute;dures KYC.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					&Agrave; l&rsquo;issue de la proc&eacute;dure
					d&rsquo;inscription des R&eacute;cipiendaires, les
					R&eacute;cipiendaires auront acc&egrave;s &agrave; leur
					CQRL, qu&rsquo;ils peuvent montrer aux Emetteurs depuis tout
					terminal, mais qu&rsquo;ils peuvent &eacute;galement
					imprimer sur tout m&eacute;dium tangible (carte de visite,
					autocollant, T-shirt, menu, etc.).
				</span>
			</p>
			<h3 class="c5" id="h.4d34og8">
				<span class="c9">6.2 Proc&eacute;dures KYC</span>
			</h3>
			<p class="c2">
				<span class="c0">
					Dans le cadre de la lutte contre le terrorisme, la
					corruption et le blanchiment d&rsquo;argent, GRANT doit
					v&eacute;rifier l&rsquo;identit&eacute; des Utilisateurs et
					la nature des transactions r&eacute;alis&eacute;es via les
					Comptes Utilisateurs. En cons&eacute;quence, la
					Proc&eacute;dure KYC r&eacute;alis&eacute;e au moment de
					l&rsquo;ouverture du Compte Utilisateur afin de
					v&eacute;rifier la sinc&eacute;rit&eacute; des justificatifs
					et des Donn&eacute;es renseign&eacute;es par
					l&rsquo;Utilisateur sur son Compte Utilisateur devra
					&ecirc;tre p&eacute;riodiquement renouvel&eacute;e.
					L&rsquo;Utilisateur s&rsquo;engage ainsi &agrave; fournir
					promptement les documents &agrave; jour demand&eacute;s par
					GRANT. Le cas &eacute;ch&eacute;ant, GRANT pourra suspendre
					l&rsquo;ex&eacute;cution des Prestations au r&eacute;sultat
					de ces Proc&eacute;dures KYC, ce que l&rsquo;Utilisateur
					reconna&icirc;t et accepte.
				</span>
			</p>
			<p class="c2">
				<span class="c25 c3 c22">
					En particulier, l&rsquo;identit&eacute; de
					l&rsquo;Utilisateur sur l&rsquo;IBAN, le RIB ou les
					coordonn&eacute;es de CB doit &ecirc;tre la m&ecirc;me que
					celle renseign&eacute;e lors de la cr&eacute;ation du Compte
					Utilisateur.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					GRANT peut notamment d&eacute;clencher une Proc&eacute;dure
					KYC lors de la cr&eacute;ation de tout Compte Utilisateur,
					lors de la premi&egrave;re &eacute;mission d&rsquo;un Grant
					par un Emetteur et/ou lors du premier virement par un
					R&eacute;cipiendaire du montant correspondant aux Grants
					cr&eacute;dit&eacute;s sur son Compte Utilisateur. Tout
					changement des Donn&eacute;es de votre Compte Utilisateur,
					et notamment tout changement du compte bancaire
					associ&eacute; au Compte Utilisateur peut d&eacute;clencher
					la mise en &oelig;uvre des Proc&eacute;dures KYC.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					GRANT peut vous demander une mise &agrave; jour des
					justificatifs fournis &agrave; tout moment et restreindre ou
					suspendre l&rsquo;acc&egrave;s et l&rsquo;utilisation de
					votre Compte Utilisateur dans cette attente. &Agrave; ce
					titre, GRANT vous informe que la production de faux
					documents ou justificatifs par l&rsquo;Utilisateur lors de
					la cr&eacute;ation du Compte Utilisateur constitue une
					fraude documentaire qui peut entra&icirc;ner l&rsquo;envoi
					d&rsquo;une d&eacute;claration de soup&ccedil;on
					aupr&egrave;s des autorit&eacute;s administratives
					comp&eacute;tentes.
				</span>
			</p>
			<h3 class="c5" id="h.2s8eyo1">
				<span class="c9">6.3 Identifiants du Compte Utilisateur</span>
			</h3>
			<p class="c2">
				<span class="c0">
					Les Identifiants sont strictement personnels et
					confidentiels pour chaque Utilisateur ; ils ne peuvent
					&ecirc;tre partag&eacute;s entre Utilisateurs et/ou transmis
					&agrave; un tiers. Toute perte ou vol d&rsquo;Identifiants
					doit &ecirc;tre communiqu&eacute; &agrave; GRANT dans les
					plus brefs d&eacute;lais et l&rsquo;Utilisateur doit
					imm&eacute;diatement modifier les Identifiants
					concern&eacute;s.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					L&rsquo;Utilisateur comprend et reconna&icirc;t que
					l&#39;int&eacute;grit&eacute;, la disponibilit&eacute; et la
					confidentialit&eacute; des Donn&eacute;es charg&eacute;es
					dans la Solution d&eacute;pendent de la
					confidentialit&eacute; de ses Identifiants. Si un tiers
					acc&egrave;de &agrave; votre Compte Utilisateur et/ou
					&agrave; ses param&egrave;tres, cette personne pourra faire
					tout ce que vous pouvez faire et notamment transmettre tout
					Grant, faire toute d&eacute;claration ou donner toute
					garantie, et ces actions seront r&eacute;put&eacute;es avoir
					&eacute;t&eacute; faites en votre nom et pour votre compte.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Par cons&eacute;quent, nous vous recommandons vivement de
					pr&eacute;server la confidentialit&eacute; des Identifiants
					de votre Compte Utilisateur et de ne pas en autoriser
					l&#39;acc&egrave;s &agrave; d&#39;autres personnes, car vous
					serez enti&egrave;rement responsable de toutes les actions
					effectu&eacute;es par le biais de votre Compte Utilisateur,
					que vous l&#39;ayez ou non express&eacute;ment
					autoris&eacute;, ainsi que de tous les dommages, co&ucirc;ts
					ou pertes pouvant r&eacute;sulter de ces actions. Tout
					acc&egrave;s, action et/ou utilisation de la Solution par un
					Utilisateur &agrave; travers un Compte Utilisateur est faite
					sous la seule responsabilit&eacute; de l&rsquo;Utilisateur,
					y compris en cas de divulgation accidentelle des
					Identifiants et/ou de toute utilisation ult&eacute;rieure de
					ces Identifiants par un tiers non autoris&eacute;.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					GRANT exclut toute responsabilit&eacute; ou garantie en cas
					de manquement de l&rsquo;Utilisateur aux conditions
					relatives &agrave; ses Identifiants et l&rsquo;Utilisateur
					sera notamment responsable de toute perte ou
					d&eacute;t&eacute;rioration des Donn&eacute;es, de toute
					atteinte aux droits de propri&eacute;t&eacute;
					intellectuelle ou au savoir-faire et d&#39;une
					mani&egrave;re g&eacute;n&eacute;rale, de tout dommage subi
					par les Parties. Le cas &eacute;ch&eacute;ant, GRANT pourra
					facturer &agrave; l&rsquo;Utilisateur toute
					r&eacute;paration ou contr&ocirc;le effectu&eacute; en cas
					d&rsquo;introduction frauduleuse ou d&rsquo;utilisation de
					la Solution par un tiers non autoris&eacute;.
				</span>
			</p>
			<h3 class="c5" id="h.17dp8vu">
				<span class="c9">
					6.4 Restrictions applicables aux Comptes Utilisateur
				</span>
			</h3>
			<p class="c2">
				<span class="c0">
					Un Utilisateur ne peut avoir qu&rsquo;un seul Compte
					Utilisateur, qui lui est strictement personnel, individuel,
					non cessible et non transf&eacute;rable. L&rsquo;Utilisateur
					acc&egrave;de &agrave; la Solution via la connexion internet
					des terminaux de l&rsquo;Utilisateur et aucune Donn&eacute;e
					ou fonctionnalit&eacute; de la Solution ne sera
					h&eacute;berg&eacute;e ou accessible directement sur les
					terminaux de l&rsquo;Utilisateur.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Vous vous engagez (i) &agrave; ne pas disposer d&rsquo;un
					autre Compte Utilisateur et &agrave; ne pas cr&eacute;er
					d&rsquo;autre Compte Utilisateur pour l&rsquo;avenir, (ii)
					&agrave; v&eacute;rifier les champs du Compte Utilisateur
					renseign&eacute;s automatiquement et &agrave;
					compl&eacute;ter les autres champs de bonne foi, en
					fournissant des informations exactes et en renseignant tous
					les champs marqu&eacute;s comme obligatoires, (iii) &agrave;
					ne pas fournir une fausse identit&eacute; ou
					l&rsquo;identit&eacute; d&rsquo;un tiers ou &agrave; usurper
					cette derni&egrave;re et (iv) &agrave; ne pas induire GRANT
					ou tout tiers sur votre identit&eacute;. GRANT ne saurait
					&ecirc;tre tenue responsable d&rsquo;informations
					p&eacute;rim&eacute;es, mensong&egrave;res, invalides ou
					erron&eacute;es, y compris en cas de changement de situation
					de l&rsquo;Utilisateur que l&rsquo;Utilisateur ne
					r&eacute;percute pas dans son Compte Client. Tout changement
					de situation peut donner lieu &agrave; une proc&eacute;dure
					KYC.
				</span>
			</p>
			<p class="c2">
				<span>
					GRANT peut refuser l&rsquo;inscription d&rsquo;Utilisateurs
					situ&eacute;s en dehors de l&rsquo;Union europ&eacute;enne,
					notamment au regard de la liste noire de la Commission
					europ&eacute;enne, de la liste des pays non
					coop&eacute;ratifs en mati&egrave;re fiscale et des listes
					de pays sous sanctions internationales (mesures
					restrictives, sanctions &eacute;conomiques, embargos, etc.)
					(les &laquo;&nbsp;
				</span>
				<span class="c3">Territoires exclus</span>
				<span class="c0">
					&nbsp;&raquo;), et/ou refuser que l&rsquo;Utilisateur
					rattache &agrave; son Compte Utilisateur un compte bancaire
					situ&eacute; dans ces Territoires exclus ou ouverts
					aupr&egrave;s d&rsquo;&eacute;tablissements financiers
					appartenant en tout ou partie &agrave; des citoyens ou
					entit&eacute;s de ces Territoires exclus. Les restrictions
					relatives aux Territoires exclus sont n&eacute;cessaires
					pour satisfaire &agrave; une obligation l&eacute;gale par
					exception &agrave; l&rsquo;article L. 121-23 du Code de la
					consommation.
				</span>
			</p>
			<h3 class="c5" id="h.3rdcrjn">
				<span class="c9">6.5 Suspension du Compte Utilisateur</span>
			</h3>
			<p class="c2">
				<span class="c0">
					Le Compte Utilisateur est un syst&egrave;me de traitement
					automatis&eacute; de donn&eacute;es. Tout acc&egrave;s
					frauduleux &agrave; ce dernier est interdit et
					sanctionn&eacute; p&eacute;nalement. GRANT et/ou LEMONWAY
					peut prenvent toute mesure d&rsquo;urgence n&eacute;cessaire
					&agrave; la s&eacute;curit&eacute; d&rsquo;un Compte
					Utilisateur et/ou de la Solution. GRANT et/ou LEMONWAY
					peuvent suspendre, geler ou fermer l&rsquo;acc&egrave;s
					&agrave; tout ou partie de votre Compte Utilisateur (et donc
					&agrave; tout ou partie de la Solution) &agrave; tout
					moment, notamment en cas de suspicion de fraude,
					d&rsquo;usurpation d&rsquo;identit&eacute; ou
					d&rsquo;atteinte &agrave; la s&eacute;curit&eacute;.
				</span>
			</p>
			<p class="c2">
				<span>
					Le Compte Utilisateur est r&eacute;put&eacute; &ecirc;tre
					cr&eacute;&eacute; par l&rsquo;Utilisateur via toute
					personne ayant mandat apparent pour engager et
					repr&eacute;senter l&rsquo;Utilisateur. Vous pouvez &agrave;
					tout moment notifier GRANT et/ou LEMONWAY si vous pensez que
					votre Compte Utilisateur est susceptible de faire
					l&rsquo;objet d&rsquo;une utilisation frauduleuse par un
					tiers en contactant GRANT &agrave;{" "}
				</span>
				<span class="c14">
					<a class="c12" href="mailto:dpo@joingrant.com">
						dpo@joingrant.com
					</a>
				</span>
				<span class="c0">.</span>
			</p>
			<p class="c2">
				<span class="c0">
					En cas de litige concernant la propri&eacute;t&eacute;
					d&#39;un Compte Utilisateur, nous nous r&eacute;servons le
					droit de d&eacute;terminer le titulaire d&rsquo;un Compte
					Utilisateur selon notre appr&eacute;ciation de la situation
					et de suspendre un Compte Utilisateur jusqu&#39;&agrave; ce
					que les parties en litige soient parvenues &agrave; une
					r&eacute;solution du litige concern&eacute;, sans que GRANT
					et/ou LEMONWAY n&rsquo;engage sa responsabilit&eacute; de ce
					fait envers vous ou envers toute autre partie. Nous pouvons
					vous demander tous documents qui peuvent nous permettre de
					d&eacute;terminer la titularit&eacute; du Compte
					Utilisateur&nbsp;; &agrave; d&eacute;faut, GRANT et/ou
					LEMONWAY est en droit de suspendre tout ou partie du Compte
					Utilisateur litigieux.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Vous vous engagez &agrave; ne pas collecter et/ou &agrave;
					ne pas utiliser de donn&eacute;es de connexion et/ou des
					mots de passe de tiers sur les Applications, la Solution
					ainsi que pour d&#39;autres sites Internet, logiciels ou
					services.
				</span>
			</p>
			<p class="c2">
				<span>
					L&rsquo;Utilisateur est inform&eacute; que son Compte
					Utilisateur peut &ecirc;tre suspendu au cas o&ugrave;
					LEMONWAY proc&egrave;derait au blocage ou au gel du compte
					bancaire servant aux paiements, conform&eacute;ment &agrave;
					ses conditions g&eacute;n&eacute;rales disponibles{" "}
				</span>
				<span class="c14">
					<a
						class="c12"
						href="https://www.google.com/url?q=https://www.lemonway.com/conditions-generales-dutilisation/&amp;sa=D&amp;source=editors&amp;ust=1679074681200528&amp;usg=AOvVaw1iuGbPv6Ue0G9LtLBUeABk"
					>
						ici
					</a>
				</span>
				<span class="c0">
					. Le cas &eacute;ch&eacute;ant, GRANT en informera
					l&rsquo;Utilisateur.
				</span>
			</p>
			<h2 class="c8" id="h.26in1rg">
				<span class="c20">
					Article 7. Conditions de fourniture des Prestations
				</span>
			</h2>
			<p class="c2">
				<span class="c0">
					GRANT n&rsquo;encaisse jamais directement d&rsquo;argent au
					nom et pour le compte des Utilisateurs, mais est
					mandat&eacute; en tant qu&rsquo;APSP par LEMONWAY, qui
					proc&egrave;de &agrave; ces op&eacute;rations en tant que
					PSP. GRANT cr&eacute;dite les op&eacute;rations
					r&eacute;alis&eacute;es par LEMONWAY en direct sur les
					Comptes Utilisateurs des Applications.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Au cas o&ugrave; LEMONWAY proc&egrave;derait &agrave; des
					&eacute;volutions majeures venant affecter ou modifier de
					mani&egrave;re importante le fonctionnement de son service
					de paiement et par voie de cons&eacute;quence les
					Prestations, GRANT en informera l&rsquo;Utilisateur dans un
					d&eacute;lai raisonnable suivant la notification qui lui
					aura &eacute;t&eacute; faite par LEMONWAY. S&rsquo;il
					n&rsquo;accepte pas ces &eacute;volutions,
					l&rsquo;Utilisateur pourra exercer sa facult&eacute; de
					r&eacute;siliation.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Chaque Utilisateur se doit d&rsquo;utiliser la Solution de
					mani&egrave;re responsable et respectueuse des droits de
					GRANT et des tiers. En acc&eacute;dant &agrave; la Solution
					via son Compte Utilisateur, l&rsquo;Utilisateur
					s&rsquo;engage et garantit notamment (i) respecter les
					stipulations des CGUA et le droit applicable, (ii)
					communiquer des Donn&eacute;es exactes, exhaustives et
					&agrave; jour, (iii) respecter les droits de GRANT et des
					tiers et (iv) utiliser la Solution pour les seules
					finalit&eacute;s pr&eacute;vues par la Documentation, sur
					une configuration utilisateur r&eacute;pondant aux
					recommandations de GRANT, et qui accepte les cookies
					utilis&eacute;s par la Solution.
				</span>
			</p>
			<h3 class="c5" id="h.lnxbz9">
				<span class="c9">
					7.1 Acc&egrave;s et utilisation du Compte Utilisateur
				</span>
			</h3>
			<p class="c2 c15">
				<span class="c0">
					L&rsquo;utilisation de la Solution et des Prestations est
					conditionn&eacute;e &agrave; la cr&eacute;ation
					pr&eacute;alable et gratuite d&rsquo;un Compte Utilisateur
					en application de l&rsquo;article &quot;Compte
					Utilisateur&quot; des CGUA ci-dessus.
				</span>
			</p>
			<p class="c2 c15">
				<span class="c0">
					Une fois le Compte Utilisateur cr&eacute;&eacute;,
					l&rsquo;Utilisateur peut verser ou recevoir des Grants et
					noter les actions, produits et services des autres
					Utilisateurs avec lesquels il a interagi dans le respect des
					pr&eacute;sentes CGUA. Afin d&rsquo;assurer la
					s&eacute;curit&eacute; des paiements en ligne,
					l&rsquo;Utilisateur doit &ecirc;tre en permanence
					connect&eacute; &agrave; son Compte Utilisateur via ses
					Identifiants (sauf en cas d&rsquo;utilisation ponctuelle de
					la Solution par un Emetteur via une page de renvoi) et
					g&eacute;n&eacute;rer et/ou scanner un Code QR pour pouvoir
					transmettre et/ou recevoir un Grant. GRANT peut vous
					demander de r&eacute;it&eacute;rer la saisie de vos
					Identifiants au moment de valider l&rsquo;&eacute;mission
					d&rsquo;un Grant.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Selon que l&rsquo;Utilisateur est un Emetteur ou un
					R&eacute;cipiendaire, il acc&egrave;de &agrave; son Compte
					Utilisateur via une des Applications pr&eacute;sentant des
					fonctionnalit&eacute;s et des interfaces sp&eacute;cifiques
					&agrave; son utilisation de la Solution et des Prestations
					en tant qu&rsquo;Emetteur ou R&eacute;cipiendaire. Son
					Compte Utilisateur et les Proc&eacute;dures KYC peuvent
					&eacute;galement varier selon cette qualit&eacute;. Il
					n&rsquo;est pas possible pour un Utilisateur
					d&rsquo;&ecirc;tre Emetteur et R&eacute;cipiendaire.
				</span>
			</p>
			<p class="c2 c15">
				<span>
					GRANT donne acc&egrave;s &agrave; chaque Utilisateur, sur
					son Compte Utilisateur, &agrave; un historique des Grants
					&eacute;mis ou re&ccedil;us et comprenant les d&eacute;tails
					des transactions et paiements correspondants,{" "}
				</span>
				<span class="c22">
					sans aucun droit de modification, ainsi que les Notations
					associ&eacute;es et les &eacute;ventuels remerciements. Les
					Utilisateurs ont la possibilit&eacute; de
					t&eacute;l&eacute;charger ces historiques et rapports et de
					les conserver sur un support durable s&eacute;par&eacute;.
					Les Notations sont class&eacute;es dans l&rsquo;historique
					par date d&rsquo;attribution. Si un Utilisateur est
					pass&eacute; d&rsquo;abord par une page de renvoi sans
					cr&eacute;er de Compte Utilisateur, les Grants
					cr&eacute;&eacute;s ou cr&eacute;dit&eacute;s avant la
					cr&eacute;ation du Compte Utilisateur via ces pages de
					renvoi ne figurent pas dans l&rsquo;historique de son Compte
					Utilisateur.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Le Compte Utilisateur des R&eacute;cipiendaires comprend un
					solde dont le montant est &eacute;gal &agrave;
					l&rsquo;ensemble des Grants cr&eacute;dit&eacute;s sur le
					Compte Utilisateur, ainsi qu&rsquo;un bouton pour
					d&eacute;clencher le virement de ces montants vers le compte
					bancaire du R&eacute;cipiendaire, dans les conditions
					pr&eacute;cis&eacute;es &agrave; l&rsquo;article
					&quot;Encaissement des Grants&quot; ci-dessous.
				</span>
			</p>
			<h3 class="c5" id="h.35nkun2">
				<span class="c9">7.2 Conditions relatives aux Grants</span>
			</h3>
			<p class="c2">
				<span class="c0">
					Si l&rsquo;Utilisateur peut mettre &agrave; jour son Compte
					Utilisateur &agrave; tout moment, le premier Grant
					d&rsquo;un Utilisateur doit &ecirc;tre &eacute;mis &agrave;
					partir de ou encaiss&eacute; sur un compte bancaire ouvert
					au nom de l&rsquo;Utilisateur au sein d&rsquo;un Etat-membre
					de l&rsquo;Union europ&eacute;enne.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Les Utilisateurs ne peuvent verser de Grants pour toute
					activit&eacute; frauduleuse ou ill&eacute;gale ainsi que
					pour les activit&eacute;s suivantes&nbsp;:
				</span>
			</p>
			<h4 class="c4" id="h.1ksv4uv">
				<span class="c25 c28">
					Financement participatif (don, pr&ecirc;t, investissement)
				</span>
			</h4>
			<ul class="c1 lst-kix_list_4-0 start">
				<li class="c7 c15 c16 li-bullet-0">
					<span class="c0">
						Mouvements extr&eacute;mistes / ultra-radicaux /
						dissidents / propagande ;
					</span>
				</li>
				<li class="c7 c15 c37 li-bullet-0">
					<span class="c0">Sectes&nbsp;;</span>
				</li>
				<li class="c37 c7 c15 li-bullet-0">
					<span class="c0">
						Promotion de causes proches d&rsquo;activit&eacute;s
						interdites (ex&nbsp;: l&eacute;galisation de produits
						stup&eacute;fiants)&nbsp;;
					</span>
				</li>
				<li class="c7 c15 c32 li-bullet-0">
					<span class="c0">
						Financement d&rsquo;amendes p&eacute;nales&nbsp;;
					</span>
				</li>
				<li class="c6 c15 li-bullet-0">
					<span>Investissements en diamants ou en terres rares.</span>
				</li>
			</ul>
			<h4 class="c4" id="h.44sinio">
				<span class="c25 c28">Vente de produits</span>
			</h4>
			<ul class="c1 lst-kix_list_4-0">
				<li class="c6 c15 li-bullet-0">
					<span>
						Vente de produits stup&eacute;fiants et de produits
						d&eacute;riv&eacute;s du cannabis (ex : cannabidiol) ;
					</span>
				</li>
				<li class="c6 c15 li-bullet-0">
					<span>
						Vente de produits hi-tech et/ou haut de gamme &agrave;
						prix cass&eacute; (ex : iPhone &agrave; 1 euro) ;
					</span>
				</li>
				<li class="c6 c15 li-bullet-0">
					<span>Vente d&#39;alcool sans licence ;</span>
				</li>
				<li class="c6 c15 li-bullet-0">
					<span>Vente de sextoys ;</span>
				</li>
				<li class="c6 c15 li-bullet-0">
					<span>Vente de tabac ;</span>
				</li>
				<li class="c6 c15 li-bullet-0">
					<span>Vente de e-cigarette ;</span>
				</li>
				<li class="c6 c15 li-bullet-0">
					<span>
						Vente de dispositifs de piratage / Logiciels espions ;
					</span>
				</li>
				<li class="c6 c15 li-bullet-0">
					<span>Vente de produits contrefaits ;</span>
				</li>
				<li class="c6 c15 li-bullet-0">
					<span>
						Vente d&rsquo;armes, d&rsquo;airsoft ou d&rsquo;armes
						factices.
					</span>
				</li>
			</ul>
			<h4 class="c4" id="h.2jxsxqh">
				<span class="c25 c28">Vente de prestations / services</span>
			</h4>
			<ul class="c1 lst-kix_list_8-0 start">
				<li class="c2 c7 li-bullet-0">
					<span class="c0">
						Diagnostics d&rsquo;accessibilit&eacute;
					</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">Voyance ;</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">Astrologie ;</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">Sites de rencontres ;</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">
						Sites de streaming non autoris&eacute;s ;
					</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">
						Sites &agrave; caract&egrave;re pornographique
						(prestations, vid&eacute;os) ;
					</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">Sites de charme ;</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">Services d&rsquo;escort ;</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">
						Emission de cartes pr&eacute;pay&eacute;es ;
					</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">Sites de partage de fichiers ;</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">Site de Trading ou FOREX ;</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">
						Activit&eacute; de cryptomonnaie / crypto-actifs ;
					</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">Cautionnement par carte ;</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">Virtual private network (VPN) ;</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">
						Recouvrement de cr&eacute;ances pour le compte de tiers
						;
					</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">
						Sites faisant l&#39;apologie du terrorisme ;
					</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">Sites pros&eacute;lytes ;</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">Etudes de Huissiers de Justice ;</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">Casinos ;</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">
						Jeux d&#39;argent en ligne (paris sportifs, poker) ;
					</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">Vente de followers ;</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">Loteries.</span>
				</li>
			</ul>
			<p class="c2">
				<span class="c0">
					GRANT se r&eacute;serve le droit (i) de transmettre,
					&agrave; sa discr&eacute;tion, toute information susceptible
					d&rsquo;avoir un caract&egrave;re ill&eacute;gal ou
					frauduleux, aux autorit&eacute;s comp&eacute;tentes, sans en
					aviser l&rsquo;Utilisateur, notamment le montant et le
					nombre des Grants collect&eacute;s et vers&eacute;s en cas
					de d&eacute;claration de soup&ccedil;on ou sur toute demande
					de l&rsquo;administration fiscale, des autorit&eacute;s
					administratives et/ou des juridictions comp&eacute;tentes
					(ii) dans les m&ecirc;mes situations, de prendre toute
					mesure dont notamment la s&eacute;questration des fonds dont
					GRANT soup&ccedil;onne de mani&egrave;re l&eacute;gitime
					qu&rsquo;ils sont li&eacute;s &agrave; une activit&eacute;
					potentiellement illicite ou frauduleuse, le
					d&eacute;clenchement d&rsquo;une Proc&eacute;dure KYC ou la
					fourniture obligatoire de justificatifs ou
					d&rsquo;informations compl&eacute;mentaires, la suspension
					de l&rsquo;ex&eacute;cution des Grants (&eacute;mission ou
					encaissement) ou la transmission des fonds &agrave;
					l&rsquo;autorit&eacute; comp&eacute;tente. Si GRANT
					identifie que des Grants (iii) sont transmis vers ou depuis
					des Territoires exclus, (iv) caract&eacute;risent, par leur
					r&eacute;p&eacute;tition ou leur montant, une
					op&eacute;ration de rapatriement de fonds et/ou (v)
					remplissent l&rsquo;un des crit&egrave;res pr&eacute;vus par
					les articles L. 561-15 et D 561-32-1 du Code
					mon&eacute;taire et financier, GRANT et son prestataire de
					services de paiement LEMONWAY sont tenus d&rsquo;effectuer
					une d&eacute;claration de soup&ccedil;ons aupr&egrave;s des
					autorit&eacute;s administratives comp&eacute;tentes.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					L&rsquo;Utilisateur renonce &agrave; tout droit ou recours,
					de quelque nature que ce soit, qui pourrait lui &ecirc;tre
					reconnu ou ouvert, en quelque lieu que ce soit, &agrave;
					raison de la communication par GRANT de ces informations aux
					autorit&eacute;s comp&eacute;tentes, dans la limite du droit
					applicable.
				</span>
			</p>
			<h3 class="c5" id="h.z337ya">
				<span class="c9">7.3 Prestations</span>
			</h3>
			<p class="c2">
				<span class="c0">
					Certaines Prestations peuvent n&eacute;cessiter une phase
					d&rsquo;initialisation et de param&eacute;trage avant de
					pouvoir produire des r&eacute;sultats. La recette des
					Prestations est tacitement accept&eacute;e &agrave; la
					r&eacute;ception des r&eacute;sultats des Prestations par
					l&rsquo;Utilisateur.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					L&rsquo;Utilisateur s&rsquo;engage &agrave; coop&eacute;rer
					avec GRANT afin de lui permettre de fournir les Prestations
					et la Solution conform&eacute;ment aux conditions des CGUA.
					L&rsquo;Utilisateur reconna&icirc;t que les Prestations et
					la Solution ne peuvent &ecirc;tre correctement fournis par
					GRANT qu&rsquo;avec la collaboration active de
					l&rsquo;Utilisateur, qui doit mettre &agrave; disposition en
					temps utile les informations et actions demand&eacute;es,
					notamment en saisissant les codes sms de confirmation et en
					respectant les Proc&eacute;dures KYC.
				</span>
			</p>
			<p class="c2 c15">
				<span class="c0">
					GRANT n&rsquo;est pas partie et n&rsquo;intervient en aucun
					cas dans les relations entre les Utilisateurs &agrave;
					l&rsquo;issue ou en relation avec les Prestations. Il
					appartient au seul Utilisateur d&rsquo;organiser la gestion
					de ses Grants.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Aux seules fins de permettre le bon fonctionnement de la
					Solution et la fourniture des Prestations,
					l&rsquo;Utilisateur accorde &agrave; GRANT une licence
					personnelle, non-exclusive, non-cessible et non
					transf&eacute;rable d&#39;utilisation des Donn&eacute;es,
					pour la dur&eacute;e des CGUA et dans le monde entier.
					L&rsquo;Utilisateur autorise express&eacute;ment GRANT
					&agrave; utiliser ses donn&eacute;es de paiement aux fins
					d&rsquo;ex&eacute;cuter les Grants et &agrave; fournir des
					Prestations aupr&egrave;s des Utilisateurs sur la base de
					ces Grants.
				</span>
			</p>
			<h4 class="c4" id="h.3j2qqm3">
				<span class="c25 c28">7.3.1 Versement de Grants</span>
			</h4>
			<p class="c2 c15">
				<span class="c0">
					Tout Emetteur peut librement g&eacute;n&eacute;rer et
					transmettre des Grants &agrave; condition de renseigner le
					compte bancaire d&rsquo;o&ugrave; seront
					pr&eacute;lev&eacute;s les Grants et les Frais de
					Transaction.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Toute personne physique peut scanner le CQRL d&rsquo;un
					R&eacute;cipiendaire &agrave; tout moment en utilisant
					l&rsquo;appareil photo de son t&eacute;l&eacute;phone
					portable ou de tout terminal adapt&eacute;&nbsp;:
				</span>
			</p>
			<ul class="c1 lst-kix_list_13-0 start">
				<li class="c2 c7 li-bullet-0">
					<span class="c0">
						Si l&rsquo;Application Emetteur est install&eacute;e sur
						le terminal utilis&eacute;, le scan d&eacute;clenche
						l&rsquo;ouverture de l&rsquo;Application o&ugrave;
						l&rsquo;Emetteur peut renseigner une Notation et
						&eacute;mettre le Grant&nbsp;; ou
					</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">
						Si l&rsquo;Application n&rsquo;est pas install&eacute;e,
						le scan d&eacute;clenche l&rsquo;ouverture d&rsquo;une
						page de renvoi sur le navigateur Internet du terminal,
						connect&eacute;e &agrave; la Solution, qui propose de
						renseigner une Notation et d&rsquo;&eacute;mettre un
						Grant soit en installant l&rsquo;Application et en
						cr&eacute;ant un Compte Utilisateur, soit directement
						&agrave; partir de la page de renvoi.
					</span>
				</li>
			</ul>
			<p class="c2">
				<span>
					La premi&egrave;re &eacute;tape consiste &agrave; fixer une
					Notation qui sera associ&eacute;e au R&eacute;cipiendaire.
					Cette Notation est attribu&eacute;e par l&rsquo;Emetteur
					sans obligation de le faire et sans que l&rsquo;Emetteur ait
					besoin d&rsquo;identifier les actions, produits ou services
					du R&eacute;cipiendaire qui l&rsquo;ont conduit &agrave;
					transmettre le Grant et/ou &agrave; attribuer cette
					Notation. Les Emetteurs ne b&eacute;n&eacute;ficient
					d&rsquo;aucune contrepartie en &eacute;change de la
					Notation. Cette Notation ne fait pas l&rsquo;objet
					d&rsquo;un contr&ocirc;le a priori ou a posteriori, mais les
					R&eacute;cipiendaires peuvent signaler un doute sur leur
					authenticit&eacute;. Il n&rsquo;est pas possible
					d&rsquo;attribuer une Notation apr&egrave;s
					l&rsquo;&eacute;mission d&rsquo;un Grant ou de modifier
					celle-ci.
				</span>
			</p>
			<p class="c2">
				<span>
					La seconde &eacute;tape consiste &agrave; fixer le montant
					du Grant &agrave; transmettre au R&eacute;cipiendaire,
					&eacute;tant entendu qu&rsquo;il est possible de fixer ce
					montant &agrave; 0 &euro; (Aucun Frais de Transaction ne
					sera pr&eacute;lev&eacute; dans ce cas).
				</span>
			</p>
			<p class="c2">
				<span>
					En validant la seconde &eacute;tape, l&rsquo;Emetteur a
					acc&egrave;s une page r&eacute;capitulative du Grant,
					pr&eacute;cisant (i) la Notation attribu&eacute;e au
					R&eacute;cipiendaire, (ii) le montant du Grant et des Frais
					de Transaction et (iii) un avertissement relatif aux frais
					d&rsquo;itin&eacute;rance que son op&eacute;rateur de
					t&eacute;l&eacute;phonie mobile peut pr&eacute;lever. Si
					l&rsquo;Emetteur n&rsquo;est pas connect&eacute; &agrave;
					son Compte Utilisateur sur l&rsquo;Application
					appropri&eacute;e, il doit accepter les CGAS via une case
					&agrave; cocher sur la page de renvoi. Dans tous les cas, il
					doit &eacute;galement accepter de renoncer &agrave; son
					droit de r&eacute;tractation afin que GRANT puisse
					&eacute;mettre imm&eacute;diatement le Grant.
				</span>
			</p>
			<p class="c2">
				<span class="c25 c3 c22">
					Le Grant est un contenu num&eacute;rique ind&eacute;pendant
					de tout support mat&eacute;riel. L&rsquo;Utilisateur doit
					accepter de renoncer &agrave; son droit de
					r&eacute;tractation s&rsquo;il veut pouvoir &eacute;mettre
					imm&eacute;diatement le Grant &agrave; compter de la
					validation de son ordre de transaction.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					En validant ce r&eacute;capitulatif, l&rsquo;Emetteur peut
					&ecirc;tre appel&eacute; &agrave; saisir un code sms de
					confirmation ou d&rsquo;effectuer une authentification forte
					(3D Secure ou toute autre norme technique en vigueur). Avec
					ou sans cette &eacute;tape, l&rsquo;Emetteur autorise la
					validation d&eacute;finitive du Grant et la
					g&eacute;n&eacute;ration d&rsquo;un CQRG associ&eacute;.
					L&rsquo;Emetteur peut alors pr&eacute;senter ce CQRG au
					R&eacute;cipiendaire depuis son t&eacute;l&eacute;phone
					portable ou tout terminal utilis&eacute;. D&egrave;s la
					g&eacute;n&eacute;ration du CQRG, le montant du Grant est
					bloqu&eacute; sur le compte bancaire de l&rsquo;Emetteur
					jusqu&rsquo;&agrave; ce qu&rsquo;il soit scann&eacute; par
					le R&eacute;cipiendaire ou jusqu&rsquo;&agrave;
					l&rsquo;expiration de sa dur&eacute;e de validit&eacute;.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					L&rsquo;Emetteur ne peut plus transmettre de nouveaux Grants
					&agrave; un R&eacute;cipiendaire au bout (i) d&rsquo;un
					montant maximum de cinquante euros (50 &euro;) sur un
					d&eacute;lai de vingt-quatre heures (24h) et (ii) d&rsquo;un
					montant maximum de cinq cents euros (500 &euro;) sur une
					dur&eacute;e d&rsquo;un mois. Ces plafonds sont cumulatifs
					et sont r&eacute;initialis&eacute;s, respectivement, au bout
					d&rsquo;une nouvelle p&eacute;riode de vingt-quatre heures
					(24h) et d&rsquo;une nouvelle p&eacute;riode d&rsquo;un
					mois.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Si le Grant n&rsquo;est pas (i) scann&eacute; dans un
					d&eacute;lai d&rsquo;un quart d&rsquo;heure (15 minutes) par
					le R&eacute;cipiendaire et/ou (ii) cr&eacute;dit&eacute; sur
					le Compte Utilisateur du R&eacute;cipiendaire dans un
					d&eacute;lai de quatre heures (4h) &agrave; compter de son
					&eacute;mission, celui-ci est annul&eacute; et ne peut plus
					&ecirc;tre utilis&eacute; par un R&eacute;cipiendaire. Une
					fois le Code QR scann&eacute; par le R&eacute;cipiendaire,
					le compte bancaire de l&rsquo;Emetteur est
					d&eacute;bit&eacute; du montant du Grant. Le Grant est
					&agrave; d&eacute;bit imm&eacute;diat et ne peut faire
					l&rsquo;objet d&rsquo;aucun d&eacute;bit
					diff&eacute;r&eacute;, d&rsquo;aucune annulation ou
					modification apr&egrave;s son cr&eacute;dit.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					L&rsquo;Emetteur est &eacute;galement pr&eacute;lev&eacute;
					des Frais de Transaction lors de l&rsquo;envoi du Grant. Ces
					Frais de Transaction sont clairement indiqu&eacute;s
					s&eacute;par&eacute;ment du Grant avant sa validation.
					L&rsquo;Emetteur est inform&eacute; et accepte sans
					r&eacute;serve qu&rsquo;en aucun cas ces Frais de
					Transaction ne pourront faire l&rsquo;objet d&rsquo;un
					remboursement.
				</span>
			</p>
			<p class="c2">
				<span class="c3">
					Tout Utilisateur peut &eacute;mettre une r&eacute;clamation
					aupr&egrave;s de GRANT au sujet d&rsquo;un ou de plusieurs
					Grants, notamment &agrave; raison du caract&egrave;re ou
					comportement trompeur ou abusif d&rsquo;un
					R&eacute;cipiendaire ou si tout ou partie des Grants
					rel&egrave;ve des activit&eacute;s interdites pr&eacute;vues
					&agrave; l&rsquo;article &quot;Conditions
					d&rsquo;&eacute;mission des Grants&quot;. GRANT se
					r&eacute;serve le droit de suspendre le versement de tout
					Grant litigieux.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					L&rsquo;Emetteur peut annuler &agrave; tout moment le Grant
					au cours de la proc&eacute;dure en quittant
					l&rsquo;Application ou la page de renvoi vers la Solution.
				</span>
			</p>
			<h4 class="c4" id="h.1y810tw">
				<span class="c25 c28">7.3.2 R&eacute;ception de Grants</span>
			</h4>
			<p class="c2">
				<span class="c0">
					Tout R&eacute;cipiendaire peut librement recevoir tout Grant
					&agrave; condition d&rsquo;installer l&rsquo;Application
					&quot;Grant T&quot;. Toute personne physique peut scanner le
					CQRG que lui montre l&rsquo;Emetteur dans le d&eacute;lai
					d&rsquo;un quart d&rsquo;heure (15 minutes) &agrave; compter
					de son &eacute;mission en utilisant l&rsquo;appareil photo
					de son t&eacute;l&eacute;phone portable ou de tout terminal
					adapt&eacute;&nbsp;:
				</span>
			</p>
			<p class="c2 c11">
				<span class="c0"></span>
			</p>
			<ul class="c1 lst-kix_list_11-0 start">
				<li class="c24 c7 li-bullet-0">
					<span class="c0">
						Si l&rsquo;Application R&eacute;cipiendaire est
						install&eacute;e sur le terminal utilis&eacute;, le scan
						d&eacute;clenche l&rsquo;ouverture de
						l&rsquo;Application o&ugrave; le R&eacute;cipiendaire
						peut transmettre un &laquo;&nbsp;Merci&nbsp;!&quot;
						&agrave; l&rsquo;Emetteur et cr&eacute;diter son Compte
						Utilisateur du montant du Grant&nbsp;;
					</span>
				</li>
				<li class="c10 c7 li-bullet-0">
					<span class="c0">
						Si l&rsquo;Application n&rsquo;est pas install&eacute;e,
						le scan d&eacute;clenche l&rsquo;ouverture d&rsquo;une
						page de renvoi sur le navigateur Internet du terminal,
						connect&eacute;e &agrave; la Solution, qui propose de
						cr&eacute;diter le montant du Grant en installant
						l&rsquo;Application et en cr&eacute;ant un Compte
						Utilisateur, dans un d&eacute;lai maximum de quatre
						heures (4h) &agrave; compter de l&rsquo;arriv&eacute;e
						sur la page de renvoi.
					</span>
				</li>
			</ul>
			<p class="c2">
				<span>
					Une fois que le R&eacute;cipiendaire a install&eacute; et
					ouvert l&rsquo;Application, le R&eacute;cipiendaire peut
					cr&eacute;diter le montant du Grant sur son Compte
					Utilisateur et prendre connaissance de la Notation
					attribu&eacute;e par l&rsquo;Emetteur.{" "}
				</span>
				<span class="c22">
					Les Notations attribu&eacute;es sont conserv&eacute;es
					pendant une p&eacute;riode de{" "}
				</span>
				<span>5 ans</span>
				<span class="c22">
					&nbsp;apr&egrave;s leur &eacute;mission. GRANT met en place
					une fonctionnalit&eacute; gratuite qui permet aux
					R&eacute;cipiendaires not&eacute;s faisant l&rsquo;objet
					d&rsquo;une Notation par un Emetteur de lui signaler un
					doute sur l&rsquo;authenticit&eacute; de cette Notation,
					&agrave; condition que ce signalement soit motiv&eacute;. Il
					est &agrave; noter que cette Notation n&rsquo;a aucune
					incidence sur les{" "}
				</span>
				<span>R&eacute;cipiendaires</span>
				<span class="c0">
					&nbsp;et une mauvaise Notation ne peut en aucun cas conduire
					&agrave; la suspension du Compte Utilisateur associ&eacute;.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Le cr&eacute;dit d&rsquo;un Grant sur le Compte Utilisateur
					du R&eacute;cipiendaire consomme d&eacute;finitivement le
					Grant qui ne peut plus &ecirc;tre annul&eacute;,
					rembours&eacute; ou affect&eacute; &agrave; un autre
					Utilisateur. Les sommes cr&eacute;dit&eacute;es sur le
					Compte Utilisateur du R&eacute;cipiendaire sont
					plac&eacute;es en s&eacute;questre sur un compte
					d&rsquo;attente de LEMONWAY dans l&rsquo;attente de leur
					encaissement par le R&eacute;cipiendaire.
				</span>
			</p>
			<h4 class="c4" id="h.4i7ojhp">
				<span class="c25 c28">7.3.3 Encaissement des Grants</span>
			</h4>
			<p class="c2 c15">
				<span class="c0">
					Le R&eacute;cipiendaire peut encaisser les Grants
					cr&eacute;dit&eacute;s sur son Compte Utilisateur &agrave;
					tout moment &agrave; condition de renseigner sur son Compte
					Utilisateur le compte bancaire o&ugrave; seront
					vers&eacute;s les Grants.
				</span>
			</p>
			<p class="c2 c15">
				<span class="c0">
					Lors du premier encaissement, le R&eacute;cipiendaire aura
					l&rsquo;obligation de satisfaire une Proc&eacute;dure KYC.
					Si le R&eacute;cipiendaire refuse de suivre ou ne satisfait
					pas la Proc&eacute;dure KYC, le R&eacute;cipiendaire pourra
					tout de m&ecirc;me poursuivre le premier encaissement du ou
					des Grants mais (i) le virement aura lieu &agrave;
					l&rsquo;issue d&rsquo;un d&eacute;lai d&rsquo;attente de
					quinze (15) jours et (ii) son Compte Utilisateur sera
					ensuite suspendu temporairement jusqu&rsquo;&agrave; ce que
					le R&eacute;cipiendaire ait satisfait une Proc&eacute;dure
					KYC.
				</span>
			</p>
			<p class="c2 c15">
				<span class="c0">
					Si le R&eacute;cipiendaire a d&eacute;j&agrave; satisfait
					une Proc&eacute;dure KYC, celui-ci peut &eacute;galement
					proc&eacute;der au virement sans devoir en repasser une,
					&agrave; la libre appr&eacute;ciation de GRANT. GRANT peut
					imposer une nouvelle Proc&eacute;dure KYC au
					R&eacute;cipiendaire &agrave; tout moment, et notamment lors
					de chaque changement de situation, de justificatifs ou des
					Donn&eacute;es de son Compte Utilisateur.
				</span>
			</p>
			<p class="c2 c15">
				<span class="c0">
					L&rsquo;encaissement du montant des Grants se fait dans un
					d&eacute;lai maximal de quinze (15) jours &agrave; compter
					de la r&eacute;alisation de la proc&eacute;dure
					d&rsquo;encaissement sur l&rsquo;Application, avec ou sans
					Proc&eacute;dure KYC.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Le R&eacute;cipiendaire ne peut plus recevoir de Grants,
					tous Utilisateurs confondus, au bout d&rsquo;un montant
					maximum de cinq mille euros (5&nbsp;000 &euro;) par mois (ou
					son &eacute;quivalent en monnaie locale au taux de
					conversion applicable &agrave; la date
					d&rsquo;&eacute;mission de chaque Grant).
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					&Agrave; d&eacute;faut de transfert du Grant
					cr&eacute;dit&eacute; sur le Compte Utilisateur du
					R&eacute;cipiendaire vers ses comptes bancaires dans un
					d&eacute;lai maximal de cinq (5) ans &agrave; compter de son
					cr&eacute;dit, les fonds seront automatiquement
					transf&eacute;r&eacute;s &agrave; la Caisse des
					D&eacute;p&ocirc;ts et Consignation dans le respect du droit
					applicable aux comptes et fonds inutilis&eacute;s.
				</span>
			</p>
			<h3 class="c5" id="h.2xcytpi">
				<span class="c9">
					7.4 R&eacute;f&eacute;rencement des R&eacute;cipiendaires
				</span>
			</h3>
			<p class="c2">
				<span class="c0">
					GRANT peut &ecirc;tre qualifi&eacute;
					d&rsquo;op&eacute;rateur de plateforme en ligne en proposant
					sur la Solution la mise en relation de plusieurs
					Utilisateurs en vue de l&rsquo;&eacute;change de Grants.
					Dans ce cadre, GRANT vous indique&nbsp;:
				</span>
			</p>
			<p class="c2 c11">
				<span class="c0"></span>
			</p>
			<ul class="c1 lst-kix_list_9-0 start">
				<li class="c7 c24 li-bullet-0">
					<span class="c0">
						GRANT n&rsquo;a pas de lien capitalistique avec les
						Utilisateurs de la Solution ou ne tire pas de
						r&eacute;mun&eacute;ration &agrave; son profit du fait
						de ces derniers, qui n&rsquo;influencent pas le
						fonctionnement de la Solution&nbsp;; et
					</span>
				</li>
				<li class="c10 c7 li-bullet-0">
					<span class="c0">
						GRANT vous informe des droits et obligations de
						l&rsquo;Emetteur en mati&egrave;re civile et fiscale
						lorsque ce dernier interagit avec des
						R&eacute;cipiendaires au sein de la Solution &agrave;
						l&rsquo;article &quot;Conditions particuli&egrave;res
						applicables aux consommateurs&quot; des pr&eacute;sentes
						CGUA.
					</span>
				</li>
			</ul>
			<h2 class="c8" id="h.1ci93xb">
				<span class="c20">
					Article 8. Conditions particuli&egrave;res applicables aux
					consommateurs
				</span>
			</h2>
			<p class="c2 c15">
				<span class="c0">
					L&rsquo;Utilisateur peut t&eacute;l&eacute;charger ou
					enregistrer une copie des pr&eacute;sentes CGUA sur un
					support durable &agrave; tout moment. Les pr&eacute;sentes
					CGUA sont accessibles depuis le Compte Utilisateur de chaque
					Utilisateur et depuis les pages de renvoi vers la Solution.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					L&rsquo;Utilisateur accepte que ses Donn&eacute;es,
					notamment ses donn&eacute;es bancaires et/ou de paiement,
					peuvent &ecirc;tre recueillies dans le cadre de la
					fourniture des Prestations par GRANT et sont transmises aux
					prestataires op&eacute;rationnels de GRANT, notamment
					&agrave; LEMONWAY, aux seules fins d&rsquo;ex&eacute;cution
					des ordres et op&eacute;rations de paiement demand&eacute;s
					par l&rsquo;Utilisateur lorsqu&rsquo;il &eacute;met ou
					re&ccedil;oit un Grant. Ces prestataires
					op&eacute;rationnels sont soumis a&#768; une
					r&eacute;glementation garantissant un niveau suffisant de
					protection tel que d&eacute;fini a&#768; l&rsquo;article
					561-7, II, 2&deg; du Code mon&eacute;taire et financier.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Les CGUA constituent un contrat portant sur des Prestations
					dont une partie sont des services financiers&nbsp;; ces
					derniers sont exclus du champ d&rsquo;application des
					articles L. 221-1 et suivants.
				</span>
			</p>
			<p class="c2">
				<span class="c3">
					Par ailleurs, GRANT informe les Utilisateurs que le droit de
					r&eacute;tractation ne peut s&rsquo;appliquer aux
					Prestations, qui sont (i) pleinement ex&eacute;cut&eacute;es
					avant la fin du d&eacute;lai de r&eacute;tractation, (ii)
					sont fournis en ligne sans support mat&eacute;riel et dont
					l&rsquo;ex&eacute;cution repose (iii) sur un accord
					sp&eacute;cifique et pr&eacute;alable de l&rsquo;Utilisateur
					pour chaque Grant et (iv) sur le renoncement expr&egrave;s
					&agrave; son droit de r&eacute;tractation.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Le R&eacute;cipiendaire est responsable de la
					d&eacute;claration et de l&rsquo;acquittement des taxes ou
					imp&ocirc;ts associ&eacute;s sur les montants re&ccedil;us
					sur son compte bancaire &agrave; partir des Grants
					vers&eacute;s par les Emetteurs. &Agrave; ce titre, GRANT
					informe le R&eacute;cipiendaire que les montants des Grants
					encaiss&eacute;s peuvent &ecirc;tre consid&eacute;r&eacute;s
					comme des traitements et salaires imposables &agrave;
					l&rsquo;imp&ocirc;t sur le revenu, au regard de
					l&rsquo;article L. 3244-1 du Code de travail selon lequel
					les Grants s&rsquo;assimilent &agrave; des pourboires remis
					volontairement par les Emetteurs, en compl&eacute;ment de
					salaire.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					L&rsquo;Utilisateur est inform&eacute; que GRANT est tenu
					&agrave; des obligations en mati&egrave;re de lutte contre
					le terrorisme, la corruption et le blanchiment
					d&rsquo;argent qui sont recens&eacute;es notamment &agrave;
					l&rsquo;article &laquo;&nbsp;Conditions de versement des
					Grants&nbsp;&raquo;. L&rsquo;Utilisateur ne peut utiliser la
					Solution pour r&eacute;aliser des donations&nbsp;; &agrave;
					cet &eacute;gard, GRANT informe que toute donation doit
					&ecirc;tre d&eacute;clar&eacute;e aupr&egrave;s de
					l&rsquo;administration fiscale par l&rsquo;Utilisateur
					donataire.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					GRANT met &agrave; disposition des personnes sourdes,
					malentendantes, sourd-aveugles et aphasiques un service de
					traduction simultan&eacute;e &eacute;crite et visuelle sans
					surco&ucirc;t pour l&rsquo;Utilisateur, accessible aux
					m&ecirc;mes coordonn&eacute;es de contact durant les
					m&ecirc;mes horaires d&rsquo;ouverture que le service client
					de GRANT.
				</span>
			</p>
			<h2 class="c8" id="h.3whwml4">
				<span class="c20">
					Article 9. Licence d&rsquo;utilisation de la Solution
				</span>
			</h2>
			<p class="c2">
				<span class="c0">
					L&rsquo;Utilisateur reconna&icirc;t que GRANT est pleinement
					propri&eacute;taire de la Solution et seule titulaire des
					droits de propri&eacute;t&eacute; intellectuelle sur la
					Solution. D&egrave;s lors, l&rsquo;Utilisateur
					reconna&icirc;t et accepte que la licence
					d&rsquo;utilisation de la Solution, qui lui est
					conc&eacute;d&eacute;e dans la section suivante de
					l&rsquo;article, n&rsquo;est pas une licence exclusive et ne
					peut emp&ecirc;cher GRANT de conc&eacute;der d&rsquo;autres
					licences d&rsquo;utilisation de la Solution &agrave;
					d&rsquo;autres Utilisateurs.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Les droits d&rsquo;usage conc&eacute;d&eacute;s &agrave;
					l&rsquo;Utilisateur en vertu des pr&eacute;sentes CGUA ne
					peuvent avoir pour effet de transf&eacute;rer quelque droit
					de propri&eacute;t&eacute; et/ou de propri&eacute;t&eacute;
					intellectuelle que ce soit sur tout ou partie de la
					Solution, autres que ceux strictement n&eacute;cessaires
					&agrave; l&rsquo;utilisation de la Solution par
					l&rsquo;Utilisateur pendant la dur&eacute;e, pour les fins
					et dans les conditions indiqu&eacute;es dans les CGUA.
				</span>
			</p>
			<h3 class="c5" id="h.2bn6wsx">
				<span class="c9">9.1 Licence d&rsquo;utilisation</span>
			</h3>
			<p class="c2">
				<span class="c0">
					La Cr&eacute;ation du Compte Utilisateur par
					l&rsquo;Utilisateur emporte la concession &agrave;
					l&rsquo;Utilisateur d&rsquo;un droit personnel, non
					exclusif, non cessible et non transmissible d&rsquo;utiliser
					la Solution et les r&eacute;sultats des Prestations pour la
					dur&eacute;e de validit&eacute; des pr&eacute;sentes CGUA,
					dans le monde entier. Ce droit d&rsquo;utilisation
					s&rsquo;&eacute;tend aux Mises &agrave; jour de la Solution,
					y compris pour tout correctif ou contournement d&rsquo;une
					anomalie.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					La pr&eacute;sente licence exclut toutes sous-licences au
					profit de tiers, de soci&eacute;t&eacute;s affili&eacute;es
					ou d&rsquo;agents des Utilisateurs.
				</span>
			</p>
			<h2 class="c8" id="h.qsh70q">
				<span class="c20">Article 10. Restrictions</span>
			</h2>
			<h3 class="c5" id="h.3as4poj">
				<span class="c9">10.1 Restrictions d&rsquo;utilisation</span>
			</h3>
			<p class="c2">
				<span class="c0">
					&Agrave; tout moment, vous vous engagez et vous acceptez de
					ne pas&nbsp;:
				</span>
			</p>
			<ol class="c1 lst-kix_list_2-0 start" start="1">
				<li class="c2 c13 li-bullet-0">
					<span class="c0">
						copier, modifier, r&eacute;utiliser, cr&eacute;er des
						&oelig;uvres d&eacute;riv&eacute;es,
						t&eacute;l&eacute;charger, adapter, faire
						l&#39;ing&eacute;nierie inverse, &eacute;muler, migrer
						vers un autre service, traduire, compiler,
						d&eacute;compiler ou d&eacute;sassembler la Solution (ou
						toute partie de celle-ci)&nbsp;;
					</span>
				</li>
				<li class="c2 c13 li-bullet-0">
					<span class="c0">
						afficher publiquement, ex&eacute;cuter, transmettre ou
						distribuer la Solution sans l&#39;autorisation
						&eacute;crite pr&eacute;alable sp&eacute;cifique de
						GRANT et/ou sans &ecirc;tre express&eacute;ment
						autoris&eacute; en vertu des pr&eacute;sentes
						CGUA&nbsp;;
					</span>
				</li>
				<li class="c2 c13 li-bullet-0">
					<span class="c0">
						acheter de mots-cl&eacute;s d&#39;un moteur de recherche
						ou d&#39;un service de paiement au clic (tel que Google
						Ads) ou de noms de domaine qui utilisent des marques,
						des variantes ou une orthographe identique(s) ou
						similaire(s) au nom de la Solution&nbsp;;
					</span>
				</li>
				<li class="c2 c13 li-bullet-0">
					<span class="c0">
						mettre en ligne sur la Solution ou de prendre toute
						action qui aurait pour effet la transmission de toute
						autre mani&egrave;re de virus logiciels ou autres codes
						informatiques, fichiers ou programmes con&ccedil;us pour
						interrompre, d&eacute;truire ou limiter la
						fonctionnalit&eacute; de tout logiciel ou
						mat&eacute;riel informatique ou &eacute;quipement de
						t&eacute;l&eacute;communication ;
					</span>
				</li>
				<li class="c2 c13 li-bullet-0">
					<span class="c0">
						perturber ou d&#39;interrompre le fonctionnement de la
						Solution, des serveurs ou des r&eacute;seaux qui y sont
						connect&eacute;s ou de violer toutes conditions,
						proc&eacute;dures, r&egrave;gles ou
						r&eacute;glementations relatives &agrave; la Solution ;
					</span>
				</li>
				<li class="c2 c13 li-bullet-0">
					<span class="c0">
						tenter d&#39;interf&eacute;rer ou interf&eacute;rer avec
						la Solution, notamment en les exposant &agrave; un
						virus, en provoquant une surcharge de la bande passante
						ou de l&rsquo;Infrastructure (que ce soit de
						mani&egrave;re involontaire ou par le biais d&#39;un
						d&eacute;ni de service distribu&eacute;), en envoyant
						des &quot;spams&quot; ou en surchargeant les services
						fournis sur la Solution ;
					</span>
				</li>
				<li class="c2 c13 li-bullet-0">
					<span class="c0">
						consulter des informations qui ne vous sont pas
						destin&eacute;es ou d&rsquo;acc&eacute;der &agrave; un
						serveur ou &agrave; un compte auquel vous
						n&#39;&ecirc;tes pas autoris&eacute; &agrave;
						acc&eacute;der ;
					</span>
				</li>
				<li class="c2 c13 li-bullet-0">
					<span class="c0">
						&eacute;valuer, de noter ou de tester la
						vuln&eacute;rabilit&eacute; de la Solution, ou de violer
						leurs mesures de s&eacute;curit&eacute; ou
						d&#39;authentification sans le consentement &eacute;crit
						pr&eacute;alable de GRANT, ou de tenter de le faire ;
					</span>
				</li>
				<li class="c2 c13 li-bullet-0">
					<span class="c0">
						vous livrer &agrave; toute activit&eacute;
						ill&eacute;gale ou toute autre activit&eacute; pouvant
						porter pr&eacute;judice aux droits de GRANT, de ses
						fournisseurs, prestataires de services, partenaires,
						Utilisateurs ou de toutes autres personnes, et
						d&#39;inciter des tiers &agrave; le faire&nbsp;;
					</span>
				</li>
				<li class="c2 c13 li-bullet-0">
					<span class="c0">
						mettre en ligne sur la Solution ou de rendre disponible
						par tout moyen des publicit&eacute;s ou des
						mat&eacute;riels promotionnels non sollicit&eacute;s ou
						non autoris&eacute;s, des &quot;pourriels&quot;, des
						&quot;spams&quot;, des &quot;cha&icirc;nes de
						lettres&quot; ou toute autre forme de sollicitation ;
					</span>
				</li>
				<li class="c2 c13 li-bullet-0">
					<span class="c0">
						usurper les Identifiants d&rsquo;un tiers (autre
						Utilisateur) pour acc&eacute;der &agrave; ses
						Donn&eacute;es, &agrave; la Solution ou aux
						Prestations&nbsp;;
					</span>
				</li>
				<li class="c2 c13 li-bullet-0">
					<span class="c0">
						mettre en ligne sur la Solution ou de rendre disponible
						par tout moyen tout contenu ill&eacute;gal, nuisible,
						diffamatoire, calomnieux, offensant, raciste, vulgaire,
						obsc&egrave;ne, mena&ccedil;ant, immoral, portant
						atteinte &agrave; la vie priv&eacute;e d&#39;autrui,
						r&eacute;pr&eacute;hensible sur le plan racial ou
						ethnique ou autrement r&eacute;pr&eacute;hensible&nbsp;;
					</span>
				</li>
				<li class="c2 c13 li-bullet-0">
					<span class="c0">
						utiliser la Solution &agrave; des fins politiques,
						pol&eacute;miques, contraires &agrave; l&rsquo;ordre
						public ou aux bonnes m&oelig;urs ou susceptibles de
						porter atteinte &agrave; des droits reconnus par la loi
						et, de mani&egrave;re g&eacute;n&eacute;rale&nbsp;;
					</span>
				</li>
				<li class="c2 c13 li-bullet-0">
					<span class="c0">
						associer la Solution &agrave; des actions ou
						activit&eacute;s susceptibles de porter atteinte ou
						d&rsquo;&ecirc;tre pr&eacute;judiciable &agrave; GRANT,
						notamment &agrave; tout comportement pouvant &ecirc;tre
						associ&eacute; directement ou indirectement &agrave; des
						actes de contrefa&ccedil;on ou de concurrence
						d&eacute;loyale, ou &agrave; tout proc&eacute;d&eacute;
						social, comptable ou fiscal interdit ou sanctionnable
						selon le droit applicable &agrave; GRANT et &agrave;
						l&rsquo;Utilisateur&nbsp;;
					</span>
				</li>
				<li class="c2 c13 li-bullet-0">
					<span class="c0">
						utiliser un &quot;robot&quot;, un &quot;spider&quot; ou
						tout autre dispositif, programme, script, algorithme,
						m&eacute;thodologie automatique ou tout autre
						proc&eacute;d&eacute; manuel identique ou similaire, aux
						fins d&rsquo;acc&eacute;der &agrave;,
						d&rsquo;acqu&eacute;rir, de copier ou de surveiller
						toute partie de la Solution ou des Donn&eacute;es&nbsp;;
					</span>
				</li>
				<li class="c2 c13 li-bullet-0">
					<span class="c0">
						reproduire ou de d&eacute;tourner la structure de
						navigation ou la pr&eacute;sentation de la Solution afin
						d&#39;obtenir ou de tenter d&#39;obtenir des
						informations, documents, services ou mat&eacute;riels
						d&#39;une mani&egrave;re non disponible par le biais de
						la Solution.
					</span>
				</li>
			</ol>
			<p class="c2">
				<span class="c0">
					Vous acceptez de vous conformer &agrave; toutes les lois,
					r&egrave;gles et proc&eacute;dures applicables concernant le
					comportement des utilisateurs en ligne et la transmission de
					donn&eacute;es techniques. &nbsp;GRANT peut mettre fin
					&agrave; votre droit d&#39;acc&egrave;s &agrave; la Solution
					&agrave; tout moment si vous ne respectez pas vos
					obligations en vertu de ces CGUA, sans pr&eacute;judice du
					droit de GRANT de r&eacute;clamer des dommages et
					int&eacute;r&ecirc;ts.
				</span>
			</p>
			<h3 class="c5" id="h.1pxezwc">
				<span class="c9">10.2 Restrictions de licence</span>
			</h3>
			<p class="c2">
				<span class="c0">
					Toute autre utilisation de la Solution et/ou des
					r&eacute;sultats des Prestations que celles
					express&eacute;ment autoris&eacute;es par GRANT ci-avant est
					interdite. La licence exclut tout droit de sous-licence, de
					reproduction, de repr&eacute;sentation ou de modification
					non express&eacute;ment autoris&eacute;s et qui sont
					interdits en application de l&rsquo;article L. 122-4 du code
					de la propri&eacute;t&eacute; intellectuelle.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					L&rsquo;Utilisateur s&rsquo;interdit de proc&eacute;der
					&agrave; (i) toute reproduction provisoire ou permanente de
					tout ou partie de la Solution, par quelque moyen que ce
					soit, (ii) tout acc&egrave;s ou tentative
					d&rsquo;acc&egrave;s aux applicatifs et syst&egrave;mes de
					traitement automatis&eacute;s de donn&eacute;es de GRANT,
					notamment ceux permettant de r&eacute;aliser les
					Prestations, (iii) toute d&eacute;compilation ou
					ing&eacute;nierie inverse de la Solution notamment en vue de
					la cr&eacute;ation d&rsquo;un service similaire, (iv) tout
					interfa&ccedil;age ou int&eacute;gration de la Solution avec
					d&rsquo;autres services ou logiciels sans autorisation
					&eacute;crite pr&eacute;alable de GRANT, (v) toute
					diffusion, distribution, mise &agrave; disposition gratuite
					ou payante de la Solution au b&eacute;n&eacute;fice
					d&rsquo;une autre entreprise, du public ou de tiers (sauf en
					cas de simple mention de l&rsquo;existence de la Solution
					avec un lien hypertexte redirigeant vers ces derniers, qui
					rappelle sans ambigu&iuml;t&eacute; que GRANT en est
					l&rsquo;&eacute;diteur), (vi) toute adaptation ou
					modification de la Solution quelle qu&rsquo;elle soit, ou
					(vii) toute introduction ou tentative d&rsquo;introduction
					frauduleuse ou non autoris&eacute;e sur la Solution. Les
					interdictions de ce paragraphe valent pour toute Mise
					&agrave; jour int&eacute;gr&eacute;e &agrave; la Solution
					dans le cadre des CGUA.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					L&rsquo;Utilisateur s&rsquo;engage &agrave; ne pas
					d&eacute;poser, &agrave; quelque titre que ce soit (marque,
					dessin et mod&egrave;le, etc.) ni d&eacute;velopper,
					utiliser ou exploiter, &agrave; quelque titre que ce soit et
					dans quelque territoire que ce soit, des logiciels,
					produits, services, signal&eacute;tiques ou logos identiques
					ou similaires ou susceptibles de porter atteinte aux droits
					de propri&eacute;t&eacute; intellectuelle de GRANT sur la
					Solution.
				</span>
			</p>
			<h3 class="c5" id="h.49x2ik5">
				<span class="c9">10.3 Violation des restrictions</span>
			</h3>
			<p class="c2">
				<span class="c0">
					GRANT d&eacute;cline toute responsabilit&eacute; pour toute
					demande, action ou r&eacute;clamation introduite par un
					tiers &agrave; raison des propos, actions ou omissions
					d&rsquo;un Utilisateur en violation avec ces restrictions.
					L&rsquo;Utilisateur s&rsquo;engage &agrave; garantir et
					indemniser GRANT contre toute dommage subi du fait
					d&rsquo;une violation des pr&eacute;sentes restrictions par
					l&rsquo;Utilisateur ou via son Compte Utilisateur.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					L&rsquo;Utilisateur est seul responsable des Donn&eacute;es
					charg&eacute;es, ainsi que des traitements, des instructions
					et des proc&eacute;dures qu&rsquo;il active sur la Solution.
					Il s&rsquo;engage &agrave; ne pas faire de la Solution et
					des Prestations un usage contraire au droit applicable
					&agrave; ses activit&eacute;s ou au commerce
					&eacute;lectronique, ni apporter son concours &agrave; un
					tel usage.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					En cas de chargement sur la Solution de Donn&eacute;es
					contraire aux pr&eacute;sentes CGUA ou en cas
					d&rsquo;utilisation de la Solution &agrave; d&rsquo;autres
					fins que celles autoris&eacute;es par GRANT, GRANT se
					r&eacute;serve le droit (i) de supprimer la Donn&eacute;e
					litigieuse en cas d&rsquo;urgence ou de menace sur
					l&rsquo;Infrastructure, (ii) d&rsquo;interrompre
					l&rsquo;acc&egrave;s &agrave; la Solution sans d&eacute;lai
					ni pr&eacute;avis et (iii) de suspendre ou r&eacute;silier
					le Compte Utilisateur de l&rsquo;Utilisateur contrevenant.
					Cette r&eacute;siliation ne donnera lieu &agrave; aucune
					indemnit&eacute; quelle qu&rsquo;elle soit, sans
					pr&eacute;judice des dommages et int&eacute;r&ecirc;ts que
					GRANT pourra r&eacute;clamer &agrave; l&rsquo;Utilisateur
					pour ses agissements.
				</span>
			</p>
			<h2 class="c8" id="h.2p2csry">
				<span class="c20">Article 11. Maintenance</span>
			</h2>
			<h3 class="c5" id="h.147n2zr">
				<span class="c9">11.1 S&eacute;curit&eacute;</span>
			</h3>
			<p class="c2">
				<span class="c0">
					La Solution et ses Comptes Utilisateurs sont un
					syst&egrave;me de traitement automatis&eacute; de
					donn&eacute;es. Tout acc&egrave;s frauduleux &agrave; ce
					dernier est interdit et sanctionn&eacute; p&eacute;nalement.
					Dans le cadre d&rsquo;une obligation de moyens, GRANT met en
					place sur la Solution des mesures de s&eacute;curit&eacute;
					respectant l&rsquo;&eacute;tat de l&rsquo;art &agrave;
					l&rsquo;encontre de tout acc&egrave;s non autoris&eacute;
					&agrave; la Solution ou toute autre violation de
					s&eacute;curit&eacute;. De la m&ecirc;me mani&egrave;re,
					l&rsquo;Utilisateur fait ses meilleurs efforts pour assurer
					la s&eacute;curit&eacute; de ses Donn&eacute;es et de son
					acc&egrave;s &agrave; Internet, y compris, mais sans s&#39;y
					limiter, en mettant en place des pare-feux et des antivirus
					pour prot&eacute;ger son syst&egrave;me d&rsquo;information
					et ses Donn&eacute;es.
				</span>
			</p>
			<h3 class="c5" id="h.3o7alnk">
				<span class="c9">11.2 Disponibilit&eacute;</span>
			</h3>
			<p class="c2">
				<span class="c0">
					Les Parties reconnaissent et acceptent qu&rsquo;en
					l&rsquo;&eacute;tat actuel de la technique, GRANT ne peut
					pas garantir une disponibilit&eacute; continue de la
					Solution ni des Prestations. GRANT s&#39;engage &agrave;
					faire des efforts commercialement raisonnables pour fournir
					&agrave; l&rsquo;Utilisateur un acc&egrave;s continu
					&agrave; la Solution 24 heures sur 24, 7 jours sur 7 (y
					compris les dimanches et jours f&eacute;ri&eacute;s),
					&agrave; l&#39;exception des p&eacute;riodes de maintenance
					planifi&eacute;es, d&rsquo;indisponibilit&eacute; par mesure
					de s&eacute;curit&eacute;, d&rsquo;&eacute;v&egrave;nement
					&eacute;chappant &agrave; son contr&ocirc;le pouvant
					cr&eacute;er des interruptions ou perturbations de services
					en tout ou partie, ou de suspension de l&rsquo;acc&egrave;s
					&agrave; la Solution pour manquement de l&rsquo;Utilisateur,
					dans les conditions &eacute;nonc&eacute;es dans les
					pr&eacute;sentes CGUA. Ces cas de suspension de
					l&#39;acc&egrave;s &agrave; la Solution ne seront pas tenus
					en compte pour &eacute;valuer la disponibilit&eacute;.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					En cons&eacute;quence, GRANT ne peut en aucun cas assurer la
					disponibilit&eacute; permanente et optimale de la Solution,
					et ne saurait &ecirc;tre tenu responsable lorsque ces
					difficult&eacute;s d&#39;acc&egrave;s ou d&#39;interruption
					de la Solution r&eacute;sultent de perturbations de la
					connexion internet de l&rsquo;Utilisateur ou de tout
					probl&egrave;me li&eacute; aux fournisseurs
					d&#39;acc&egrave;s &agrave; internet de l&rsquo;Utilisateur.
					GRANT d&eacute;cline toute responsabilit&eacute; en cas
					d&rsquo;indisponibilit&eacute; de la Solution et fournit la
					Solution en l&rsquo;&eacute;tat, sans garantie
					d&rsquo;aucune sorte, en ce compris les garanties ou
					obligations expresses ou implicites de qualit&eacute;,
					d&#39;ad&eacute;quation aux besoins ou toute autre garantie
					&ndash; dans la limite de ce qui est autoris&eacute; par le
					droit applicable et des garanties propos&eacute;es au sein
					des pr&eacute;sentes CGUA.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					GRANT prendra les mesures commercialement raisonnables dont
					elle dispose pour limiter ou r&eacute;soudre les
					interruptions ou perturbations de service sur la Solution.
				</span>
			</p>
			<h3 class="c5" id="h.23ckvvd">
				<span class="c9">11.3 H&eacute;bergement</span>
			</h3>
			<p class="c2 c11">
				<span class="c0"></span>
			</p>
			<p class="c2 c15">
				<span class="c0">
					La Solution et les Donn&eacute;es sont
					h&eacute;berg&eacute;es sur l&rsquo;Infrastructure des
					h&eacute;bergeurs de GRANT, o&ugrave; elles font l&#39;objet
					de sauvegardes r&eacute;guli&egrave;res. L&rsquo;Utilisateur
					accorde express&eacute;ment &agrave; GRANT et aux
					h&eacute;bergeurs sous-traitants de GRANT un droit
					personnel, incessible et non transf&eacute;rable de
					reproduction des Donn&eacute;es sur la Solution ainsi que
					pour la fourniture des Prestations, &agrave; l&#39;exclusion
					de toute autre utilisation ou transfert, pour la
					dur&eacute;e des CGUA et dans le monde entier. Cette licence
					comprend le droit de conc&eacute;der les m&ecirc;mes droits
					&agrave; l&rsquo;h&eacute;bergeur de GRANT. La Solution et
					les Prestations n&#39;incluent pas de services de suivi ou
					de nettoyage des Donn&eacute;es, dont
					l&#39;int&eacute;grit&eacute;, la l&eacute;galit&eacute; et
					l&#39;utilisation restent sous la seule
					responsabilit&eacute; de l&rsquo;Utilisateur. GRANT ne fait
					aucune utilisation ou reproduction des Donn&eacute;es qui ne
					soit strictement n&eacute;cessaire &agrave; la fourniture de
					la Solution et/ou &agrave; la fourniture des Prestations.
				</span>
			</p>
			<h3 class="c5" id="h.ihv636">
				<span class="c9">11.4 Maintenance corrective</span>
			</h3>
			<p class="c2">
				<span class="c0">
					Conform&eacute;ment &agrave; l&#39;article L. 122-6-1,
					2&deg; du Code de la propri&eacute;t&eacute; intellectuelle,
					GRANT se r&eacute;serve le droit de corriger les Anomalies
					et de publier les Mises &agrave; jour. En
					cons&eacute;quence, l&rsquo;Utilisateur s&#39;interdit toute
					maintenance corrective et/ou &eacute;volutive de la
					Solution. Le cas &eacute;ch&eacute;ant, le prix de la
					Maintenance est compris dans les Frais de Transaction.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					L&rsquo;Utilisateur reconna&icirc;t que les logiciels ne
					sont jamais exempts de d&eacute;fauts. Sous r&eacute;serve
					des autres stipulations des pr&eacute;sentes CGUA, GRANT ne
					garantit pas que les Prestations ou la Solution seront
					exempts d&#39;Anomalies. En cas d&#39;Anomalie affectant la
					Solution, l&rsquo;Utilisateur peut en informer GRANT depuis
					l&rsquo;application en d&eacute;taillant les circonstances
					de l&#39;Anomalie. GRANT fera une &eacute;valuation initiale
					de l&rsquo;incident rapport&eacute; afin de
					d&eacute;terminer s&rsquo;il s&rsquo;agit d&rsquo;une
					anomalie attribuable &agrave; la Solution ou d&rsquo;un
					incident provoqu&eacute; par des facteurs externes tels que
					les Donn&eacute;es, le syst&egrave;me d&rsquo;information,
					la connexion ou le r&eacute;seau de l&#39;utilisateur. Si la
					cause n&#39;est pas imputable &agrave; la Solution,
					l&rsquo;incident est exclu de la Maintenance.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					GRANT fournit la Maintenance corrective dans le cadre
					d&#39;une obligation de moyens. L&rsquo;Utilisateur
					reconna&icirc;t que le temps n&eacute;cessaire pour corriger
					les Anomalies peut varier en fonction des circonstances
					sp&eacute;cifiques de chaque Anomalie, y compris, sans
					limitation, la nature de l&#39;incident,
					l&#39;&eacute;tendue et l&#39;exactitude des informations
					disponibles sur l&#39;incident, et le niveau de
					coop&eacute;ration et de r&eacute;activit&eacute; de
					l&rsquo;Utilisateur dans la fourniture des documents, des
					informations, de l&#39;acc&egrave;s et du soutien
					raisonnablement requis par le Prestataire pour parvenir
					&agrave; la r&eacute;solution de l&#39;Anomalie.
				</span>
			</p>
			<h3 class="c5" id="h.32hioqz">
				<span class="c9">11.5 Maintenance &eacute;volutive</span>
			</h3>
			<p class="c2">
				<span class="c0">
					GRANT fournit la Maintenance &eacute;volutive dans le cadre
					d&#39;une obligation de moyens. Les Mises &agrave; jour sont
					publi&eacute;es selon la roadmap produit de GRANT et
					n&#39;incluent pas de demandes sp&eacute;cifiques de
					l&rsquo;Utilisateur. Elles sont automatiquement
					d&eacute;ploy&eacute;es sur la Solution sans
					r&eacute;gression fonctionnelle pour les Utilisateurs.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Conform&eacute;ment aux articles L. 217-21 et suivants du
					Code de la consommation&nbsp;:
				</span>
			</p>
			<p class="c2 c11">
				<span class="c0"></span>
			</p>
			<ul class="c1 lst-kix_list_14-0 start">
				<li class="c24 c7 li-bullet-0">
					<span class="c0">
						La Maintenance &eacute;volutive est fournie pendant la
						dur&eacute;e des CGUA et jusqu&rsquo;&agrave; leur
						terme, quelle qu&rsquo;en soit la raison et quelle que
						soit la Partie qui y a mis fin&nbsp;;
					</span>
				</li>
				<li class="c7 c10 li-bullet-0">
					<span class="c0">
						L&rsquo;Utilisateur est inform&eacute; des Mises
						&agrave; jour, y compris des Mises &agrave; jour de
						s&eacute;curit&eacute;, qui sont n&eacute;cessaires au
						maintien de la conformit&eacute; de la Solution, lors de
						sa connexion sur son Compte Utilisateur&nbsp;;
					</span>
				</li>
			</ul>
			<p class="c2">
				<span class="c0">
					Les Utilisateurs ne peuvent pas s&#39;opposer ou
					emp&ecirc;cher une Mise &agrave; jour de la Solution, dont
					l&rsquo;opportunit&eacute; et l&rsquo;impl&eacute;mentation
					rel&egrave;vent de la comp&eacute;tence exclusive de
					GRANT&nbsp;: s&rsquo;ils refusent la Mise &agrave; jour,
					cela conduit &agrave; la r&eacute;siliation des CGUA par
					l&rsquo;Utilisateur. Les Mises &agrave; jour n&#39;incluent
					pas la mise &agrave; jour de logiciels tiers
					interconnect&eacute;s avec la Solution ou la
					compatibilit&eacute; ascendante de la Solution due &agrave;
					une mise &agrave; jour de ces logiciels.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					L&rsquo;Utilisateur reconna&icirc;t et accepte que la
					Maintenance &eacute;volutive a lieu au moins deux (2) heures
					par semaine. En cas de Mise &agrave; jour majeure (nouvelle
					version de la Solution), GRANT donnera un pr&eacute;avis
					d&rsquo;un jour ouvr&eacute; aux Utilisateurs. Toute mise en
					&oelig;uvre d&#39;une Mise &agrave; jour sur la Solution
					peut entra&icirc;ner une indisponibilit&eacute; temporaire
					et planifi&eacute;e de la Solution. Les Mises &agrave; jour
					ne constituent pas un manquement de GRANT &agrave; la
					disponibilit&eacute; de la Solution et en aucun cas
					l&rsquo;Utilisateur ne peut pr&eacute;tendre &agrave; un
					remboursement du fait de l&rsquo;indisponibilit&eacute;
					caus&eacute;e par la Maintenance &eacute;volutive.
				</span>
			</p>
			<h3 class="c5" id="h.1hmsyys">
				<span class="c9">11.6 Exclusions de Maintenance</span>
			</h3>
			<p class="c2">
				<span class="c0">
					En tout &eacute;tat de cause, GRANT ne peut &ecirc;tre tenu
					responsable et ne sera pas responsable de la Maintenance de
					toute Anomalie r&eacute;sultant (i) d&#39;une utilisation de
					la Solution par le Utilisateur non conforme &agrave; sa
					destination, &agrave; sa Documentation ou aux
					recommandations de GRANT, (ii) d&#39;une d&eacute;faillance
					mat&eacute;rielle ou logicielle d&#39;un ou plusieurs
					&eacute;l&eacute;ments du syst&egrave;me ou du r&eacute;seau
					de l&rsquo;Utilisateur, (iii) de la d&eacute;faillance, du
					ralentissement ou de la congestion du r&eacute;seau de
					communications &eacute;lectroniques de l&rsquo;Utilisateur
					ou tout autre &eacute;v&eacute;nement de force majeure
					affectant GRANT ou ses sous-traitants, (iv) de la
					configuration du syst&egrave;me d&rsquo;information de
					l&rsquo;Utilisateur, y compris celle de ses terminaux,
					infrastructures ou postes de travail, ou du format ou du
					contenu des Donn&eacute;es mises en ligne mis en ligne par
					l&rsquo;Utilisateur dans la Solution, (v) du refus de
					l&rsquo;Utilisateur de coop&eacute;rer pleinement avec GRANT
					dans la r&eacute;solution des Anomalies, (vi) d&rsquo;une
					incompatibilit&eacute; entre la Solution et les nouveaux
					mat&eacute;riels ou logiciels tiers mis en &oelig;uvre par
					l&rsquo;Utilisateur, (vii) de la contamination par un virus
					informatique du syst&egrave;me d&rsquo;information de
					l&rsquo;Utilisateur ou des Donn&eacute;es mises en ligne par
					l&rsquo;Utilisateur dans la Solution, (viii) d&rsquo;un acte
					de piratage ou d&#39;intrusion frauduleuse dans le
					syst&egrave;me d&rsquo;information de l&rsquo;Utilisateur,
					(ix) d&rsquo;une intervention d&#39;un tiers sur la Solution
					sans autorisation de GRANT, (x) d&rsquo;un manquement de
					l&rsquo;Utilisateur aux restrictions de l&rsquo;article
					&laquo;&nbsp;Restrictions&nbsp;&raquo; et (xi) plus
					g&eacute;n&eacute;ralement, de tout acte volontaire de
					d&eacute;gradation, de malveillance, de sabotage de
					l&rsquo;Utilisateur ou d&#39;un tiers ou de
					d&eacute;t&eacute;rioration due &agrave; tout
					&eacute;v&eacute;nement de force majeure.
				</span>
			</p>
			<h2 class="c15 c34" id="h.41mghml">
				<span class="c20">
					Article 12. Conditions financi&egrave;res
				</span>
			</h2>
			<h3 class="c5" id="h.2grqrue">
				<span class="c9">12.1 Frais de Transaction</span>
			</h3>
			<p class="c2">
				<span class="c0">
					La cr&eacute;ation du Compte Utilisateur,
					l&rsquo;utilisation de la Solution et des Prestations sont
					fournis en contrepartie, lors du versement des Grants entre
					Utilisateurs, du pr&eacute;l&egrave;vement des Frais de
					Transaction. Le cas &eacute;ch&eacute;ant, l&rsquo;Emetteur
					s&rsquo;engage &agrave; payer le prix des Frais de
					Transaction pr&eacute;vus, qui est r&eacute;put&eacute;
					comprendre le prix des Prestations fournies ainsi que la
					redevance d&rsquo;utilisation de la Solution.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Les Frais de Transaction sont calcul&eacute;s &agrave;
					partir d&rsquo;un montant fixe par Grant, auquel
					s&rsquo;ajoutent (i) les frais proportionnels au Grant
					demand&eacute;s par LEMONWAY et (ii) les frais de
					fonctionnement de GRANT. Selon les territoires depuis
					lesquels les Prestations sont r&eacute;alis&eacute;es (lieu
					d&rsquo;&eacute;mission du Grant, lieu d&rsquo;encaissement
					du Grant) et o&ugrave; sont domicili&eacute;s le compte
					bancaire que l&rsquo;Utilisateur a rattach&eacute; &agrave;
					son Compte Utilisateur, ces Frais de Transaction peuvent
					varier de mani&egrave;re non-discriminatoire pour tous les
					Utilisateurs. En tout &eacute;tat de cause, les Frais sont
					r&eacute;visables &agrave; tout moment, &agrave; compter
					leur notification &agrave; l&rsquo;Utilisateur lors de sa
					connexion &agrave; son Compte Utilisateur.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Par ailleurs, L&rsquo;Utilisateur est express&eacute;ment
					inform&eacute; que des frais suppl&eacute;mentaires peuvent
					s&rsquo;appliquer dans les cas suivants&nbsp;:
				</span>
			</p>
			<ul class="c1 lst-kix_list_10-0 start">
				<li class="c2 c7 li-bullet-0">
					<span class="c0">
						Selon l&rsquo;op&eacute;rateur de
						t&eacute;l&eacute;phonie mobile de l&rsquo;Utilisateur,
						ce dernier peut pr&eacute;lever des frais
						d&rsquo;itin&eacute;raire aupr&egrave;s de
						l&rsquo;Utilisateur, que GRANT ne peut pr&eacute;voir ni
						contr&ocirc;ler&nbsp;; et
					</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">
						Si le compte bancaire utilis&eacute; par
						l&rsquo;Utilisateur sur la Solution n&rsquo;a pas pour
						devise l&rsquo;euro, des frais de change peuvent
						&ecirc;tre appliqu&eacute;s par
						l&rsquo;&eacute;tablissement bancaire de
						l&rsquo;Utilisateur, sans que GRANT ou LEMONWAY ne
						puisse les pr&eacute;voir ou contr&ocirc;ler.
					</span>
				</li>
			</ul>
			<h3 class="c5" id="h.vx1227">
				<span class="c9">12.2 Conditions de paiement</span>
			</h3>
			<p class="c2">
				<span class="c0">
					Les Grants et les Frais sont exprim&eacute;s en euros, nets
					et sans escompte, et incluent la taxe sur la valeur
					ajout&eacute;e applicable. En cas de r&eacute;siliation des
					CGUA, GRANT ne peut rembourser les Grants d&eacute;j&agrave;
					&eacute;mis et ne pourra pas verser les Grants qui
					n&rsquo;ont pas encore &eacute;t&eacute; encaiss&eacute;s
					par l&rsquo;Utilisateur, ce que celui-ci reconna&icirc;t.
				</span>
			</p>
			<p class="c2">
				<span>
					Les Grants et les Frais sont payables imm&eacute;diatement,
					par paiement s&eacute;curis&eacute; via notre prestataire
					LEMONWAY
				</span>
				<span class="c29">.</span>
			</p>
			<h3 class="c5" id="h.3fwokq0">
				<span class="c9">12.3 Retard de paiement</span>
			</h3>
			<p class="c2">
				<span class="c0">
					En toute hypoth&egrave;se, tout retard ou d&eacute;faut de
					paiement entra&icirc;ne l&rsquo;application &agrave;
					l&rsquo;Utilisateur (i) de l&rsquo;indemnit&eacute;
					forfaitaire l&eacute;gale (40&euro;) pour frais de
					recouvrement, conform&eacute;ment &agrave; l&rsquo;article
					L.441-10 du Code de commerce, (ii) des &eacute;ventuels
					frais suppl&eacute;mentaires qui seraient n&eacute;cessaires
					au recouvrement de la cr&eacute;ance et (iii) d&rsquo;un
					int&eacute;r&ecirc;t de retard non lib&eacute;ratoire
					&agrave; un taux &eacute;gal &agrave; trois (3) fois le taux
					d&rsquo;int&eacute;r&ecirc;t l&eacute;gal en vigueur,
					comptabilis&eacute; en jours &agrave; compter du premier
					jour de retard sur la date d&rsquo;exigibilit&eacute; du
					Grant et/ou des Frais et jusqu&rsquo;au jour du paiement
					effectif. GRANT pr&eacute;l&egrave;ve les frais de
					recouvrement aupr&egrave;s des Utilisateurs consommateurs
					dans les conditions &eacute;tablies &agrave; l&rsquo;article
					L. 111-8 du Code des proc&eacute;dures civiles
					d&rsquo;ex&eacute;cution.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					En cas de retard persistant de plus d&rsquo;un (1) mois,
					GRANT pourra suspendre les Prestations et/ou le droit
					d&rsquo;utilisation de la Solution sans indemnit&eacute; ni
					pr&eacute;avis, et/ou mettre en &oelig;uvre la
					r&eacute;siliation des CGUA aux torts exclusifs de
					l&rsquo;Utilisateur en application de l&rsquo;article 1217
					du Code civil et de l&rsquo;article
					&laquo;&nbsp;R&eacute;siliation&nbsp;&raquo; des
					pr&eacute;sentes CGUA. Pendant la suspension,
					l&rsquo;Utilisateur demeure redevable de toute somme
					normalement due dans le cadre des CGUA.
				</span>
			</p>
			<h2 class="c8" id="h.1v1yuxt">
				<span class="c20">Article 13. Garantie</span>
			</h2>
			<p class="c2">
				<span class="c0">
					GRANT garantit la conformit&eacute; substantielle de la
					Solution avec sa Documentation pendant une p&eacute;riode de
					vingt-quatre (24) mois &agrave; compter de la
					cr&eacute;ation du Compte Utilisateur. Dans la limite du
					droit applicable, GRANT ne garantit pas l&rsquo;exactitude
					ou la sinc&eacute;rit&eacute; du r&eacute;sultat des
					Prestations, qui d&eacute;pendent int&eacute;gralement et
					enti&egrave;rement des Donn&eacute;es fournies par
					l&rsquo;Utilisateur, et d&eacute;cline toute
					responsabilit&eacute; ou garantie &agrave; cet &eacute;gard.
					En cas de d&eacute;faut &agrave; cette conformit&eacute;, la
					r&eacute;paration ou le remplacement du composant
					d&eacute;fectueux de la Solution se fait, sans frais pour
					l&rsquo;Utilisateur, dans les m&ecirc;mes conditions que la
					Maintenance.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					GRANT garantit qu&rsquo;elle dispose des autorisations et
					droits de propri&eacute;t&eacute; intellectuelle sur la
					Solution. En cons&eacute;quence, GRANT accepte de
					d&eacute;fendre et d&#39;indemniser l&rsquo;Utilisateur pour
					les dommages li&eacute;s &agrave; des r&eacute;clamations,
					poursuites ou condamnations, intent&eacute;s par un tiers
					all&eacute;guant que la Solution contrefait un droit de
					propri&eacute;t&eacute; intellectuelle, sous r&eacute;serve
					que l&rsquo;Utilisateur lui notifie imm&eacute;diatement par
					&eacute;crit l&#39;existence de la poursuite, fasse une
					demande pour sa d&eacute;fense, lui apporte sa totale
					coop&eacute;ration dans ladite d&eacute;fense et ne transige
					pas sans avoir au pr&eacute;alable recueilli l&#39;accord
					&eacute;crit de GRANT. La pr&eacute;sente garantie ne
					s&rsquo;applique pas aux utilisations des r&eacute;sultats
					des Prestations ni aux composants sous licence open source.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Dans la mesure o&ugrave; GRANT reconna&icirc;t que le
					composant litigieux de la Solution est contrefaisant, elle
					pourra &agrave; son choix et &agrave; ses frais : (i) le
					modifier de sorte qu&rsquo;il ne soit plus contrefaisant,
					(ii) le remplacer par un composant non contrefaisant aux
					fonctionnalit&eacute;s globalement &eacute;quivalentes ou
					(iii) obtenir les droits d&rsquo;utilisation pour que
					l&rsquo;Utilisateur puisse continuer &agrave; exploiter la
					Solution conform&eacute;ment aux termes des CGUA. A
					d&eacute;faut, GRANT pourra prononcer la r&eacute;siliation
					des CGUA et proc&eacute;dera au remboursement de tout
					montant pay&eacute; &agrave; l&rsquo;avance par
					l&rsquo;Utilisateur pour la dur&eacute;e en cours restant
					&agrave; courir &agrave; compter de la date effective de
					r&eacute;siliation, &agrave; titre de compensation
					exclusive.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					De son c&ocirc;t&eacute;, l&rsquo;Utilisateur apporte
					&agrave; GRANT la m&ecirc;me garantie sur son utilisation de
					la Solution et des r&eacute;sultats des Prestations.
					L&rsquo;Utilisateur tient GRANT indemne contre toute
					r&eacute;clamation, poursuite ou condamnation
					r&eacute;sultant des all&eacute;gations d&rsquo;un tiers
					fond&eacute;e sur une utilisation illicite, trompeuse ou
					plus g&eacute;n&eacute;ralement pr&eacute;judiciable des
					r&eacute;sultats des Prestations ou sur une atteinte
					&agrave; des droits de propri&eacute;t&eacute;
					intellectuelle, sur des faits ou all&eacute;gations de
					blanchiment, de corruption, de financement du terrorisme, de
					concurrence d&eacute;loyale, d&rsquo;atteinte &agrave; la
					vie priv&eacute;e, &agrave; la protection des
					donn&eacute;es, au secret des affaires et &agrave; toute
					obligation contractuelle, l&eacute;gale ou
					r&egrave;glementaire de confidentialit&eacute; en raison de
					l&rsquo;utilisation par l&rsquo;Utilisateur de la Solution
					et/ou des r&eacute;sultats des Prestations.
				</span>
			</p>
			<p class="c2 c15">
				<span class="c0">
					Dans la limite du droit applicable, GRANT exclut toute
					garantie autre que celles sp&eacute;cifi&eacute;es
					ci-dessus. En particulier, GRANT ne garantit pas (i) que la
					Solution ou le Site soient compatibles avec le
					syst&egrave;me d&rsquo;information de l&rsquo;Utilisateur
					et/ou le formatage des Donn&eacute;es de l&rsquo;Utilisateur
					de mani&egrave;re permanente, GRANT n&#39;ayant pas
					contr&ocirc;le sur ceux-ci, ce que l&rsquo;Utilisateur
					reconna&icirc;t, et (ii) que la Solution sera exempte
					d&#39;erreurs, ce qui conduit GRANT &agrave; proposer la
					Maintenance dont le prix est compris dans les Frais de
					Transaction. GRANT exclut toute garantie pour les composants
					open source int&eacute;gr&eacute;s ou utilis&eacute;s en
					conjonction avec la Solution, qui, le cas
					&eacute;ch&eacute;ant, sont li&eacute;s par leurs licences
					open source respectives.
				</span>
			</p>
			<h2 class="c8" id="h.4f1mdlm">
				<span class="c20">Article 14. Responsabilit&eacute;</span>
			</h2>
			<p class="c2 c15">
				<span class="c0">
					La Solution est fournie dans le cadre d&rsquo;une obligation
					g&eacute;n&eacute;rale de moyens. GRANT met fin &agrave;
					toute indisponibilit&eacute; de la Solution dans des
					d&eacute;lais commercialement raisonnables et ne peut
					&ecirc;tre tenue responsable en cas de d&eacute;faillance
					des r&eacute;seaux de communications &eacute;lectroniques,
					de ralentissement ou d&rsquo;engorgement du r&eacute;seau
					internet ou du r&eacute;seau de l&rsquo;Utilisateur, de
					contamination par un virus informatique, d&rsquo;acte de
					piratage, d&rsquo;intrusion frauduleuse ou
					d&rsquo;acc&egrave;s ou d&rsquo;intervention non
					autoris&eacute; d&rsquo;un tiers, et plus
					g&eacute;n&eacute;ralement de tout acte volontaire de
					d&eacute;gradation, malveillance, sabotage ou
					d&eacute;t&eacute;rioration ou devant tout autre cas de
					force majeure impactant GRANT ou ses sous-traitants.
				</span>
			</p>
			<p class="c2 c15">
				<span class="c0">
					Chacune des Parties assure sa responsabilit&eacute; selon le
					droit commun. En tant qu&rsquo;APSP, GRANT peut fournir les
					Prestations en reposant l&rsquo;&eacute;change de Grants sur
					les services de LEMONWAY, sous la responsabilit&eacute; de
					LEMONWAY.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					De convention expresse entre les Parties et dans la limite
					du droit applicable, la responsabilit&eacute; encourue par
					GRANT en cas de manquement &agrave; ses obligations
					prouv&eacute; par l&rsquo;Utilisateur, tous dommages
					confondus, est limit&eacute;e &agrave; un montant
					&eacute;gal aux Frais de Transaction effectivement
					encaiss&eacute;s lors des six (6) mois
					pr&eacute;c&eacute;dant la date de survenance du dommage ou,
					si l&rsquo;Utilisateur est inscrit depuis moins de six (6)
					mois, au montant des Frais de Transaction effectivement
					encaiss&eacute;s par GRANT pour la p&eacute;riode
					contractuelle &eacute;coul&eacute;e &agrave; la date de
					survenance du dommage. La pr&eacute;sente limitation est
					stipul&eacute;e au regard des prix et redevances consentis
					et fait partie de l&rsquo;&eacute;quilibre &eacute;conomique
					des CGUA.
				</span>
			</p>
			<p class="c2 c15">
				<span>
					En tout &eacute;tat de cause, l&rsquo;Utilisateur est seul
					responsable de l&rsquo;exactitude, de la
					sinc&eacute;rit&eacute; et de la pertinence des
					Donn&eacute;es transmises &agrave; GRANT ainsi que de son
					utilisation des Prestations et de la Solution et
					s&rsquo;engage &agrave; ne pas faire des Prestations et/ou
					de la Solution un usage contraire au droit applicable,
					notamment au droit relatif &agrave; la concurrence
					d&eacute;loyale, au secret des affaires et aux r&egrave;gles
					sp&eacute;cifiques &agrave; son secteur
					d&rsquo;activit&eacute;, ni &agrave; apporter son concours
					&agrave; un tel usage.{" "}
				</span>
				<span class="c22">
					L&rsquo;Utilisateur reconna&icirc;t et accepte qu&rsquo;il
					effectue ses propres sauvegardes des Donn&eacute;es
					qu&rsquo;il charge sur son Compte Utilisateur et GRANT
					d&eacute;cline toute responsabilit&eacute; en cas de perte
					des Donn&eacute;es dupliqu&eacute;es sur celui-ci.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					De m&ecirc;me, GRANT ne peut &ecirc;tre tenue en aucun cas
					responsable des dommages indirects qui seraient subis par
					l&rsquo;Utilisateur ou les tiers (incluant notamment toute
					perte d&rsquo;exploitation, de client&egrave;le, de
					prospect, perte commerciale ou financi&egrave;re,
					pr&eacute;judice d&rsquo;image, manque &agrave; gagner,
					etc.) cons&eacute;cutifs &agrave; la fourniture des
					Prestations et/ou &agrave; l&rsquo;utilisation de la
					Solution et des r&eacute;sultats de la Prestation,
					m&ecirc;me si GRANT a &eacute;t&eacute; pr&eacute;venue ou
					pouvait raisonnablement pr&eacute;voir lesdits dommages.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Il appartient en outre &agrave; chacune des Parties de
					souscrire les polices d&rsquo;assurances n&eacute;cessaires
					&agrave; son activit&eacute; et ses produits. Il est
					rappel&eacute; que la responsabilit&eacute; contractuelle de
					GRANT ne peut &ecirc;tre engag&eacute;e si un dommage
					survient en cas de manquement aux articles
					&laquo;&nbsp;Restrictions&nbsp;&raquo; et
					&laquo;&nbsp;Exclusions de Maintenance&nbsp;&raquo; des
					CGUA. De m&ecirc;me, en aucun cas la responsabilit&eacute;
					de GRANT n&rsquo;est engag&eacute;e en cas de demande
					d&rsquo;interruption temporaire ou d&eacute;finitive de la
					Solution, du Site ou des Prestations &eacute;manant
					d&rsquo;une autorit&eacute; administrative ou judiciaire, et
					plus g&eacute;n&eacute;ralement en cas de manquement, de
					n&eacute;gligence ou de faute intentionnelle de
					l&rsquo;Utilisateur ou en cas d&rsquo;intervention
					d&rsquo;un tiers non plac&eacute; sous le contr&ocirc;le de
					GRANT.
				</span>
			</p>
			<h2 class="c8" id="h.2u6wntf">
				<span class="c20">Article 15. R&eacute;siliation</span>
			</h2>
			<p class="c2 c15">
				<span class="c0">
					L&rsquo;Utilisateur peut mettre fin aux CGUA &agrave; tout
					moment.
				</span>
			</p>
			<h3 class="c5" id="h.19c6y18">
				<span class="c9">15.1 R&eacute;siliation pour manquement</span>
			</h3>
			<p class="c2">
				<span class="c0">
					En cas de manquement de l&rsquo;Utilisateur aux articles
					&quot;Conditions de fourniture des Prestations&quot;,
					&quot;Licence d&rsquo;utilisation de la Solution&quot;,
					&quot;Restrictions&quot;, &quot;Maintenance&quot; et
					&quot;Conditions financi&egrave;res&quot; des CGUA ainsi que
					dans le cas o&ugrave; l&rsquo;Utilisateur porte atteinte aux
					droits de propri&eacute;t&eacute; intellectuelle de GRANT,
					GRANT se r&eacute;serve le droit de suspendre les
					Prestations et/ou la licence d&rsquo;utilisation de la
					Solution, et de r&eacute;silier les CGUA sans pr&eacute;avis
					ni compensation, du seul fait de l&rsquo;inex&eacute;cution,
					r&eacute;put&eacute;e suffisamment grave par les Parties, ce
					que l&rsquo;Utilisateur reconna&icirc;t.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Pour tout autre manquement aux CGUA, la Partie non fautive
					adresse une mise en demeure par lettre recommand&eacute;e
					avec accus&eacute; de r&eacute;ception &agrave; la Partie
					fautive mentionnant la pr&eacute;sente clause
					r&eacute;solutoire, le ou les manquements invoqu&eacute;s
					ainsi que l&rsquo;indication d&rsquo;un d&eacute;lai de
					trente (30) jours &agrave; compter de la r&eacute;ception de
					la mise en demeure pour que la Partie fautive se mette en
					conformit&eacute;, &agrave; d&eacute;faut de quoi la
					r&eacute;siliation sera acquise. &Agrave; d&eacute;faut de
					mise en conformit&eacute; &agrave; l&rsquo;issue du
					d&eacute;lai, les CGUA sont r&eacute;put&eacute;es
					r&eacute;sili&eacute;es de plein droit et sans
					indemnit&eacute;s, sur simple notification par la Partie non
					fautive &agrave; la Partie fautive par tous moyens.
				</span>
			</p>
			<h3 class="c5" id="h.3tbugp1">
				<span class="c9">
					15.2. Cons&eacute;quences de la r&eacute;siliation
				</span>
			</h3>
			<p class="c2">
				<span class="c0">
					En cas de r&eacute;siliation des CGUA pour manquement de
					l&rsquo;Utilisateur, l&rsquo;ensemble des sommes
					pay&eacute;es restent acquises &agrave; GRANT et/ou aux
					R&eacute;cipiendaires. La r&eacute;siliation prend effet
					apr&egrave;s que le solde du Compte Utilisateur dont
					l&rsquo;Utilisateur est &eacute;ventuellement
					cr&eacute;dit&eacute; lui soit vers&eacute; sur son compte
					bancaire, si n&eacute;cessaire apr&egrave;s le passage de
					Proc&eacute;dures KYC.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					L&rsquo;Utilisateur devra cesser d&rsquo;utiliser la
					Solution en relation avec ses Donn&eacute;es et notamment,
					cesser d&rsquo;utiliser les r&eacute;sultats des Prestations
					sur tout support de communication ou de promotion ou
					document comptable. Il ne pourra plus &eacute;mettre de
					Grants, ni scanner de Grants ou encaisser leurs montants.
					Sur demande, GRANT s&rsquo;engage &agrave; adresser une
					copie de l&rsquo;ensemble des Donn&eacute;es en format
					standard dans leur dernier &eacute;tat &agrave; la date de
					la demande. GRANT s&rsquo;engage &agrave; ne conserver
					aucune copie des Donn&eacute;es &agrave; compter du terme du
					CGUA en dehors de ses obligations l&eacute;gales.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Les Parties reconnaissent express&eacute;ment que les
					conditions des CGUA qui, par leur nature, continuent de
					s&rsquo;appliquer apr&egrave;s son expiration ou sa
					r&eacute;siliation, resteront en vigueur et notamment les
					stipulations des articles &quot;D&eacute;finitions&quot;,
					&quot;Conditions financi&egrave;res&quot;,
					&quot;Garantie&quot;, &quot;Responsabilit&eacute;&quot;,
					&quot;Confidentialit&eacute;&quot; et &quot;Divers&quot;.
				</span>
			</p>
			<h2 class="c8" id="h.28h4qwu">
				<span class="c20">Article 16. Confidentialit&eacute;</span>
			</h2>
			<p class="c2 c15">
				<span class="c0">
					En tant qu&rsquo;APSP, GRANT est assimil&eacute; &agrave; un
					employ&eacute; de son mandataire LEMONWAY (le prestataire de
					services de paiement) et doit respecter en
					cons&eacute;quence la m&ecirc;me obligation de secret
					professionnel sur les transactions et les donn&eacute;es
					relatives aux transactions r&eacute;alis&eacute;es par
					l&rsquo;&eacute;mission et l&rsquo;encaissement des Grants.
					La Solution, les Prestations, les Donn&eacute;es (notamment
					les donn&eacute;es de paiement) et les Grants de chaque
					Utilisateur constituent pour les Parties des informations
					confidentielles.
				</span>
			</p>
			<p class="c2 c15">
				<span class="c22">
					GRANT peut communiquer les informations confidentielles
					&agrave; ses prestataires sous-traitants dans le respect du
					droit applicable et de la pr&eacute;sente obligation
					confidentielle. GRANT conclut &agrave; ce titre tout accord
					de confidentialit&eacute; appropri&eacute; avec ces
					prestataires avant toute transmission.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Chacune des Parties s&rsquo;oblige &agrave; (i) tenir
					confidentielles toutes les informations qu&rsquo;elle
					recevra de l&rsquo;autre Partie, (ii) ne pas divulguer les
					informations confidentielles de l&rsquo;autre Partie
					&agrave; un tiers quelconque et &agrave; (iii)
					n&rsquo;utiliser les informations confidentielles de
					l&rsquo;autre Partie qu&rsquo;&agrave; l&rsquo;effet
					d&rsquo;ex&eacute;cuter ses obligations aux termes des CGUA.
					L&rsquo;engagement de confidentialit&eacute; d&rsquo;une
					Partie dure tant que l&rsquo;autre Partie entend maintenir
					ses informations confidentielles, sauf en cas de demande de
					transmission &eacute;manant d&rsquo;une autorit&eacute;
					l&eacute;gale ou judiciaire.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Nonobstant ce qui pr&eacute;c&egrave;de, aucune des Parties
					n&#39;aura d&#39;obligation concernant les informations qui
					(i) sont tomb&eacute;es dans le domaine public, ou (ii) ont
					&eacute;t&eacute; d&eacute;velopp&eacute;es de
					mani&egrave;re ind&eacute;pendante par la Partie qui les
					re&ccedil;oit, ou (iii) &eacute;taient connues de la Partie
					sans obligation de confidentialit&eacute; avant leur
					divulgation, ou (iv) ont &eacute;t&eacute;
					l&eacute;gitimement re&ccedil;ues d&#39;un tiers sans
					violation de la confidentialit&eacute;, ou (v) devraient
					&ecirc;tre divulgu&eacute;es selon la loi ou sur ordre
					d&#39;un tribunal (auquel cas elles ne seront
					divulgu&eacute;es que dans la mesure requise et apr&egrave;s
					notification &eacute;crite &agrave; la Partie qui les
					fournit).
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Les obligations de confidentialit&eacute; des Parties
					restent en vigueur pendant toute la dur&eacute;e des CGUA et
					pour une p&eacute;riode de cinq (5) ans apr&egrave;s la fin
					des CGUA. &Agrave; la fin des CGUA, chaque Partie restitue
					toutes les copies des documents et supports contenant des
					informations confidentielles de l&#39;autre Partie, sauf
					obligation l&eacute;gale de conservation d&ucirc;ment
					notifi&eacute;e &agrave; la Partie &agrave; qui elles
					appartiennent.
				</span>
			</p>
			<h2 class="c8" id="h.nmf14n">
				<span class="c20">
					Article 17. Protection des Donn&eacute;es Personnelles
				</span>
			</h2>
			<p class="c2 c15">
				<span class="c0">
					Des Donn&eacute;es Personnelles sont collect&eacute;es par
					GRANT et sont trait&eacute;es dans le but de vous permettre
					d&rsquo;acc&eacute;der &agrave; et d&rsquo;utiliser la
					Solution, soit directement lorsque la personne
					concern&eacute;e utilise la Solution depuis les Applications
					ou les pages de renvoi vers la Solution, soit indirectement
					lorsqu&rsquo;un Utilisateur transmet des Donn&eacute;es
					Personnelles sur les pages de renvoi vers la Solution ou sur
					la Solution.
				</span>
			</p>
			<ul class="c1 lst-kix_list_5-0 start">
				<li class="c2 c7 li-bullet-0">
					<span>
						GRANT est une soci&eacute;t&eacute; respectueuse de la
						vie priv&eacute;e de ses Utilisateurs. &Agrave; ce
						titre, elle respecte ses obligations au titre de la
						protection des Donn&eacute;es Personnelles et a
						&eacute;tabli une{" "}
					</span>
					<span class="c14 c18">
						<a
							class="c12"
							href="https://www.google.com/url?q=https://joingrant.com/donnees-personnelles/&amp;sa=D&amp;source=editors&amp;ust=1679074681261418&amp;usg=AOvVaw3iJgrXYpXDVh4FzCVE99UO"
						>
							politique de confidentialit&eacute;
						</a>
					</span>
					<span class="c0">
						&nbsp;d&eacute;di&eacute;e aux fins d&rsquo;informer les
						personnes concern&eacute;es. Cette politique de
						confidentialit&eacute; est jointe en Annexe 1 des CGUA
						et fait partie des CGUA.
					</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span class="c0">
						Conform&eacute;ment &agrave; l&rsquo;article L. 561-12
						du Code mon&eacute;taire et financier, GRANT et son
						mandant LEMONWAY peuvent &ecirc;tre contraints de
						conserver les documents relatifs aux Grants
						&eacute;chang&eacute;s par les Utilisateurs pendant une
						p&eacute;riode de cinq ans &agrave; compter de la
						r&eacute;siliation ou de l&rsquo;expiration des CGUA,
						quelle qu&rsquo;en soit la raison.
					</span>
				</li>
			</ul>
			<p class="c2">
				<span class="c0">
					GRANT conserve l&rsquo;historique des connexions des
					Utilisateurs aux Applications. &nbsp;En outre, GRANT pourra
					suivre la navigation des Utilisateurs au sein de chaque
					Application et des pages de renvoi vers la Solution
					gr&acirc;ce &agrave; des cookies. Lorsque vous
					acc&eacute;dez et utilisez la Solution via ces
					derni&egrave;res, des cookies sont install&eacute;s sur
					l&#39;appareil que vous utilisez pour naviguer.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Un cookie est un fichier texte
					t&eacute;l&eacute;charg&eacute; sur votre appareil lors de
					votre navigation. Son but est de collecter des informations
					relatives &agrave; votre utilisation de la Solution et de
					vous fournir les fonctionnalit&eacute;s de la Solution et/ou
					une meilleure exp&eacute;rience utilisateur de la Solution
					en &eacute;changeant des informations entre GRANT et votre
					&eacute;quipement terminal. Certains cookies sont de nature
					temporaire et limit&eacute;s &agrave; une session de
					navigation ; ils sont tous supprim&eacute;s lorsque vous
					fermez la fen&ecirc;tre du navigateur. Certains cookies
					restent sur votre appareil pendant un certain temps
					apr&egrave;s la fermeture de la session de votre navigateur,
					durant lequel ils seront activ&eacute;s chaque fois que vous
					utiliserez la Solution.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Certains cookies sont n&eacute;cessaires &agrave; la
					fourniture de la Solution&nbsp;; d&rsquo;autres sont
					optionnels.
				</span>
			</p>
			<ul class="c1 lst-kix_list_1-0 start">
				<li class="c2 c7 li-bullet-0">
					<span class="c0">
						GRANT vous informe et vous donne l&rsquo;occasion
						d&rsquo;accepter ou de refuser les cookies optionnels
						dans un bandeau d&rsquo;information lors de votre
						premi&egrave;re visite sur les pages de renvoi vers la
						Solution ou dans les Applications.
					</span>
				</li>
				<li class="c2 c7 li-bullet-0">
					<span>Vous pouvez &eacute;galement consulter sa </span>
					<span class="c14 c18">
						<a
							class="c12"
							href="https://www.google.com/url?q=https://joingrant.com/cookies/&amp;sa=D&amp;source=editors&amp;ust=1679074681264252&amp;usg=AOvVaw13RdWH1nn0EgiIYO80sU17"
						>
							politique de gestion des cookies
						</a>
					</span>
					<span class="c0">
						&nbsp;d&eacute;di&eacute;e pour plus d&rsquo;information
						sur les cookies utilis&eacute;s. Cette politique cookies
						constitue l&rsquo;Annexe 2 des pr&eacute;sentes CGUA et
						fait partie des CGUA.
					</span>
				</li>
			</ul>
			<h2 class="c8" id="h.37m2jsg">
				<span class="c20">Article 18. Divers</span>
			</h2>
			<p class="c2">
				<span class="c0">
					Les CGUA constituent l&rsquo;int&eacute;gralit&eacute; de
					l&rsquo;accord entre les Parties relatif &agrave; la
					fourniture des Prestations et &agrave; l&rsquo;utilisation
					de la Solution. &Agrave; ce titre, les CGUA annulent et
					remplacent tous documents ant&eacute;rieurs conclus entre
					elles &agrave; cet &eacute;gard.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Les Parties sont r&eacute;put&eacute;es &ecirc;tre des
					contractants ind&eacute;pendants et aucune Partie ne sera
					r&eacute;put&eacute;e &ecirc;tre l&rsquo;employ&eacute;e ou
					l&rsquo;agent de l&rsquo;autre. Rien dans les CGUA
					n&rsquo;est cens&eacute; ou ne peut &ecirc;tre
					interpr&eacute;t&eacute; comme &eacute;tablissant un
					partenariat ou une joint-venture entre les Parties ni comme
					instituant une partie comme repr&eacute;sentante ou
					mandataire de l&rsquo;autre Partie.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					GRANT peut sous-traiter tout ou partie de ses Prestations
					(notamment &agrave; son prestataire
					d&rsquo;h&eacute;bergement) mais demeure responsable de
					l&rsquo;ensemble des prestations sous-trait&eacute;es par
					ses soins. L&rsquo;Utilisateur ne peut c&eacute;der ou
					transf&eacute;rer son Compte Utilisateur &agrave; un tiers
					en aucune mani&egrave;re sans l&rsquo;accord expr&egrave;s
					de GRANT.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Dans l&#39;hypoth&egrave;se o&ugrave; une ou plusieurs
					stipulations des pr&eacute;sentes seraient
					consid&eacute;r&eacute;es nulles, inapplicables ou
					inopposables par toute juridiction comp&eacute;tente, les
					autres stipulations des pr&eacute;sentes resteront valables
					sauf disposition contraire de ladite juridiction. Les
					Parties conviennent qu&rsquo;en telle hypoth&egrave;se,
					elles n&eacute;gocieront de bonne foi des stipulations de
					remplacement qui seront conformes &agrave; l&#39;intention
					initiale des Parties.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					La responsabilit&eacute; de GRANT ne pourra &ecirc;tre
					engag&eacute;e pour une cause dont le fait
					g&eacute;n&eacute;rateur a pour origine un cas de force
					majeure, y compris si un tel cas de force majeure touche un
					de ses sous-traitants, ainsi que pour tout
					&eacute;v&egrave;nement en dehors de son contr&ocirc;le
					exclusif. En cas de force majeure, les obligations des
					Parties seront suspendues pendant la dur&eacute;e de cette
					cause. De fa&ccedil;on expresse, sont
					consid&eacute;r&eacute;s comme cas de force majeure ceux
					habituellement retenus par la jurisprudence des cours et
					tribunaux fran&ccedil;ais. Dans un premier temps, les cas de
					force majeure suspendront l&#39;ex&eacute;cution des CGUA.
					Si le cas de force majeure se prolonge pendant plus de trois
					(3) mois, les pr&eacute;sentes CGUA seront
					r&eacute;sili&eacute;es sur notification par lettre
					recommand&eacute;e avec accus&eacute; de r&eacute;ception
					adress&eacute;e par l&rsquo;une des Parties, sauf accord
					contraire entre les Parties.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Les collaborateurs de GRANT affect&eacute;s &agrave;
					l&rsquo;ex&eacute;cution des CGUA demeurent sous la
					responsabilit&eacute; enti&egrave;re et exclusive de GRANT
					seule habilit&eacute;e &agrave; leur adresser des
					directives. GRANT s&rsquo;engage &agrave; fournir &agrave;
					l&rsquo;Utilisateur, sur demande de ce dernier, les
					informations et attestations requises par la loi en
					mati&egrave;re sociale et fiscale.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Le fait pour l&#39;une des Parties de ne pas se
					pr&eacute;valoir d&#39;un engagement par l&#39;autre Partie
					&agrave; l&#39;une quelconque des obligations vis&eacute;es
					par les pr&eacute;sentes, ne saurait &ecirc;tre
					interpr&eacute;t&eacute; pour l&#39;avenir comme une
					renonciation &agrave; l&#39;obligation en cause.
				</span>
			</p>
			<h2 class="c8" id="h.1mrcu09">
				<span class="c20">
					Article 19. R&eacute;clamation - M&eacute;diation
				</span>
			</h2>
			<p class="c2">
				<span class="c0">
					En cas de litige, l&rsquo;Utilisateur s&rsquo;engage
					&agrave; contacter en priorit&eacute; GRANT afin de tenter
					de r&eacute;soudre &agrave; l&rsquo;amiable tout
					diff&eacute;rend susceptible d&rsquo;intervenir concernant
					l&rsquo;ex&eacute;cution des CGUA et la fourniture des
					Prestations.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					&Agrave; d&eacute;faut d&rsquo;accord amiable ou en
					l&rsquo;absence de r&eacute;ponse de GRANT dans un
					d&eacute;lai raisonnable d&rsquo;un (1) mois,
					l&rsquo;Utilisateur consommateur au sens de l&rsquo;article
					liminaire du code de la consommation a la possibilit&eacute;
					de saisir gratuitement, si un d&eacute;saccord subsiste, un
					m&eacute;diateur inscrit sur la liste des m&eacute;diateurs
					&eacute;tablie par la Commission d&rsquo;&eacute;valuation
					et de contr&ocirc;le de la m&eacute;diation de la
					consommation en application de l&rsquo;article L.&nbsp;615-1
					du code de la consommation, &agrave; savoir :
				</span>
			</p>
			<ul class="c1 lst-kix_list_12-0 start">
				<li class="c6 li-bullet-0">
					<span class="c0">
						Le M&eacute;diateur de l&rsquo;AFEPAME, association
						AFEPAME, 36 rue Taitbout 75009 Paris.
					</span>
				</li>
			</ul>
			<p class="c2">
				<span class="c0">
					Le m&eacute;diateur est charg&eacute; de recommander des
					solutions au litige et statue dans un d&eacute;lai de deux
					mois &agrave; compter de sa saisine. La proc&eacute;dure de
					m&eacute;diation est gratuite pour l&rsquo;Utilisateur qui
					conserve cependant la charge de ses propres frais, notamment
					ses frais de d&eacute;placement ou frais li&eacute;s
					&agrave; la r&eacute;mun&eacute;ration du conseil
					qu&rsquo;il choisirait de s&rsquo;adjoindre.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Par ailleurs, l&rsquo;Utilisateur que la d&eacute;cision du
					m&eacute;diateur ne satisferait pas peut saisir la
					juridiction comp&eacute;tente &agrave; l&rsquo;issue de la
					proc&eacute;dure de m&eacute;diation dans les conditions
					pr&eacute;vues &agrave; l&rsquo;article &quot;Droit et
					juridiction applicables&quot;. Les constatations et les
					d&eacute;clarations que le m&eacute;diateur recueillent ne
					peuvent &ecirc;tre ni produites, ni invoqu&eacute;es dans la
					suite de la proc&eacute;dure sans l&rsquo;accord des
					Parties.
				</span>
			</p>
			<p class="c42">
				<span>
					Pour toute r&eacute;clamation qui rel&egrave;verait du bon
					fonctionnement du syst&egrave;me de paiement mis en
					&oelig;uvre par LEMONWAY et utilis&eacute; par la Solution,
					l&rsquo;Utilisateur peut prendre contact directement avec
					LEMONWAY pour effectuer une r&eacute;clamation
					conform&eacute;ment &agrave; ses conditions
					g&eacute;n&eacute;rales disponibles{" "}
				</span>
				<span class="c14 c3">
					<a
						class="c12"
						href="https://www.google.com/url?q=https://www.lemonway.com/conditions-generales-dutilisation/&amp;sa=D&amp;source=editors&amp;ust=1679074681272673&amp;usg=AOvVaw0SPP5xq14vs7X63Pmm16-8"
					>
						ici
					</a>
				</span>
				<span>
					, notamment en ce qui concerne les conditions de
					d&eacute;lais{" "}
				</span>
				<span class="c14 c3">
					<a
						class="c12"
						href="https://www.google.com/url?q=https://www.lemonway.com/conditions-generales-dutilisation/&amp;sa=D&amp;source=editors&amp;ust=1679074681273315&amp;usg=AOvVaw0fD_o8Aylh1-OUfrulbrW0"
					>
						ici
					</a>
				</span>
				<span class="c0">.</span>
			</p>
			<h2 class="c8" id="h.46r0co2">
				<span class="c20">
					Article 20. Droit et juridiction applicables
				</span>
			</h2>
			<p class="c2">
				<span class="c0">
					Les pr&eacute;sentes CGUA sont r&eacute;gies par le droit
					fran&ccedil;ais.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Les Parties font leurs meilleurs efforts pour parvenir
					&agrave; une r&eacute;solution amiable de tout litige
					caus&eacute; ou en relation avec les CGUA. Si les Parties
					&eacute;chouent &agrave; n&eacute;gocier un accord &agrave;
					l&rsquo;amiable permettant de solutionner le
					diff&eacute;rend en cause dans les conditions de
					m&eacute;diation pr&eacute;vues &agrave; l&rsquo;article
					&laquo;&nbsp;M&eacute;diation&nbsp;&raquo; des
					pr&eacute;sentes CGUA, chaque Partie reprend sa
					libert&eacute; d&rsquo;action.
				</span>
			</p>
			<p class="c2">
				<span class="c3">
					En cas d&rsquo;&eacute;chec de la m&eacute;diation et sauf
					en cas d&rsquo;application d&rsquo;une r&egrave;gle
					d&rsquo;ordre public, tout litige relatif aux CGUA et
					&agrave; l&rsquo;utilisation de la Solution est soumis
					&agrave; la comp&eacute;tence exclusive des tribunaux du
					ressort de la cour d&rsquo;appel de Paris, m&ecirc;me en cas
					de pluralit&eacute; de d&eacute;fendeurs, d&rsquo;appel en
					garantie, de proc&eacute;dures conservatoires, de
					r&eacute;f&eacute;r&eacute; ou de requ&ecirc;te.
				</span>
			</p>
			<p class="c2">
				<span class="c0">
					Les CGUA contiennent trois Annexes&nbsp;:
				</span>
			</p>
			<p class="c2">
				<span class="c18">Annexe 1. </span>
				<span class="c14 c18">
					<a
						class="c12"
						href="https://www.google.com/url?q=https://joingrant.com/donnees-personnelles/&amp;sa=D&amp;source=editors&amp;ust=1679074681279680&amp;usg=AOvVaw1QfSNgd8dUENbhVSuRbkV5"
					>
						Politique de confidentialit&eacute;
					</a>
				</span>
			</p>
			<p class="c2">
				<span class="c18">Annexe 2. </span>
				<span class="c14 c18">
					<a
						class="c12"
						href="https://www.google.com/url?q=https://joingrant.com/cookies/&amp;sa=D&amp;source=editors&amp;ust=1679074681280487&amp;usg=AOvVaw0JT0UHGLL_hv87M4pLNvtm"
					>
						Politique cookies
					</a>
				</span>
			</p>
			<p class="c2">
				<span class="c18">Annexe 3. </span>
				<span class="c18 c29">Documentation</span>
			</p>
			<p class="c2 c11">
				<span class="c0"></span>
			</p>
			<p class="c2 c11">
				<span class="c0"></span>
			</p>
			<p class="c2 c11">
				<span class="c0"></span>
			</p>
			<p class="c2 c11">
				<span class="c0"></span>
			</p>
			<p class="c2 c11">
				<span class="c0"></span>
			</p>
			<p class="c2 c11">
				<span class="c0"></span>
			</p>
			<p class="c2 c11">
				<span class="c0"></span>
			</p>
			<div>
				<p class="c11 c33">
					<span class="c0"></span>
				</p>
				<p class="c11 c38">
					<span class="c21"></span>
				</p>
			</div>
		</div>
	);
};

export default Content;
