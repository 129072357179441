import { useContext } from "react";
import withLayout from "../withLayout";

import { TranslationsContext } from "../../services/intl";
import Button from "../../components/Button";

const RequestDelete = () => {
	const Locale = useContext(TranslationsContext);

	return (
		<>
			<div className="flex flex-col mt-5">
				<h1 className="mb-4">{Locale.get("request_delete")}</h1>
				<p className="text-slate-400 mb-5">
					{Locale.get("request_delete_text")}
				</p>
				<p className="text-slate-400 mb-8">
					{Locale.get("request_delete_privacy")}
				</p>
				<div className="flex flex-row">
					<Button
						to={
							"https://wa.me/41787400957?text=Requesting%20to%20delete%20my%20Grant%20account%2C%20here%20are%20my%20account%20details%3A"
						}
					>
						{Locale.get("request_delete")}
					</Button>
				</div>
			</div>
		</>
	);
};

export default withLayout(RequestDelete);
