import { useContext } from "react";
import withLayout from "../../withLayout";

import { TranslationsContext } from "../../../services/intl";
import ContentEn from "./en";
import ContentFr from "./fr";

const Home = () => {
	const Locale = useContext(TranslationsContext);
	const Content = Locale.getLocale() === "fr" ? ContentFr : ContentEn;
	return <Content />;
};

export default withLayout(Home);
