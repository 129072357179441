import Section from "../../components/Section";
import Button from "../../components/Button";
import Highlight from "../../components/Highlight";
import ListInfo from "./ListInfo";

const Tutorial = () => {
	return (
		<>
			<Section>
				<h1>Comment ça marche ?</h1>
				<br />
				<br />
				<h6 className="mb-4">Qui est Grant Solutions ?</h6>
				<p className="mb-4">
					Grant Solutions est une société française qui développe{" "}
					<Highlight>grant</Highlight> , une application de notation
					et de rémunération, dont la mission est de faciliter la
					transmission des pourboires et de dons entre clients
					(donateurs) et prestataires de services (bénéficiaires).
					C'est une solution unique car MONDIALE et sans barrières
					monétaires.
				</p>
				<p className="mb-4">
					<Highlight>grant</Highlight> permet aux professionnels
					(indépendants et employés) d'être mieux récompensés pour
					leurs services. Dans un monde où le numéraire tend à
					disparaître, grant est une application intuitive permettant
					la collecte digitale de gratifications, vous offrant ainsi
					de maintenir et d'augmenter vos revenus annexes.
				</p>
			</Section>
			<Section alt>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-12">
					<div className="">
						<h6 className="mb-4">
							Pourquoi <Highlight>grant</Highlight> ?
						</h6>
						<ListInfo
							items={{
								"Gratification instantanée": () => (
									<p>
										Créez votre code QR code unique et
										laissez les clients vous donner leur
										sentiment sur votre service et un
										pourboire en quelques secondes.
									</p>
								),
								" Virements rapides": () => (
									<p>
										Surveillez vos gains en temps réel dans
										l'application. Nos virements bancaires
										garantissent que vos pourboires soient
										toujours transférables de banque à
										banque, selon vos désirs, 24/7.
									</p>
								),
								"C'est mondial !": () => (
									<p>
										Gagnez des pourboires partout dans le
										monde, quel que soit le pays d'origine
										de vos clients ou quel que soit
										l'endroit où vous êtes.
									</p>
								),
								"Transactions sécurisées et privées": () => (
									<p>
										Votre nom, votre numéro de téléphone ou
										d'autres données personnelles ne sont
										pas partagés avec les clients.
									</p>
								),
								"Croissance professionnelle": () => (
									<p>
										<Highlight>grant</Highlight> est plus
										qu'une application financière ; c'est
										une source de motivation pour atteindre
										l'excellence du service, offrant des
										récompenses pour votre dévouement et
										votre travail acharné.
									</p>
								),
							}}
						/>
					</div>
					<div className="md:mt-0 mt-4">
						<h6 className="mb-4">
							Caractéristiques uniques{" "}
							<Highlight>de grant</Highlight> :
						</h6>
						<ListInfo
							items={{
								"Configuration rapide": () => (
									<p>
										Commencez en moins d'une minute en
										fournissant votre nom et votre numéro de
										téléphone pour générer votre QR code
										unique pour un pourboire immédiat.
									</p>
								),
								"Alertes en temps réel": () => (
									<p>
										Recevez des notifications instantanément
										pour chaque transaction, vous tenant
										informé de vos revenus lors de vos
										déplacements.
									</p>
								),
								Convivial: () => (
									<p>
										<Highlight>grant</Highlight> offre une
										interface simple et facile à utiliser
										avec un didacticiel de démarrage rapide,
										garantissant que tout le monde peut
										l'utiliser sans effort.
									</p>
								),
								"Interface personnalisable": () => (
									<p>
										Adaptez <Highlight>grant aux</Highlight>{" "}
										préférences de vos clients. Choisissez
										votre langue et la couleur de votre
										application et personnalisez votre texte
										pour les demandes de gratifications
									</p>
								),
								"Frais transparents": () => (
									<p>
										L'utilisation de{" "}
										<Highlight>grant est</Highlight>{" "}
										gratuite et seuls de petits frais de
										transactions sont appliqués au moment du
										retrait.
									</p>
								),
								"Partage facile": () => (
									<p>
										Invitez vos collègues, amis et autres
										professionnels à utiliser{" "}
										<Highlight>grant</Highlight> via un
										lien.
									</p>
								),
								"Transactions sécurisées": () => (
									<p>
										Nous accordons la priorité à votre
										sécurité. <Highlight>grant</Highlight>{" "}
										est conforme au RGPD Européen et toutes
										les transactions sont réglementées par
										des institutions financières et des
										prestataires de services de paiement
										agréés, dont les sièges sont en France.
									</p>
								),
							}}
						/>
					</div>
				</div>
			</Section>
			<Section>
				<h6 className="mb-4">
					A quoi sert <Highlight>grant</Highlight>?
				</h6>
				<p className="mb-4">
					<Highlight>grant</Highlight> est une application mobile
					utilisée par tous types de prestataires de services (SP) et
					de taskeurs à travers le monde, leur permettant de «
					demander » une note sur la qualité du service fourni et
					potentiellement un pourboire, à un donateur potentiel
					inconnu ou connu en faisant simplement flasher/scanner son
					QR code de paiement , sans avoir à échanger de numéro de
					téléphone. Il s'agit d'une manière exceptionnellement
					sécurisée et confidentielle.
				</p>
				<p className="mb-4">
					Depuis <Highlight>grant</Highlight>, le SP peut créer son
					propre QR Code, en 1 minute, valable à vie et exclusif, le
					montrer à toute personne à qui il a rendu un service, et
					après avoir été flashé/scanné, recevoir une gratification
					sur la qualité du service et peut-être (certainement..)
					aussi un pourboire qui sera stocké dans son « Wallet » (un
					portefeuille virtuel stocké et sécurisé dans une banque
					mondiale de premier rang…la BNP).
				</p>
			</Section>
			<Section alt>
				<h6 className="mb-4">Terminologie</h6>
				<p className="mb-4">
					Le{" "}
					<strong className="underline">
						Prestataire ou Fourniseur ou Grantee ou Taskeur
					</strong>
					: toute personne physique et indépendante qui fournit un
					service de toute nature à une autre personne et partout dans
					le monde : service à table, livraison, portier, room
					service, voiturier, plombier, électricien, agriculteur,
					laveur de voiture, baby-sitter, etc...
				</p>
				<p className="mb-4">
					Le{" "}
					<strong className="underline">
						Donateur ou Client ou Grantor
					</strong>
					: toute personne, particulier, à qui il a été demandé
					d'attribuer une note et un pourboire/grant dématérialisés
					pour récompenser la qualité d'une prestation, fournie par un
					Prestataire et à qui il a été demandé de flasher un QR Code
					sans avoir à demander le numéro de téléphone ou le compte
					bancaire ou l'IBAN ou l'adresse du SP, etc. confidentialité
					totale assurée.
				</p>
				<p className="mb-4">
					<strong className="underline">Jar/Wallet</strong>:
					Portefeuille du Prestataire dans un espace cyber sécurisé et
					dans lequel tous ses avis et pourboires sont ajoutés et
					stockés et consultables uniquement par le bénéficiaire,
					partout sur terre, 24/7 et à vie.
				</p>
			</Section>
			<Section>
				<h1 className="mb-8">
					Si vous êtes un fournisseur de services, un grantee, un
					taskeur ou un intervenant indépendant en tous types de
					travaux et aides <Highlight>COMMENCEZ ICI</Highlight>
				</h1>
				<p className="mb-4">
					Téléchargez gratuitement <Highlight>grant</Highlight> depuis
					l'App Store ou Google Play Store ou recevez une invitation
					de quelqu'un qui l'utilise déjà.
				</p>
				<p className="mb-4">
					Créez un compte utilisateur <Highlight>grant</Highlight>.
					Cela devrait vous prendre 1 minute pour effectuer la
					première intégration : la plus importante…celle qui vous
					attribue votre QR code personnel à vie. Il y a 2
					intégrations. Vous disposez ensuite de 5 jours, une fois les
					premiers pourboires reçus, pour réaliser la deuxième
					intégration afin d'encaisser ce que vous avez dans votre
					Wallet/Portefeuille.
				</p>
				<div className="mb-4 mt-12 text-lg">
					<strong>Étape 1:</strong> installez l' application{" "}
					<Highlight>grant</Highlight>
				</div>
				<div className="flex flex-row items-center pl-8">
					<p className="mr-2">
						Obentir <Highlight>grant</Highlight> de:{" "}
					</p>
					<Button to="/download-app">Télécharger Grant</Button>
				</div>
				<div className="mb-4 mt-12 text-lg">
					<strong>Étape 2:</strong> Créer un compte utilisateur
				</div>
				<div className="pl-8 mb-4">
					<p className="">
						Pour créer un compte, vous devez lancer l'application{" "}
						<Highlight>grant</Highlight> sur votre téléphone.
						Cliquez sur <strong>« Je signe et j'accepte »</strong>{" "}
						après avoir lu nos CGUs : bonne lecture ;O)
					</p>
					<ol className="list-decimal mt-4 pl-8">
						<li className="mb-2">
							Sur le 1er écran, saisissez votre numéro de
							téléphone , clickez sur Continuer, recevez un SMS
							avec un code à 6 chiffres... alors ? non reçu?
							vérifiez votre numéro et demandez à nouveau le SMS
							ou peut-être que vous l'avez reçu sur votre
							application SMS et qu'il n'apparaît pas sur
							l'application <Highlight>grant</Highlight>. Allez le
							chercher… copiez-le et tapez-le dans{" "}
							<Highlight>grant</Highlight>
						</li>
						<li className="mb-2">
							Sur le 2ème écran, saisissez votre nom et prénom
						</li>
						<li className="mb-2">
							Confirmez la création du compte en cliquant sur
							"Suivant".
						</li>
						<li className="mb-2">
							Votre QR Code est désormais le vôtre et vous pouvez
							commencer à le faire flasher/scanner par tout le
							monde autour de vous
						</li>
					</ol>
					<p className="mb-4">
						Plus tard (MAX. 5 JOURS) ou immédiatement si vous avez
						du temps disponible, faites votre deuxième et dernier
						onboarding/intégration : Ajoutez vos identifiants + Iban
						/ RIB
					</p>
					<p className="mb-4">
						Avec <Highlight>grant</Highlight>, l'utilisateur doit
						ajouter les coordonnées de son compte bancaire depuis la
						page d'accueil de l'application pour : recevoir ses
						pourboires dans son Wallet, Transférer son argent de son
						Wallet vers son compte bancaire, d'un simple glissement
						de doigt ; Voir le solde de son pourboire dans
						l'application, Voir tous ses paiements dans un seul
						historique ; Disposez d'alertes pertinentes pour avoir
						rapidement un aperçu de l'état du compte, à tout moment
						(notifications sur votre solde, ou pour chaque opération
						effectuée)
					</p>
				</div>
				<div className="mb-4 mt-12 text-lg">
					<strong>Étape 3:</strong> Personnalisez l'environnement de{" "}
					<Highlight>grant</Highlight>
				</div>
				<div className="pl-8 mb-4">
					Ces options d'affichage sont disponibles lors de votre
					inscription ou à chaque fois que vous souhaitez présenter
					votre QR Code. « tapez » sur le QR code pour changer la
					couleur, cliquez sur la photo (dans les paramètres) pour la
					changer, cliquez sur la phrase pour choisir celle qui
					correspond à l'ambiance du moment ou à la langue.
				</div>
			</Section>
		</>
	);
};

export default Tutorial;
