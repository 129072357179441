import { useContext } from "react";
import withLayout from "../withLayout";

import { TranslationsContext } from "../../services/intl";

import AboutEn from "./en";
import AboutFr from "./fr";

const About = () => {
	const Locale = useContext(TranslationsContext);

	const Content = Locale.getLocale() === "fr" ? AboutFr : AboutEn;

	return (
		<>
			<div className="flex flex-col mt-5">
				<div className="grid grid-cols-1 md:grid-cols-3 w-full gap-0 md:gap-8">
					<div className="col-span-1">
						<img
							src="assets/img/grantoffice.jpg"
							alt="About Grant"
							className="object-cover w-full h-auto aspect-[4/3] rounded"
						/>
					</div>
					<div className="col-span-2 md:mt-0 mt-4">
						<Content />
					</div>
				</div>
			</div>
		</>
	);
};

export default withLayout(About);
