const Info = ({ items }) => {
	return (
		<table className="mt-4">
			<tbody>
				{Object.entries(items).map(([key, value]) => {
					return (
						<tr key={"key"}>
							<td className="font-bold">
								<h6>{key}</h6>
							</td>
							<td className="">{value}</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default Info;
