import { Link } from "react-router-dom";

const Button = ({ children, icon = <></>, to = "#" }) => {
	return (
		<Link to={to} className="store-button">
			{icon}
			<div className="text">{children}</div>
		</Link>
	);
};

export default Button;
