import { useContext } from "react";
import Navbar from "../../components/Navbar";
import NavRight from "../../components/NavbarRight";

import { TranslationsContext } from "../../services/intl";

/**
Businesses: 

- As a business create a centralized grant account for each of your employees to use
a single Business QR Code.
- The QR code shown to the customer, the tip will arrive on the centrilized account of 
the business account
- Business owner can split the total tips (i.e. Kitchen, Toilet cleaner etc.)

 */

const Business = () => {
	return (
		<div className="container">
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<Navbar />
				<NavRight />
			</div>
			<div className="grid">
				<div
					style={{
						height: "90vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						padding: 40,
					}}
				></div>
				<div
					style={{
						height: "90vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<h5>Coming Soon</h5>
					<h1>Grant Teams</h1>
					<br />
					<p>
						Empower your business with Tips as a Service by Grant;{" "}
					</p>
				</div>
			</div>
		</div>
	);
};

export default Business;
