import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import { TranslationsContext } from "../services/intl";

const NavItem = ({ label, to, disabled = false }) => {
	const Locale = useContext(TranslationsContext);

	return (
		<li>
			{disabled ? (
				<Link
					className="cursor-not-allowed block py-2 pl-3 pr-4 text-stone-400 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
					data-tooltip-id="not-available-tooltip"
					data-tooltip-content={Locale.get("not_available")}
					data-tooltip-place="bottom"
				>
					{label}
				</Link>
			) : (
				<Link
					to={to}
					className="block py-2 pl-3 pr-4 text-stone-400 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
					aria-current="page"
				>
					{label}
				</Link>
			)}
		</li>
	);
};

const Navbar = () => {
	const [toggle, setToggle] = useState(false);
	const Locale = useContext(TranslationsContext);

	const links = [
		{
			label: Locale.get("about"),
			to: "/about",
		},
		{
			label: Locale.get("how_works"),
			to: "/how-it-works",
		},
		{
			label: Locale.get("business"),
			to: "/business",
			disabled: true,
		},
	];

	return (
		<nav className="px-2 sm:px-4 py-2.5 rounded">
			<div className="container flex flex-wrap items-center justify-between mx-auto">
				<Link to="/" className="flex items-center">
					<img
						src="assets/icons/grant-web-logo.svg"
						className="h-8 mr-3 sm:h-9"
						alt="Grant Logo"
					/>
				</Link>
				<button
					type="button"
					className="inline-flex items-center p-2 ml-3 text-sm text-stone-400 rounded-lg md:hidden focus:outline-none"
					onClick={() => setToggle((t) => !t)}
				>
					<span className="sr-only">Open main menu</span>
					<svg
						className="w-6 h-6"
						fill="currentColor"
						viewBox="0 0 20 20"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
							clipRule="evenodd"
						></path>
					</svg>
				</button>
				<div
					className={`${
						toggle ? "" : "hidden"
					} w-full md:block md:w-auto`}
					id="navbar-default"
				>
					<ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-card md:bg-transparent md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 border-gray-700">
						{links.map((item, index) => (
							<NavItem key={`nav-item-${index}`} {...item} />
						))}
						<li
							onClick={() => Locale.toggleLanguage()}
							className="block py-2 pl-3 pr-4 text-stone-400 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
						>
							<img
								style={{ height: 18, width: "auto" }}
								src={Locale.getFlag()}
								alt="language change"
							/>
						</li>
					</ul>
				</div>
			</div>
			<Tooltip id="not-available-tooltip" />
		</nav>
	);
};

export default Navbar;
